import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UrlCR } from '@environment';
import { BehaviorSubject, Observable } from "rxjs";
import { bufferCount } from "rxjs/operators";
import jwt_decode from 'jwt-decode';
@Injectable()
export class XAuthService {

    eventAlertaSesion = new BehaviorSubject<any>(null);
    eventSesionState = new BehaviorSubject<any>(null);

    constructor(public http: HttpClient) { }

    async validarXToken(token: string) {
        const headers = {
            headers: { 'X-Authorization': `Bearer ${token}` }
        }
        const url = `${UrlCR}/auth/tokenizer/validar-token`;
        return await this.http.post(url, {}, headers).toPromise();
    }

    async generarXToken(data: any) {
        const url = `${UrlCR}/auth/tokenizer/crear-token-asesor`;
        return await this.http.post(url, { inputData: data }).toPromise();
    }

    async borrarXToken(token: string) {
        const url = `${UrlCR}/auth/tokenizer/eliminar-token`;
        return await this.http.post(url, { xtoken: token }).toPromise();
    }

    async procesarXToken(loginInfo: any) {
        const { xAuthResponse, token, tokenData } = loginInfo;
        if (xAuthResponse) {
            const { legacyToken, token } = xAuthResponse;
            const tokenInfo: any = await this.validarXToken(token);
            if (tokenInfo.response) {
                return { token: legacyToken, xtoken: token, tokenData };
            }
            throw 'INVALID X TOKEN - NO SE PUDO VALIDAR (xAuthResponse)';
        }
        const jwtData: any = jwt_decode(token);
        return { token: token, xtoken: null, tokenData: jwtData };
    }

    obMultipleSesions() {
        return {
            get: () => this.eventAlertaSesion.asObservable().pipe(bufferCount(1)),
            set: (value) => this.eventAlertaSesion.next(value)
        }
    }

    obSesionState() {
        return {
            set: (data: any) => this.eventSesionState.next(data),
            get: (): Observable<any> => this.eventSesionState.asObservable().pipe(bufferCount(1)),
        };
    }

}