import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { ActiveDescendantKeyManager, FocusMonitor, ListKeyManager } from '@angular/cdk/a11y';
import { CdkOverlayOrigin, Overlay, OverlayPositionBuilder, OverlayRef, ViewportRuler } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { SearchListItemComponent } from '../search-list-item/search-list-item.component';
import { FocusOrigin } from '@angular/cdk/a11y/focus-monitor/focus-monitor';

@Component({
    selector: 'app-search-list',
    templateUrl: './search-list.component.html',
    styleUrls: ['./search-list.component.scss'],
})
export class SearchListComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() readonly searchList: ReadonlyArray<SearchItem> = [];
    @Input() value$: Observable<string>;
    @Input() origin: CdkOverlayOrigin;
    @Input() maxHeight: number = null;

    @Input() position: 'global' | 'fromOrigin' = 'fromOrigin';
    @Input() globalWidth: string;
    @Input() globalTopPosition: string;

    @Output() selectedItem = new EventEmitter<SearchItem>();
    @ViewChild('contentList', { read: ElementRef }) contentList: ElementRef;
    @ViewChild('templateRef') templateRef;
    @ViewChildren('listItem') listItems: QueryList<SearchListItemComponent>;

    private overlayRef: OverlayRef;
    private templatePortal: TemplatePortal;
    private triggerRect: ClientRect;

    private unsubscribe$ = new Subject<void>();
    private unsubscribeOrigin$: Subject<void>;
    private keyboardEventsManager: ListKeyManager<SearchListItemComponent>;
    private currentInputFocus: FocusOrigin = null;

    loading = false;
    filteredList$ = new BehaviorSubject<SearchItem[]>([]);
    focusContentList$ = new BehaviorSubject<'focus' | null>(null);
    currentValue = '';

    private readonly openPanelStringLength = 3;

    constructor(
        private focusMonitor: FocusMonitor,
        private viewContainerRef: ViewContainerRef,
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private viewportRuler: ViewportRuler,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.initFilterList();

        this.viewportRuler
            .change()
            .pipe(takeUntil(this.unsubscribe$), debounceTime(100))
            .subscribe(() => {
                if (this.position !== 'global') {
                    this.triggerRect = this.origin.elementRef.nativeElement.getBoundingClientRect();
                    this.overlayRef.updateSize({ width: this.triggerRect.width });
                    this.changeDetectorRef.detectChanges();
                }
            });

        // Open Overlays while value length is greater than zero
        this.value$.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
            this.currentValue = value;
            if (value?.length >= this.openPanelStringLength) {
                this.openOverlay();
            } else {
                this.closeOverlay();
            }
        });
    }

    ngAfterViewInit(): void {
        this.initOverlay(this.origin);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.origin && !changes.origin.firstChange && changes.origin.currentValue !== changes.origin.previousValue) {
            if (this.position === 'fromOrigin') {
                this.initOverlay(this.origin);
            } else {
                this.initGlobalOverlay(this.globalTopPosition, this.globalWidth, this.origin);
            }
        }
    }

    private initOverlay(origin: CdkOverlayOrigin) {
        this.clearOrigin();
        const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(origin.elementRef).withPositions([
            {
                originX: 'center',
                originY: 'bottom',
                overlayX: 'center',
                overlayY: 'top',
            },
        ]);
        this.triggerRect = origin.elementRef.nativeElement.getBoundingClientRect();
        this.overlayRef = this.overlay.create({
            positionStrategy,
            width: this.triggerRect.width,
            maxHeight: this.maxHeight,
            scrollStrategy: this.overlay.scrollStrategies.close(),
        });
        this.templatePortal = new TemplatePortal(this.templateRef, this.viewContainerRef);
        this.setUpAll(origin);
    }

    private initGlobalOverlay(topPosition: string, width: string, origin: CdkOverlayOrigin) {
        this.clearOrigin();
        const positionStrategy = this.overlayPositionBuilder.global().top(topPosition);
        this.overlayRef = this.overlay.create({
            positionStrategy,
            width,
            scrollStrategy: this.overlay.scrollStrategies.block(),
        });
        this.templatePortal = new TemplatePortal(this.templateRef, this.viewContainerRef);
        this.setUpAll(origin);
    }

    setUpAll(origin: CdkOverlayOrigin) {
        const input = origin.elementRef.nativeElement.getElementsByTagName('input')[0];
        this.setUpFocusMonitor(input);
        this.setUpClickEvents(origin);
        this.setUpKeyManager(input);
    }

    setUpFocusMonitor(input: HTMLInputElement) {
        // Open Overlay while value length is greater than zero and input is focused
        combineLatest([this.value$, this.focusMonitor.monitor(input)])
            .pipe(takeUntil(this.unsubscribe$), takeUntil(this.unsubscribeOrigin$))
            .subscribe(([value, monitor]) => {
                if (value?.length >= this.openPanelStringLength && monitor !== null) {
                    this.currentInputFocus = monitor;
                    this.openOverlay();
                }
            });
    }

    setUpClickEvents(origin: CdkOverlayOrigin) {
        // Close overlay when is clicked outside input or contentList
        fromEvent(document, 'click')
            .pipe(takeUntil(this.unsubscribe$), takeUntil(this.unsubscribeOrigin$))
            .subscribe((e) => {
                if (this.overlayRef.hasAttached() && this.position !== 'global') {
                    if (!isDescendantOrSame(this.contentList.nativeElement, e.target) && !isDescendantOrSame(origin.elementRef.nativeElement, e.target)) {
                        this.closeOverlay();
                    }
                }
            });
    }

    setUpKeyManager(input: HTMLInputElement) {
        fromEvent(input, 'keydown')
            .pipe(takeUntil(this.unsubscribe$), takeUntil(this.unsubscribeOrigin$))
            .subscribe((event: any) => {
                if (this.keyboardEventsManager) {
                    if (event.code === 'ArrowDown' || event.code === 'ArrowUp') {
                        // passing the event to key manager so we get a change fired
                        this.keyboardEventsManager.onKeydown(event);
                    } else if (event.code === 'Enter' && this.keyboardEventsManager.activeItem) {
                        this.selected(this.keyboardEventsManager.activeItem.item);
                    }
                    if (event.code === 'Enter') {
                        this.closeOverlay();
                    }
                }
            });
        this.filteredList$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.keyboardEventsManager = new ActiveDescendantKeyManager(this.listItems).withWrap();
        });
    }

    contentListFocus(event: FocusEvent) {
        this.focusContentList$.next(event.type === 'focus' ? 'focus' : null);
    }

    selected(item: SearchItem) {
        this.selectedItem.emit(item);
        this.closeOverlay();
    }

    openOverlay() {
        if (!this.overlayRef.hasAttached()) {
            this.overlayRef.attach(this.templatePortal);
            this.overlayRef.updatePosition();
        }
    }

    closeOverlay() {
        if (this.overlayRef?.hasAttached()) {
            this.overlayRef.detach();
        }
    }

    initFilterList() {
        // Filtered List observable
        this.value$
            .pipe(
                startWith(''),
                tap(() => (this.loading = true)),
                filter((val) => {
                    return val?.length >= 3;
                }),
                debounceTime(300),
                tap(() => (this.loading = false)),
                map((value) =>
                    this.searchList.filter((item) => {
                      value = value.toLocaleLowerCase().replace('.', '').replace('.', '').replace('-', '');
                      return item.searchValue.toLocaleLowerCase().search(value) !== -1 || item.id.replace('-', '').toLocaleLowerCase().search(value) !== -1;
                    }),
                ),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((filteredList) => {
                this.filteredList$.next(filteredList);
            });
    }

    clearOrigin() {
        if (this.unsubscribeOrigin$) {
            this.unsubscribeOrigin$.next();
            this.unsubscribeOrigin$.complete();
            this.unsubscribeOrigin$.unsubscribe();
        }
        this.unsubscribeOrigin$ = new Subject<void>();
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    ngOnDestroy(): void {
        this.overlayRef.dispose();
        this.filteredList$.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
        this.unsubscribeOrigin$.unsubscribe();
    }
}

function isDescendantOrSame(parent: HTMLElement, child): boolean {
    if (parent === child) {
        return true;
    }
    let node = child.parentNode;
    while (node != null) {
        if (node === parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}

export interface SearchItem {
    id: string;
    searchValue: string;
}
