import { Component, forwardRef, Input, OnDestroy, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements OnInit, ControlValueAccessor, OnDestroy {

  constructor(
    @Self() private ngControl: NgControl,
  ) {
    ngControl.valueAccessor = this;
  }

  @Input() label: string;
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() idInput: string;
  @Input() name: string;
  @Input() check: boolean;

  subscriber = new Subject();

  onChange = (value: any) => {
  };
  onTouched = () => {
  };

  ngOnInit() {
    this.ngControl.control.valueChanges
      .pipe(takeUntil(
        this.subscriber,
      ))
      .subscribe(value => {
        // Check to ensure the value wasn't already set (Template driven forms)
        if (this.value === value) return;
        this.writeValue(value);
      });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
  }

  ngOnDestroy(): void {
    this.subscriber.next();
    this.subscriber.complete();
    this.subscriber.unsubscribe();
  }

}
