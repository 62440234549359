import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonSize, ButtonStyle } from '../button-sura/button-sura.component';

export type OptionsMessage = {
  titleMessage?: string,
  message?: string,
  showButton?: boolean,
  icon?: string,
  showIcon?: boolean,
  textButton?: string,
  sizeButton?: ButtonSize,
  buttonStyle?: ButtonStyle
};

@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss'],
})
export class DialogMessageComponent {
  optionsMessage: OptionsMessage;

  constructor(
    public dialogRef: MatDialogRef<DialogMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      const { optionsMessage } = data;
      this.optionsMessage = optionsMessage;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
