import { ClientRequestService } from '../../../../services/client-request/client-request.service';
import { ClientSearchResponseModel } from '../../../../models/client-search/client-search.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ClientService } from '../../../../services/client/client.service';
import { Observable, Subject } from 'rxjs';
import { DataClientModel, Documentation, PasswordStatus, Product, Rutero } from '../../../../models/client-summary/data-client.model';
import { takeUntil } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { GtmService } from '../../../../../shared/services/gtm/gtm.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IProductsCount } from '../../../../../../app/features/models';
import { ExecutiveService } from '../../../../../shared/services/executive/executive.service';
import { AppService, ContentfulService } from '../../../../../shared/services';
import { environment } from '@environment';
import * as utils from '../../../../../shared/helpers/utils';
import { ClientPortfolioService } from './../../../../services/client-portfolio/client-portfolio.service';
import { SurapassService } from 'src/app/features/services/surapass/surapass.service';
import { AliasInputLogType, InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import * as moment from 'moment';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { MatDialog } from '@angular/material/dialog';
import { IIndicadoresCliente } from '../../../../../shared/services/indicadores/indicador-cliente.interface';
import { LoginService } from 'src/app/features/services';

@Component({
    selector: 'app-data-client',
    templateUrl: './data-client.component.html',
    styleUrls: ['./data-client.component.scss'],
})
export class DataClientComponent implements OnInit, OnDestroy {
    @Input() productsDataEvent: IProductsCount;
    @Output() clientProducts = new EventEmitter<object>();
    @Input() bloqueoOptimus = false;

    //determina si posee un dispositivo SURAPass activo parte no definido hasta que el servicio proporcione el estado
    appMovilSuraPassActivo?: string = undefined;

    clientData: ClientSearchResponseModel;
    dataClient$: Observable<DataClientModel>;
    show = false;
    listDocumentation: Documentation[] = [];
    listStatusPassword: PasswordStatus[] = [];
    listProducts: Product[] = [];
    private unsubscribe$ = new Subject<void>();
    isSignedMandate: boolean;
    isStateNoMandato: boolean;
    fichaCliente:boolean;
    messageContent: string = 'Cliente no desea firmar el <strong>Mandato AFP</strong>';
    showPasswordSection: boolean = true;
    showCartolaSection: boolean = false;
    showProfileSection: boolean = true;
    showButtonProfile: boolean = true;
    isSupervisor: boolean = false;
    isBoss: boolean = false;
    supervisorName: string = '';
    profileName: string = '';
    paymentPlan: any;
    clientOptions: any;
    listProductsExists: Product[] = [];
    showPaymentPlans = true;
    showDocument = [
        { key: 'ticketClient', ruteroName: 'DOCUMENT_TICKETCLIENT', show: false },
        { key: 'ccg', ruteroName: 'DOCUMENT_CCG', show: false },
        { key: 'planApv', ruteroName: 'DOCUMENT_PLANAPV', show: false },
        { key: 'mandateAfp', ruteroName: '', show: true },
    ];
    showDocumentSection: boolean = true;
    isCompany: boolean = false;
    circularizacion: any = {};
    @Input() pension: any;

    /** Usado para el parche https://surati.atlassian.net/browse/NCES-3083 */
    desBtnSitioCliente = false;

    constructor(
        private clientService: ClientService,
        private breakPointObserver: BreakpointObserver,
        private clientRequest: ClientRequestService,
        private gtmService: GtmService,
        private router: Router,
        private activateRouter: ActivatedRoute,
        private executiveService: ExecutiveService,
        private contentfulService: ContentfulService,
        private clientPortfolioService: ClientPortfolioService,
        private surapassService: SurapassService,
        private tracesService: TracesService,
        public dialog: MatDialog,
        private loginService: LoginService,
        private appService: AppService
    ) {
        breakPointObserver
            .observe('(max-width: 768px)')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.show = !val.matches;
            });
        this.setCircularizacionData('', '', '', '', '', '');
    }

    get indicadoresCliente(){
        return JSON.parse(sessionStorage.getItem('indicadoresCliente') || '{}');
    }
    
    async ngOnInit() {
        this.showButtonProfile = this.executiveService.executiveModel.executiveProfile.allowTransaction;
        await this.getDocumentVisibility();
        this.dataClient$ = this.clientService.getDataClient();
        this.dataClient$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {

            // Listado de Productos: si la lista de productos tiene AFP y no tiene el mandato firmado entonces debo sacar el producto AFP
            // Listado de Documentacion: si la lista de documentacion no tiene AFP entonces no debo mostrar el mandato AFP firmado
            if (data) {
                this.getSURAPass(data.rut);

                sessionStorage.setItem('clientData', JSON.stringify(data));
                this.getExecutivesProfiles(data.rut);
                this.isSignedMandate = data.documentation.filter((doc) => doc.key === 'mandateAfp')[0].isOkey;
                this.isStateNoMandato = data.documentation.filter((doc) => doc.key === 'mandateAfp')[0].isStateNoMandato;

                if (!this.isSignedMandate) {
                    this.listProducts = data.products.filter((prod) => prod.displayName !== 'AFP');
                } else {
                    this.listProducts = data.products;
                }
                const listDocumentationOfProducts = data.products.filter((prod) => prod.exists === true).map((e) => e.displayName);
                if (listDocumentationOfProducts?.includes('AFP')) {
                    this.listDocumentation = data.documentation;
                    if (!this.isStateNoMandato) {
                        this.listDocumentation = this.listDocumentation.filter((doc) => doc.key !== 'mandateAfp');
                    }
                } else {
                    this.listProducts = data.products.filter((prod) => prod.displayName !== 'AFP');
                    this.listDocumentation = data.documentation.filter((doc) => doc.key !== 'mandateAfp');
                }

                const list = listDocumentationOfProducts?.filter((prod) => prod !== 'AFP' && prod !== 'Vida');

                if (list?.length === 0) {
                    this.listDocumentation = this.listDocumentation.filter((doc) => doc.key !== 'planApv');
                }
                this.showDocumentoSection();

                this.listStatusPassword = data.statusPaswword.filter((pass) => pass.codigoEstadoClave > 0 && pass.codigoEstadoClave < 4);
                this.documentationOfProducts(this.listStatusPassword);
                this.clientOptions = data.datosPortafolio;
                this.listProductsExists = data.products.filter((prod) => prod.exists === true);
                this.getPaymentPlans();
                this.loadCircularizacion(data.rut);
                this.fichaCliente = data.documentation.filter((doc) => doc.key === 'ticketClient')[0].isOkey;
            }
        });
    }

    /**
     * Consulta estado dispositivo SURAPass segun rut de cliente consultado
     * @param rut rut del cliente formado numero separador guion y verificador
     */
    async getSURAPass(rut: string) {
        if (this.appMovilSuraPassActivo === undefined) {
            const rutlimpio = rut.replace('.', '').replace('.', '').replace(',', '').replace(',', '');
            this.appMovilSuraPassActivo = 'NOACTIVO';
            this.surapassService
                .getDispositivoActivo(rutlimpio)
                .then((estado) => {
                    if (estado.codigo === 0) {
                        if (estado.dispositivo && estado.dispositivo.length > 0) {
                            this.appMovilSuraPassActivo = estado.dispositivo[0].estadoDispositivo;
                        }
                    }
                })
                .catch((err) => {
                    this.appMovilSuraPassActivo = 'NOACTIVO';
                });
        }
    }

    documentationOfProducts(listStatusPassword: any) {
        listStatusPassword.forEach((movement: PasswordStatus) => {
            if (movement.codigoEstadoClave == 1) {
                movement.colorStausIcon = 'orange'; // provisoria
                movement.typeStatusIcon = 'watch_later';
            }
            if (movement.codigoEstadoClave == 2) {
                movement.colorStausIcon = 'red'; // bloqueada
                movement.typeStatusIcon = 'priority_high';
            }
            if (movement.codigoEstadoClave == 3) {
                movement.colorStausIcon = 'green'; // vigente
                movement.typeStatusIcon = 'done';
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.showCartolaSection = !!(this.productsDataEvent.balancesAPVQty + this.productsDataEvent.balancesNOAPVQty);
    }

    async goSiteClient() {
        if(!this.desBtnSitioCliente){
            this.desBtnSitioCliente = true;
            const clientData = this.clientRequest.clientData.value;
            const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
                Categoria: 'Resumen Cliente',
                Operacion: 'Resumen Cliente',
                Metodo: 'goSiteClient()',
                eventoLog: 'click boton Ir al Sitio Cliente',
                status: Status.OK,
                Secuencia: 0,
                EntradaLog: {
                    accion: 'click boton Ir al Sitio Cliente redireccionar',
                },
            };
            if (this.executiveService.executiveModel.executiveProfile.ownCustomers) {
                logtemp.Categoria = 'Cartera Clientes';
            }
            this.logData(logtemp, 'log_negocio');
    
            const rut =  `${clientData.RutCliente}-${clientData.DigitoVerificador}`
            if(!await this.loginService.puedeConsultarValor(rut, 'cliente')){
                this.appService.alertaMensaje("Aceptar", "Notificación", "No tiene permisos para consultar el rut : " + rut);
                return;
            }
    
            await this.clientService.goToSiteClient(
                {
                    rut: `${clientData.RutCliente}-${clientData.DigitoVerificador}`,
                    nombre: clientData.NombreCliente,
                    apellidoPaterno: clientData.NombreCliente.split(' ')[0],
                    apellidoMaterno: clientData.NombreCliente.split(' ')[1],
                },
                '',
            );
            this.gtmService.pushTagEventoInteractivo('evento-interactivo', 'Resumen Cliente', 'Click', 'Ir al home sitio del cliente');
            this.desBtnSitioCliente = false;
        }
    }

    async goSiteClientViewProfile() {
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: 'Resumen Cliente',
            Operacion: 'Resumen Cliente',
            Metodo: 'goSiteClientViewProfile()',
            eventoLog: 'click boton Ver Perfil Completo',
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton Ver Perfil Completo redireccionar',
            },
        };
        this.logData(logtemp, 'log_negocio');
        await this.router.navigate(['profile-resume'], {
            relativeTo: this.activateRouter,
        });
    }

    async goSiteClientViewDocuments() {
        const clientData = this.clientRequest.clientData.value;
        await this.clientService.goToSiteClient(
            {
                rut: `${clientData.RutCliente}-${clientData.DigitoVerificador}`,
                nombre: clientData.NombreCliente,
                apellidoPaterno: clientData.NombreCliente.split(' ')[0],
                apellidoMaterno: clientData.NombreCliente.split(' ')[1],
            },
            'documentos',
        );
        this.gtmService.pushTagEventoInteractivo('evento-interactivo', 'Resumen Cliente', 'Click', 'Ir a los documentos del sitio del cliente');
    }

    toggleDiv() {
        this.show = !this.show;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
    }

    async goToPassword() {
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: 'Resumen Cliente',
            Operacion: 'Resumen Cliente',
            Metodo: 'goToPassword()',
            eventoLog: 'click boton Actualizar Claves',
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton Actualizar Claves redireccionar',
            },
        };
        this.logData(logtemp, 'log_negocio');
        await this.router.navigate(['password'], {
            relativeTo: this.activateRouter,
        });
    }

    async goToChangeProfile() {
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: 'Resumen Cliente',
            Operacion: 'Resumen Cliente',
            Metodo: 'goToChangeProfile()',
            eventoLog: 'click boton Modificar Perfil',
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton Modificar Perfil redireccionar',
            },
        };
        this.logData(logtemp, 'log_negocio');
        await this.router.navigate(['change-profile'], {
            relativeTo: this.activateRouter,
        });
    }

    async getPaymentPlans() {
        if (this.listProductsExists.length === 1) {
            if (this.listProductsExists[0].displayName === 'Vida' || this.listProductsExists[0].displayName === 'AFP') {
                this.showPaymentPlans = false;
            }
        }

        const paymentPlans = await this.contentfulService.getEntryFromFront(environment.KEYS.CONTENTFUL.PAYMENT_PLAN);

        let option: string;

        if (this.clientOptions) {
            for (const item of this.clientOptions) {
                if (item.CodigoPortafolio === '0') {
                    option = `opcion${item.IdFormaCobro.charAt(item.IdFormaCobro.length - 1)}`;
                    break;
                }
            }
            if (option) this.paymentPlan = paymentPlans[option];
        }
    }

    async getDocumentVisibility() {
        let rut: string;
        let promises = [];

        this.activateRouter.params.subscribe((parameter) => {
            rut = parameter.rut;
            this.isCompany = utils.isCompany(rut);
        });

        this.showDocument.forEach(async (document) => {
            if (document.key !== 'mandateAfp') {
                promises.push({ key: document.key, req: this.searchRutListByKey(document.key, document.ruteroName, rut) });
            }
        });
        await Promise.all(promises);

        this.hideMandateStateDocument(this.isCompany);
    }

    showStateDocument(docKey: string): boolean {
        const document = this.showDocument.filter((doc) => doc.key === docKey)[0];
        if (docKey.trim().toLowerCase() === 'mandateafp' && !this.isCompany) return true;
        return document?.show;
    }

    hideMandateStateDocument(isCompany: boolean) {
        if (isCompany) {
            //mmb cambio forzado por sonar por error no de no setear
            this.showDocument = this.showDocument.map((document) => {
                if (document.key === 'mandateAfp' || document.key === 'planApv') document.show = false;
                return document;
            });
        }
    }

    showDocumentoSection() {
        let available = 0;
        const listVisibleDocs = this.showDocument.filter((doc) => doc.show).map((doc) => doc.key);
        this.listDocumentation.forEach((prod: any) => {
            if (listVisibleDocs.includes(prod.key)) available++;
        });
        this.showDocumentSection = available > 0 ? true : false;
    }

    getSupervisorName(rut: string) {
        rut = rut.split('.').join('');
        this.clientPortfolioService.supervisorsData.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
            if (!data) data = this.clientPortfolioService.setSupervisorsData(null);
            this.supervisorName = data.filter((client) => client.RutCliente === rut)[0]?.NombreSupervisor;
        });
    }

    getExecutivesProfiles(rut: string) {
        this.isSupervisor = this.executiveService.executiveModel.executiveProfile.isSupervisor;
        this.isBoss = this.executiveService.executiveModel.executiveProfile.isBoss;
        if (this.isBoss || this.isSupervisor) this.getSupervisorName(rut);
    }

    async searchRutListByKey(key: string, name: string, rut: string): Promise<string[]> {
        try {
            const ruteroPromises = [];
            let response: string[] = [];

            if (key !== 'mandateAfp') {
                const keyList: string[] = environment.KEYS.CMSRUTERO[name];

                keyList.forEach((element) => {
                    ruteroPromises.push(this.clientRequest.getRutero(element));
                });
            }

            const ruteroDocumentsData = await Promise.allSettled(ruteroPromises);

            ruteroDocumentsData.forEach((res, index) => {
                if (res.status === 'fulfilled') {
                    const resData = res.value as Rutero[];
                    if (resData[0].active) response = [...response, ...resData[0].ruteroArr];
                } else if (res.status === 'rejected') {
                    console.warn(`rutero ${name} error response`);
                }
            });

            let show = response.includes(rut);

            this.showDocument = this.showDocument.map((document) => {
                if (document.key === key) document.show = show;
                return document;
            });

            return response;
        } catch (error) {
            return [];
        }
    }

    async logData(log: Partial<InputLog> & Pick<InputLog, AliasInputLogType>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...EntradaLog,
            };
            inputLog.Salida = '-';
            inputLog.Secuencia = 0;
            inputLog.Servicio = '';
            inputLog.Tipo_sesion = 'ASESOR';
            inputLog.extra = {};

            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, 'NWSA_ASESOR', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }

    async loadCircularizacion(rut: string) {
        this.circularizacion.visible = false;
        const formattedRut = rut.replace(/\./g, '');
        const contentfulVisibility = await this.getCircularizacionContentfulVisibility();

        if (contentfulVisibility) {
            const ruteroVisibility = await this.getCircularizacionRuteroVisibility(formattedRut);
            if (ruteroVisibility) {
                this.circularizacion.visible = true;
            }
        }

        if (this.circularizacion.visible) {
            const serviceData = await this.clientRequest.getCircularizacion(formattedRut);
            const serviceResult = serviceData.ObtenerCircularizacionPorRutResult;

            if (serviceResult === '') {
                this.setCircularizacionData('Pendiente', '', 'orange', 'priority_high', '', 'pendiente');
            } else {
                let fecha = this.getFormattedDate(serviceResult.CircularizacionBE?.fecha);
                switch (serviceResult.CircularizacionBE?.estado) {
                    case 'A':
                        this.setCircularizacionData('Firmado', `Firmado el ${fecha}.`, 'green', 'done', '', '');
                        break;
                    case 'R':
                        const motivo = serviceResult.CircularizacionBE?.Motivo !== '' ? `Motivo: ${serviceResult.CircularizacionBE?.Motivo}.` : '';
                        this.setCircularizacionData('Rechazado', `Rechazado el ${fecha}. ${motivo}`, 'red', 'priority_high', 'absolute', '');
                        break;
                    default:
                        this.setCircularizacionData('Sin datos', '', 'orange', 'priority_high', '', '');
                        break;
                }
            }
        } else {
            this.setCircularizacionData('', '', '', '', '', '');
        }
    }

    setCircularizacionData(state: string, detail: string, iconClass: string, iconName: string, classAbsolute: string, classPendiente: string) {
        this.circularizacion.state = state;
        this.circularizacion.detail = detail;
        this.circularizacion.icon = { class: iconClass, name: iconName };
        this.circularizacion.classAbsolute = classAbsolute;
        this.circularizacion.classPendiente = classPendiente;
    }

    async getCircularizacionContentfulVisibility() {
        const entryID = environment.KEYS.CONTENTFUL.CIRCULARIZACION;
        const dataContent = await this.contentfulService.getEntryFromFront(entryID);
        const visible = dataContent.enabled && dataContent.environmentList.environments.includes(environment.name);
        return visible;
    }

    async getCircularizacionRuteroVisibility(rut: string) {
        const ruteroID = environment.KEYS.CMSRUTERO.CIRCULARIZACION;
        const dataRutero = await this.clientRequest.getRutero(ruteroID);
        const included = dataRutero[0].ruteroArr.includes(rut);
        return included;
    }

    getFormattedDate(date: string) {
        let auxDate = date.split('T');
        auxDate = auxDate[0].split('-');
        return `${auxDate[2]}/${auxDate[1]}/${auxDate[0]}`;
    }
}
