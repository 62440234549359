import { Injectable } from '@angular/core';
import { ClientRequestService } from '../../../features/services/client-request/client-request.service';
import * as rutHelpers from 'rut-helpers';
import { ClientSearchResponseModel, DatosProductoBELModel } from '../../../features/models/client-search/client-search.model';
import { ClientSignedMandate } from '../../../features/models/client-summary/client-data.model';
import { ClientService, UserMessage } from '../../../features/services/client/client.service';
import { Router } from '@angular/router';
import { ExecutiveService } from '../executive/executive.service';
import { LoadingService } from '../loading/loading.service';
import { DialogService } from '../dialog/dialog.service';
import { DialogMessageComponent, OptionsMessage } from '../../components/dialog-message/dialog-message.component';
import { environment } from '@environment';
import { BehaviorSubject } from 'rxjs';
import { RuteroCMS } from '../../models/cms.model';
import { MatDialogRef } from '@angular/material/dialog';

export class ClientSearchModel {
    dni?: string;
    dataClient?: ClientSearchResponseModel;
    dataSigned?: ClientSignedMandate;
}

@Injectable({
    providedIn: 'root',
})
export class ClientSearchService {
    clientsAFG: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    modalClientesAFG: MatDialogRef<DialogMessageComponent>;

    constructor(
        private clientRequestService: ClientRequestService,
        private clientService: ClientService,
        private router: Router,
        private executiveService: ExecutiveService,
        private loaderService: LoadingService,
        private dialogService: DialogService,
    ) {}

    /**
     * Set DNI whit @rutHelpers Lib
     * Return String
     */
    setRut(dni: string): string {
        const rutDv = rutHelpers.rutClean(dni);
        const rut = rutDv?.substring(0, rutDv.length - 1);
        const dv = rutDv?.substring(rutDv.length - 1, rutDv.length);

        if (rut && dv) {
            return `${rut}-${dv}`;
        }
        return null;
    }

    validarSegmento(dataClient: ClientSearchResponseModel): boolean {
        return dataClient.CodigoSegmento > 0 || (dataClient.CodigoSegmento == 0 && dataClient.RutCliente.length > 0);
    }
    /**
     * Rut as string | value
     */
    public async clientSearch(value: string): Promise<ClientSearchModel> {
        let mensaje: string; 
        return new Promise<ClientSearchModel>(async (resolve, reject) => {
            sessionStorage.removeItem("clientData");
            const dni = this.setRut(value);
            try {
                const [dataSigned, dataClient] = await this.clientRequestService.getClientLoadData({ rut: dni });
                if (this.validarSegmento(dataClient) && dataClient.EstadoCliente === "V") {
                    const listAFP: DatosProductoBELModel[] = dataClient.DatosProductoGeneral.DatosProductoBEL.filter((item) => {
                        return item.DescripcionLinea === 'AFP' || item.DescripcionLinea === 'COL';
                    });
                    if (listAFP.length === dataClient.DatosProductoGeneral.DatosProductoBEL.length && dataSigned.mandatoSuscrito === false) {
                        resolve({ dataSigned: null });
                    }
                    resolve({ dni, dataSigned, dataClient });
                } else {
                    reject({
                        code: 'notFound',
                        message: `Lo sentimos, no hemos encontrado un resultado para <br><b>${dni}</b><br> Inténtalo nuevamente`,
                    });
                }
            } catch (e) {
                if (e.status === 403) {
                    const canSearchResultMessage: UserMessage = {
                        isError: true,
                        useMessage: 'El cliente que estás buscando no es parte de tu cartera.',
                        userMessageType: 'info',
                    };
                    this.clientService.searchResult.next(canSearchResultMessage);
                    const typeExecutive = this.executiveService.executiveModel;
                    if (typeExecutive.executiveProfile.ownCustomers) {
                        await this.router.navigate(['/main/client-portfolio']);
                        const optionsMessage: OptionsMessage = {
                            textButton: 'Entendido',
                            buttonStyle: 'outline-grey',
                            showButton: true,
                            sizeButton: 'lg',
                            icon: 'assets/img/icono-escudo-info.svg',
                            showIcon: true,
                            titleMessage: 'Importante.',
                            message: 'El cliente que estás buscando no es parte de tu cartera.',
                        };
                        const config = {
                            panelClass: 'modal-md',
                            disableClose: true,
                            data: {
                                optionsMessage,
                            },
                        };
                        this.dialogService.openDialog(DialogMessageComponent, config);
                    } else {
                        await this.router.navigate(['/main/client-search']);
                    }
                }
                this.loaderService.hideLoading();
            }
        });
    }

    /**
     * Valida que un ejecutivo no pueda buscar mas de un cliente
     * en el sitio de asesor
     */
    public validIsSearchedClient(dni): boolean {
        return dni === sessionStorage.getItem('searchedClient');
    }

    public async getClientsAGF() {
        const clientList = await this.clientRequestService.getRutero(environment.KEYS.CMSRUTERO.CLIENT_AGF);
        this.clientsAFG.next(clientList[0]);
    }

    public async validateClientAGF(rut: string, clientList: any, openModal?: boolean): Promise<boolean> {
        const isAdmin = this.executiveService.executiveModel.executiveProfile.isAdmin;
        if (isAdmin) return false;

        if (!clientList) {
            await this.getClientsAGF();
            this.clientsAFG.subscribe((data: RuteroCMS) => (clientList = data));
        }

        if (clientList?.active && clientList?.ruteroArr.includes(rut)) {
            openModal = openModal ?? true;
            if (openModal) {
                this.closePreviousDialog();
                const optionsMessage: OptionsMessage = {
                    textButton: 'ENTENDIDO',
                    buttonStyle: 'outline-grey',
                    showButton: true,
                    sizeButton: 'lg',
                    icon: 'assets/img/icono-escudo-info.svg',
                    showIcon: true,
                    titleMessage: 'Cliente con saldos AGF.',
                    message: 'Por lo tanto no va a poder operar en el sitio privado, únicamente consultar sus saldos.',
                };
                const config = {
                    panelClass: 'modal-md',
                    disableClose: true,
                    data: {
                        optionsMessage,
                    },
                };
                this.modalClientesAFG = this.dialogService.openDialog(DialogMessageComponent, config);
            }
            return true;
        }
        return false;
    }

    private closePreviousDialog() {
        if (!this.modalClientesAFG) return;
        this.modalClientesAFG.close();
    }
}
