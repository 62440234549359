import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PershingInstrumentoTipo, SaldoCustodioCtaPershingJson } from 'src/app/features/models/ordenes/pershing/orden.modelo';
import { OrdenesPershingService } from 'src/app/features/services/ordenes/pershing/ordenes.pershing.service';
import { joinFiltersData } from 'src/app/shared/helpers/utils';
@Component({
    selector: 'app-modal-filtro-instrumentos',
    templateUrl: './modal-filtro-instrumentos.component.html',
    styleUrls: ['./modal-filtro-instrumentos.component.scss'],
})
export class ModalFiltroInstrumentosComponent implements OnInit {
    tipoCategoria: PershingInstrumentoTipo[];
    tipoManager: PershingInstrumentoTipo[];
    tipoRecomendacion: PershingInstrumentoTipo[];

    //formulario
    filtroForm: FormGroup;

    //preseteado
    fcategoria: string;
    fmanager: string;
    frecomendacion: string;

    public categoria = new FormControl('', null); //[Validators.required]);
    public manager = new FormControl('', null); //[Validators.required]);
    public recomendacion = new FormControl('', null); //[Validators.required]);

    constructor(private dialogRef: MatDialogRef<ModalFiltroInstrumentosComponent>, private readonly ordenesService: OrdenesPershingService, private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data) {
            this.fcategoria = data.categoria ? data.categoria : '';
            this.fmanager = data.manager ? data.manager : '';
            this.frecomendacion = data.recomendacion ? data.recomendacion : '';
        } else {
            this.fcategoria = '';
            this.fmanager = '';
            this.frecomendacion = '';
        }
    }

    ngOnInit() {
        let cuentaseleccionada = sessionStorage.getItem(this.ordenesService.STORAGE_PERSHING_CUENTA_SELECCIONADA);
        let cuenta: SaldoCustodioCtaPershingJson = JSON.parse(cuentaseleccionada) || [];

        //LOG DE ACCION
        this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 1', 'ModalFiltroInstrumentosComponent - ngOnInit()', 'ok', `Filstrando instrumentos cambio de fondo destino`);

        //inicializa formulario
        this.inicializaFormulario();

        //consulta tipo categorias
        this.ordenesService
            .GetInstrumentoTipo(cuenta['cuenta-preferente'] ? 'P': 'N', 'CATEGORIA')
            .then((data) => {
                this.tipoCategoria = <PershingInstrumentoTipo[]>data['tipo-instrumentos'] || [];
                this.ordenesService
                .GetInstrumentoTipo('M', 'CATEGORIA')
                .then((data2) => {
                    const tipoCategoriaMixto = <PershingInstrumentoTipo[]>data2['tipo-instrumentos'] || [];
                    this.tipoCategoria = joinFiltersData(this.tipoCategoria, tipoCategoriaMixto);
                }).finally(() => { 
                    const seleccione: PershingInstrumentoTipo = {
                        codigo: '',
                        descripcion: '-- Seleccione --',
                        seleccionado: false,
                    };
                    if (this.fcategoria && this.fcategoria != '') {
                        this.tipoCategoria.forEach((item) => {
                            item.seleccionado = item.codigo === this.fcategoria && this.fcategoria !== '' ? true : false;
                        });
                    } else {
                        seleccione.seleccionado = true;
                    }
                    this.tipoCategoria.unshift(seleccione);
                });
                
            })
            .finally(() => {
              
            });

        //consulta tipo manager
        this.ordenesService
            .GetInstrumentoTipo(cuenta['cuenta-preferente']? 'P': 'N', 'MANAGER')
            .then((data) => {
                this.tipoManager = <PershingInstrumentoTipo[]>data['tipo-instrumentos'] || [];
                
                this.ordenesService
                .GetInstrumentoTipo('M', 'MANAGER')
                .then((data2) => {
                    const tipoManagerMixto = <PershingInstrumentoTipo[]>data2['tipo-instrumentos'] || [];
                    this.tipoManager = joinFiltersData(this.tipoManager, tipoManagerMixto);
                }).finally(() => {
                    const seleccione: PershingInstrumentoTipo = {
                        codigo: '',
                        descripcion: '-- Seleccione --',
                        seleccionado: false,
                    };
                    if (this.fmanager && this.fmanager != '') {
                        this.tipoManager.forEach((item) => {
                            item.seleccionado = item.codigo === this.fmanager ? true : false;
                        });
                    } else {
                        seleccione.seleccionado = true;
                    }
                    this.tipoManager.unshift(seleccione);
                });
            })
            .finally(() => {
               
            });

        //consulta tipo categorias
        this.ordenesService
            .GetInstrumentoTipo(cuenta['cuenta-preferente']? 'P': 'N', 'RECOMENDACION')
            .then((data) => {
                this.tipoRecomendacion = <PershingInstrumentoTipo[]>data['tipo-instrumentos'] || [];
             
                this.ordenesService
                .GetInstrumentoTipo('M', 'RECOMENDACION')
                .then((data2) => {
                    const tipoRecomendacionMixto = <PershingInstrumentoTipo[]>data['tipo-instrumentos'] || [];
                    this.tipoRecomendacion = joinFiltersData(this.tipoRecomendacion, tipoRecomendacionMixto);
                }).finally(() => {
                    const seleccione: PershingInstrumentoTipo = {
                        codigo: '',
                        descripcion: '-- Seleccione --',
                        seleccionado: false,
                    };
                    if (this.frecomendacion && this.frecomendacion != '') {
                        this.tipoRecomendacion.forEach((item) => {
                            item.seleccionado = item.codigo === this.frecomendacion ? true : false;
                        });
                    } else {
                        seleccione.seleccionado = true;
                    }
                    this.tipoRecomendacion.unshift(seleccione);
                });
            })
            .finally(() => {
             
            });
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    /**
     * Limpiar el fomrulario
     */
    filtrarLimpiar() {
        //LOG DE ACCION
        this.ordenesService.saveLogAccionAsesor(
            'Ingreso Ordenes Pershing Off Line',
            'Ingreso Ord Pershing Off Line Paso 1',
            'ModalFiltroInstrumentosComponent - filtrarInstrumentos()',
            'ok',
            `Limpiando Filtro instrumentos cambio de fondo destino`,
        );

        this.filtroForm.setValue({
            categoria: '',
            manager: '',
            recomendacion: '',
        });
    }

    /**
     * Setea el filtro de informacion
     */
    filtrarInstrumentos() {
        //LOG DE ACCION
        this.ordenesService.saveLogAccionAsesor(
            'Ingreso Ordenes Pershing Off Line',
            'Ingreso Ord Pershing Off Line Paso 1',
            'ModalFiltroInstrumentosComponent - filtrarInstrumentos()',
            'ok',
            `Aplicando Filtro instrumentos cambio de fondo destino`,
        );

        this.dialogRef.close({
            aplicar: true,
            categoria: this.filtroForm.get('categoria').value,
            manager: this.filtroForm.get('manager').value,
            recomendacion: this.filtroForm.get('recomendacion').value,
        });
    }

    /**
     * Inicializa el formulario
     */
    inicializaFormulario() {
        this.filtroForm = this.fb.group({
            categoria: this.categoria,
            manager: this.manager,
            recomendacion: this.recomendacion,
        });

        this.filtroForm.setValue({
            categoria: this.fcategoria != undefined ? this.fcategoria : '',
            manager: this.fmanager != undefined ? this.fmanager : '',
            recomendacion: this.frecomendacion != undefined ? this.frecomendacion : '',
        });
    }

    removeDuplicates(arr, id) {
        const map = new Map();
        return arr.filter(item => {
            if (!map.has(item[id])) {
                map.set(item[id], true);
                return true;
            }
            return false;
        });
    }
}
