import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrdenCarroComponent } from './orden-carro.component';
import { OrdenCarroComponentRoutingModule } from './orden-carro-routing.module';
import { DetalleOrdenesIngresadasComponent } from './components/detalle-ordenes-ingresadas/detalle-ordenes-ingresadas.component';
import { ConfirmaModalComponentDivisas } from './components/confirma-modal/confirma-modal.component';
import { UploadArchivoRespaldoComponent } from './components/upload-archivo-respaldo/upload-archivo-respaldo.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    OrdenCarroComponent,
    DetalleOrdenesIngresadasComponent,
    ConfirmaModalComponentDivisas,
    UploadArchivoRespaldoComponent
  ],
  imports: [
    OrdenCarroComponentRoutingModule,
    CommonModule,
    SharedModule,
    ReactiveFormsModule
  ],
  providers: [
    DatePipe
  ]
})
export class OrdenCarroModuleDivisas { }
