import { formatDate, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Component, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { ClienteActivoDto, MensajeBackend, ModalGenericoOrdenes, SolicitudCorredoraJson, UploadContentManagerJSON } from 'src/app/features/models/ordenes/corredora/orden.modelo';
import { SURAPassAPIPagoCorredoraJSON, SURAPassTransaccionCampoJson, SURAPassTransaccionDetalleJson, SURAPassTransaccionGrupoJson, SURAPassTransaccionJson } from 'src/app/features/models/surapass/surapass.modelo';
import { OrdenesCorredoraService } from 'src/app/features/services/ordenes/corredora/ordenes.corredora.service';
import { SurapassService } from 'src/app/features/services/surapass/surapass.service';
import { DialogService, LoadingService } from 'src/app/shared/services';
import { ConfirmaModalComponentCorredora } from './components/confirma-modal/confirma-modal.component';
import { SessionStorageService } from 'src/app/features/services/session-storage/session-storage.service';
registerLocaleData(es, 'es');

@Component({
    selector: 'app-orden-carro',
    templateUrl: './orden-carro.component.html',
    styleUrls: ['./orden-carro.component.scss'],
    providers: [{ provide: LOCALE_ID, useValue: 'es-*' }],
})
export class OrdenCarroComponent implements OnInit, OnDestroy {
    //CLIENTE CONECTADO
    public clienteconectado: ClienteActivoDto;
    public surapassconfig: any; //configuraciones de SURAPass

    public MENSAJE_NEGOCIO_ALERTA_TIPO: string = 'INFORMACION';
    public MENSAJE_NEGOCIO_ALERTA_TITULO: string = 'Atención';
    public MENSAJE_NEGOCIO_ALERTA_MENSAJE: string = 'Esta transacción esta sujeta a confirmación de la mesa corredora, las cuales serán procesadas hasta 15 minutos antes del cierre bursátil, después de dicho horario serán procesadas en el día hábil siguiente.';


    //TEXTOS
    public TEXTO_TIT_PENDIENTE: string = 'Transacción pendiente:';
    public TEXTO_TIT_RECHAZADA: string = 'Transacción rechazada:';
    public TEXTO_TIT_EXPIRADA: string = 'Transacción expirada:';
    public TEXTO_TIT_EXCEDE_INTENTOS: string = 'Transacción bloqueada:';

    public TEXTO_MSG_PENDIENTE: string = 'Estamos esperando la autorización o rechazo de la transacción.';
    public TEXTO_MSG_RECHAZADA: string = 'Se ha rechazado la autorización. Te recomendamos contactar al cliente para tener más antecedentes del motivo de su rechazo.';
    public TEXTO_MSG_EXPIRADA: string = 'La transacción supero el máximo de tiempo de espera para ser autorizada, debera ser ingresada nuevamente.';
    public TEXTO_MSG_EXCEDE_INTENTOS: string = 'Se ha excedido la cantidad máxima de intentos de autorización. Por seguridad se bloqueado la clave SURA Pass del cliente. Para poder desbloquearla deberá hacer el proceso de enrolamiento nuevamente.';
    

    //TIEMPO EN SEGUNDOS
    private SEGUNDOS_REVISION_MULTIPASS: number = 5; //5 SEGUNDOS
    private SURAPASS_APLICACION_ID: number = 2001;
    private SURAPASS_APLICACION_APP: string = 'NEW-WEB-ASESOR-ORD-CORREDORA-' + environment.name;

    //INDICADORES
    clienteTieneMultipassActivo: boolean = false;
    esperandoMultipass: boolean = false;
    hayarchivoUploded: boolean = false;
    expandirAutorizaSURAPass: boolean;
    expandirAutorizaUpload: boolean;

    //ARCHIVO
    responseFileData: any = null;
    messageAlertFile: boolean;
    data: any;

    //transaccion SURAPAss
    transaccion: SURAPassTransaccionJson;

    //WIZZARD
    linea = {
        steps: [],
        currentStep: null,
    };

    mensaje: MensajeBackend = {
        messageContent: '',
        messageType: 'error',
        messageTitle: 'Algo salio mal',
        showMessage: false,
    };

    idintervalo: any;

    //solicitud
    solicitud: SolicitudCorredoraJson;

    constructor(private router: Router, private loadingService: LoadingService, private dialogService: DialogService, private corredoraService: OrdenesCorredoraService, private surapassService: SurapassService, private sessionStorageService: SessionStorageService) {
        this.loadingService.showLoading();
    }

    /**
     * Destruye la ejecucion
     */
    ngOnDestroy(): void {
        if (this.idintervalo) {
            clearInterval(this.idintervalo);
            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
        }
    }

    /**
     * Inicializa el TS
     */
    ngOnInit(): void {
        this.clienteconectado = this.validarClienteActivo();
        this.linea.steps = ['Ingreso de órdenes', 'Confirmación'];
        this.linea.currentStep = 2;

        //datos de ejecutivo
        let ejecutivoJson = sessionStorage.getItem('dataContentful');
        let ejecutivoAdicJson = sessionStorage.getItem('dataExecutive');
        let ejecutivoAdic = JSON.parse(ejecutivoAdicJson);
        let ejecutivo = JSON.parse(ejecutivoJson);

        let cliente = this.validarClienteActivo();
        var fecha = new Date();
        const formattedDate = formatDate(fecha, 'yyyyMMddHHmmss', 'en');
        var numeroorden: number = Number(formattedDate); //debe ser un numero corto

        //RECUPERA EL CARRO DE COMPRA
        let carroFinalJson = sessionStorage.getItem(this.corredoraService.STORAGE_CORREDORA_CARRO_FINAL);
        this.solicitud = JSON.parse(carroFinalJson) || {
            'id-folio-orden': numeroorden,
            estado: 'INF',
            'id-ejecutivo': ejecutivo.executiveRut,
            'nombre-ejecutivo': ejecutivo.executiveName,
            'email-ejecutivo': ejecutivoAdic && ejecutivoAdic.MailEjecutivo ? ejecutivoAdic.MailEjecutivo : 'ejecutivo@sura.cl',
            'rut-cliente': cliente.rutparteentera,
            'rut-dv-cliente': cliente.rutdigitoverificador,
            'nombre-cliente': cliente.nombre,
            'email-cliente': cliente.email,
            ordenes: [],
        };
        this.solicitud['rut-cliente'] = cliente.rutparteentera;
        this.solicitud['rut-dv-cliente'] =cliente.rutdigitoverificador;
        this.solicitud['nombre-cliente'] =cliente.nombre;
        this.solicitud['email-cliente'] =cliente.email;

        //VERIFICA SI HAY UN DISPOSITIVO SURAPASS ACTIVO PARA EL CLIENTE
        this.expandirAutorizaSURAPass = false;
        this.expandirAutorizaUpload = true;
        this.clienteTieneMultipassActivo = false;
        this.consultaConfiguracionSuraPass().then((config) => {
            this.convenioActivoSURAPAss(config).then( activo => {
                this.verificaSURAPassActivo(activo);
                this.inicializaRevisionPorIntervaloSURAPass(activo);
            })
            this.loadingService.hideLoading();
        });
    }

    /**
     * Analiza la confguracion SURAPass para indicar si eol convenio esta activo para funcionar
     * @param config
     * @returns
     */
    async convenioActivoSURAPAss(config) {
        let resultado: boolean = false;
        if (config && config.codigo === 0) {
            if (config.configuracion && config.configuracion.surapass) {
                config.configuracion.surapass.forEach((conve) => {
                    if (conve['idconvenio'] === this.SURAPASS_APLICACION_ID) {
                        if (conve['activo'] === 'S') {
                            resultado = true;
                        }
                    }
                });
            }
        }
        return resultado;
    }

    /**
     * Inicializa la revision cada X Tiempo
     */
    inicializaRevisionPorIntervaloSURAPass(activo) {
        if (activo) {
            this.consultaSiHayTransaccionSuraPass();
            //EJECUCION CADA 5 SEGUNDOS
            this.idintervalo = setInterval(() => {
                this.consultaSiHayTransaccionSuraPass();
            }, this.SEGUNDOS_REVISION_MULTIPASS * 1000);
        }
    }

    /**
     * Consulta al Servicio SURAPass el estado de autorizacion de una transaccion
     */
    async consultaConfiguracionSuraPass() {
        return this.surapassService
            .getConfiguracion()
            .then((datasurapass) => {
                if (datasurapass && datasurapass.codigo === 0 ) {
                    this.surapassconfig = datasurapass;
                }
                return datasurapass;
            })
            .catch((errsurpass) => {
                this.corredoraService.saveLogAccionAsesor(
                    'Ingreso de Ordenes de Compra y Venta Off Line',
                    'Ingreso OCV Off Line Paso 2',
                    'OrdenCarroComponent - consultaConfiguracionSuraPass()',
                    'error',
                    'SURAPASS - Error al consultar configuracion SURAPass mediante servicios REST',
                    errsurpass,
                );
                return {
                    codigo: -888,
                    resultado: 'No se logro consultar configuracion SURAPass',
                    configuracion: {},
                };
            });
    }
    /**
     * Verifica si Multipass Esta Activo
     */
    async verificaSURAPassActivo(activo) {
        if (activo) {
            this.surapassService
                .getDispositivoActivo(this.clienteconectado.rut)
                .then((datasurapass) => {
                    this.corredoraService.saveLogAccionAsesor(
                        'Ingreso de Ordenes de Compra y Venta Off Line',
                        'Ingreso OCV Off Line Paso 2',
                        'OrdenCarroComponent - ngOnInit()',
                        'ok',
                        'SURAPASS - Consulta cliente con dispositivo ACTIVO',
                        datasurapass,
                    );

                    if (datasurapass && datasurapass['codigo'] === 0) {
                        this.clienteTieneMultipassActivo = true;
                        this.expandirAutorizaSURAPass = true;
                        this.expandirAutorizaUpload = false;
                    }
                })
                .catch((errsurpass) => {
                    //log nueva web
                    this.corredoraService.saveLogAccionAsesor(
                        'Ingreso de Ordenes de Compra y Venta Off Line',
                        'Ingreso OCV Off Line Paso 2',
                        'OrdenCarroComponent - ngOnInit()',
                        'error',
                        'SURAPASS - Error al consultar dispositivo activo',
                        errsurpass,
                    );
                });
        }
    }

    cambioExpancionSuraPass($event) {
        this.expandirAutorizaSURAPass = $event;
        this.expandirAutorizaUpload = this.expandirAutorizaSURAPass ? false : true;
    }

    cambioExpancionUpload($event) {
        this.expandirAutorizaUpload = $event;
        this.expandirAutorizaSURAPass = this.expandirAutorizaUpload ? false : true;
    }

    /**
     * Entrega los datos en base 64
     * @param event
     */
    responseFile(event) {
        this.responseFileData = event;
        if (this.responseFileData && this.responseFileData.data) {
            let cliente = this.validarClienteActivo();
            let datos = this.responseFileData.data.split(',');
            let cabecera: string = datos[0];
            let tipoarchivoa = cabecera.split(';')[0].split(':')[1];
            let base64 = datos[1];
            let archivobase64: UploadContentManagerJSON = {
                idorden: 0,
                'archivo-base64': base64,
                'tipo-contenido': tipoarchivoa,
                nombre: 'orden-pershing.' + this.extensionByMime(tipoarchivoa),
                'rut-cliente': '' + cliente.rutparteentera,
                'nombre-cliente': cliente.nombre,
            };
            //SUBE EL ARCHIVO AL STORAGE
            this.sessionStorageService.set(this.corredoraService.STORAGE_CORREDORA_ARCHIVO_CONTENT_MANAGER, JSON.stringify(archivobase64));
            this.hayarchivoUploded = true;

            let copia = archivobase64;
            copia['archivo-base64'] = base64.length + ' bytes';

            this.corredoraService.saveLogAccionAsesor(
                'Ingreso de Ordenes de Compra y Venta Off Line',
                'Ingreso OCV Off Line Paso 2',
                'OrdenCarroComponent - responseFile()',
                'ok',
                'Upload de archivo del cliente para content manager tipo contenido',
                copia,
            );
        }
    }

    /**
     * Setea si hay un archivo cargado
     * @param event
     */
    hayArchivoCargado(event) {
        if (event === false) {
            this.hayarchivoUploded = false;
            this.sessionStorageService.remove(this.corredoraService.STORAGE_CORREDORA_ARCHIVO_CONTENT_MANAGER);
        }
    }

    /**
     * Enviar autorizacion
     * @param forma
     * @returns
     */
    goToEnviarSolicitud(forma) {
        if (forma === 'UPLOAD' && this.hayarchivoUploded === false) return;
        if (this.esperandoMultipass) return;

        let optionsMessage: ModalGenericoOrdenes = {
            titulo: '¿ Estás seguro de enviar la orden ?',
            mensaje: forma === 'UPLOAD' ? 'Una vez enviada, será procesada por la mesa Corredora de Bolsa SURA.' : 'Una vez enviada, deberás informale al cliente para que la revise y autorice a través de su SURAPass.',
            labelacepta: 'SI, ENVIAR',
            labelarechaza: 'CANCELAR',
        };
        const config = {
            panelClass: ['modal-confirmacion-box'],
            disableClose: false,
            data: { optionsMessage },
        };

        //llama al modal
        let dialogRef = this.dialogService.openDialog(ConfirmaModalComponentCorredora, config);

        //evaua el resultado del modal
        dialogRef.afterClosed().subscribe((data: any) => {
            if (data != null && data === 'OK') {
                if (forma === 'SURAPASS') {
                    //GENERAR TRANSACCION DE INGRESO y ACTIVA LA ESPERA MULTIPASS
                    this.esperandoMultipass = true;
                    this.creaAutorizacionSURAPass();

                    //log nueva web
                    this.corredoraService.saveLogAccionAsesor(
                        'Ingreso de Ordenes de Compra y Venta Off Line',
                        'Ingreso OCV Off Line Paso 2',
                        'OrdenCarroComponent - goToEnviarSolicitud()',
                        'ok',
                        'Se envia solicitud a traves de autorizacion SURAPASS',
                    );
                }

                if (forma === 'UPLOAD') {
                    //log nueva web
                    this.corredoraService.saveLogAccionAsesor(
                        'Ingreso de Ordenes de Compra y Venta Off Line',
                        'Ingreso OCV Off Line Paso 2',
                        'OrdenCarroComponent - goToEnviarSolicitud()',
                        'ok',
                        'Se envia solicitud a traves de autorizacion Upload Content Manager',
                    );

                    this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/corredora-finaliza`);
                }
            }
        });
    }

    scrollTo(el: Element): void {
        if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    goBack() {
        this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/corredora-carro`);
    }

    /**
     * Comprar
     */
    goToComprar() {
        if (this.esperandoMultipass) {
            let optionsMessage: ModalGenericoOrdenes = {
                titulo: '¿ Estás seguro abandonar ?',
                mensaje: 'Estas en un proceso de autorización de orden con SURA PASS, al abandonar se cancela esta autorización ',
                labelacepta: 'ABANDONAR',
                labelarechaza: 'CANCELAR',
            };
            const config = {
                panelClass: ['modal-confirmacion-box'],
                disableClose: false,
                data: { optionsMessage },
            };

            //llama al modal
            let dialogRef = this.dialogService.openDialog(ConfirmaModalComponentCorredora, config);

            //evaua el resultado del modal
            dialogRef.afterClosed().subscribe((data: any) => {
                if (data != null && data === 'OK') {
                    sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                    sessionStorage.removeItem(this.corredoraService.STORAGE_CORREDORA_COMPRA_VENTA_CARRO);

                    //log nueva web
                    this.corredoraService.saveLogAccionAsesor(
                        'Ingreso de Ordenes de Compra y Venta Off Line',
                        'Ingreso OCV Off Line Paso 2',
                        'OrdenCarroComponent - goToComprar()',
                        'ok',
                        'Abandona el proceso de autorizacion para agregar una nueva Compra',
                    );

                    this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/corredora-orden-compra-venta`);
                }
            });
        } else {
            sessionStorage.removeItem(this.corredoraService.STORAGE_CORREDORA_COMPRA_VENTA_CARRO);
            //log nueva web
            this.corredoraService.saveLogAccionAsesor('Ingreso de Ordenes de Compra y Venta Off Line', 'Ingreso OCV Off Line Paso 2', 'OrdenCarroComponent - goToComprar()', 'ok', 'Accion de agregar una nueva Compra');

            this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/corredora-orden-compra-venta`);
        }
    }

    /**
     * Vender
     */
    goToVender() {
        if (this.esperandoMultipass) {
            let optionsMessage: ModalGenericoOrdenes = {
                titulo: '¿ Estás seguro abandonar ?',
                mensaje: 'Estas en un proceso de autorización de orden con SURA PASS, al abandonar se cancela esta autorización ',
                labelacepta: 'ABANDONAR',
                labelarechaza: 'CANCELAR',
            };
            const config = {
                panelClass: ['modal-confirmacion-box'],
                disableClose: false,
                data: { optionsMessage },
            };

            //llama al modal
            let dialogRef = this.dialogService.openDialog(ConfirmaModalComponentCorredora, config);

            //evaua el resultado del modal
            dialogRef.afterClosed().subscribe((data: any) => {
                if (data != null && data === 'OK') {
                    sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                    sessionStorage.removeItem(this.corredoraService.STORAGE_CORREDORA_COMPRA_VENTA_CARRO);
                    //log nueva web
                    this.corredoraService.saveLogAccionAsesor(
                        'Ingreso de Ordenes de Compra y Venta Off Line',
                        'Ingreso OCV Off Line Paso 2',
                        'OrdenCarroComponent - goToVender()',
                        'ok',
                        'Abandona el proceso de autorizacion para agregar una nueva Venta',
                    );

                    this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/corredora-orden-compra-venta`);
                }
            });
        } else {
            sessionStorage.removeItem(this.corredoraService.STORAGE_CORREDORA_COMPRA_VENTA_CARRO);
            //log nueva web
            this.corredoraService.saveLogAccionAsesor('Ingreso de Ordenes de Compra y Venta Off Line', 'Ingreso OCV Off Line Paso 2', 'OrdenCarroComponent - goToVender()', 'ok', 'Accion de agregar una nueva Venta');

            this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/corredora-orden-compra-venta`);
        }
    }

    /**
     * Paso 1
     * Crea la transaccion SURAPass
     * @returns
     */
    private creaAutorizacionSURAPass() {
        //log nueva web
        this.corredoraService.saveLogAccionAsesor(
            'Ingreso de Ordenes de Compra y Venta Off Line',
            'Ingreso OCV Off Line Paso 2',
            'OrdenCarroComponent - creaAutorizacionSURAPass()',
            'ok',
            'SURAPASS - Se iniciar un proceso de autorizacion por APP Movil',
        );

        let cliente = this.validarClienteActivo();

        //CANTIDAD TRANSACCIONES
        let countCompras: number = 0;
        let countVentas: number = 0;

        //TOTAL USD TRANSACCIONES
        let sumaTotalCLPCompras: number = 0;
        let sumaTotalCLPVentas: number = 0;

        //GENERA LOS DETALLES
        let detalles: SURAPassTransaccionDetalleJson[] = [];

        //CARGA LOS DETALLE
        this.solicitud.ordenes.forEach((orden) => {
            //========================================
            // COMPRA
            //========================================
            if (orden['tipo-operacion'] === 'COMPRA') {
                countCompras++;
                sumaTotalCLPCompras += orden['monto-cantidad'];
                detalles.push(this.crearAutorizacionSURAPassAddCompra(orden));
            }

            //========================================
            // VENTA
            //========================================
            if (orden['tipo-operacion'] === 'VENTA') {
                countVentas++;
                sumaTotalCLPVentas += orden['monto-cantidad'];
                detalles.push(this.crearAutorizacionSURAPassAddVenta(orden));
            }
        });

        this.creaAutorizacionSURAPassPaso2(cliente, countCompras, countVentas, sumaTotalCLPCompras, sumaTotalCLPVentas, detalles);
    }

    crearAutorizacionSURAPassAddCompra(orden) {
        //CARGA DETALLES
        let grupocargar: SURAPassTransaccionGrupoJson[] = [];
        //CREA PRESENTACION DE LA OPERACION
        let camposadd: SURAPassTransaccionCampoJson[] = [
            {
                titulo: 'PRODUCTO',
                valor: orden['descripcion-producto'],
            },
            {
                titulo: 'INSTRUMENTO',
                valor: orden['descripcion-instrumento'],
            },
            {
                titulo: 'NEMOTÉCNICO',
                valor: orden['nemotecnico'],
            },
            {
                titulo: 'TIPO ORDEN',
                valor: orden['tipo-orden'] === 'MONTO' ? 'POR MONTO' : 'POR CANTIDAD',
            },
            {
                titulo: orden['tipo-orden'] === 'MONTO' ? 'MONTO' : 'CANTIDAD',
                valor: this.formatearNumeroPesos(orden['monto-cantidad'], orden['tipo-orden'] === 'MONTO' ? false : true),
            },
            {
                titulo: 'TIPO PRECIO',
                valor: orden['tipo-precio'],
            },
            {
                titulo: 'VIGENCIA',
                valor: orden['vigencia'],
            },
            {
                titulo: 'RÉGIMEN TRIBUTARIO',
                valor: orden['descripcion-regimen-tributario'],
            },
            {
                titulo: 'TIPO RÉGIMEN',
                valor: orden['tipo-regimen'],
            },
        ];

        //SE CARGAN LOS CAMPOS POR CADA GRUPO
        let grupoadd: SURAPassTransaccionGrupoJson = {
            campos: camposadd,
        };
        //CARGA EL GRUPO CON LOS CAMPOS
        grupocargar.push(grupoadd);

        //CREA EL DETALLE DE LA OPERACION DE COMPRA
        return {
            descripcion1: 'Compra de Fondos',
            descripcion2: orden['tipo-orden'] === 'MONTO' ? 'Compra por Monto' : 'Compra por Cantidad',
            descripcion3: this.formatearNumeroPesos(orden['monto-cantidad'], orden['tipo-orden'] === 'MONTO' ? false : true) + (orden['tipo-orden'] === 'MONTO' ? '' : ' Unidades'),
            monto: orden['monto-cantidad'],
            grupo: grupocargar,
        };
    }

    crearAutorizacionSURAPassAddVenta(orden) {
        //CARGA DETALLES
        let grupocargar: SURAPassTransaccionGrupoJson[] = [];

        //CREA PRESENTACION DE LA OPERACION
        let camposadd: SURAPassTransaccionCampoJson[] = [
            {
                titulo: 'PRODUCTO',
                valor: orden['descripcion-producto'],
            },
            {
                titulo: 'INSTRUMENTO',
                valor: orden['descripcion-instrumento'],
            },
            {
                titulo: 'NEMOTÉCNICO',
                valor: orden['nemotecnico'],
            },
            {
                titulo: 'TIPO ORDEN',
                valor: orden['tipo-orden'] === 'MONTO' ? 'POR MONTO' : 'POR CANTIDAD',
            },
            {
                titulo: orden['tipo-orden'] === 'MONTO' ? 'MONTO' : 'CANTIDAD',
                valor: this.formatearNumeroPesos(orden['monto-cantidad'], orden['tipo-orden'] === 'MONTO' ? false : true),
            },
            {
                titulo: 'TIPO PRECIO',
                valor: orden['tipo-precio'],
            },
            {
                titulo: 'VIGENCIA',
                valor: orden['vigencia'],
            },
            {
                titulo: 'RÉGIMEN TRIBUTARIO',
                valor: orden['descripcion-regimen-tributario'],
            },
            {
                titulo: 'TIPO RÉGIMEN',
                valor: orden['tipo-regimen'],
            },
        ];

        //SE CARGAN LOS CAMPOS POR CADA GRUPO
        let grupoadd: SURAPassTransaccionGrupoJson = {
            campos: camposadd,
        };
        //CARGA EL GRUPO CON LOS CAMPOS
        grupocargar.push(grupoadd);

        //CREA EL DETALLE DE LA OPERACION DE COMPRA
        return {
            descripcion1: 'Venta de Fondos',
            descripcion2: orden['tipo-orden'] === 'MONTO' ? 'Venta por Monto' : 'Venta por Cantidad',
            descripcion3: this.formatearNumeroPesos(orden['monto-cantidad'], orden['tipo-orden'] === 'MONTO' ? false : true) + (orden['tipo-orden'] === 'MONTO' ? '' : ' Unidades'),
            monto: orden['monto-cantidad'],
            grupo: grupocargar,
        };
    }

    /**
     * Paso 2
     * @param cliente
     * @param countCompras
     * @param countVentas
     * @param sumaTotalCLPCompras
     * @param sumaTotalCLPVentas
     * @param detalles
     */
    creaAutorizacionSURAPassPaso2(cliente: ClienteActivoDto, countCompras: number, countVentas: number, sumaTotalCLPCompras: number, sumaTotalCLPVentas: number, detalles: SURAPassTransaccionDetalleJson[]) {
        //TOTAL USD TRANSACCIONES
        let totalGeneralCLPOperacion: number = 0;
        let fechaconsultastr = formatDate(new Date(), 'EEEE d MMMM, y, h:mm', 'es') + 'hrs';

        let operacion: string = undefined;
        if (countCompras > 0 && countVentas === 0 && operacion === undefined) {
            operacion = 'Compra'; 
            totalGeneralCLPOperacion += sumaTotalCLPCompras;
        }
        if (countCompras === 0 && countVentas > 0 && operacion === undefined) {
            operacion = 'Venta'; 
            totalGeneralCLPOperacion += sumaTotalCLPVentas;
        }
        if (countCompras > 0 && countVentas > 0 && operacion === undefined) {
            operacion = 'Compra y Venta';
            totalGeneralCLPOperacion += sumaTotalCLPCompras;
        }

         //DATA PARASURAPASS
         this.solicitud.estado = 'INF';
         let jsonxml : SURAPassAPIPagoCorredoraJSON = {
            transaccion : this.solicitud,
            correo: this.solicitud
        }

        //caso de alerta
        let alertax = {
            tipo: this.MENSAJE_NEGOCIO_ALERTA_TIPO, 
            mensaje: this.MENSAJE_NEGOCIO_ALERTA_MENSAJE, 
            titulo: this.MENSAJE_NEGOCIO_ALERTA_TITULO 
        }


        //CREA LA TRANSACION
        this.transaccion = {
            rut: cliente.rut,
            codigoMoneda: 'CLP',
            solicitante: 'EJECUTIVO',
            nombreEjecutivo: this.solicitud['id-ejecutivo'].toUpperCase() + ' ' + this.solicitud['nombre-ejecutivo'],
            descripcionTransaccion1: this.recuperarLineaNegocioSuraPass(),
            descripcionTransaccion2: operacion,
            descripcionTransaccion3: fechaconsultastr,
            montoAutorizar: Number(totalGeneralCLPOperacion),
            aplicacionOrigen: this.SURAPASS_APLICACION_APP,
            detalle: detalles,
            alerta: (this.MENSAJE_NEGOCIO_ALERTA_TIPO ? alertax : null),
            dataJsonXml: JSON.stringify(jsonxml)
        };

        this.corredoraService.saveLogAccionAsesor(
            'Ingreso de Ordenes de Compra y Venta Off Line',
            'Ingreso OCV Off Line Paso 2',
            'OrdenCarroComponent - creaAutorizacionSURAPass()',
            'ok',
            'SURAPASS - Datos enviados para crear autorizacion',
            this.transaccion,
        );

        //llama a los servicios
        this.creaAutorizacionSURAPassPaso3();
    }

        /**
     * Recupera el nombre de la transaccion SURAPass
     * @returns 
     */
         recuperarLineaNegocioSuraPass() {
            let linea: string = 'CORREDORA DE BOLSA';
            if (this.surapassconfig && this.surapassconfig.configuracion && this.surapassconfig.configuracion.surapass) {
                this.surapassconfig.configuracion.surapass.forEach(conve => {
                    if (conve['idconvenio'] === this.SURAPASS_APLICACION_ID) {
                        linea = conve['descripcion'];
                    }
                })
            }
            return linea;
        }

    /**
     * Paso 3
     * @param token
     */
    creaAutorizacionSURAPassPaso3() {
        //crea la transaccion SURA PASS
        this.surapassService
            .postCrearTransaccion(this.SURAPASS_APLICACION_ID, this.transaccion)
            .then((datasurapass) => {
                if (datasurapass['codigo'] === 0 || datasurapass['codigo'] === 22) {
                    this.expandirAutorizaSURAPass = true;
                    this.expandirAutorizaUpload = false;
                    this.esperandoMultipass = true;

                    //CARGA VARIABLE EN STORAGE
                    sessionStorage.setItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION, JSON.stringify(datasurapass));

                    //log nueva web
                    this.corredoraService.saveLogAccionAsesor(
                        'Ingreso de Ordenes de Compra y Venta Off Line',
                        'Ingreso OCV Off Line Paso 2',
                        'OrdenCarroComponent - creaAutorizacionSURAPass()',
                        'ok',
                        'SURAPASS - Autorizacion solicitada con exito, en espera de APP Apruebe ',
                        datasurapass,
                    );
                } else {
                    this.scrollTo(document.querySelector('#fldseccionmensajes'));
                    this.mensaje = {
                        messageContent: datasurapass.resultado,
                        messageType: 'error',
                        messageTitle: 'Algo salio mal',
                        showMessage: true,
                    };
                   

                    //log nueva web
                    this.corredoraService.saveLogAccionAsesor(
                        'Ingreso de Ordenes de Compra y Venta Off Line',
                        'Ingreso OCV Off Line Paso 2',
                        'OrdenCarroComponent - creaAutorizacionSURAPass()',
                        'error',
                        'SURAPASS - Servicio No de logro generar Autorizacion',
                        datasurapass,
                    );
                }
            })
            .catch((errsurpass) => {
                this.scrollTo(document.querySelector('#fldseccionmensajes'));
                this.mensaje = {
                    messageContent: errsurpass.resultado,
                    messageType: 'error',
                    messageTitle: 'Algo salio mal',
                    showMessage: true,
                };
                this.esperandoMultipass = false;

                //log nueva web
                this.corredoraService.saveLogAccionAsesor(
                    'Ingreso de Ordenes de Compra y Venta Off Line',
                    'Ingreso OCV Off Line Paso 2',
                    'OrdenCarroComponent - creaAutorizacionSURAPass()',
                    'error',
                    'SURAPASS - Error al ejecutar servicio de autorizacion',
                    errsurpass,
                );
            });
    }

    /**
     * Formatea
     * @param value
     * @param sinmoneda
     * @returns
     */
    formatearNumeroPesos(value: any, sinmoneda?: boolean): string {
        if (value != null && !isNaN(value)) {
            return (sinmoneda !== undefined && sinmoneda === true ? '' : 'CLP $') + Intl.NumberFormat('es-CL', { maximumFractionDigits: 0 }).format(value);
        }
        return '';
    }

    /**
     * Consulta el cliente conectado de la ultima session en el Storage
     * @returns Cliente conectado
     */
    validarClienteActivo(): ClienteActivoDto {
        let data = sessionStorage.getItem('clientData');
        if (data) {
            const dataClient = JSON.parse(data);
            let rutlimpio = dataClient.rut.split('.').join('');
            let rutPartes = rutlimpio.split('-');
            let nombre = dataClient.name;
            return {
                rut: rutlimpio,
                rutparteentera: Number(rutPartes[0]),
                rutdigitoverificador: rutPartes[1],
                email: this.recuperaEmail(dataClient),
                nombre: nombre,
            };
        } else {
            this.router.navigate(['main/client-search']);
            return undefined;
        }
    }

    recuperaEmail(dataClient) {
        let email = undefined;
            
        if (dataClient.clientInfo) {
            dataClient.clientInfo.forEach( artsa => {
                if (artsa.icon === 'mail') {
                    email = artsa.text;
                }
            })
        }
        if (dataClient.companyInfo) {
            dataClient.companyInfo.forEach( artsb => {
                if (artsb.icon === 'mail') {
                    email = artsb.text;
                }
            })
        }
        if (!email) email = 'sincorreo@sura.cl';
        return email;
    }

    /**
     * Consulta al Servicio SURAPass el estado de autorizacion de una transaccion
     */
    async consultaSiHayTransaccionSuraPass() {
        let surapassJson = sessionStorage.getItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
        let surapass = JSON.parse(surapassJson) || {};

        //VERIFICANDO SI HAY UNA TRANSACCION ENCOLADA PRODUCTO DE UN REFRESH DE PANTALLA
        if (this.esperandoMultipass === false) {
            if (surapass != undefined && surapass.codigo === 0 && surapass.idTransaccion > 0) {
                this.esperandoMultipass = true;
            }
        }

        //REVISAR SI HAY UNA OPERACION
        if (this.esperandoMultipass && surapass) {
            //llama a los servicios
            this.consultaEstadoTransaccionSuraPass(surapass);
        }
    }

    /**
     * Consulta estado de transaccion
     * @param accesstoken
     * @param surapass
     */
     consultaEstadoTransaccionSuraPass(surapass) {
        
        if (!surapass || !surapass.idTransaccion) return ;

        //crea la transaccion SURA PASS
        this.surapassService
            .getEstadoTransaccion(surapass.idTransaccion, this.SURAPASS_APLICACION_APP)
            .then((datasurapass) => {
                if (datasurapass !== undefined && datasurapass['codigo'] === 0) {
                    //REVISNADO LOS ESTADOS
                    switch (datasurapass['estado']) {
                        case 'AUTORIZADA': {
                            //log nueva web
                            this.corredoraService.saveLogAccionAsesor(
                                'Ingreso de Ordenes de Compra y Venta Off Line',
                                'Ingreso OCV Off Line Paso 2',
                                'OrdenCarroComponent - consultaEstadoSuraPass()',
                                'ok',
                                'SURAPASS - Estado transaccion AUTORIZADA',
                                datasurapass,
                            );
                            this.esperandoMultipass = false;
                            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                            this.router.navigate(['main/client-search/summary/ordenes/corredora-finaliza']);
                            break;
                        }
                        case 'PENDIENTE': {
                            this.mensaje = {
                                messageContent: this.TEXTO_MSG_PENDIENTE,
                                messageType: 'warning',
                                messageTitle: this.TEXTO_TIT_PENDIENTE,
                                showMessage: true,
                            };
                            break;
                        }
                        case 'EXPIRADA': {
                            this.esperandoMultipass = false;
                            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                            this.scrollTo(document.querySelector('#fldseccionmensajes'));
                            this.mensaje = {
                                messageContent: this.TEXTO_MSG_EXPIRADA,
                                messageType: 'info',
                                messageTitle: this.TEXTO_TIT_EXPIRADA,
                                showMessage: true,
                            };
                            //log nueva web
                            this.corredoraService.saveLogAccionAsesor(
                                'Ingreso de Ordenes de Compra y Venta Off Line',
                                'Ingreso OCV Off Line Paso 2',
                                'OrdenCarroComponent - consultaEstadoSuraPass()',
                                'ok',
                                'SURAPASS - Estado transaccion EXPIRADA',
                                datasurapass,
                            );
                            break;
                        }
                        case 'EXCEDE INTENTOS': {
                            this.esperandoMultipass = false;
                            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                            this.scrollTo(document.querySelector('#fldseccionmensajes'));
                            this.mensaje = {
                                messageContent: this.TEXTO_MSG_EXCEDE_INTENTOS,
                                messageType: 'error',
                                messageTitle: this.TEXTO_TIT_EXCEDE_INTENTOS,
                                showMessage: true,
                            };
                            //log nueva web
                            this.corredoraService.saveLogAccionAsesor(
                                'Ingreso de Ordenes de Compra y Venta Off Line',
                                'Ingreso OCV Off Line Paso 2',
                                'OrdenCarroComponent - consultaEstadoSuraPass()',
                                'ok',
                                'SURAPASS - Estado transaccion EXCEDE INTENTOS',
                                datasurapass,
                            );
                            break;
                        }
                        case 'RECHAZADA': {
                            this.esperandoMultipass = false;
                            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                            this.scrollTo(document.querySelector('#fldseccionmensajes'));
                            this.mensaje = {
                                messageContent:this.TEXTO_MSG_RECHAZADA,
                                messageType: 'error',
                                messageTitle: this.TEXTO_TIT_RECHAZADA,
                                showMessage: true,
                            };
                            //log nueva web
                            this.corredoraService.saveLogAccionAsesor(
                                'Ingreso de Ordenes de Compra y Venta Off Line',
                                'Ingreso OCV Off Line Paso 2',
                                'OrdenCarroComponent - consultaEstadoSuraPass()',
                                'ok',
                                'SURAPASS - Estado transaccion RECHAZADA',
                                datasurapass,
                            );
                            break;
                        }
                        default: {
                            //statements;
                            break;
                        }
                    }
                } else {
                    this.esperandoMultipass = false;
                    this.scrollTo(document.querySelector('#fldseccionmensajes'));
                    this.mensaje = {
                        messageContent: datasurapass.resultado,
                        messageType: 'error',
                        messageTitle: 'Algo salio mal',
                        showMessage: true,
                    };

                    //log nueva web
                    this.corredoraService.saveLogAccionAsesor(
                        'Ingreso de Ordenes de Compra y Venta Off Line',
                        'Ingreso OCV Off Line Paso 2',
                        'OrdenCarroComponent - consultaEstadoSuraPass()',
                        'error',
                        'SURAPASS - Servicio No de logro consultar estado transaccion',
                        datasurapass,
                    );
                }
            })
            .catch((errsurpass) => {
                console.log("ERROR CONSULAT ESTADO",errsurpass );
                this.esperandoMultipass = false;
                this.scrollTo(document.querySelector('#fldseccionmensajes'));
                this.mensaje = {
                    messageContent: errsurpass.resultado,
                    messageType: 'error',
                    messageTitle: 'Algo salio mal',
                    showMessage: true,
                };
                //log nueva web
                this.corredoraService.saveLogAccionAsesor(
                    'Ingreso de Ordenes de Compra y Venta Off Line',
                    'Ingreso OCV Off Line Paso 2',
                    'OrdenCarroComponent - consultaEstadoSuraPass()',
                    'error',
                    'SURAPASS - Error al ejecutar servicio de cosulta estado transaccion',
                    errsurpass,
                );
            });
    }

    extensionByMime(mimetype: string) {
        let extension: string = 'txt';
        let comprar: any = [
            { tipo: 'text/html', extension: 'html' },
            { tipo: 'text/css', extension: 'css' },
            { tipo: 'text/xml', extension: 'xml' },
            { tipo: 'image/gif', extension: 'gif' },
            { tipo: 'image/jpeg', extension: 'jpg' },
            { tipo: 'application/x-javascript', extension: 'js' },
            { tipo: 'application/atom+xml', extension: 'atom' },
            { tipo: 'application/rss+xml', extension: 'rss' },
            { tipo: 'text/mathml', extension: 'mml' },
            { tipo: 'text/plain', extension: 'txt' },
            { tipo: 'text/vnd.sun.j2me.app-descriptor', extension: 'jad' },
            { tipo: 'text/vnd.wap.wml', extension: 'wml' },
            { tipo: 'text/x-component', extension: 'htc' },
            { tipo: 'image/png', extension: 'png' },
            { tipo: 'image/tiff', extension: 'tiff' },
            { tipo: 'image/vnd.wap.wbmp', extension: 'wbmp' },
            { tipo: 'image/x-icon', extension: 'ico' },
            { tipo: 'image/x-jng', extension: 'jng' },
            { tipo: 'image/x-ms-bmp', extension: 'bmp' },
            { tipo: 'image/svg+xml', extension: 'svg' },
            { tipo: 'image/webp', extension: 'webp' },
            { tipo: 'application/java-archive', extension: 'jar' },
            { tipo: 'application/mac-binhex40', extension: 'hqx' },
            { tipo: 'application/msword', extension: 'doc' },
            { tipo: 'application/pdf', extension: 'pdf' },
            { tipo: 'application/postscript', extension: 'ps' },
            { tipo: 'application/rtf', extension: 'rtf' },
            { tipo: 'application/vnd.ms-excel', extension: 'xls' },
            { tipo: 'application/vnd.ms-powerpoint', extension: 'ppt' },
            { tipo: 'application/vnd.wap.wmlc', extension: 'wmlc' },
            { tipo: 'application/vnd.google-earth.kml+xml', extension: 'kml' },
            { tipo: 'application/vnd.google-earth.kmz', extension: 'kmz' },
            { tipo: 'application/x-7z-compressed', extension: '7z' },
            { tipo: 'application/x-cocoa', extension: 'cco' },
            { tipo: 'application/x-java-archive-diff', extension: 'jardiff' },
            { tipo: 'application/x-java-jnlp-file', extension: 'jnlp' },
            { tipo: 'application/x-makeself', extension: 'run' },
            { tipo: 'application/x-perl', extension: 'pl' },
            { tipo: 'application/x-pilot', extension: 'prc' },
            { tipo: 'application/x-rar-compressed', extension: 'rar' },
            { tipo: 'application/x-redhat-package-manager', extension: 'rpm' },
            { tipo: 'application/x-sea', extension: 'sea' },
            { tipo: 'application/x-shockwave-flash', extension: 'swf' },
            { tipo: 'application/x-stuffit', extension: 'sit' },
            { tipo: 'application/x-tcl', extension: 'tcl' },
            { tipo: 'application/x-x509-ca-cert', extension: 'der' },
            { tipo: 'application/x-xpinstall', extension: 'xpi' },
            { tipo: 'application/xhtml+xml', extension: 'xhtml' },
            { tipo: 'application/zip', extension: 'zip' },
            { tipo: 'application/octet-stream', extension: 'txt' },
            { tipo: 'audio/midi', extension: 'mid' },
            { tipo: 'audio/mpeg', extension: 'mp3' },
            { tipo: 'audio/ogg', extension: 'ogg' },
            { tipo: 'audio/x-realaudio', extension: 'ra' },
            { tipo: 'video/3gpp', extension: '3gp' },
            { tipo: 'video/mpeg', extension: 'mpg' },
            { tipo: 'video/quicktime', extension: 'mov' },
            { tipo: 'video/x-flv', extension: 'flv' },
            { tipo: 'video/x-mng', extension: 'mng' },
            { tipo: 'video/x-ms-asf', extension: 'asf' },
            { tipo: 'video/x-ms-wmv', extension: 'wmv' },
            { tipo: 'video/x-msvideo', extension: 'avi' },
            { tipo: 'video/mp4', extension: 'mp4' },
        ];

        comprar.forEach((buscar) => {
            if (buscar.tipo.trim().toLowerCase() === mimetype.trim().toLowerCase()) {
                extension = buscar.extension;
            }
        });
        return extension;
    }
}
