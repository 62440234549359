import { Component, Input } from '@angular/core';
import { ModalGenericoOrdenes, SolicitudDto } from 'src/app/features/models/ordenes/divisas/divisas.modelo';
import { OrdenesDivisasService } from 'src/app/features/services/ordenes/divisas/ordenes.divisas.service';
import { DialogService } from 'src/app/shared/services';
import { ConfirmaModalComponentDivisas } from '../confirma-modal/confirma-modal.component';

@Component({
    selector: 'app-detalle-ordenes-ingresadas',
    templateUrl: './detalle-ordenes-ingresadas.component.html',
    styleUrls: ['./detalle-ordenes-ingresadas.component.scss'],
})
export class DetalleOrdenesIngresadasComponent {
    //ordenes de la solicitud
    @Input() solicitud: SolicitudDto;

    constructor(private dialogService: DialogService, private divisasService: OrdenesDivisasService) {}

    cuentasGroupOrdenes() {
        return Array.from(this.solicitud.ordenes.reduce((entryMap, e) => entryMap.set(e.instruccion, { ...(entryMap.get(e.instruccion) || {}), ...e }), new Map()).values());
    }

    hayCompra() {
        var cuantos = this.solicitud.ordenes.filter(function (element) {
            return element.instruccion === 'Compra';
        }).length;
        return cuantos > 0 ? true : false;
    }

    hayVenta() {
        var cuantos = this.solicitud.ordenes.filter(function (element) {
            return element.instruccion === 'Venta';
        }).length;
        return cuantos > 0 ? true : false;
    }

    //filtra los ordenes
    filtrarOrdenes(tipooperacion) {
        return this.solicitud.ordenes.filter((x) => x.instruccion == tipooperacion);
    }

    //elininar una orden (compra, venta, o cambio de fondos)
    goToEliminarOrden(orden) {
        console.log('elimina');
        //configura el modal para ejecutar
        let optionsMessage: ModalGenericoOrdenes = {
            titulo: '¿ Estás seguro de eliminar Orden ?',
            mensaje: 'Una vez eliminada no se podrá recuperar y deberás ingresarla nuevamente.',
            labelacepta: 'Si, la quiero eliminar',
            labelarechaza: 'No, La quiero conservar',
        };

        const config = {
            panelClass: ['modal-confirmacion-box'],
            disableClose: false,
            data: { optionsMessage },
        };

        //llama al modal
        let dialogRef = this.dialogService.openDialog(ConfirmaModalComponentDivisas, config);

        //evaua el resultado del modal
        dialogRef.afterClosed().subscribe((data: any) => {
            if (data != null && data === 'OK') {
                //se elimina la orden seleccionada
                this.solicitud.ordenes.splice(
                    this.solicitud.ordenes.findIndex((item) => item['id-orden'] === orden['id-orden']),
                    1,
                );

                //actualiza la solicitud con los datos incorporados
                sessionStorage.setItem(this.divisasService.STORAGE_DIVISAS_CARRO_FINAL, JSON.stringify(this.solicitud));
            }
        });
    }
}
