// Angular Core
import { Injectable, EventEmitter } from '@angular/core';

// RxJS
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    public errorTimeoutSubject = new BehaviorSubject<boolean>(null);

    menu = false;
    menuWindow = false;
    event = new EventEmitter();
    eventSession = new EventEmitter();
    eventPage = new Subject();
    eventMenu = new Subject();

    getEventMenu(): Observable<any> {
        return this.eventMenu.asObservable();
    }

    getEventPage(): Observable<any> {
        return this.eventPage.asObservable();
    }

    getEvent() {
        return this.event;
    }

    toogleMenu(open?: boolean) {
        if (open !== undefined) {
            this.menu = open;
        } else {
            this.menu = !this.menu;
        }
    }

    toogleMenuWindow(open?: boolean) {
        if (open !== undefined) {
            this.menuWindow = open;
        } else {
            this.menuWindow = !this.menuWindow;
        }
    }

    isMenuWindowOpen() {
        return this.menuWindow;
    }

    isOpen() {
        return this.menu;
    }

    setErrorTimeout(error: boolean) {
        this.errorTimeoutSubject.next(error);
    }

    getErrorTimeout(): Observable<boolean> {
        return this.errorTimeoutSubject.asObservable();
    }
}
