import { Component, Inject } from "@angular/core";
import { Idle } from "@ng-idle/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppService } from "../../services/app/app.service";
import { Router } from "@angular/router";
import { LoginService } from '../../../features/services/login/login.service';
import { DialogService } from "../../services";

@Component({
  selector: "app-timeout-message",
  templateUrl: "./timeout-message.component.html",
  styleUrls: ["./timeout-message.component.scss"]
})
export class TimeoutMessageComponent {

  countdown;

  constructor(
    public dialogRef: MatDialogRef<TimeoutMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private idle: Idle,
    private appService: AppService,
    private router: Router,
    private loginService: LoginService,
    private dialogService: DialogService
  ) {
    dialogRef.disableClose = true;
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.countdown = countdown;
    });

    idle.onTimeout.subscribe(() => {
      this.dialogRef.close();
      this.dialogService.closeDialog();
    });
  }

  onStay() {
    this.dialogRef.close();
    this.idle.watch();
  }

  async onLeave() {
    this.appService.setUserLoggedIn(false);
    this.dialogRef.close();
    this.dialogService.closeDialog();
    this.loginService.signOutSession();
  }

}
