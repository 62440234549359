import { Component } from '@angular/core';
import * as moment from 'moment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import { ClientRequestService, ClientService } from '../../../../../../../src/app/features/services';

@Component({
    selector: 'app-tax-certificates',
    templateUrl: './tax-certificates.component.html',
    styleUrls: ['./tax-certificates.component.scss'],
})
export class TaxCertificatesComponent {
    constructor(private clientRequest: ClientRequestService, private clientService: ClientService, private tracesService: TracesService) {}

    async goSiteClientTaxCertificates() {
        const clientData = this.clientRequest.clientData.value;
        const logtemp: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'> = {
            Categoria: 'Resumen Cliente',
            Operacion: 'Resumen Cliente',
            Metodo: 'goSiteClientTaxCertificates()',
            eventoLog: 'click boton generar certificado',
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton generar certificado redireccionar',
            },
        };
        this.logData(logtemp, 'log_negocio');
        await this.clientService.goToSiteClient(
            {
                rut: `${clientData.RutCliente}-${clientData.DigitoVerificador}`,
                nombre: clientData.NombreCliente,
                apellidoPaterno: clientData.NombreCliente.split(' ')[0],
                apellidoMaterno: clientData.NombreCliente.split(' ')[1],
            },
            'mis-certificados',
        );
    }
    async logData(log: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...EntradaLog,
            };
            inputLog.Salida = '-';
            inputLog.Secuencia = 0;
            inputLog.Servicio = '';
            inputLog.Tipo_sesion = 'ASESOR';
            inputLog.extra = {};

            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, 'NWSA_ASESOR', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }
}
