import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { $ } from 'protractor';
import { AssetService } from 'src/app/shared/services/asset/asset.service';

@Component({
  selector: 'app-filtro-tabla-asset',
  templateUrl: './filtro-tabla-asset.component.html',
  styleUrls: ['./filtro-tabla-asset.component.scss']
})
export class FiltroTablaAssetComponent implements OnInit {

  @ViewChild('dropdownFondos', { static: false }) dropdownFondos: any;
  @ViewChild('dropdownRegimen', { static: false }) dropdownRegimen: any;
  @ViewChild('dropdownProducto', { static: false }) dropdownProducto: any;
  @ViewChild('dropdownFondosMov', { static: false }) dropdownFondosMov: any;
  @ViewChild('dropdownRegimenMov', { static: false }) dropdownRegimenMov: any;
  @ViewChild('dropdownProductoMov', { static: false }) dropdownProductoMov: any;
  parametro: any;
  productos: any;
  regimenes: any;
  isFondosLoaded = false;
  @Output() filtrarDatos = new EventEmitter<any>();
  fondos: any;
  fondosFiltros: any;
  instrumentos: any;
  fondoSelect: any;
  instrumentoMov = new FormControl();
  instrumentosDesktop = new FormControl();
  regimenDesktop = new FormControl();
  productosSelect:any;

  constructor(
    public dialog: MatDialog,
    private assetService: AssetService
  ) { }

  ngOnInit(): void {
      this.assetService.parametroSubject.subscribe((value) => {
      this.parametro = value;
      this.instrumentos = this.parametro.responsePaf?.DetalleInstrumentos;
      this.regimenes = this.mapperRegimen(this.instrumentos);
      this.productos = this.mapperProductos(this.instrumentos);
      this.productosSelect = 'TODOS';
    });
    this.isFondosLoaded = true;
  }

  mapperProductos(instrumentos) {
    const detalleProductosMap = [];
    for (const instrumento of instrumentos) {
      detalleProductosMap.push({
        nombreProducto: instrumento?.Producto ? instrumento.Producto : "PRODUCTO"
      });
    }
    let productosDistintos = detalleProductosMap.filter((producto, index, self) =>
      index === self.findIndex((r) => r.nombreProducto === producto.nombreProducto)
    );

    productosDistintos = this.orderFiltros(productosDistintos, 'nombreProducto');
    productosDistintos.unshift({ "nombreProducto": "TODOS" });
    return productosDistintos;
  }

  mapperRegimen(instrumentos) {
    const detalleRegimenMap = [];

    for (const instrumento of instrumentos) {
      detalleRegimenMap.push({
        nombreRegimen: instrumento?.Regimen ? instrumento.Regimen : "REGIMEN"
      });
    }
    let regimenesDistintos = detalleRegimenMap.filter((regimen, index, self) =>
      index === self.findIndex((r) => r.nombreRegimen === regimen.nombreRegimen)
    );

    regimenesDistintos = this.orderFiltros(regimenesDistintos, 'nombreRegimen');
    regimenesDistintos.unshift({ "nombreRegimen": "TODOS" });
    return regimenesDistintos;
  }

  orderFiltros(fondos, tipo) {
    fondos.sort(function (a, b) {
      var nombreA = a[tipo].toUpperCase();
      var nombreB = b[tipo].toUpperCase();
      if (nombreA < nombreB) {
        return -1;
      }
      if (nombreA > nombreB) {
        return 1;
      }
      return 0;
    });
    return fondos;
  }

  mapperFondo() {
    
    const valor = this.productosSelect;
    let detalleFondoMap = [];
    
    if (valor !== 'TODOS') {
      detalleFondoMap = this.obtenerFondosPorProducto(valor);
    } else {
      
      for (const instrumento of this.instrumentos) {
        detalleFondoMap.push(instrumento.Fondo);
      }
      detalleFondoMap = detalleFondoMap.filter((valor, indice, self) => {
        return self.indexOf(valor) === indice;
      });
    detalleFondoMap = this.orderFondos(detalleFondoMap);
    }
    return detalleFondoMap;
  }

  cambioAutocompleteFondo($event) {
    $event ? this.fondoSelect = $event : this.fondoSelect = 'TODOS';
  }

  onBuscar() {

    const datosFiltrados = {
      fondo: this.fondoSelect ? this.fondoSelect : 'TODOS',
      regimen: this.dropdownRegimen.model,
      producto: this.dropdownProducto.model
    }
   
    this.filtrarDatos.emit(datosFiltrados);
  }

  onBuscarMov() {
    
    const datosFiltrados = {
      fondo: this.fondoSelect ? this.fondoSelect : 'TODOS',
      regimen: this.dropdownRegimenMov.model,
      producto: this.dropdownProductoMov.model
    }
    this.filtrarDatos.emit(datosFiltrados);
    
  }

  onProductoChanged(event) {
    this.productosSelect = event.target.value;
    if (this.productosSelect === "TODOS") {
      this.regimenes = this.mapperRegimen(this.instrumentos);
    } else {
      this.regimenes = this.regimenesFiltrados(this.productosSelect);      
    }
    this.mapperFondo();
    this.instrumentosDesktop.reset();  
    this.fondoSelect = 'TODOS';
    
  }

  regimenesFiltrados(producto) {

    const objetosFiltrados = this.instrumentos.filter(objeto => objeto.Producto === producto);
    let regimenes =  objetosFiltrados.reduce((valores, objeto) => {
      if (!valores.some(val => val.nombreRegimen === objeto.Regimen)) {
        valores.push({ nombreRegimen: objeto.Regimen });
      }
    return valores;
    }, []);
    
    if(regimenes.length > 1) {
      regimenes.unshift({ "nombreRegimen": "TODOS" });
    }
    return regimenes;
  }

  obtenerFondosPorProducto(producto) {
    const objetosFiltrados = this.instrumentos.filter(objeto => objeto.Producto === producto);
    let fondos = [...new Set(objetosFiltrados.map(objeto => objeto.Fondo))].sort();
    return this.orderFondos(fondos);
    
  }

  orderFondos(fondos) {
   return fondos.sort((a, b) => {
      if (a.startsWith("SURA") && !b.startsWith("SURA")) {
        return -1;
      } else if (!a.startsWith("SURA") && b.startsWith("SURA")) {
        return 1;
      } else {
        return a.localeCompare(b);
      }
    });
  }

}
