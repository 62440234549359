import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ClientSearchResponseModel } from '../../../../models/client-search/client-search.model';
import { DataClientModel, PasswordStatus } from '../../../../models/client-summary/data-client.model';
import { DialogService } from './../../../../../shared/services/dialog/dialog.service';
import { SuraPasswordModalComponent, OptionsSuraPassword } from './sura-password-modal/sura-password-modal.component';
import { ClientRequestService } from './../../../../services/client-request/client-request.service';
import { LoadingService } from './../../../../../shared/services';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs';
import { environment } from '@environment';
import { InputLog } from 'src/app/features/models/log/InputLog';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import * as moment from 'moment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { Status } from 'src/app/features/models/log/status';
import { OperacionesContratoLog } from 'src/app/features/models/log/models';
import { isCompany } from './../../../../../shared/helpers/utils';

type Log = Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'>;

@Component({
    selector: 'app-passwords',
    templateUrl: './passwords.component.html',
    styleUrls: ['./passwords.component.scss'],
})
@Injectable({
    providedIn: 'root',
})
export class PasswordsComponent implements OnInit {
    dataClient$: Observable<DataClientModel>;
    private unsubscribe$ = new Subject<void>();
    isOkey: boolean = true;
    clientData: ClientSearchResponseModel;
    isSignedMandate: boolean;
    APV: boolean;
    rut: string;
    rutRepresentante: string;
    emails: any[];
    icon: string = 'success';
    buttonDisabled: boolean = false;
    show = false;
    claveSecreta: any;
    claveSecretaTransaccional: any;

    iconClaveSecreta: any;
    glosaClaveSecreta: any;

    iconClaveSecretaTransactional: any;
    glosaClaveSecretaTransactional: any;

    @ViewChild(SuraPasswordModalComponent) child;
    listStatusPassword: PasswordStatus[];

    alertaClienteSinProductos: boolean;
    alertaMandatoAFP: boolean;

    constructor(
        private dialogService: DialogService,
        private clientRequestService: ClientRequestService,
        private loadingService: LoadingService,
        private location: Location,
        private tracesService: TracesService
    ) { }

    ngOnInit(): void {
        let data = sessionStorage.getItem('clientData');
        if (!data) {
            this.location.back();
            return;
        }
        const dataClient: DataClientModel = JSON.parse(data);

        this.isSignedMandate = dataClient.documentation.filter((doc) => doc.key === 'mandateAfp')[0].isOkey;

        this.APV = dataClient.documentation.filter((doc) => doc.key === 'planApv')[0].isOkey;

        this.rut = dataClient.rut.split('.').join('');

        if (dataClient.companyInfo) {
            const rutRepresentante = dataClient.companyInfo.find((item) => {
                return item.text.includes('-');
            });
            this.rutRepresentante = sessionStorage.getItem('rutRepresentanteLegal') ?? rutRepresentante.text.split('.').join('');
        }

        this.procesarAlertaBotones(dataClient);

        this.listStatusPassword = dataClient.statusPaswword.filter((pass) => pass.codigoEstadoClave > 0 && pass.codigoEstadoClave < 4);
        this.separateListPassword(this.listStatusPassword);

        this.clientRequestService.claveSecreta.subscribe((dataClaveSecreta: any) => {
            const listNow = this.modifyArrayPassword(dataClaveSecreta, this.listStatusPassword);
            this.separateListPassword(listNow);
        });
    }

    private procesarAlertaBotones(dataClient: DataClientModel) {
        let esEmpresa = isCompany(this.rut);

        const log: Log = {
            Categoria: 'Clave SURA',
            Operacion: 'Clave SURA',
            Metodo: 'procesarAlertaBotones()',
            eventoLog: 'Carga inicial',
            status: Status.NOK,
            Secuencia: 1,
            EntradaLog: {
                data_cliente: dataClient
            },
        };

        let sinProductos = !dataClient.products.some(({exists}) => exists);

        /** Sin productos */
        if (sinProductos) {
            this.alertaClienteSinProductos = true;
            this.buttonDisabled = true;
            this.logData(log, 'log_error');
            return;
        }

        let soloAFP = dataClient.products.every(({ displayName, exists }) => (displayName === 'AFP' && exists) || (displayName !== 'AFP' && !exists));

        /** Solo AFP && Sin Mandato && No Empresa => Deshabilitar BTN / Mensaje  */
        if (soloAFP && !this.isSignedMandate && !esEmpresa) {
            this.alertaMandatoAFP = true;
            this.buttonDisabled = true;
            this.logData(log, 'log_negocio');
            return;
        }
    }

    async openSuraPassword(flag?: string) {
        if (this.buttonDisabled) return;
        let emails: any[] = [];
        this.loadingService.showLoading();
        if (Number(this.rut.split('-')[0]) > 50000000) {
            const params = {
                rut: this.rut,
                rutRepresentante: this.rutRepresentante,
            };
            emails = await this.clientRequestService.getAllEmailsPersonaJuridica(params);
        } else {
            emails = await this.clientRequestService.getAllEmails(this.rut, this.APV, this.isSignedMandate);
        }
        let optionsMessage: OptionsSuraPassword;

        if (emails.length > 0) optionsMessage = { emailList: emails, step: 1, flag };
        else {
            optionsMessage = {
                step: 2,
                flag,
                emailList: [
                    {
                        CodigoLineaNegocio: '',
                        CorreoElectronico: '',
                        Telefono: '',
                    },
                ],
            };
        }
        const config = {
            panelClass: ['modal-800', 'top'],
            disableClose: false,
            data: {
                optionsMessage,
                rutRepresentante: this.rutRepresentante,
            },
        };
        let ope: OperacionesContratoLog = `Clave SURA`;
        let cat: OperacionesContratoLog = `Clave SURA`;
        if (flag === 'CT') {
            cat = `Clave Transaccional`;
            ope = `Clave Transaccional`;
        }
        this.logData(
            {
                Categoria: cat,
                Operacion: ope,
                Metodo: 'separateListPassword()',
                eventoLog: `Click en Crear clave ${ flag === 'CS' ? 'SURA' : ' TRANSACCIONAL' }`,
                status: Status.OK,
                Secuencia: 2,
                EntradaLog: {
                    listaCorreos: emails
                },
            },
            'log_negocio',
        );
        this.loadingService.hideLoading();
        this.dialogService.openDialog(SuraPasswordModalComponent, config);
    }

    modifyArrayPassword(listNew: any, listOld: any) {
        listOld.map((old) => {
            if (old.glosaTipoClave.trim() === listNew[0].glosaTipoClave.trim()) {
                old.codigoEstadoClave = listNew[0].codigoEstadoClave;
                old.glosaEstadoClave = listNew[0].glosaEstadoClave;
                old.colorStausIcon = listNew[0].colorStausIcon;
                old.typeStatusIcon = listNew[0].typeStatusIcon;
            }
        });
        return listOld;
    }

    separateListPassword(listStatusPassword: any) {
        this.claveSecreta = {};
        this.claveSecretaTransaccional = {};
        listStatusPassword.forEach((password: PasswordStatus) => {
            if (password.glosaTipoClave.trim() === 'SECRETA' || password.glosaTipoClave === 'CLAVE SECRETA') {
                this.claveSecreta = password;
            }
            if (password.glosaTipoClave.trim() === 'TRANSACCIONAL' || password.glosaTipoClave === 'CLAVE TRANSACCIONAL') {
                this.claveSecretaTransaccional = password;
            }
        });
        this.logData(
            {
                Categoria: 'Clave SURA',
                Operacion: 'Clave SURA',
                Metodo: 'separateListPassword()',
                eventoLog: 'Carga inicial',
                status: Status.OK,
                Secuencia: 1,
                EntradaLog: {
                    estado_clave_SURA: this.claveSecreta.glosaEstadoClave,
                    estado_clave_Transaccional: this.claveSecretaTransaccional.glosaEstadoClave,
                },
            },
            'log_negocio',
        );
    }

    async logData(log: Log, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...EntradaLog,
            };
            inputLog.Salida = '-';
            inputLog.Tipo_sesion = 'ASESOR';
            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, 'NWSA_ASESOR', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }
}
