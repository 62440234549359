import { Injectable } from '@angular/core';
import { environment, urlMsCR } from '@environment';
import { RequestHandlerService } from '../../../core/services/request-handler/request-handler.service';
import { ClientPortfolioModel } from '../../models/client-portfolio/client-portfolio.model';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from '../login/login.service';
import { LoadingService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root',
})
export class ClientPortfolioRequestService {
  clientPortfolio$ = new BehaviorSubject<ClientPortfolioModel[]>([]);

  constructor(private requestHandler: RequestHandlerService, private login: LoginService, private loadingService: LoadingService) {}

  async getExecutivePortfolio(): Promise<ClientPortfolioModel[]> {
    const url = `${urlMsCR}/cartera-clientes/`
    sessionStorage.setItem("loadingCartera", "true");
    return this.requestHandler
      .doGet<ClientPortfolioModel[]>(url)
      .then((portfolio: any) => {
        this.clientPortfolio$.next(portfolio.data);
        sessionStorage.setItem("loadingCartera", "false");
        return portfolio;
      })
      .catch((error) => {
        this.loadingService.hideLoading();
        if (error.status === 403) {
          this.login.signOutSession();
        }
        console.error(error);
        sessionStorage.setItem("loadingCartera", "false");
        return [];
      });
  }

   getOwnerOfTheData(executiveRut: string ): boolean {
    const portfolio = this.clientPortfolio$.value[0];
    return (executiveRut === portfolio?.RutEjecutivo || 
        executiveRut === portfolio?.RutJefe || 
        executiveRut === portfolio?.RutSupervisor);
   }
}
