import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatBotAsesorComponent } from './chat-bot-asesor.component';
import {FormsModule} from "@angular/forms";
import {SuraCommonsUiModule} from "@surainvestments/sura-common-ui";
import {ResizableModule} from "angular-resizable-element";
import { LinkifyDirective } from '../../directives/detectarEnlaces/detectaEnlaces.directive';
import { TerminosServicioComponent } from './terminos-servicio/terminos-servicio.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { ChatBotLogService } from '../../services/chat-bot/chat-bot-log.service';
import { LogService } from '../../services/logs/logs.service';
import { AssetService } from '../../services/asset/asset.service';
import * as marked from 'marked';

@NgModule({
    declarations: [ChatBotAsesorComponent, LinkifyDirective, TerminosServicioComponent],
    exports: [
        ChatBotAsesorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SuraCommonsUiModule,
        ResizableModule,
        MatDialogModule,
        MatButtonModule,
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useValue: {
                    gfm: true,
                    breaks: false,
                    pedantic: false,
                    smartLists: true,
                    smartypants: false,
                    renderer: new (class extends marked.Renderer {
                        link(href: string, title: string, text: string) {
                            return `<a href="${href}" title="${title || ''}" target="_blank">${text}</a>`;
                        }
                    })()
                }
            }
        })
    ],
    providers: [
        ChatBotLogService,
        LogService,
        AssetService
    ],
    entryComponents: [TerminosServicioComponent]
})
export class ChatBotAsesorModule { }
