import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OrdenesPershingService } from 'src/app/features/services/ordenes/pershing/ordenes.pershing.service';
import { SurapassService } from 'src/app/features/services/surapass/surapass.service';
import {
    ClienteActivoDto,
    InstrumentoCompraPershingJson, SaldoCustodioCtaPershingJson,
    SolicitudPershingJson,
    SolicitudPershingOrdenInstrumentoJson,
    SolicitudPershingOrdenJson
} from '../../../../../../models/ordenes/pershing/orden.modelo';

enum AccionCarrito {
    edit = 'edit',
    cDlt = 'confirmDelete',
}

@Component({
    selector: 'app-carro-compra-instrumentos',
    templateUrl: './carro-compra-instrumentos.component.html',
    styleUrls: ['./carro-compra-instrumentos.component.scss'],
})
export class CarroCompraInstrumentosComponent implements OnInit {
    //CARRO DE COMPRA
    @Input() cuenta: SaldoCustodioCtaPershingJson;
    @Input() carrito: InstrumentoCompraPershingJson[];
    @Input() instrumentos: InstrumentoCompraPershingJson[];
    @Input() tooltipmsgok;
    @Input() tooltipmsgerror;
    @Input() custodiocompratotalUSD: number;

    tipoCompra = [
        { id: 'P', valor: '%' },
        { id: 'M', valor: 'USD$' },
        //{ id: 'C', valor: '#' },
    ];

    //total de venta en USD
    editarForm: FormGroup;

    //ver modal de resumen carrito
    showModal = false;
    loadDetail = false;

    Accion = AccionCarrito;

    public MONTO_MINIMO: number = 1;
    public MONTO_MAXIMO: number = 9999999999;
    public PORCENTAJE_MINIMO: number = 1;
    public PORCENTAJE_MAXIMO: number = 100;
    public USD_INGRESO: number;

    //FORM CONTROLES
    public tipoingreso = new FormControl('', [Validators.required]);
    public montocandidadporcentaje = new FormControl('', [Validators.required, this.validateMinValue(this.MONTO_MINIMO), this.validateMaxValue(this.MONTO_MAXIMO)]);

    constructor(private ordenesService: OrdenesPershingService, private fb: FormBuilder, private router: Router, private surapassService: SurapassService) {}

    ngOnInit() {
        this.inicializaFormulario();
    }

    inicializaFormulario() {
        //inicializa formulario
        this.editarForm = this.fb.group({
            tipoingreso: this.tipoingreso,
            montocandidadporcentaje: this.montocandidadporcentaje,
        });
    }

    /**
     * Cambia el tipo de compra
     * @param $event
     */
    tipoIngresoChange(instrumento): void {
        if (this.tipoingreso.value === 'P') {
            let cuandoDisponible = Number(this.custodiocompratotalUSD);
            let cuandoCarro = Number(this.totalcarritoUSD() - instrumento.agregadoTotalInstruccionUSD);
            let porcentajeEquivalencia = (100 * cuandoCarro) / cuandoDisponible;
            this.PORCENTAJE_MINIMO = 1;
            this.PORCENTAJE_MAXIMO = Number((100 - porcentajeEquivalencia).toFixed(2));
            if (this.PORCENTAJE_MINIMO > this.PORCENTAJE_MAXIMO) this.PORCENTAJE_MINIMO = this.PORCENTAJE_MAXIMO;
            this.USD_INGRESO = 0;
            this.montocandidadporcentaje.setValidators([Validators.required, this.validateMinValue(this.PORCENTAJE_MINIMO), this.validateMaxValue(this.PORCENTAJE_MAXIMO)]);
            this.montocandidadporcentaje.updateValueAndValidity();
        }
        if (this.tipoingreso.value === 'M') {
            this.MONTO_MINIMO = 1;
            this.MONTO_MAXIMO = Number((this.custodiocompratotalUSD - (this.totalcarritoUSD() - instrumento.agregadoTotalInstruccionUSD)).toFixed(2));
            if (this.MONTO_MINIMO > this.MONTO_MAXIMO) this.MONTO_MINIMO = this.MONTO_MAXIMO;
            this.USD_INGRESO = 0;
            this.montocandidadporcentaje.setValidators([Validators.required, this.validateMinValue(this.MONTO_MINIMO), this.validateMaxValue(this.MONTO_MAXIMO)]);
            this.montocandidadporcentaje.updateValueAndValidity();
        }
        this.actualizaValorIngresado(this.montocandidadporcentaje.value);
    }

    /**
     * Cambia el valor del monto
     * @param $event
     */
    montoCantidadPorcentajeChange($event) {
        let valortmp = Number($event.target.value.replaceAll('.', '').replaceAll(',', '.'));
        this.actualizaValorIngresado(valortmp);
    }

    /**
     * Actualiza el valor
     * @param valortmp
     */
    actualizaValorIngresado(valortmp) {
        if (this.tipoingreso.value === 'P') {
            this.USD_INGRESO = this.custodiocompratotalUSD * (valortmp / 100);
        }
        if (this.tipoingreso.value === 'M') {
            this.USD_INGRESO = valortmp ? valortmp: 0;
        }
    }

    openModal() {
        this.showModal = !this.showModal;
    }

    closeModal() {
        this.showModal = false;
        this.closeItems();
    }

    closeItems() {
        this.carrito.forEach((object) => {
            object.accion = null;
        });
    }

    /**
     * Edita el item
     * @param item 
     * @param i 
     */
    editarItem(item) {
        this.closeItems();
        item.accion = this.Accion.edit;
        this.editarForm.setValue({
            tipoingreso: 'P',
            montocandidadporcentaje: null,
        });
        this.editarForm.markAsUntouched();
        this.tipoIngresoChange(item);
    }

    eliminarItem(item) {
        item.accion = this.Accion.cDlt;
    }

    /**
     * Modifica los valores ingresados
     * @param item
     */
    modificarValorIngresado(item) {
        if (this.editarForm.valid) {

            //LOG DE ACCION
            this.ordenesService.saveLogAccionAsesor(
            'Ingreso Ordenes Pershing Off Line', 
            'Ingreso Ord Pershing Off Line Paso 1', 
            'CarroCompraInstrumentosComponent - modificarValorIngresado()', 'ok', `Modifica valor ingresado de instrumento cambio de fondo destino`, item);

            
            //BUSCAMOS EL CARRO Y LO ACTUALIZAMOS
            this.carrito.forEach( card => {
                if (card['fondo-ticker'] === item['fondo-ticker']) {
                    card.tipocompra = this.tipoingreso.value;
                    card.agregadoMontoCantidad =  this.montocandidadporcentaje.value.replaceAll('.', '').replaceAll(',', '.');
                    card.agregadoTotalInstruccionUSD = this.USD_INGRESO;
                }
            });

            //ACTUALIZA EL CARRITO
            sessionStorage.setItem(this.ordenesService.STORAGE_PERSHING_COMPRA_CARRO, JSON.stringify(this.carrito));
           
            item.accion = null;
        } else {
            //Verifica los campos
            if (!this.tipoingreso.valid) {
                this.tipoingreso.markAsTouched();
            } else {
                this.tipoingreso.markAsUntouched();
            }
            //Verifica los campos
            if (!this.montocandidadporcentaje.valid) {
                this.montocandidadporcentaje.markAsTouched();
            } else {
                this.montocandidadporcentaje.markAsUntouched();
            }
        }
    }

    /**
     * Elimina el item seleccionado
     * @param item
     */
    emilinarInstrumentoIngresado(item) {
        let ticker = item['fondo-ticker'];
        this.carrito.splice(
            this.carrito.findIndex((itemsr) => itemsr['fondo-ticker'] === item['fondo-ticker']),
            1,
        );

        //ACTUALIZO EL CARRO DE COMPRA EN EL STORAGE
        sessionStorage.setItem(this.ordenesService.STORAGE_PERSHING_COMPRA_CARRO, JSON.stringify(this.carrito));

        //QUITAMOS DEL OBSERVABLE LA MARCA DE AGREGADO

        this.instrumentos.forEach((instupate) => {
            if (instupate['fondo-ticker'] === ticker) {
                instupate.agregado = false;
                instupate.seleccionado = false;
                instupate.accion = '';
            }
        });

        //genera mensaje tooltip en el carro
        this.tooltipmsgerror = '¡ Orden eliminada !';
        setTimeout(() => {
            this.tooltipmsgerror = null;
        }, 2000);

        //LOG DE ACCION
        this.ordenesService.saveLogAccionAsesor(
        'Ingreso Ordenes Pershing Off Line', 
        'Ingreso Ord Pershing Off Line Paso 1', 
        'CarroCompraInstrumentosComponent - emilinarInstrumentoIngresado()', 'ok', `Elimina instrumento cambio de fondo destino`, item);


        //cierra el window
        if (this.carrito.length === 0) {
            this.showModal = false;
        }

        item.accion = null;
    }

    /**
     * Consulta el cliente conectado de la ultima session en el Storage
     * @returns Cliente conectado
     */
    validarClienteActivo(): ClienteActivoDto {
        let data = sessionStorage.getItem('clientData');
        if (data) {
            const dataClient = JSON.parse(data);
            let rutlimpio = dataClient.rut.split('.').join('');
            let rutPartes = rutlimpio.split('-');
            let nombre = dataClient.name;
            return {
                rut: rutlimpio,
                rutparteentera: Number(rutPartes[0]),
                rutdigitoverificador: rutPartes[1],
                email: this.recuperaEmail(dataClient),
                nombre: nombre,
            };
        } else {
            this.router.navigate(['main/client-search']);
            return undefined;
        }
    }

    recuperaEmail(dataClient) {
        let email = undefined;
            
        if (dataClient.clientInfo) {
            dataClient.clientInfo.forEach( artsa => {
                if (artsa.icon === 'mail') {
                    email = artsa.text;
                }
            })
        }
        if (dataClient.companyInfo) {
            dataClient.companyInfo.forEach( artsb => {
                if (artsb.icon === 'mail') {
                    email = artsb.text;
                }
            })
        }
        if (!email) email = 'sincorreo@sura.cl';
        return email;
    }

    totalcarritoUSD() {
        let totalUSD: number = 0;
        if (this.carrito.length > 0) {
            this.carrito.forEach((inst) => {
                totalUSD += inst.agregadoTotalInstruccionUSD;
            });
        }
        return totalUSD;
    }

    /**
     * Recupera los fondos de venta
     * @returns 
     */
    fondosOrigen() {
        let carroVenta = sessionStorage.getItem(this.ordenesService.STORAGE_PERSHING_VENTA_CARRO);
       return JSON.parse(carroVenta) || [];
    }

  

    /**
     * OK confirma el envio del carro
     */
    goToCarroOK() {
        if (this.carrito.length > 0) {

             //LOG DE ACCION
            this.ordenesService.saveLogAccionAsesor(
                'Ingreso Ordenes Pershing Off Line', 
                'Ingreso Ord Pershing Off Line Paso 1', 
                'CarroCompraInstrumentosComponent - goToCarroOK()', 'ok', `Confirma ingreso de orden de cambio de Fondo`);

            
            //datos de ejecutivo
            let ejecutivoJson = sessionStorage.getItem('dataContentful');
            let ejecutivoAdicJson = sessionStorage.getItem('dataExecutive');
            let ejecutivoAdic = JSON.parse(ejecutivoAdicJson);
            let ejecutivo = JSON.parse(ejecutivoJson);

            //cuenta seleccionada
            let cuentaJson = sessionStorage.getItem(this.ordenesService.STORAGE_PERSHING_CUENTA_SELECCIONADA);
            let cuenta: SaldoCustodioCtaPershingJson = JSON.parse(cuentaJson);

            //datos cliente
            let cliente = this.validarClienteActivo();
            
            //RECUPERA EL CARRO DE COMPRA
            let carroFinalJson = sessionStorage.getItem(this.ordenesService.STORAGE_PERSHING_CARRO_FINAL);
            let solicitudFinal: SolicitudPershingJson = JSON.parse(carroFinalJson) || {
                'id-ejecutivo': ejecutivo.executiveRut,
                'nombre-ejecutivo': ejecutivo.executiveName,
                'email-ejecutivo': ( ejecutivoAdic && ejecutivoAdic.MailEjecutivo ? ejecutivoAdic.MailEjecutivo : 'ejecutivo@sura.cl'),                    'rut-cliente': cliente.rutparteentera,
                'rut-dv-cliente': cliente.rutdigitoverificador,
                'nombre-cliente': cliente.nombre,
                'email-cliente': cliente.email,
                'cantidad-ordenes-compra': 0,
                'cantidad-ordenes-venta': 0,
                'cantidad-ordenes-cambio-fondo': 0,
                'fecha-valorizacion': cuenta['tipo-cambio-fecha'],
                ordenes: [],
            };
            solicitudFinal['rut-cliente'] = cliente.rutparteentera;
            solicitudFinal['rut-dv-cliente'] = cliente.rutdigitoverificador;
            solicitudFinal['nombre-cliente'] = cliente.nombre;
            solicitudFinal['email-cliente'] = cliente.email;

            //cargamos la nueva orden de compra
            let nuevaOrden: SolicitudPershingOrdenJson = {
                id: solicitudFinal.ordenes.length + 1,
                instruccion: 'Cambio Fondo',
                'numero-cuenta': cuenta['numero-cuenta'],
                'descripcion-cuenta': cuenta['descripcion-cuenta'],
                'tipo-regimen': cuenta['tipo-regimen'],
                totalusdcompra: 0,
                totalusdventa: 0,
                instrumentos: [],
            };

            let totalVentaUSD = 0;
            let totalCompraUSD = 0;

            //instrumentos a incroporar a la solicitud
            let nuevosInstrumentos: SolicitudPershingOrdenInstrumentoJson[] = [];

            //carga los instrumentos de venta
            let carroVenta = sessionStorage.getItem(this.ordenesService.STORAGE_PERSHING_VENTA_CARRO);
            let custodioventa = JSON.parse(carroVenta) || [];
            custodioventa.forEach((itemcarro) => {
                let itemAdd: SolicitudPershingOrdenInstrumentoJson = {
                    ticker: itemcarro['ticker'],
                    'tipo-instrumento': itemcarro['tipo-instrumento'],
                    'numero-cuenta': cuenta['numero-cuenta'],
                    'origen-destino': 'O', //O=ORIGEN (COMPRA/VENTA)  D=CAMBIO DE FONDOS DESTINO
                    'nombre-instrumento': itemcarro['nombre-instrumento'],
                    'tipo-compra': null,
                    'tipo-venta': itemcarro.tipoventa,
                    cuotas: itemcarro['cuotas'],
                    'valor-cuota-usd': itemcarro['cuotavalor-cuota-usd'],
                    'saldo-valorizado-usd': itemcarro['saldo-valorizado-usd'],
                    'monto-cuotas-porcentaje': Number(itemcarro.agregadoMontoCantidadCuotas),
                    'total-instruccion-usd': Number(itemcarro.agregadoTotalInstruccionUSD),
                    'comision-usd': Number(itemcarro.montocomisionUSD),
                    'porcentaje-riesgo': 0,
                    'rango-porcentaje-minimo': 0,
                    'rango-porcentaje-maximo': 0,
                    'rango-cuotas-minimo': 0,
                    'rango-cuotas-maximo': 0,
                    'rango-monto-usd-minimo': 0,
                    'rango-monto-usd-maximo': 0,
                    'captura-nota-riesgo': this.recuperaNotaRiesgo(itemcarro),
                    'captura-nota-descuento':  this.recuperaNotaDescuento(itemcarro),
                    'captura-indicador-descuento': this.recuperaIndicadorDescuento(itemcarro),
                    'captura-permite-descuento': this.recuperaPermiteDescuento(itemcarro),
                    'tipo-serie': this.recuperaSerie(itemcarro)
                };
                //agrega instrumentos uno a uno
                nuevosInstrumentos.push(itemAdd);

                //sumatoria
                totalVentaUSD += itemAdd['total-instruccion-usd'];
            });

            //cargamos los instrumentos
            this.carrito.forEach((itemcarro) => {
                    let itemAdd: SolicitudPershingOrdenInstrumentoJson = {
                        ticker: itemcarro['fondo-ticker'],
                        'tipo-instrumento': 'Fondos Mutuos',
                        'numero-cuenta': cuenta['numero-cuenta'],
                        'origen-destino': 'D', //O=ORIGEN (COMPRA/VENTA)  D=CAMBIO DE FONDOS DESTINO
                        'nombre-instrumento': itemcarro['fondo-descripcion'],
                        'tipo-compra': itemcarro.tipocompra,
                        'tipo-venta': null,
                        cuotas: 0,
                        'valor-cuota-usd': 0,
                        'saldo-valorizado-usd': 0,
                        'monto-cuotas-porcentaje': Number(itemcarro.agregadoMontoCantidad),
                        'total-instruccion-usd': Number(itemcarro.agregadoTotalInstruccionUSD),
                        'comision-usd': Number(itemcarro.montocomisionUSD),
                        'porcentaje-riesgo': itemcarro['fondo-nota-riesgo'],
                        'rango-porcentaje-minimo': 0,
                        'rango-porcentaje-maximo': 0,
                        'rango-cuotas-minimo': 0,
                        'rango-cuotas-maximo': 0,
                        'rango-monto-usd-minimo': 0,
                        'rango-monto-usd-maximo': 0,
                        'captura-nota-riesgo': itemcarro['fondo-nota-riesgo'],
                        'captura-nota-descuento': itemcarro['fondo-nota-descuento'],
                        'captura-indicador-descuento': itemcarro['indicador-comision'],
                        'captura-permite-descuento': itemcarro['permite-comision'],
                        'tipo-serie':  itemcarro['fondo-serie'] === 'P' ? 'Preferente' : itemcarro['fondo-serie'] === 'N' ? 'Normal': 'Mixto'
                    };

                    //agrega instrumentos uno a uno
                    nuevosInstrumentos.push(itemAdd);

                    //sumatoria
                    totalCompraUSD += itemAdd['total-instruccion-usd'];
                });
         

            //setea instrumentos
            nuevaOrden.instrumentos = nuevosInstrumentos;
            nuevaOrden.totalusdcompra = totalCompraUSD;
            nuevaOrden.totalusdventa = totalVentaUSD;

            //agregar la nueva orden a la solicitud

            solicitudFinal.ordenes.push(nuevaOrden);

            //LOG DE ACCION
            this.ordenesService.saveLogAccionAsesor(
            'Ingreso Ordenes Pershing Off Line', 
            'Ingreso Ord Pershing Off Line Paso 1', 
            'CarroCompraInstrumentosComponent - goToCarroOK()', 'ok', `Nueva orden de cambio de fondo ingresada`, nuevaOrden);


            //actualiza la solicitud con los datos incorporados
            sessionStorage.setItem(this.ordenesService.STORAGE_PERSHING_CARRO_FINAL, JSON.stringify(solicitudFinal));

            //redireccina
            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
            this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/carro`);
  
        }
    }

    recuperaNotaRiesgo(item) {
        return item && item['fondo-nota-riesgo'] ? item['fondo-nota-riesgo'] : 1;
    }

    recuperaNotaDescuento(item) {
        return item && item['fondo-nota-descuento']? item['fondo-nota-descuento'] : 0;
    }

    recuperaIndicadorDescuento(item) {
        return item && item['indicador-comision']? item['indicador-comision'] : 'O';
    }

    recuperaPermiteDescuento(item) {
        return item && item['permite-comision']? item['permite-comision']: 'N';
    }


    recuperaSerie(item) {
        if (item && item['fondo-serie']) {
            return item['fondo-serie'] === 'P' ? 'Preferente' : item['fondo-serie'] === 'N' ? 'Normal': 'Mixto';
        } else {
            return 'Normal';
        }
    }

    public validateMinValue(minValue: number) {
        return (currentControl: AbstractControl): { [key: string]: any } => {
            let isValid = true;
            const currentNumber = currentControl?.value ? currentControl?.value : '';
            if (currentNumber != '' && Number(currentNumber.replaceAll('.', '').replaceAll(',', '.')) < minValue) {
                isValid = false;
            }
            return isValid ? null : { min: true };
        };
    }

    public validateMaxValue(maxValue: number) {
        return (currentControl: AbstractControl): { [key: string]: any } => {
            let isValid = true;
            const currentNumber = currentControl?.value ? currentControl?.value : '';
            if (Number(currentNumber.replaceAll('.', '').replaceAll(',', '.')) > maxValue) {
                isValid = false;
            }
            return isValid ? null : { max: true };
        };
    }
}
