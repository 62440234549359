// Angular Core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../../../shared/shared.module';


import { OrdenCambioFondoCompraComponent } from './orden-cambio-fondo-compra.component';
import { OrdenCambioFondoCompraComponentRoutingModule } from './orden-cambio-fondo-compra-routing.module';
import { MainOrdenCambioFondoCompraComponent } from './components/main-orden-cambio-fondo-compra.component';
import { CarroCompraInstrumentosComponent } from './components/carro-compra-instrumentos/carro-compra-instrumentos.component';
import { DetalleInstrumentosCompraSeleccionarComponent } from './components/detalle-instrumentos-compra-seleccionar/detalle-instrumentos-compra-seleccionar.component';
import { ModalFiltroInstrumentosComponent } from './components/modal-filtro-instrumentos/modal-filtro-instrumentos.component';

@NgModule({
  declarations: [
    OrdenCambioFondoCompraComponent,
    MainOrdenCambioFondoCompraComponent,
    CarroCompraInstrumentosComponent,
    DetalleInstrumentosCompraSeleccionarComponent,
    ModalFiltroInstrumentosComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    OrdenCambioFondoCompraComponentRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    OverlayModule
  ],
})
export class OrdenCambioFondoCompraModule {}
