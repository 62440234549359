import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'mmb-wizzard',
    templateUrl: './mmb-wizzard.component.html',
    styleUrls: ['./mmb-wizzard.component.scss'],
})
export class MmbWizzardComponent implements OnChanges {
    @Input() currentStep: number;
    @Input() steps;

    porcentaje = null;

    stepsNumbers = 0;

    ngOnChanges(c: SimpleChanges) {
        if (c.steps && c.steps.currentValue.length) {
            this.stepsNumbers = this.steps.length;
            this.porcentaje = 100 / this.stepsNumbers / 2 + ((100 - 100 / this.stepsNumbers) / (this.stepsNumbers - 1)) * (this.currentStep - 1);
            this.porcentaje = this.porcentaje > 50 ? 100 : this.porcentaje;
        }
    }

    getPercent() {
        return this.porcentaje;
    }
}
