import { Injectable } from '@angular/core';
import { UrlCR } from '@environment';
import { RequestHandlerService } from 'src/app/core/services/request-handler/request-handler.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureVarsService {

    constructor(
        private requestHandler: RequestHandlerService
    ) { }

    async obtener(detalleCategoria?: string, idCategoria?: string, codigoVariable?: string) {

        try {
            let query = idCategoria ? `codigoCategoria=${idCategoria}` : '';
            query = codigoVariable ? `&codigo=${codigoVariable}` : ''
            query = detalleCategoria ? `&detalleCategoria=${detalleCategoria}` : ''
            const url = `${UrlCR}/transversal/feature-vars?${query}`;
            return await this.requestHandler.doGet<any>(url);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async getMapped(input:any): Promise<any> {
        const obj = {};
        try {
            const { detalleCategoria, idCategoria, codigoVariable } = input;
            const items: any = await this.obtener(detalleCategoria, idCategoria, codigoVariable);
            const vars = items.data ?? [];
            for (const vr of vars) {
                const svars = vr.subFeatureVars ?? [];
                vr.svrs = {};
                for (const svr of svars) {
                    vr.svrs[svr.codigo] = svr;
                }
                obj[vr.codigo] = vr;
            }
            return obj;
        } catch (err) {
            return obj;
        }
    }
}
