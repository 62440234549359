import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  parametroSubject = new BehaviorSubject<any>('valor inicial');

  updateParametro(value: any) {
    this.parametroSubject.next(value);
  }
  
  constructor() { }

  getImagePath(imageName: string): string {
    return `assets/img/${imageName}`;
  }
}

