import { Injectable } from '@angular/core';
import { RequestHandlerService } from '../../../core/services/request-handler/request-handler.service';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ClientOptimusService {
  constructor(       
     private requestHandler: RequestHandlerService,
    ) { }

  async getValidarBloqueoOptimus() {
    try {
        const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTUSERS}/validarBloqueoOptimus`;
        return this.requestHandler.doGet<any>(url);

    } catch (error) {
        return error;
    }
  }
}