import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loading = false;
  public hiddenContent = false;

  public showLoading(hidden = true) {
    this._loading = true;
    this.hiddenContent = hidden;
  }

  public hideLoading() {
    this._loading = false;
    this.hiddenContent = false;
  }

  public toggleLoading() {
    this._loading = !this._loading;
  }

  public get loading() {
    return this._loading;
  }
}
