import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import * as rutHelpers from 'rut-helpers';

@Directive({
  selector: '[appFormatRutName]',
})
export class RutNameDirective {
  @Output() onenter: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('keyup', ['$event']) onBlur(ev: Event) {
    const htmlInputElement: HTMLInputElement = ev.target as HTMLInputElement;
    const value = htmlInputElement.value.trim();

    if (value === '0') {
      htmlInputElement.value = '';
    }
    
    if (value.charAt(0) === '0') {
      htmlInputElement.value = htmlInputElement.value.substr(1);
    }

    const convertedValue = Number( value.split('-')[0].replace('.','').replace('-',''));
    if(isNaN(convertedValue)) return;

    htmlInputElement.value = rutHelpers.rutFormat(htmlInputElement.value) || '';
    htmlInputElement.dispatchEvent(new Event('input'));
  }

  @HostListener('keydown.enter', ['$event']) onKeydownHandler(_: KeyboardEvent) {
    this.onenter.emit('enter');
  }
}
