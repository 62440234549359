import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainOrdenCarroComponent } from './components/main-orden-carro.component';
import { OrdenCarroComponent } from './orden-carro.component';

const routes: Routes = [
  {
    path: '',
    component: MainOrdenCarroComponent,
    children: [
      {
        path: '',
        component: OrdenCarroComponent,
      },
      { path: '**', redirectTo: '' },
    ],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrdenCarroComponentRoutingModule {}
