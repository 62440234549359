import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class DocumentsService {
  constructor(public http: HttpClient) {}

  async getExcel(body: any): Promise<any> {
    try {
        const config = environment.ENDPOINTS.BFF.BFF_DOCUMENTS;
        const endpoint = config + "/procesar-excel";
        return await this.http.post<any>(endpoint, body).toPromise();
    } catch (error) {
        console.warn(error);
    }
}

}
