import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-poll",
  templateUrl: "./poll.component.html",
  styleUrls: ["./poll.component.scss"]
})
export class PollComponent {

  urlPoll: string;

  constructor(
    public dialogRef: MatDialogRef<PollComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.urlPoll = data.url;
    }
  }

  onClosePoll() {
    this.dialogRef.close();
  }
}
