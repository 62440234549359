import { DetalleAccion } from "./DetalleAccion.interface";
import { CategoriasContratoLog, LineaNegocioContratoLog, OperacionesContratoLog, TipoSesionContratoLog, TransaccionesSura } from "./models";
import { Status } from "./status";
export class InputLog {
  DetalleAccionExtra: any = {};

  Operacion: OperacionesContratoLog;

  Secuencia = 0;

  Metodo = "";

  EntradaLog: any = {};

  Salida = "-";

  status: Status = Status.OK;

  Servicio = "-";

  extra: any = {};

  details: any;

  Categoria: CategoriasContratoLog;

  /* tslint:disable-next-line:variable-name */
  Linea_negocio: LineaNegocioContratoLog;

  /* tslint:disable-next-line:variable-name */
  Detalle_accion: DetalleAccion;

  /* tslint:disable-next-line:variable-name */
  Tipo_sesion: TipoSesionContratoLog;

  eventoLog: string;

  detalleLogTransaccionSURA?: string;

  constructor(data?: Partial<InputLog>) {
    this.DetalleAccionExtra = data?.DetalleAccionExtra;
    this.Operacion = data?.Operacion;
    this.Secuencia = data?.Secuencia;
    this.Metodo = data?.Metodo;
    this.EntradaLog = data?.EntradaLog;
    this.Salida = data?.Salida;
    this.status = data?.status;
    this.Servicio = data?.Servicio;
    this.extra = data?.extra;
    this.details = data?.details;
    this.Categoria = data?.Categoria;
    this.Linea_negocio = data?.Linea_negocio;
    this.Detalle_accion = data?.Detalle_accion;
    this.Tipo_sesion = data?.Tipo_sesion;
    this.eventoLog = data?.eventoLog;
  }
}

export type AliasInputLogType = "Categoria" | "Metodo" | "Operacion" | "status";
export class DetalleLogTransaccion {
  folioTransaccion: string;

  rutClienteTransaccion: string;

  tipoTransaccion: TransaccionesSura;

  fechaTransaccion: string;

  fondoOrigen: string;

  regimenOrigen: string;

  serieOrigen: string;

  porcentajeOrigen: string;

  cuotasOrigen: string;

  montoOrigen: string;

  fondoDestino: string;

  regimenDestino: string;

  serieDestino: string;

  porcentajeDestino: string;

  cuotasDestino: string;

  montoDestino: string;

  idSession: string;

  rutEjecutivo: string;

  lineaNegocio: LineaNegocioContratoLog;
}

export class FondoOrigenLogTransaccion {
  fondoOrigen: string;

  regimenOrigen: string;

  serieOrigen: string;

  porcentajeOrigen: string;

  cuotasOrigen: string;

  montoOrigen: string;
}

export class FondoDestinoLogTransaccion {
  fondoDestino: string;

  regimenDestino: string;

  serieDestino: string;

  porcentajeDestino: string;

  cuotasDestino: string;

  montoDestino: string;
}
