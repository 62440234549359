import { FormControl } from '@angular/forms';

export const nombreValidator = (control: FormControl) => {
var letras="abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ";

  if (!control.value) {
    return null;
  }

   let nombre = control.value.toLowerCase();
  for(let i=0; i <nombre.length; i++){
     if (letras.indexOf(nombre.charAt(i),0)!=-1){
        return null;
     }else{
        return  {
            errorRut: 'rutinvalido',
        };
     }
  }
}