import { MenuItemModel } from '../../../models/menu-item.model';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GtmService } from "../../../../shared/services/gtm/gtm.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-button',
  templateUrl: './side-button.component.html',
  styleUrls: ['./side-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideButtonComponent {
  @Input() menuItem: MenuItemModel;
  @Input() active = false;

  constructor(private readonly router: Router,private gtmService: GtmService) {}

  async goTo() {
    const { blank, url, isFnAction } = this.menuItem;
    this.gtmService.pushTagEventoInteractivo(
      'evento-interactivo',
      'Menu Lateral',
      'Click',
      this.menuItem.text
    );

    if(isFnAction) return this.menuItem.fnAction();

    if (!url) return;

    if (blank) return window.open(url);

    await this.router.navigate(['./'], {
      skipLocationChange: true,
    });

    this.router.navigate([url]);
  }
}
