import { formatDate, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Component, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environment';
import { SURAPassAPIPagoPershingJSON, SURAPassTransaccionCampoJson, SURAPassTransaccionDetalleJson, SURAPassTransaccionGrupoJson, SURAPassTransaccionJson } from 'src/app/features/models/surapass/surapass.modelo';
import { OrdenesPershingService } from 'src/app/features/services/ordenes/pershing/ordenes.pershing.service';
import { SurapassService } from 'src/app/features/services/surapass/surapass.service';
import { UsdPipeCustom } from 'src/app/shared/pipes/usd.pipe';
import { DialogService, LoadingService } from 'src/app/shared/services';
import { ClienteActivoDto, MensajeBackend, ModalGenericoOrdenes, SolicitudPershingJson, UploadContentManagerJSON } from '../../../../models/ordenes/pershing/orden.modelo';
import { ConfirmaModalComponentPershing } from './components/confirma-modal/confirma-modal.component';
registerLocaleData(es, 'es');

@Component({
    selector: 'app-orden-carro',
    templateUrl: './orden-carro.component.html',
    styleUrls: ['./orden-carro.component.scss'],
    providers: [{ provide: LOCALE_ID, useValue: 'es-*' }],
})
export class OrdenCarroComponent implements OnInit, OnDestroy {
    //CLIENTE CONECTADO
    public clienteconectado: ClienteActivoDto;
    public surapassconfig: any; //configuraciones de SURAPass

    public MENSAJE_NEGOCIO_ALERTA_TIPO: string = 'INFORMACION';
    public MENSAJE_NEGOCIO_ALERTA_TITULO: string = 'Atención';
    public MENSAJE_NEGOCIO_ALERTA_MENSAJE: string = 'Esta transacción esta sujeta a confirmación de la mesa corredora, las cuales serán procesadas hasta 15 minutos antes del cierre bursátil, después de dicho horario serán procesadas en el día hábil siguiente.';

    //TEXTOS
    public TEXTO_TIT_PENDIENTE: string = 'Transacción pendiente:';
    public TEXTO_TIT_RECHAZADA: string = 'Transacción rechazada:';
    public TEXTO_TIT_EXPIRADA: string = 'Transacción expirada:';
    public TEXTO_TIT_EXCEDE_INTENTOS: string = 'Transacción bloqueada:';

    public TEXTO_MSG_PENDIENTE: string = 'Estamos esperando la autorización o rechazo de la transacción.';
    public TEXTO_MSG_RECHAZADA: string = 'Se ha rechazado la autorización. Te recomendamos contactar al cliente para tener más antecedentes del motivo de su rechazo.';
    public TEXTO_MSG_EXPIRADA: string = 'La transacción supero el máximo de tiempo de espera para ser autorizada, debera ser ingresada nuevamente.';
    public TEXTO_MSG_EXCEDE_INTENTOS: string = 'Se ha excedido la cantidad máxima de intentos de autorización. Por seguridad se bloqueado la clave SURA Pass del cliente. Para poder desbloquearla deberá hacer el proceso de enrolamiento nuevamente.';

    //TIEMPO EN SEGUNDOS
    private SEGUNDOS_REVISION_MULTIPASS: number = 5; //5 SEGUNDOS
    private SURAPASS_APLICACION_ID: number = 2000;
    private SURAPASS_APLICACION_APP: string = 'NEW-WEB-ASESOR-ORD-PERSHING-' + environment.name;

    //INDICADORES
    clienteTieneMultipassActivo: boolean = false;
    esperandoMultipass: boolean = false;
    hayarchivoUploded: boolean = false;
    expandirAutorizaSURAPass: boolean;
    expandirAutorizaUpload: boolean;

    //ARCHIVO
    responseFileData: any = null;
    messageAlertFile: boolean;
    data: any;

    //WIZZARD
    linea = {
        steps: [],
        currentStep: null,
    };

    //solicitud
    solicitud: SolicitudPershingJson;
    transaccion: SURAPassTransaccionJson;

    showPaginaMostrarTodos = false;
    pageSize = 10;

    mensaje: MensajeBackend = {
        messageContent: '',
        messageType: 'error',
        messageTitle: 'Algo salio mal 0',
        showMessage: false,
    };

    idintervalo: any;

    constructor(
        private router: Router,
        private ordenesService: OrdenesPershingService,
        private activatedRoute: ActivatedRoute,
        private loadingService: LoadingService,
        private dialogService: DialogService,
        private surapassService: SurapassService,
    ) {
        this.loadingService.showLoading();
    }

    /**
     * Destruye la ejecucion
     */
    ngOnDestroy(): void {
        if (this.idintervalo) {
            clearInterval(this.idintervalo);
            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
        }
    }

    /**
     * Inicializa el TS
     */
    ngOnInit(): void {
        //INICIALIZA
        this.linea.steps = ['Ingreso de órdenes', 'Confirmación'];
        this.linea.currentStep = 2;

        //datos de ejecutivo
        let ejecutivoJson = sessionStorage.getItem('dataContentful');
        let ejecutivoAdicJson = sessionStorage.getItem('dataExecutive');
        let ejecutivoAdic = JSON.parse(ejecutivoAdicJson);
        let ejecutivo = JSON.parse(ejecutivoJson);

        //datos de cliente
        this.clienteconectado = this.validarClienteActivo();

        //RECUPERA EL CARRO DE COMPRA
        let carroFinalJson = sessionStorage.getItem(this.ordenesService.STORAGE_PERSHING_CARRO_FINAL);
        this.solicitud = JSON.parse(carroFinalJson) || {
            'id-ejecutivo': ejecutivo.executiveRut,
            'nombre-ejecutivo': ejecutivo.executiveName,
            'email-ejecutivo': ejecutivoAdic && ejecutivoAdic.MailEjecutivo ? ejecutivoAdic.MailEjecutivo : 'ejecutivo@sura.cl',
            'rut-cliente': this.clienteconectado.rutparteentera,
            'rut-dv-cliente': this.clienteconectado.rutdigitoverificador,
            'nombre-cliente': this.clienteconectado.nombre,
            'email-cliente': this.clienteconectado.email,
            'cantidad-ordenes-compra': 0,
            'cantidad-ordenes-venta': 0,
            'cantidad-ordenes-cambio-fondo': 0,
            ordenes: [],
        };
        this.solicitud['rut-cliente'] = this.clienteconectado.rutparteentera;
        this.solicitud['rut-dv-cliente'] = this.clienteconectado.rutdigitoverificador;
        this.solicitud['nombre-cliente'] = this.clienteconectado.nombre;
        this.solicitud['email-cliente'] = this.clienteconectado.email;

        //VERIFICA SI HAY UN DISPOSITIVO SURAPASS ACTIVO PARA EL CLIENTE
        this.expandirAutorizaSURAPass = false;
        this.expandirAutorizaUpload = true;
        this.clienteTieneMultipassActivo = false;
        this.consultaConfiguracionSuraPass().then((config) => {
            this.convenioActivoSURAPAss(config).then((activo) => {
                this.verificaSURAPassActivo(activo);
                this.inicializaRevisionPorIntervaloSURAPass(activo);
            });
            this.loadingService.hideLoading();
        });
    }

    /**
     * Analiza la confguracion SURAPass para indicar si eol convenio esta activo para funcionar
     * @param config
     * @returns
     */
    async convenioActivoSURAPAss(config) {
        let resultado: boolean = false;
        if (config && config.codigo === 0) {
            if (config.configuracion && config.configuracion.surapass) {
                config.configuracion.surapass.forEach((conve) => {
                    if (conve['idconvenio'] === this.SURAPASS_APLICACION_ID) {
                        if (conve['activo'] === 'S') {
                            resultado = true;
                        }
                    }
                });
            }
        }
        return resultado;
    }

    /**
     * Inicializa la revision cada X Tiempo
     */
    inicializaRevisionPorIntervaloSURAPass(activo) {
        if (activo) {
            this.consultaSiHayTransaccionSuraPass();
            //EJECUCION CADA 5 SEGUNDOS
            this.idintervalo = setInterval(() => {
                this.consultaSiHayTransaccionSuraPass();
            }, this.SEGUNDOS_REVISION_MULTIPASS * 1000);
        }
    }

    /**
     * Consulta al Servicio SURAPass el estado de autorizacion de una transaccion
     */
    async consultaConfiguracionSuraPass() {
        return this.surapassService
            .getConfiguracion()
            .then((datasurapass) => {
                if (datasurapass && datasurapass.codigo === 0 ) {
                    this.surapassconfig = datasurapass;
                }
                return datasurapass;
            })
            .catch((errsurpass) => {
                this.ordenesService.saveLogAccionAsesor(
                    'Ingreso Ordenes Pershing Off Line',
                    'Ingreso Ord Pershing Off Line Paso 2',
                    'OrdenCarroComponent - consultaConfiguracionSuraPass()',
                    'ok',
                    'SURAPASS - Error al consultar configuracion SURAPass mediante servicios REST',
                    errsurpass,
                );
                return {
                    codigo: -888,
                    resultado: 'No se logro consultar configuracion SURAPass',
                    configuracion: {},
                };
            });
    }

    /**
     * Verifica si Multipass Esta Activo
     */
    async verificaSURAPassActivo(activo) {
        if (activo) {
            this.surapassService
                .getDispositivoActivo(this.clienteconectado.rut)
                .then((datasurapass) => {
                    this.ordenesService.saveLogAccionAsesor(
                        'Ingreso Ordenes Pershing Off Line',
                        'Ingreso Ord Pershing Off Line Paso 2',
                        'OrdenCarroComponent - ngOnInit()',
                        'ok',
                        'SURAPASS - Consulta cliente con dispositivo ACTIVO',
                        datasurapass,
                    );

                    if (datasurapass && datasurapass['codigo'] === 0) {
                        this.clienteTieneMultipassActivo = true;
                        this.expandirAutorizaSURAPass = true;
                        this.expandirAutorizaUpload = false;
                    }
                })
                .catch((errsurpass) => {
                    //log nueva web
                    this.ordenesService.saveLogAccionAsesor(
                        'Ingreso Ordenes Pershing Off Line',
                        'Ingreso Ord Pershing Off Line Paso 2',
                        'OrdenCarroComponent - ngOnInit()',
                        'error',
                        'SURAPASS - Error al consultar dispositivo activo',
                        errsurpass,
                    );
                });
        }
    }

    cambioExpancionSuraPass($event) {
        this.expandirAutorizaSURAPass = $event;
        this.expandirAutorizaUpload = this.expandirAutorizaSURAPass ? false : true;
    }

    cambioExpancionUpload($event) {
        this.expandirAutorizaUpload = $event;
        this.expandirAutorizaSURAPass = this.expandirAutorizaUpload ? false : true;
    }

    /**
     * Consulta el cliente conectado de la ultima session en el Storage
     * @returns Cliente conectado
     */
    validarClienteActivo(): ClienteActivoDto {
        let data = sessionStorage.getItem('clientData');
        if (data) {
            const dataClient = JSON.parse(data);
            let rutlimpio = dataClient.rut.split('.').join('');
            let rutPartes = rutlimpio.split('-');
            let nombre = dataClient.name;
            return {
                rut: rutlimpio,
                rutparteentera: Number(rutPartes[0]),
                rutdigitoverificador: rutPartes[1],
                email: this.recuperaEmail(dataClient),
                nombre: nombre,
            };
        } else {
            this.router.navigate(['main/client-search']);
            return undefined;
        }
    }

    recuperaEmail(dataClient) {
        let email = undefined;
            
        if (dataClient.clientInfo) {
            dataClient.clientInfo.forEach( artsa => {
                if (artsa.icon === 'mail') {
                    email = artsa.text;
                }
            })
        }
        if (dataClient.companyInfo) {
            dataClient.companyInfo.forEach( artsb => {
                if (artsb.icon === 'mail') {
                    email = artsb.text;
                }
            })
        }
        if (!email) email = 'sincorreo@sura.cl';
        return email;
    }
    goToOrdenCompra() {
        this.router.navigate(['orden-compra'], {
            relativeTo: this.activatedRoute,
        });
    }

    //enviar solicitud
    goToEnviarSolicitud(forma) {
        if (forma === 'UPLOAD' && this.hayarchivoUploded === false) return;
        if (this.esperandoMultipass) return;

        //actualiza las totalizaciones de la solicitud
        this.cargatotalizaciones();

        let optionsMessage: ModalGenericoOrdenes = {
            titulo: '¿ Estás seguro de enviar la orden ?',
            mensaje: forma === 'UPLOAD' ? 'Confirmas el envío de la(s) solicitud(es) a la mesa internacional.' : 'Una vez enviada, deberás informale al cliente para que la revise y autorice a través de su SURAPass.',
            labelacepta: 'SI, ENVIAR',
            labelarechaza: 'CANCELAR',
        };
        const config = {
            panelClass: ['modal-confirmacion-box'],
            disableClose: false,
            data: { optionsMessage },
        };

        //llama al modal
        let dialogRef = this.dialogService.openDialog(ConfirmaModalComponentPershing, config);

        //evaua el resultado del modal
        dialogRef.afterClosed().subscribe((data: any) => {
            if (data != null && data === 'OK') {
                if (forma === 'SURAPASS') {
                    this.ordenesService.saveLogAccionAsesor(
                        'Ingreso Ordenes Pershing Off Line',
                        'Ingreso Ord Pershing Off Line Paso 2',
                        'OrdenCarroComponent - goToEnviarSolicitud()',
                        'ok',
                        `Envio de Solicitud para ser autorizada con SURAPass`,
                    );

                    //GENERAR TRANSACCION DE INGRESO y ACTIVA LA ESPERA MULTIPASS
                    this.esperandoMultipass = true;

                    this.creaAutorizacionSURAPass();
                }

                if (forma === 'UPLOAD') {
                    this.ordenesService.saveLogAccionAsesor(
                        'Ingreso Ordenes Pershing Off Line',
                        'Ingreso Ord Pershing Off Line Paso 2',
                        'OrdenCarroComponent - goToEnviarSolicitud()',
                        'ok',
                        `Envio de Solicitud para ser autorizada con Archivo Upload en Content Manager`,
                    );

                    this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/carro-finaliza`);
                }
            }
        });
    }

    scrollTo(el: Element): void {
        if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    /**
     * Crea la transaccion SURAPass
     * @returns
     */
    private creaAutorizacionSURAPass() {
        this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 2', 'OrdenCarroComponent - creaAutorizacionSURAPass()', 'ok', `Inicio de Creacion de autorizacion SURAPass...`);

        let cliente = this.validarClienteActivo();

        //CANTIDAD TRANSACCIONES
        let countCompras: number = 0;
        let countVentas: number = 0;
        let countCDF: number = 0;

        //TOTAL USD TRANSACCIONES
        let sumaTotalUSDCompras: number = 0;
        let sumaTotalUSDVentas: number = 0;
        let sumaTotalUSDCDFCompra: number = 0;
        let sumaTotalUSDCDFVenta: number = 0;

        //GENERA LOS DETALLES
        let detalles: SURAPassTransaccionDetalleJson[] = [];

        //CARGA LOS DETALLE
        this.solicitud.ordenes.forEach((orden) => {
            //===========================================================
            // COMPRA Y VENTA
            //===========================================================
            if (orden.instruccion === 'Compra' || orden.instruccion === 'Venta') {
                if (orden.instruccion === 'Compra') {
                    countCompras++;
                    sumaTotalUSDCompras += orden.totalusdcompra;
                }
                if (orden.instruccion === 'Venta') {
                    countVentas++;
                    sumaTotalUSDVentas += orden.totalusdventa;
                }
                detalles.push(this.crearAutorizacionSURAPassAddCompraVenta(orden));
            }

            //===========================================================
            // CAMBIO DE FONDO
            //===========================================================
            if (orden.instruccion === 'Cambio Fondo') {
                countCDF++;
                sumaTotalUSDCDFCompra += orden.totalusdcompra;
                sumaTotalUSDCDFVenta += orden.totalusdventa;
                detalles.push(this.crearAutorizacionSURAPassAddCDF(orden));
            }
        });

        this.creaAutorizacionSURAPassPaso2(cliente, countCompras, countVentas, countCDF, sumaTotalUSDCompras, sumaTotalUSDVentas, sumaTotalUSDCDFCompra, sumaTotalUSDCDFVenta, detalles);
    }

    /**
     * Cambio de Fondos
     * @param orden
     * @returns
     */
    crearAutorizacionSURAPassAddCDF(orden) {
        //CARGA DETALLES
        let totalInstrumentosUSD: number = 0;
        let cantidadInstrumentosCompra: number = 0;
        let cantidadInstrumentosVenta: number = 0;
        let comisionesCompraTotalUSD: number = 0;
        let comisionesVentaTotalUSD: number = 0;
        let grupocargar: SURAPassTransaccionGrupoJson[] = [];

        //ITERA LOS INSTRUMENTOS
        orden.instrumentos.forEach((instrumento) => {
            let camposadd: SURAPassTransaccionCampoJson[] = [
                {
                    titulo: 'CUENTA',
                    valor: orden['descripcion-cuenta'],
                },
                {
                    titulo: 'ACCION CDF',
                    valor: instrumento['origen-destino'].startsWith('O') ? 'VENTA' : 'COMPRA',
                },
                {
                    titulo: 'SERIE',
                    valor: instrumento['tipo-serie'],
                },
                {
                    titulo: 'TICKER',
                    valor: instrumento['ticker'],
                },
                {
                    titulo: 'FONDO',
                    valor: instrumento['nombre-instrumento'],
                },
                {
                    titulo: 'CANTIDAD',
                    valor: this.cantidadOrdenIntsrumento(orden, instrumento),
                },
                {
                    titulo: 'MONTO',
                    valor: this.formatearNumeroDolares(instrumento['total-instruccion-usd']),
                },
            ];

            //SE CARGAN LOS CAMPOS POR CADA GRUPO
            let grupoadd: SURAPassTransaccionGrupoJson = {
                campos: camposadd,
            };
            //CARGA EL GRUPO CON LOS CAMPOS
            grupocargar.push(grupoadd);

            //SUMO EL TOAL
            totalInstrumentosUSD += instrumento['total-instruccion-usd'];
            if (instrumento['origen-destino'].startsWith('O')) {
                cantidadInstrumentosVenta++;
                comisionesVentaTotalUSD += instrumento['comision-usd'];
            }
            if (instrumento['origen-destino'].startsWith('D')) {
                cantidadInstrumentosCompra++;
                comisionesCompraTotalUSD += instrumento['comision-usd'];
            }
        });

        //AGREGA EL TOTAL DE LAS COMISIONES
        let camposaddcomision: SURAPassTransaccionCampoJson[] = [
            {
                titulo: 'COMISIONES DE VENTA',
                valor: this.formatearNumeroDolares(comisionesVentaTotalUSD),
            },
            {
                titulo: 'COMISIONES DE COMPRA',
                valor: this.formatearNumeroDolares(comisionesCompraTotalUSD),
            },
        ];
        let grupocomisiones: SURAPassTransaccionGrupoJson = {
            campos: camposaddcomision,
        };
        grupocargar.push(grupocomisiones);

        //CREA EL DETALLE DE LA OPERACION DE COMPRA
        return {
            descripcion1: 'Cambio de Fondos',
            descripcion2: this.formatearNumeroDolares(totalInstrumentosUSD),
            descripcion3: 'Instrumento(s) ' + cantidadInstrumentosVenta + ' Venta y ' + cantidadInstrumentosVenta + ' Compra',
            monto: totalInstrumentosUSD,
            grupo: grupocargar,
        };
    }

    /**
     * Compra y Venta
     * @param orden
     * @returns
     */
    crearAutorizacionSURAPassAddCompraVenta(orden) {
        //CARGA DETALLES
        let totalInstrumentosUSD: number = 0;
        let cantidadInstrumentos: number = 0;
        let comisionesTotalUSD: number = 0;
        let grupocargar: SURAPassTransaccionGrupoJson[] = [];
        //ITERA LOS INSTRUMENTOS
        orden.instrumentos.forEach((instrumento) => {
            cantidadInstrumentos++;
            let camposadd: SURAPassTransaccionCampoJson[] = [
                {
                    titulo: 'CUENTA',
                    valor: orden['descripcion-cuenta'],
                },
                {
                    titulo: 'SERIE',
                    valor: instrumento['tipo-serie'],
                },
                {
                    titulo: 'TICKER',
                    valor: instrumento['ticker'],
                },
                {
                    titulo: 'FONDO',
                    valor: instrumento['nombre-instrumento'],
                },
                {
                    titulo: 'CANTIDAD',
                    valor: this.cantidadOrdenIntsrumento(orden, instrumento),
                },
                {
                    titulo: 'MONTO',
                    valor: this.formatearNumeroDolares(instrumento['total-instruccion-usd']),
                },
            ];

            //SE CARGAN LOS CAMPOS POR CADA GRUPO
            let grupoadd: SURAPassTransaccionGrupoJson = {
                campos: camposadd,
            };
            //CARGA EL GRUPO CON LOS CAMPOS
            grupocargar.push(grupoadd);

            //SUMO EL TOTAL
            totalInstrumentosUSD += instrumento['total-instruccion-usd'];
            comisionesTotalUSD += instrumento['comision-usd'];
        });

        //AGREGA EL TOTAL DE LAS COMISIONES
        let camposaddcomision: SURAPassTransaccionCampoJson[] = [
            {
                titulo: 'COMISIONES',
                valor: this.formatearNumeroDolares(comisionesTotalUSD),
            },
        ];
        let grupocomisiones: SURAPassTransaccionGrupoJson = {
            campos: camposaddcomision,
        };
        grupocargar.push(grupocomisiones);

        //CREA EL DETALLE DE LA OPERACION DE COMPRA
        return {
            descripcion1: orden.instruccion + ' de Fondos',
            descripcion2: this.formatearNumeroDolares(totalInstrumentosUSD),
            descripcion3: cantidadInstrumentos + ' Instrumento(s)',
            monto: totalInstrumentosUSD,
            grupo: grupocargar,
        };
    }

    cantidadOrdenIntsrumento(orden, instrumento) {
        let cantidadview: string = '';
        if (orden.instruccion === 'Compra') {
            cantidadview = this.casoCompra(instrumento);
        }
        if (orden.instruccion === 'Venta') {
            cantidadview = this.casoVenta(instrumento);
        }
        if (orden.instruccion === 'Cambio Fondo') {
            if (instrumento['tipo-venta']) {
                cantidadview = this.casoVentaCDF(instrumento);
            }
            if (instrumento['tipo-compra']) {
                cantidadview = this.casoCompraCDF(instrumento);
            }
        }
        return cantidadview;
    }

    casoCompra(instrumento) {
        const usdPipeCustom = new UsdPipeCustom();
        let cantin: string = '';
        if (instrumento['tipo-compra'] === 'P') {
            cantin = instrumento['monto-cuotas-porcentaje'] + '%';
        }
        if (instrumento['tipo-compra'] === 'M') {
            cantin = usdPipeCustom.transform(instrumento['monto-cuotas-porcentaje']);
        }
        if (instrumento['tipo-compra'] === 'C') {
            cantin = instrumento['monto-cuotas-porcentaje'] + ' cuotas';
        }
        return cantin;
    }

    casoVenta(instrumento) {
        const usdPipeCustom = new UsdPipeCustom();
        let cantin: string = '';
        if (instrumento['tipo-venta'] === 'F') {
            cantin = 'FULL';
        }
        if (instrumento['tipo-venta'] === 'P') {
            cantin = instrumento['monto-cuotas-porcentaje'] + '%';
        }
        if (instrumento['tipo-venta'] === 'M') {
            cantin = usdPipeCustom.transform(instrumento['monto-cuotas-porcentaje']);
        }
        if (instrumento['tipo-venta'] === 'C') {
            cantin = instrumento['monto-cuotas-porcentaje'] + ' cuotas';
        }
        return cantin;
    }

    casoVentaCDF(instrumento) {
        const usdPipeCustom = new UsdPipeCustom();
        let cantin: string = '';
        if (instrumento['tipo-venta'] === 'F') {
            cantin = 'FULL';
        }
        if (instrumento['tipo-venta'] === 'P') {
            cantin = instrumento['monto-cuotas-porcentaje'] + '%';
        }
        if (instrumento['tipo-venta'] === 'M') {
            cantin = usdPipeCustom.transform(instrumento['monto-cuotas-porcentaje']);
        }
        if (instrumento['tipo-venta'] === 'C') {
            cantin = instrumento['monto-cuotas-porcentaje'] + ' cuotas';
        }
        return cantin;
    }

    casoCompraCDF(instrumento) {
        const usdPipeCustom = new UsdPipeCustom();
        let cantin: string = '';
        if (instrumento['tipo-compra'] === 'F') {
            cantin = 'FULL';
        }
        if (instrumento['tipo-compra'] === 'P') {
            cantin = instrumento['monto-cuotas-porcentaje'] + '%';
        }
        if (instrumento['tipo-compra'] === 'M') {
            cantin = usdPipeCustom.transform(instrumento['monto-cuotas-porcentaje']);
        }
        if (instrumento['tipo-compra'] === 'C') {
            cantin = instrumento['monto-cuotas-porcentaje'] + ' cuotas';
        }
        return cantin;
    }

    /**
     * Carga detalle de autorizacion paso 2
     * @param cliente
     * @param countCompras
     * @param countVentas
     * @param countCDF
     * @param sumaTotalUSDCompras
     * @param sumaTotalUSDVentas
     * @param sumaTotalUSDCDFCompra
     * @param sumaTotalUSDCDFVenta
     * @param detalles
     */
    creaAutorizacionSURAPassPaso2(cliente, countCompras, countVentas, countCDF, sumaTotalUSDCompras, sumaTotalUSDVentas, sumaTotalUSDCDFCompra, sumaTotalUSDCDFVenta, detalles: SURAPassTransaccionDetalleJson[]) {
        this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 2', 'OrdenCarroComponent - creaAutorizacionSURAPassPaso2()', 'ok', `Registra totalizaciones y detalle de SURAPass`);

        //TOTAL USD TRANSACCIONES
        let fechaconsultastr = formatDate(new Date(), 'EEEE d MMMM, y h:mm', 'es') + 'hrs';
        let totalGeneralUSDOperacion: number = 0;
        let operacion: string = undefined;
        if (countCompras > 0 && countVentas === 0 && countCDF === 0 && operacion === undefined) {
            operacion = 'Compra'; 
            totalGeneralUSDOperacion += sumaTotalUSDCompras;
        }
        if (countCompras === 0 && countVentas > 0 && countCDF === 0 && operacion === undefined) {
            operacion = 'Venta'; 
            totalGeneralUSDOperacion += sumaTotalUSDVentas;
        }
        if (countCompras > 0 && countVentas > 0 && countCDF === 0 && operacion === undefined) {
            operacion = 'Compra y Venta'; 
            totalGeneralUSDOperacion += sumaTotalUSDCompras;
        }
        if (countCompras === 0 && countVentas === 0 && countCDF > 0 && operacion === undefined) {
            operacion = 'Cambio de Fondo'; 
            totalGeneralUSDOperacion = sumaTotalUSDCDFCompra;
        }
        if (countCompras > 0 && countVentas === 0 && countCDF > 0 && operacion === undefined) {
            operacion = 'Compra y Cambio de Fondo'; 
            totalGeneralUSDOperacion += sumaTotalUSDCompras + sumaTotalUSDCompras;
        }
        if (countCompras === 0 && countVentas > 0 && countCDF > 0 && operacion === undefined) {
            operacion = 'Venta y Cambio de Fondo';
            totalGeneralUSDOperacion = sumaTotalUSDCDFCompra;
        }
        if (countCompras > 0 && countVentas > 0 && countCDF > 0 && operacion === undefined) {
            operacion = 'Compra, Venta y Cambio de Fondo'; 
            totalGeneralUSDOperacion += sumaTotalUSDCompras + sumaTotalUSDCDFCompra;
        }

        //DATA PARASURAPASS
        this.solicitud['id-folio-orden'] = 123;
        this.solicitud['id-solicitud'] = 123;
        this.solicitud.estado = 'INF';
        let jsonxml: SURAPassAPIPagoPershingJSON = {
            solicitud: this.solicitud,
            transaccion: this.solicitud,
            correo: this.solicitud,
        };

        //caso de alerta
        let alertax = {
            tipo: this.MENSAJE_NEGOCIO_ALERTA_TIPO, 
            mensaje: this.MENSAJE_NEGOCIO_ALERTA_MENSAJE, 
            titulo: this.MENSAJE_NEGOCIO_ALERTA_TITULO 
        }

        //CREA LA TRANSACION
        this.transaccion = {
            rut: cliente.rut,
            codigoMoneda: 'USD',
            solicitante: 'EJECUTIVO',
            nombreEjecutivo: this.solicitud['id-ejecutivo'].toUpperCase() + ' ' + this.solicitud['nombre-ejecutivo'],
            descripcionTransaccion1: this.recuperarLineaNegocioSuraPass(),
            descripcionTransaccion2: operacion,
            descripcionTransaccion3: fechaconsultastr,
            montoAutorizar: Number(totalGeneralUSDOperacion),
            aplicacionOrigen: this.SURAPASS_APLICACION_APP,
            detalle: detalles,
            alerta: (this.MENSAJE_NEGOCIO_ALERTA_TIPO ? alertax : null),
            dataJsonXml: JSON.stringify(jsonxml),
        };
        this.creaAutorizacionSURAPassPaso3();
    }

    /**
     * Recupera el nombre de la transaccion SURAPass
     * @returns 
     */
    recuperarLineaNegocioSuraPass() {
        let linea: string = 'INVERSIÓN EXTRANJERA';
        if (this.surapassconfig && this.surapassconfig.configuracion && this.surapassconfig.configuracion.surapass) {
            this.surapassconfig.configuracion.surapass.forEach(conve => {
                if (conve['idconvenio'] === this.SURAPASS_APLICACION_ID) {
                    linea = conve['descripcion'];
                }
            })
        }
        return linea;
    }

    /**
     * Carga totalizaciones
     */
    cargatotalizaciones() {
        //fuerzo correo personal para que no salga
        let cantidadOrdenesCompra = 0;
        let cantidadOrdenesVenta = 0;
        let cantidadOrdensCDF = 0;

        if (this.solicitud && this.solicitud.ordenes) {
            this.solicitud.ordenes.forEach((ord) => {
                if (ord.instruccion === 'Compra') cantidadOrdenesCompra++;
                if (ord.instruccion === 'Venta') cantidadOrdenesVenta++;
                if (ord.instruccion === 'Cambio Fondo') cantidadOrdensCDF++;
            });
        }

        this.solicitud['cantidad-ordenes-venta'] = cantidadOrdenesVenta;
        this.solicitud['cantidad-ordenes-compra'] = cantidadOrdenesCompra;
        this.solicitud['cantidad-ordenes-cambio-fondo'] = cantidadOrdensCDF;
    }

    /**
     * Crear autorizaicon paso 3
     */
    creaAutorizacionSURAPassPaso3() {
        this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 2', 'OrdenCarroComponent - creaAutorizacionSURAPassPaso3()', 'ok', `Solicitia AccessToken de autorizacion SURAPass`);

        //llama a los servicios

        this.creaAutorizacionSURAPassPaso4();
    }

    /**
     * AUtoriza transaccion paso 4
     * @param token
     */
    creaAutorizacionSURAPassPaso4() {
        this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 2', 'OrdenCarroComponent - creaAutorizacionSURAPassPaso4()', 'ok', `Inicia creacion de transaccion SURAPass`);

        //crea la transaccion SURA PASS
        this.surapassService
            .postCrearTransaccion(this.SURAPASS_APLICACION_ID, this.transaccion)
            .then((datasurapass) => {
                if (datasurapass['codigo'] === 0 || datasurapass['codigo'] === 22) {
                    this.ordenesService.saveLogAccionAsesor(
                        'Ingreso Ordenes Pershing Off Line',
                        'Ingreso Ord Pershing Off Line Paso 2',
                        'OrdenCarroComponent - creaAutorizacionSURAPassPaso4()',
                        'ok',
                        `Transaccion SURAPass creada con exito`,
                        datasurapass,
                    );

                    this.expandirAutorizaSURAPass = true;
                    this.expandirAutorizaUpload = false;
                    this.esperandoMultipass = true;

                    //CARGA VARIABLE EN STORAGE
                    sessionStorage.setItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION, JSON.stringify(datasurapass));
                } else {
                    this.scrollTo(document.querySelector('#fldseccionmensajes'));
                    this.mensaje = {
                        messageContent:  datasurapass.resultado,
                        messageType: 'error',
                        messageTitle: 'Algo salio mal',
                        showMessage: true,
                    };
                    this.esperandoMultipass = false;

                    this.ordenesService.saveLogAccionAsesor(
                        'Ingreso Ordenes Pershing Off Line',
                        'Ingreso Ord Pershing Off Line Paso 2',
                        'OrdenCarroComponent - creaAutorizacionSURAPassPaso4()',
                        'error',
                        `Problema para crear transaccion SURAPass`,
                        datasurapass,
                    );
                }
            })
            .catch((errsurpass) => {
                this.scrollTo(document.querySelector('#fldseccionmensajes'));
                this.mensaje = {
                    messageContent: 'Error al crear transaccion SURAPass : ' + errsurpass.message,
                    messageType: 'error',
                    messageTitle: 'Algo salio mal',
                    showMessage: true,
                };
                this.esperandoMultipass = false;

                this.ordenesService.saveLogAccionAsesor(
                    'Ingreso Ordenes Pershing Off Line',
                    'Ingreso Ord Pershing Off Line Paso 2',
                    'OrdenCarroComponent - creaAutorizacionSURAPassPaso4()',
                    'error',
                    `Error para crear transaccion SURAPass`,
                    errsurpass,
                );
            });
    }

    formatearNumeroDolares(value: any): string {
        if (value != null && !isNaN(value)) {
            return 'USD $' + Intl.NumberFormat('es-CL', { maximumFractionDigits: 2 }).format(value);
        }
        return '';
    }

    /**
     * Rdirecciona a ingresar una nueva compra
     */
    goToAccion(accion) {
        if (this.esperandoMultipass) return;
        this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 2', 'OrdenCarroComponent - goToAccion()', 'ok', `Seleccionado opcion ${accion}`);

        if (this.esperandoMultipass) {
            let optionsMessage: ModalGenericoOrdenes = {
                titulo: '¿ Estás seguro abandonar ?',
                mensaje: 'Estas en un proceso de autorización de orden con SURA PASS, al abandonar se cancela esta autorización ',
                labelacepta: 'ABANDONAR',
                labelarechaza: 'CANCELAR',
            };
            const config = {
                panelClass: ['modal-confirmacion-box'],
                disableClose: false,
                data: { optionsMessage },
            };

            //llama al modal
            let dialogRef = this.dialogService.openDialog(ConfirmaModalComponentPershing, config);

            //evaua el resultado del modal
            dialogRef.afterClosed().subscribe((data: any) => {
                if (data != null && data === 'OK') {
                    sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                    this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/pershing-selector-cuenta`);
                }
            });
        } else {
            this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/pershing-selector-cuenta`);
        }
    }

    /**
     * Setea si hay un archivo cargado
     * @param event
     */
    hayArchivoCargado(event) {
        if (event === false) {
            this.hayarchivoUploded = false;
            this.ordenesService.archivobase64 = null;
        }
    }

    /**
     * Entrega los datos en base 64
     * @param event
     */
    responseFile(event) {
        this.responseFileData = event;
        if (this.responseFileData && this.responseFileData.data) {
            let cliente = this.validarClienteActivo();
            let datos = this.responseFileData.data.split(',');
            let cabecera: string = datos[0];
            let tipoarchivoa = cabecera.split(';')[0].split(':')[1];
            let base64 = datos[1];
            this.ordenesService.archivobase64 = {
                idorden: 0,
                'archivo-base64': base64,
                'tipo-contenido': tipoarchivoa,
                nombre: 'orden-pershing',
                'rut-cliente': '' + cliente.rutparteentera,
                'nombre-cliente': cliente.nombre,
            };
            this.hayarchivoUploded = true;
            this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 2', 'OrdenCarroComponent - responseFile()', 'ok', `Nuevo archivo adjuntado ${tipoarchivoa}`);
        }
    }

    /**
     * Consulta al Servicio SURAPass el estado de autorizacion de una transaccion
     */
    consultaSiHayTransaccionSuraPass() {
        let surapassJson = sessionStorage.getItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
        let surapass = JSON.parse(surapassJson) || {};

        //VERIFICANDO SI HAY UNA TRANSACCION ENCOLADA PRODUCTO DE UN REFRESH DE PANTALLA
        if (this.esperandoMultipass === false) {
            if (surapass != undefined && surapass.codigo === 0 && surapass.idTransaccion > 0) {
                this.esperandoMultipass = true;
            }
        }

        //REVISAR SI HAY UNA OPERACION
        if (this.esperandoMultipass && surapass) {
            this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 2', 'OrdenCarroComponent - consultaEstadoSuraPass()', 'ok', `Consutado estado de transaccion SURAPass`, surapass);

            //llama a los servicios

            this.consultaEstadoTransaccionSuraPass(surapass);
        }
    }

    /**
     * Consulta el estado de la transaccion multipass
     * @param token
     * @param surapass
     */
    async consultaEstadoTransaccionSuraPass(surapass) {
        
        if (!surapass || !surapass.idTransaccion) return ;

        this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 2', 'OrdenCarroComponent - consultaEstadoSuraPassPaso2()', 'ok', `Consulta Transaccion`, surapass);

        //crea la transaccion SURA PASS
        await this.surapassService
            .getEstadoTransaccion(surapass.idTransaccion, this.SURAPASS_APLICACION_APP)
            .then((datasurapass) => {
                if (datasurapass !== undefined && datasurapass['codigo'] === 0) {
                    this.ordenesService.saveLogAccionAsesor(
                        'Ingreso Ordenes Pershing Off Line',
                        'Ingreso Ord Pershing Off Line Paso 2',
                        'OrdenCarroComponent - consultaEstadoSuraPassPaso2()',
                        'ok',
                        `Consulta Realizada con exito, evaluando resultado ${datasurapass['estado']}`,
                        datasurapass,
                    );

                    //REVISNADO LOS ESTADOS
                    switch (datasurapass['estado']) {
                        case 'AUTORIZADA': {
                            this.ordenesService.saveLogAccionAsesor(
                                'Ingreso Ordenes Pershing Off Line',
                                'Ingreso Ord Pershing Off Line Paso 2',
                                'OrdenCarroComponent - consultaEstadoSuraPassPaso2()',
                                'ok',
                                'SURAPASS - Estado transaccion AUTORIZADA',
                                datasurapass,
                            );

                            this.esperandoMultipass = false;
                            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                            this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/carro-finaliza`);

                            break;
                        }

                        case 'PENDIENTE': {
                            this.mensaje = {
                                messageContent: this.TEXTO_MSG_PENDIENTE,
                                messageType: 'warning',
                                messageTitle: this.TEXTO_TIT_PENDIENTE,
                                showMessage: true,
                            };
                            break;
                        }
                        case 'EXPIRADA': {
                            this.esperandoMultipass = false;
                            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                            this.scrollTo(document.querySelector('#fldseccionmensajes'));
                            this.mensaje = {
                                messageContent: this.TEXTO_MSG_EXPIRADA,
                                messageType: 'info',
                                messageTitle: this.TEXTO_TIT_EXPIRADA,
                                showMessage: true,
                            };
                            break;
                        }
                        case 'EXCEDE INTENTOS': {
                            this.esperandoMultipass = false;
                            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                            this.scrollTo(document.querySelector('#fldseccionmensajes'));
                            this.mensaje = {
                                messageContent:this.TEXTO_MSG_EXCEDE_INTENTOS,
                                messageType: 'error',
                                messageTitle: this.TEXTO_TIT_EXCEDE_INTENTOS,
                                showMessage: true,
                            };
                            break;
                        }
                        case 'RECHAZADA': {
                            this.esperandoMultipass = false;
                            sessionStorage.removeItem(this.surapassService.STORAGE_SURAPASS_ULTIMA_TRANSACCION);
                            this.scrollTo(document.querySelector('#fldseccionmensajes'));
                            this.mensaje = {
                                messageContent:
                                    this.TEXTO_MSG_RECHAZADA,
                                messageType: 'error',
                                messageTitle: this.TEXTO_TIT_RECHAZADA,
                                showMessage: true,
                            };
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                } else {
                    this.esperandoMultipass = false;
                    this.scrollTo(document.querySelector('#fldseccionmensajes'));
                    this.mensaje = {
                        messageContent: datasurapass.resultado,
                        messageType: 'error',
                        messageTitle: 'Algo salio mal',
                        showMessage: true,
                    };

                    this.ordenesService.saveLogAccionAsesor(
                        'Ingreso Ordenes Pershing Off Line',
                        'Ingreso Ord Pershing Off Line Paso 2',
                        'OrdenCarroComponent - consultaEstadoSuraPassPaso2()',
                        'error',
                        `Problema al consultar estado de transaccion SURAPass`,
                        datasurapass,
                    );
                }
            })
            .catch((errsurpass) => {
                this.esperandoMultipass = false;
                this.scrollTo(document.querySelector('#fldseccionmensajes'));
                this.mensaje = {
                    messageContent: errsurpass.resultado,
                    messageType: 'error',
                    messageTitle: 'Algo salio mal',
                    showMessage: true,
                };

                this.ordenesService.saveLogAccionAsesor(
                    'Ingreso Ordenes Pershing Off Line',
                    'Ingreso Ord Pershing Off Line Paso 2',
                    'OrdenCarroComponent - consultaEstadoSuraPassPaso2()',
                    'error',
                    `Error al consultar estado de transaccion SURAPass`,
                    errsurpass,
                );
            });
    }
}
