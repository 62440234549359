import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteActivoDto, ModalGenericoOrdenes, SolicitudCorredoraJson } from 'src/app/features/models/ordenes/corredora/orden.modelo';
import { OrdenesCorredoraService } from 'src/app/features/services/ordenes/corredora/ordenes.corredora.service';
import { DialogService } from 'src/app/shared/services';
import { ConfirmaModalComponentCorredora } from '../confirma-modal/confirma-modal.component';

@Component({
    selector: 'app-detalle-ordenes-ingresadas',
    templateUrl: './detalle-ordenes-ingresadas.component.html',
    styleUrls: ['./detalle-ordenes-ingresadas.component.scss'],
})
export class DetalleOrdenesIngresadasComponent implements OnInit {
     //CLIENTE CONECTADO
     public clienteconectado: ClienteActivoDto;

    //solicitud general 
    @Input() esperandoMultipass: boolean;
    @Input() solicitud: SolicitudCorredoraJson;

    secciones = [
        { id: 'COMPRA', descripcion: 'Compra de Acciones' },
        { id: 'VENTA', descripcion: 'Venta de Acciones' },
    ];

    constructor(
        private router: Router,
        private dialogService: DialogService, 
        private corredoraService: OrdenesCorredoraService) {}

    ngOnInit(): void {
          this.clienteconectado = this.validarClienteActivo();
    }

    cuentasGroupOrdenes() {
        return Array.from(this.solicitud.ordenes.reduce((entryMap, e) => entryMap.set(e['tipo-operacion'], { ...(entryMap.get(e['tipo-operacion']) || {}), ...e }), new Map()).values());
    }

    hayCompra() {
        if (this.solicitud && this.solicitud.ordenes) {
            var cuantos = this.solicitud.ordenes.filter(function (element) {
                return element['tipo-operacion'] == 'COMPRA';
            }).length;
            return cuantos > 0 ? true : false;
        }
        return false;
    }

    hayVenta() {
        if (this.solicitud && this.solicitud.ordenes) {
            var cuantos = this.solicitud.ordenes.filter(function (element) {
                return element['tipo-operacion'] == 'VENTA';
            }).length;
            return cuantos > 0 ? true : false;
        }
        return false;
    }

    //filtra los ordenes
    filtrarOrdenes(tipooperacion) {
        return this.solicitud.ordenes.filter((x) => x['tipo-operacion'] == tipooperacion);
    }

    //elininar una orden (compra, venta, o cambio de fondos)
    goToEliminarOrden(orden) {
        if (this.esperandoMultipass) return;

        //configura el modal para ejecutar
        let optionsMessage: ModalGenericoOrdenes = {
            titulo: '¿ Estás seguro de eliminar Orden ?',
            mensaje: 'Una vez eliminada no se podrá recuperar y deberás ingresarla nuevamente.',
            labelacepta: 'Si, la quiero eliminar',
            labelarechaza: 'No, La quiero conservar',
        };

        const config = {
            panelClass: ['modal-confirmacion-box'],
            disableClose: false,
            data: { optionsMessage },
        };

        //llama al modal
        let dialogRef = this.dialogService.openDialog(ConfirmaModalComponentCorredora, config);

        //evaua el resultado del modal
        dialogRef.afterClosed().subscribe((data: any) => {
            if (data != null && data === 'OK') {
                //se elimina la orden seleccionada
                this.solicitud.ordenes.splice(
                    this.solicitud.ordenes.findIndex((item) => item['id-orden'] === orden['id-orden']),
                    1,
                );

                //actualiza la solicitud con los datos incorporados
                sessionStorage.setItem(this.corredoraService.STORAGE_CORREDORA_CARRO_FINAL, JSON.stringify(this.solicitud));

                //log nueva web
                this.corredoraService.saveLogAccionAsesor(
                    'Ingreso de Ordenes de Compra y Venta Off Line',
                    'Ingreso OCV Off Line Paso 2',
                    'DetalleOrdenesIngresadasComponent - goToEliminarOrden()', 'ok', 'Se eliminar orden del carro princial', orden);
            }
        });
    }

      /**
     * Consulta el cliente conectado de la ultima session en el Storage
     * @returns Cliente conectado
     */
       validarClienteActivo(): ClienteActivoDto {
        let data = sessionStorage.getItem('clientData');
        if (data) {
            const dataClient = JSON.parse(data);
            let rutlimpio = dataClient.rut.split('.').join('');
            let rutPartes = rutlimpio.split('-');
            let nombre = dataClient.name;
            return {
                rut: rutlimpio,
                rutparteentera: Number(rutPartes[0]),
                rutdigitoverificador: rutPartes[1],
                email: this.recuperaEmail(dataClient),
                nombre: nombre,
            };
        } else {
            this.router.navigate(['main/client-search']);
            return undefined;
        }
    }

    recuperaEmail(dataClient) {
        let email = undefined;
            
        if (dataClient.clientInfo) {
            dataClient.clientInfo.forEach( artsa => {
                if (artsa.icon === 'mail') {
                    email = artsa.text;
                }
            })
        }
        if (dataClient.companyInfo) {
            dataClient.companyInfo.forEach( artsb => {
                if (artsb.icon === 'mail') {
                    email = artsb.text;
                }
            })
        }
        if (!email) email = 'sincorreo@sura.cl';
        return email;
    }

  
}
