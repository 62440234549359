import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdenCarroComponent } from './orden-carro.component';
import { MainOrdenCarroComponent } from './components/main-orden-carro.component';
import { OrdenCarroRoutingModule } from './orden-carro-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DetalleOrdenesIngresadasComponent } from './components/detalle-ordenes-ingresadas/detalle-ordenes-ingresadas.component';
import { ConfirmaModalComponentPershing } from './components/confirma-modal/confirma-modal.component';
import { UploadArchivoRespaldoComponent } from './components/upload-archivo-respaldo/upload-archivo-respaldo.component';



@NgModule({
  declarations: [
    OrdenCarroComponent,
    MainOrdenCarroComponent,
    DetalleOrdenesIngresadasComponent,
    ConfirmaModalComponentPershing,
    UploadArchivoRespaldoComponent
  ],
  imports: [
    OrdenCarroRoutingModule,
    CommonModule, 
    SharedModule
  ]
})


export class OrdenCarroModulePershing { }
