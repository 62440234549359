// Angular Core
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

// Kit UI
import { SuraCommonsUiModule } from '@surainvestments/sura-common-ui';

// Shared
import { SharedModule } from '../../../shared/shared.module';

// Components
import { CLIENT_SUMMARY_COMPONENTS, ENTRY_CLIENT_SUMMARY_COMPONENTS } from './components';

// Module
import { ClientSummaryRoutingModule } from './client-summary-routing.module';
import { MaterialModule } from './../../../shared/modules/material.module';

// NGX Mask
import { NgxMaskModule } from 'ngx-mask';
import { DndDirective } from './components/file-upload/directives/dnd.directive';
import { ProfileSummaryComponent } from './components/profile-summary/profile-summary.component';
import { TabContentComponent } from './components/profile-summary/tab-content/tab-content.component';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { TaxCertificatesComponent } from './components/tax-certificates/tax-certificates.component';
import { ConfirmModalComponent } from './../../../shared/components/confirm-modal/confirm-modal.component';
import { BalanceCertificateComponent } from './components/balance-certificate/balance-certificate.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { HomeAssetAllocationModule } from '../home-asset-allocation/home-asset-allocation.module';
import { DocumentMessageModule } from './components/document-message/document-message.module';
import { PafProfitabilityCardComponent } from './components/paf-profitability-card/paf-profitability-card.component';

@NgModule({
    declarations: [
        CLIENT_SUMMARY_COMPONENTS,
        DndDirective,
        ProfileSummaryComponent,
        TabContentComponent,
        TaxCertificatesComponent,
        ConfirmModalComponent,
        BalanceCertificateComponent,
        PafProfitabilityCardComponent
    ],
    imports: [
        MatPaginatorModule,
        MatTableModule,
        CommonModule,
        SharedModule,
        NgxMaskModule,
        FormsModule,
        ReactiveFormsModule,
        SuraCommonsUiModule,
        RouterModule,
        ClientSummaryRoutingModule,
        MaterialModule,
        MatRadioModule,
        MatExpansionModule,
        MatIconModule,
        HighchartsChartModule,
        HomeAssetAllocationModule,
        DocumentMessageModule
    ],
    entryComponents: [ENTRY_CLIENT_SUMMARY_COMPONENTS],
})
export class ClientSummaryModule { }
