// Angular Core
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// RxJS
import { Observable, Subject } from 'rxjs';

// Services
import { InvestmentReportService, ClientRequestService } from 'src/app/features/services';
import { LoadingService, LocalStorageService } from 'src/app/shared/services';

// Models
import { ClientSearchResponseModel, ProfitabilityItem } from 'src/app/features/models';

// Moment
import * as moment from 'moment';

// KIT UI
import { SelectItem } from '@surainvestments/sura-common-ui/app/sura-ui-kit/components/select/select.component';

// Utils
import { toTitleCase } from '../../../../../shared/helpers/utils';

// Utils
import * as utils from 'src/app/shared/helpers/utils';
import { AliasInputLogType, InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import { CategoriasContratoLog, OperacionesContratoLog } from 'src/app/features/models/log/models';

moment.locale('es');

type messageType = 'error' | 'info' | 'warning';

@Component({
    selector: 'app-investment-report',
    templateUrl: './investment-report.component.html',
    styleUrls: ['./investment-report.component.scss'],
})
export class InvestmentReportComponent implements OnInit {
    isPershingReport: boolean;
    labelPershing: string = 'nacional';
    listMonth = [];
    listYears = [];

    public clientData: ClientSearchResponseModel;

    signedMandate: boolean;
    messageContent: string;
    messageType: messageType;
    messageTitle: string;
    showMessage: boolean;
    investmentFormGroup: FormGroup;
    nameClient?: string;
    profitability$: Observable<ProfitabilityItem[]>;

    logCategoria: CategoriasContratoLog = 'Informe de Inversiones';
    logOperacion: OperacionesContratoLog = 'Informe de Inversiones';

    constructor(
        private readonly investmentReportService: InvestmentReportService,
        private active: ActivatedRoute,
        private clientRequest: ClientRequestService,
        private loader: LoadingService,
        private formBuild: FormBuilder,
        private localStorageService: LocalStorageService,
        private tracesService: TracesService,
    ) {
        this.active.params.subscribe(async (data) => {
            this.clientData = await this.clientRequest.searchClient({ rut: data.rut });
            this.nameClient = toTitleCase(this.clientData.NombreCliente);
        });
        let lastDate: any = new Date();
        lastDate = lastDate.setDate(1)
        lastDate = moment(lastDate).add(-1, 'month');
        const monthInitValue = moment().month(lastDate.month()).format('MM');
        const yearInitValue = `${lastDate.year()}`;
        this.investmentFormGroup = this.formBuild.group({
            monthSelected: [ monthInitValue, [Validators.required]],
            yearSelected: [ yearInitValue, [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.active.data.subscribe((data) => {
            this.isPershingReport = data.pershing;
            if (this.isPershingReport) {
                this.labelPershing = 'internacional';
                this.logCategoria = 'Informe de Pershing';
                this.logOperacion = 'Informe de Pershing';
            }
            this.logData(
                {
                    Categoria: this.logCategoria,
                    Operacion: this.logOperacion,
                    Metodo: 'ngOnInit()',
                    eventoLog: 'Carga inicial (ingreso a la página)',
                    status: Status.OK,
                    Secuencia: 1,
                    EntradaLog: {
                        accion: 'Carga inicial (ingreso a la pagina)',
                    },
                },
                'log_negocio',
            );
        });
        this.listMonth = this.generateMonths();
        this.listYears = this.generateYears();
    }

    onGeneratedReport() {
        this.showMessage = false;

        if (this.investmentFormGroup.invalid) {
            this.investmentFormGroup.updateValueAndValidity();
            this.investmentFormGroup.markAllAsTouched();
            return;
        }

        const monthSelected = this.investmentFormGroup.get('monthSelected').value;
        const yearSelected = this.investmentFormGroup.get('yearSelected').value;
        let date = `${yearSelected}-${monthSelected}-01`;
        this.loader.showLoading(false);

        let logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: this.logCategoria,
            Operacion: this.logOperacion,
            Metodo: 'onGeneratedReport()',
            eventoLog: `Click en botón generar ${this.logCategoria}`,
            status: Status.OK,
            Secuencia: 2,
            EntradaLog: {
                fechaFiltro: date,
            },
        };
        this.logData(logtemp, 'log_negocio');
        const dataExecutive = this.localStorageService.get('dataExecutive', true);
        const accountName = `${dataExecutive.RutEjecutivo}|${dataExecutive.MailEjecutivo}|${dataExecutive.NombreEjecutivo}`;
        let indexlogstash: ConfigIndexLogStash = 'log_negocio';

        // ejecuta informe de inversiones
        if (!this.isPershingReport) {
            this.investmentReportService
                .getInvestmentReport(this.clientData.RutCliente, 'Mensual', date, accountName)
                .then((data) => {
                    if (data.statusCode === 206) {
                        this.messageContent = data.message;
                        this.messageType = data.message === 'Cliente Sin Información para el periodo consultado' ? 'warning' : 'error';
                        this.messageTitle = 'Cliente sin información';
                        this.showMessage = true;
                        this.loader.hideLoading();
                        logtemp.eventoLog = `Click en botón generar result: ${this.messageTitle}`;
                    } else {
                        this.loader.hideLoading();
                        const fileName = `${this.clientData.RutCliente}-${date}.pdf`;
                        utils.downloadFile(data.report, fileName, 'application/pdf');
                        logtemp.eventoLog = `Click en botón generar result: DESCARGADO`;
                    }
                })
                .catch((error) => {
                    this.loader.hideLoading();
                    this.messageContent = 'No hemos podido generar el informe de inversiones.';
                    this.messageType = 'error';
                    this.messageTitle = 'Algo salio mal';
                    this.showMessage = true;
                    logtemp.status = Status.NOK;
                    logtemp.eventoLog = `Click en botón generar result: ERROR`;
                    logtemp.EntradaLog = { ...logtemp.EntradaLog, error };
                    indexlogstash = 'log_error';
                })
                .finally(() => {
                    this.logData(logtemp, indexlogstash);
                });
        } else {
            // ejecuta informe de inversiones pershing
            this.investmentReportService
                .getInvestmentPershingReport(this.clientData.RutCliente, 'Mensual', date, accountName)
                .then((data) => {
                    if (data.hasOwnProperty('report')) {
                        if (this.isBase64(data.report)) {
                            this.loader.hideLoading();
                            date = `${yearSelected}-${monthSelected}`;
                            const fileName = `InformeInversiones_Plataforma_Extranjera_${date}_${this.clientData?.RutCliente}${this.clientData?.DigitoVerificador}.pdf`;
                            utils.downloadFile(data.report, fileName, 'application/pdf');
                            this.loader.hideLoading();
                        } else {
                            this.messageContent = data.report === 'No existe información en el periodo consultado.' ? 'No hemos podido generar el informe plataforma internacional, debido a que el cliente no cuenta con datos en ese periodo.' : data.report;
                            this.messageType = data.report === 'No existe información en el periodo consultado.' ? 'warning' : 'error';

                            this.messageTitle = 'Cliente sin información';
                            this.showMessage = true;
                            this.loader.hideLoading();
                            logtemp.eventoLog = `Click en botón generar result: ${this.messageTitle}`;
                            logtemp.EntradaLog = { ...logtemp.EntradaLog, message: this.messageTitle };
                        }
                    } else {
                        this.messageContent = data.message;
                        this.messageType = 'warning';
                        this.messageTitle = 'Cliente sin información';
                        this.showMessage = true;
                        this.loader.hideLoading();
                        logtemp.eventoLog = `Click en botón generar result: ${this.messageTitle}`;
                        logtemp.EntradaLog = { ...logtemp.EntradaLog, message: this.messageTitle };
                        this.loader.hideLoading();
                    }
                })
                .catch((error) => {
                    this.loader.hideLoading();
                    this.messageContent = 'No hemos podido generar el Informe Plataforma Nacional.';
                    this.messageType = 'error';
                    this.messageTitle = 'Algo salio mal';
                    this.showMessage = true;

                    logtemp.status = Status.NOK;
                    logtemp.EntradaLog = { ...logtemp.EntradaLog, error, mensaje: this.messageTitle };
                    indexlogstash = 'log_error';
                })
                .finally(() => {
                    this.logData(logtemp, indexlogstash);
                });
        }
    }

    isBase64(str): boolean {
        try {
            return btoa(atob(str)) == str;
        } catch (err) {
            return false;
        }
    }

    generateMonths() {
        this.listMonth.push({ label: 'Selecciona', value: null });
        Array.apply(0, Array(12)).map((_, i) => {
            this.listMonth.push({ value: moment().month(i).format('MM'), label: moment().month(i).format('MMMM') });
        });
        return this.listMonth;
    }

    generateYears() {
        const year = new Date().getFullYear();
        const yearSince = this.isPershingReport ? 2021 : 2012;
        const listYears: SelectItem[] = [];
        const years = Array.from({ length: 20 }, (v, i) => year - 20 + i + 1);

        for (const y of years) {
            if (y >= yearSince) {
                listYears.push({
                    label: y.toString(),
                    value: y.toString(),
                });
            }
        }

        listYears.push({ label: 'Selecciona', value: null });

        return listYears.reverse();
    }

    async logData(log: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...EntradaLog,
            };
            inputLog.Salida = '-';
            inputLog.Tipo_sesion = 'ASESOR';
            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, 'NWSA_ASESOR', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }
}
