import { Component, Input } from '@angular/core';

@Component({
  selector: 'mmb-checkbox',
  templateUrl: './mmb-checkbox.component.html',
  styleUrls: ['./mmb-checkbox.component.scss']
})
export class MmbCheckboxComponent {
  @Input() checked: boolean;
  @Input() label: string;
  @Input() disabled: boolean = false;
}
