import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClienteActivoDto, MensajeBackend, ModalGenericoOrdenes, SolicitudDto, UploadContentManagerJSON } from 'src/app/features/models/ordenes/divisas/divisas.modelo';
import { OrdenesDivisasService } from 'src/app/features/services/ordenes/divisas/ordenes.divisas.service';
import { DialogService, LoadingService, UtilsService } from 'src/app/shared/services';
import { ConfirmaModalComponentDivisas } from './components/confirma-modal/confirma-modal.component';
import { DialogMessageComponent, OptionsMessage } from 'src/app/shared/components/dialog-message/dialog-message.component';

@Component({
    selector: 'app-orden-carro',
    templateUrl: './orden-carro.component.html',
    styleUrls: ['./orden-carro.component.scss'],
})
export class OrdenCarroComponent implements OnInit {
    //CLIENTE CONECTADO
    public clienteconectado: ClienteActivoDto;

    //INDICADORES
    clienteTieneMultipassActivo: boolean = false;
    esperandoMultipass: boolean = false;
    hayarchivoUploded: boolean = false;
    expandirAutorizaSURAPass: boolean;
    expandirAutorizaUpload: boolean;

    editarNumerotelefonico: boolean;
    formtelefono: FormGroup;
    public telefono = new FormControl(null, [Validators.required, this.validateMinValue(11111111), this.validateMaxValue(999999999999)]);

    //ARCHIVO
    responseFileData: any = null;
    messageAlertFile: boolean;
    data: any;

    mensaje: MensajeBackend = {
        messageContent: '',
        messageType: 'error',
        messageTitle: 'Algo salio mal',
        showMessage: false,
    };

    //WIZZARD
    linea = {
        steps: [],
        currentStep: null,
    };

    //SOLICITUD CORREDORA
    solicitud: SolicitudDto;

    horaApertura: string = '--:--';
    horaCierre: string = '--:--';
    modalActivo: boolean = false;

    constructor(private router: Router, private fb: FormBuilder, private loadingService: LoadingService, private dialogService: DialogService, private divisasService: OrdenesDivisasService, private datepipe: DatePipe, private utilsService: UtilsService) {
        this.clienteTieneMultipassActivo = true;
        this.expandirAutorizaSURAPass = true;
        this.expandirAutorizaUpload = false;
    }

    ngOnInit(): void {
        this.inicializaFormulario();
        this.clienteconectado = this.validarClienteActivo();

        //INICIALIZA LA REVISION
        this.linea.steps = ['Ingreso de órdenes', 'Confirmación'];
        this.linea.currentStep = 2;

        //datos de ejecutivo
        let ejecutivoJson = sessionStorage.getItem('dataContentful');
        let ejecutivoAdicJson = sessionStorage.getItem('dataExecutive');
        let ejecutivoAdic = JSON.parse(ejecutivoAdicJson);
        let ejecutivo = JSON.parse(ejecutivoJson);

        let cliente = this.validarClienteActivo();
        var fecha = new Date();
        const formattedDate = formatDate(fecha, 'yyyyMMddHHmmss', 'en');
        var numeroorden: number = Number(formattedDate); //debe ser un numero corto

        //RECUPERA EL CARRO DE COMPRA
        let carroFinalJson = sessionStorage.getItem(this.divisasService.STORAGE_DIVISAS_CARRO_FINAL);
        this.solicitud = JSON.parse(carroFinalJson) || {
            'id-folio-orden': numeroorden,
            estado: 'INF',
            'id-ejecutivo': ejecutivo.executiveRut,
            'nombre-ejecutivo': ejecutivo.executiveName,
            'email-ejecutivo': ejecutivoAdic && ejecutivoAdic.MailEjecutivo ? ejecutivoAdic.MailEjecutivo : 'ejecutivo@sura.cl',
            'rut-cliente': cliente.rutparteentera,
            'rut-dv-cliente': cliente.rutdigitoverificador,
            'nombre-cliente': cliente.nombre,
            'email-cliente': cliente.email,
            'fono-cliente': cliente.telefono,
            ordenes: [],
        };
    }

    inicializaFormulario() {
        this.formtelefono = this.fb.group({
            telefono: this.telefono,
        });
    }

    /**
     * Enviar autorizacion
     * @param forma
     * @returns
     */
    goToEnviarSolicitud(forma) {
        if (this.esperandoMultipass) return;

        let optionsMessage: ModalGenericoOrdenes = {
            titulo: '¿ Estás seguro de enviar la orden ?',
            mensaje: forma === 'NONE' ? 'Una vez enviada, será procesada por la mesa Corredora de Bolsa SURA.' : 'Una vez enviada, deberás informale al cliente para que la revise y autorice a través de su SURAPass.',
            labelacepta: 'SI, ENVIAR',
            labelarechaza: 'CANCELAR',
        };
        const config = {
            panelClass: ['modal-confirmacion-box'],
            disableClose: false,
            data: { optionsMessage },
        };

        //llama al modal
        let dialogRef = this.dialogService.openDialog(ConfirmaModalComponentDivisas, config);

        //evaua el resultado del modal
        dialogRef.afterClosed().subscribe(async (data: any) => {
            if (data != null && data === 'OK') {
                await this.consultaModalActivo();
                if(!this.modalActivo){
                    if (forma === 'SURAPASS') {
                        //GENERAR TRANSACCION DE INGRESO y ACTIVA LA ESPERA MULTIPASS
                        this.esperandoMultipass = true;
                    }
    
                    if (forma === 'NONE') {
                        this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/divisas-finaliza`);
                    }
                } else{
                    const mensajeHorario = `El horario para ingresar órdenes de compra o venta de divisas es de <strong>lunes a viernes desde las ${this.horaApertura} hasta las ${this.horaCierre} horas.</strong>`
                    const optionsMessage: OptionsMessage = {
                        textButton: 'ENTENDIDO',
                        buttonStyle: 'outline-grey',
                        showButton: true,
                        sizeButton: 'lg',
                        icon: 'assets/img/icono-escudo-info.svg',
                        showIcon: true,
                        titleMessage: 'Importante.',
                        message: mensajeHorario,
                    };
                    const config = {
                        panelClass: 'modal-md',
                        disableClose: true,
                        data: {
                            optionsMessage,
                        },
                    };
                    this.dialogService.openDialog(DialogMessageComponent, config);
                }
            }
        });
    }

    async consultaModalActivo() {
        try {
            const { codigo, fecha } = await this.divisasService.getFechaHoraServidor();
            const { horaApertura, horaCierre } = JSON.parse(sessionStorage.getItem("dataHorarioDivisas"));
            this.horaApertura = horaApertura;
            this.horaCierre = horaCierre;
            if (codigo === 0 && fecha && horaApertura && horaCierre) {
                const datosAdicionalesFecha = { fechaServicio: fecha, horaApertura, horaCierre };
                const { fechaSERVER, fechaApertura, fechaCierre } = this.utilsService.formatearFechaAperturaCierre(datosAdicionalesFecha);
                this.modalActivo = !(fechaSERVER.getTime() >= fechaApertura.getTime() && fechaSERVER.getTime() <= fechaCierre.getTime());
            }
        } catch (error) {
            console.log(error);
            this.modalActivo = true;
        }
    }

    
    goToComprar() {
        sessionStorage.removeItem(this.divisasService.STORAGE_DIVISAS_COMPRA_VENTA_CARRO);
        this.router.navigate(['main/client-search/summary/ordenes/divisas-orden-compra-venta']);
    }

    goToVender() {
        sessionStorage.removeItem(this.divisasService.STORAGE_DIVISAS_COMPRA_VENTA_CARRO);
        this.router.navigate(['main/client-search/summary/ordenes/divisas-orden-compra-venta']);
    }

    goEditarEvent() {
        this.telefono.setValue(this.solicitud['fono-cliente']);
        this.editarNumerotelefonico = !this.editarNumerotelefonico;
    }

    goCerrarActualizaTelefono() {
        this.editarNumerotelefonico = false;
    }

    goEditarTelefono() {
        if (this.formtelefono.valid) {
            let carroFinalJson = sessionStorage.getItem(this.divisasService.STORAGE_DIVISAS_CARRO_FINAL);
            let solicitudtmp = JSON.parse(carroFinalJson);
            solicitudtmp['fono-cliente'] = this.telefono.value;
           
            //actualiza la solicitud con los datos incorporados
            sessionStorage.setItem(this.divisasService.STORAGE_DIVISAS_CARRO_FINAL, JSON.stringify(solicitudtmp));
            this.solicitud = solicitudtmp

            this.editarNumerotelefonico = false;
        } else {
            if (!this.telefono.valid) {
                this.telefono.markAsTouched();
            } else {
                this.telefono.markAsUntouched();
            }
        }
    }

    /**
     * Entrega los datos en base 64
     * @param event
     */
    responseFile(event) {
        this.responseFileData = event;
        if (this.responseFileData && this.responseFileData.data) {
            let cliente = this.validarClienteActivo();
            let datos = this.responseFileData.data.split(',');
            let cabecera: string = datos[0];
            let tipoarchivoa = cabecera.split(';')[0].split(':')[1];
            let base64 = datos[1];
            let archivobase64: UploadContentManagerJSON = {
                idorden: 0,
                'archivo-base64': base64,
                'tipo-contenido': tipoarchivoa,
                nombre: 'orden-pershing.' + this.extensionByMime(tipoarchivoa),
                'rut-cliente': '' + cliente.rutparteentera,
                'nombre-cliente': cliente.nombre,
            };
            //SUBE EL ARCHIVO AL STORAGE
            sessionStorage.setItem(this.divisasService.STORAGE_DIVISAS_ARCHIVO_CONTENT_MANAGER, JSON.stringify(archivobase64));
            this.hayarchivoUploded = true;

            let copia = archivobase64;
            copia['archivo-base64'] = base64.length + ' bytes';

            this.divisasService.saveLogAccionAsesor(
                'Ingreso Ordenes Divisas Off Line',
                'Ingreso OCV Off Line Paso 2',
                'OrdenCarroComponent - responseFile()',
                'ok',
                'Upload de archivo del cliente para content manager tipo contenido',
                copia,
            );
        }
    }

    hayArchivoCargado(event) {
        if (event === false) {
            this.hayarchivoUploded = false;
        }
    }

    /**
     * Consulta el cliente conectado de la ultima session en el Storage
     * @returns Cliente conectado
     */
    validarClienteActivo(): ClienteActivoDto {
        let data = sessionStorage.getItem('clientData');
        if (data) {
            const dataClient = JSON.parse(data);
            let rutlimpio = dataClient.rut.split('.').join('');
            let rutPartes = rutlimpio.split('-');
            let nombre = dataClient.name;
            let email = ''; 
            let phone = '';
            if (dataClient.clientInfo) {
                email = dataClient.clientInfo[0] && dataClient.clientInfo[0].text && dataClient.clientInfo[0].text.indexOf('@') > 0 ? dataClient.clientInfo[0].text : '';
                phone = dataClient.clientInfo[1] && dataClient.clientInfo[1].text && Number(dataClient.clientInfo[1].text) !== NaN ? dataClient.clientInfo[1].text : '';
            }
            if (dataClient.companyInfo) {
                if(dataClient.companyInfo[2] && dataClient.companyInfo[2].text && dataClient.companyInfo[2].text.indexOf('@') > 0) {
                     email = dataClient.companyInfo[2].text;
                } 
                if (dataClient.companyInfo[3] && dataClient.companyInfo[3].text && Number(dataClient.companyInfo[3].text) !== NaN ){
                    phone = dataClient.companyInfo[3].text 
                } 
                const dataClientName = dataClient.companyInfo[0]?.text;
                if (dataClient.companyInfo[0] && dataClientName && dataClientName.length > 5) {
                    nombre = nombre === dataClientName ? dataClientName : nombre;
                }
            }
            return {
                rut: rutlimpio,
                rutparteentera: Number(rutPartes[0]),
                rutdigitoverificador: rutPartes[1],
                email: email ? email : 'sincorreo@correo.cl',
                nombre: nombre,
                telefono: phone,
            };
        } else {
            this.router.navigate(['main/client-search']);
            return undefined;
        }
    }

    extensionByMime(mimetype: string) {
        let extension: string = 'txt';
        let comprar: any = [
            { tipo: 'text/html', extension: 'html' },
            { tipo: 'text/css', extension: 'css' },
            { tipo: 'text/xml', extension: 'xml' },
            { tipo: 'image/gif', extension: 'gif' },
            { tipo: 'image/jpeg', extension: 'jpg' },
            { tipo: 'application/x-javascript', extension: 'js' },
            { tipo: 'application/atom+xml', extension: 'atom' },
            { tipo: 'application/rss+xml', extension: 'rss' },
            { tipo: 'text/mathml', extension: 'mml' },
            { tipo: 'text/plain', extension: 'txt' },
            { tipo: 'text/vnd.sun.j2me.app-descriptor', extension: 'jad' },
            { tipo: 'text/vnd.wap.wml', extension: 'wml' },
            { tipo: 'text/x-component', extension: 'htc' },
            { tipo: 'image/png', extension: 'png' },
            { tipo: 'image/tiff', extension: 'tiff' },
            { tipo: 'image/vnd.wap.wbmp', extension: 'wbmp' },
            { tipo: 'image/x-icon', extension: 'ico' },
            { tipo: 'image/x-jng', extension: 'jng' },
            { tipo: 'image/x-ms-bmp', extension: 'bmp' },
            { tipo: 'image/svg+xml', extension: 'svg' },
            { tipo: 'image/webp', extension: 'webp' },
            { tipo: 'application/java-archive', extension: 'jar' },
            { tipo: 'application/mac-binhex40', extension: 'hqx' },
            { tipo: 'application/msword', extension: 'doc' },
            { tipo: 'application/pdf', extension: 'pdf' },
            { tipo: 'application/postscript', extension: 'ps' },
            { tipo: 'application/rtf', extension: 'rtf' },
            { tipo: 'application/vnd.ms-excel', extension: 'xls' },
            { tipo: 'application/vnd.ms-powerpoint', extension: 'ppt' },
            { tipo: 'application/vnd.wap.wmlc', extension: 'wmlc' },
            { tipo: 'application/vnd.google-earth.kml+xml', extension: 'kml' },
            { tipo: 'application/vnd.google-earth.kmz', extension: 'kmz' },
            { tipo: 'application/x-7z-compressed', extension: '7z' },
            { tipo: 'application/x-cocoa', extension: 'cco' },
            { tipo: 'application/x-java-archive-diff', extension: 'jardiff' },
            { tipo: 'application/x-java-jnlp-file', extension: 'jnlp' },
            { tipo: 'application/x-makeself', extension: 'run' },
            { tipo: 'application/x-perl', extension: 'pl' },
            { tipo: 'application/x-pilot', extension: 'prc' },
            { tipo: 'application/x-rar-compressed', extension: 'rar' },
            { tipo: 'application/x-redhat-package-manager', extension: 'rpm' },
            { tipo: 'application/x-sea', extension: 'sea' },
            { tipo: 'application/x-shockwave-flash', extension: 'swf' },
            { tipo: 'application/x-stuffit', extension: 'sit' },
            { tipo: 'application/x-tcl', extension: 'tcl' },
            { tipo: 'application/x-x509-ca-cert', extension: 'der' },
            { tipo: 'application/x-xpinstall', extension: 'xpi' },
            { tipo: 'application/xhtml+xml', extension: 'xhtml' },
            { tipo: 'application/zip', extension: 'zip' },
            { tipo: 'application/octet-stream', extension: 'txt' },
            { tipo: 'audio/midi', extension: 'mid' },
            { tipo: 'audio/mpeg', extension: 'mp3' },
            { tipo: 'audio/ogg', extension: 'ogg' },
            { tipo: 'audio/x-realaudio', extension: 'ra' },
            { tipo: 'video/3gpp', extension: '3gp' },
            { tipo: 'video/mpeg', extension: 'mpg' },
            { tipo: 'video/quicktime', extension: 'mov' },
            { tipo: 'video/x-flv', extension: 'flv' },
            { tipo: 'video/x-mng', extension: 'mng' },
            { tipo: 'video/x-ms-asf', extension: 'asf' },
            { tipo: 'video/x-ms-wmv', extension: 'wmv' },
            { tipo: 'video/x-msvideo', extension: 'avi' },
            { tipo: 'video/mp4', extension: 'mp4' },
        ];

        comprar.forEach((buscar) => {
            if (buscar.tipo.trim().toLowerCase() === mimetype.trim().toLowerCase()) {
                extension = buscar.extension;
            }
        });
        return extension;
    }

    public validateMinValue(minValue: number) {
        return (currentControl: AbstractControl): { [key: string]: any } => {
            let isValid = true;
            const currentNumber = currentControl?.value ? currentControl?.value : '';
            if (currentNumber != '' && Number(currentNumber.replaceAll('.', '').replaceAll(',', '.')) < minValue) {
                isValid = false;
            }
            return isValid ? null : { min: true };
        };
    }

    public validateMaxValue(maxValue: number) {
        return (currentControl: AbstractControl): { [key: string]: any } => {
            let isValid = true;

            const currentNumber = currentControl?.value ? currentControl?.value : '';
            if (Number(currentNumber.replaceAll('.', '').replaceAll(',', '.')) > maxValue) {
                isValid = false;
            }
            return isValid ? null : { max: true };
        };
    }
}
