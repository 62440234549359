const contratoCategoria = [
  "Autentificacion",
  "Consolidado",
  "Invertir/Fondos Mutuos",
  "Cambio de Fondos/APV",
  "Cambio de Fondos/Fondos Mutuos",
  "Rescate/Fondos Mutuos",
  "Rescate/57Bis",
  "Invertir/Seguros",
  "Seguros/Distribucion Pagos Futuros",
  "Seguros/Denuncia Siniestro",
  "Seguros/Actualizacion Beneficiarios",
  "Seguros/Cambio Fondos",
  "Seguros/Actualizacion Antecedentes",
  "Actualizacion Antecedentes FFMM",
  "Cambio de Fondos/57Bis",
  "Invertir/57Bis",
  "Cierre de Sesion",
  "Clave Transaccional",
  "Mandato AFP",
  "Detalle Fondos Mutuos",
  "Detalle Fondos Mutuos 57Bis",
  "Detalle Fondos Mutuos APV",
  "Detalle Poliza",
  "Detalle Cuenta Dos",
  "Detalle Cuenta Dos 57Bis",
  "Detalle Cotizacion Obligatoria AFP",
  "Detalle Acciones APV",
  "Detalle APV AFP",
  "Detalle Poliza Seguro",
  "Subsaldo APV",
  "Subsaldo Cuenta Dos",
  "Subsaldo Cuenta Dos 57Bis",
  "Solicitud de Clave",
  "Cambio de Clave Transitoria",
  "Detalle Renta Previsional",
  "Detalle Renta Privada",
  "Reactivacion Poliza",
  "Formulario Contacto Sitio Publico - Consulta",
  "Suscripcion PAC-PAT",
  "Formulario Contacto Sitio Publico - Venta",
  "Asesoria TN",
  "Suscripcion Clientes FFMM",
  "Seguro Oncologico",
  "Formulario Contacto Sitio Privado",
  "Suscripcion Online",
  "Webinar",
  "Caida Core",
  "Firma de Contratos CB",
  "Invertir/APV",
  "Suscripcion Mensual APV",
  "Popup Administrable",
  "Pershing",
  "Modificacion Suscripcion Mensual APV FFMM",
  "Rescate/APV",
  "Recomendacion Fondos Estrategia",
  "Autentificacion Sura Ejecutivo",
  "Consulta Cliente Sura Ejecutivo",
  "Transacciones y Movimientos Vida",
  "Pershing APV",
  "Informe Inversión",
  "Modulo Firma Endosos",
  "Saldos Graficados",
  "Suscripcion Mensual APV FFMM",
  "Transacción en proceso",
  "Seguros/Cambio Fondos On Line",
  "Seguros/Invertir",
  "Seguros/Distribucion Flujos Futuros On Line",
  "Traspaso FFMM APV entre Entidades",
  "Ingreso de Ordenes de Compra y Venta Off Line",
  "Seguros/Rescate Flexible No APV On Line",
  "Seguros/Rescate Ahorro NOAPV",
  "Seguros/Rescate Ahorro APV",
  "Ingreso Ordenes Pershing Off Line",
  "Sura Publico/Suscripcion 100% On Line",
  "Aporte a Cuenta de Inversion",
  "Aporte a Cuenta de Inversion NO APV",
  "Aporte a Cuenta de Inversion APV",
  "Mis datos",
  "Modal acceso nueva web",
  "Volver al sitio actual",
  "Perfil Inversionista",
  "Circularizacion",
  "Acciones",
  "Cartolas",
  "Banner",
  "Comportamiento de Fondos",
  "Error Web",
  "Mis Certificados",
  "Transacciones",
  "Home",
  "Valor Cuota",
  "Suscripciones",
  "SURA PASS",
  "Invertir",
  "Ficha Cliente",
  "Cambio de fondo",
  "Informe de Inversiones",
  "Ingresar orden de compra de acciones",
  "Modificación de flujo no APV",
  "Buscador Cliente",
  "Resumen Cliente",
  "Cartera Clientes",
  "Informe de Pershing",
  "Clave SURA",
  "Múltiple Tabs - Multisesión (asesores)",
  "Certificado de Saldos",
  "Saldos Consolidados",
  "Notificaciones Asesor",
  "Buscador Cliente Cumplimiento"
] as const;

export { contratoCategoria };
