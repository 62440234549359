import { Component, Input, OnInit, OnChanges, Output, EventEmitter, HostListener, Injectable } from '@angular/core';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { RequestSaveLogModel, ValueModel } from 'src/app/shared/models';
import { ContentfulService, ExecutiveService } from 'src/app/shared/services';
import { PafService } from 'src/app/shared/services/paf/paf.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AssetService } from 'src/app/shared/services/asset/asset.service';
import { environment } from '@environment';
import { ClientRequestService, ClientService } from 'src/app/features/services';
import { IndicadorClienteService } from 'src/app/shared/services/indicadores/indicador-cliente.service';
import { takeUntil } from 'rxjs/operators';
import { SaveLogModel } from '../../../../../shared/models/request-save-log.model';


@Component({
  selector: 'app-asset-allocation',
  templateUrl: './asset-allocation.component.html',
  styleUrls: ['./asset-allocation.component.scss']
})
@Injectable()
export class AssetAllocationComponent implements OnInit, OnChanges {

  showAssetState: boolean = false;
  @Input() rutCliente: any;
  @Output() hiddenAsset = new EventEmitter<boolean>();
  @Output() allocationData = new EventEmitter<any>();
  assetData:any;
  clientePaf: any;
  pafRentaFija:any;
  pafRentaVariable:any;
  pafAlternativos:any;
  pafMoneyM:any;
  loading = false;
  errorPaf = false;
  desalineadoRV:boolean;
  tipoDesalineadoRV:string;
  habilitarAsset:boolean;
  ruteroPaf:boolean;
  textoDesalineado:string;
  sinSaldo = false;
  perfilDesac: string;
  instruIncomp = false;
  onlyInsurnace = false;
  firmaMandato = false;

  dataClient$: Observable<any>;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private pafService: PafService,
    private executiveService: ExecutiveService,
    private tracesService: TracesService,
    private assetService: AssetService,
    private contentfulService: ContentfulService,
    private clientRequestService: ClientRequestService,
    private clientService : ClientService,
    public indicadorClienteService: IndicadorClienteService
  ) { }

 async ngOnInit() {

    this.habilitarAsset = await this.habilitarAssetAllo();
    this.ruteroPaf = await this.getPafRuteroVisibility(this.rutCliente);
  }

  ngOnChanges(): void {
    this.showAssetState = false;
    this.clientePaf = null;
  }

  async showAsset() {

    await this.saveLogPaf('ok');
    if (!this.loading) {
      this.calculoPaf();
    }
   }

  async calculoPaf() {
    
    try {
      this.loading = true;
      this.clientePaf = true;
      const clienteJuridico =  this.tipoPersona();
      const tieneProductosAFP = this.validarProductoAfp();
      const mandatoFirmado = this.validarMandatoFirmado();
      const dataIndicador = this.indicadorClienteService.indicadoresCliente;
      this.perfilDesac = dataIndicador?.perfilInversionista;
      this.firmaMandato = clienteJuridico ? true : (tieneProductosAFP ? mandatoFirmado : true);

      const dataBalance = this.clientRequestService.clientBalances.value;
      const sumaItem = dataBalance.reduce((prev, valor) => prev + valor.amount, 0);
      sumaItem === 0 ?  (this.sinSaldo = true, this.clientePaf = false ): '';
     
      dataBalance.every( author =>{
        author.prevision !== 'APV' && author.prevision !== 'NOAPV' ? 
            (this.onlyInsurnace = true, this.sinSaldo = false, this.clientePaf = false) : '';
      });

      if(this.firmaMandato && this.clientePaf){

        this.clientePaf = await this.pafService.getCalculoPaf(this.rutCliente);
        this.assetService.updateParametro(this.clientePaf);
        const saldoPaf = this.clientePaf.responsePaf?.Cliente;
        this.pafRentaFija = saldoPaf.RentaFija;    
        this.pafRentaVariable = saldoPaf.RentaVariable;
        this.desalineadoRV = this.pafRentaVariable?.Desalineado;
        this.tipoDesalineadoRV = this.pafRentaVariable?.TipoDesalineado;
        const conCartaTexto = saldoPaf?.RentaVariable?.Carta ? 'Desalineado con carta RV' : 'Desalineado RV';
        const alineado = "Alineado RV"
        this.textoDesalineado = this.desalineadoRV ? `${conCartaTexto} ${this.tipoDesalineadoRV}` : alineado;
        this.pafAlternativos = saldoPaf.Alternativos;
        this.pafMoneyM = saldoPaf.MoneyMarket;
        this.loading = false;
        this.showAssetState = !this.showAssetState;

      }else{
        this.showAssetState = true;
        this.loading = false;
      }

    } catch (error) {
      this.clientePaf?.responsePaf?.CodigoError === 2 ? this.saveLogPafError('2','Los intrumentos no concuerdan con nuestros registros') : this.saveLogPafError('NO_SE_PUEDE_EJECUTAR_EL_SERVICIO','No se puede ejecutar el servicio');
      this.firmaMandato = true;
      this.errorPaf = true;
      this.clientePaf = null;
      this.showAssetState = !this.showAssetState;
      this.loading = false;
      return;
    }
  }

  async saveLogPafError(codigo, mensaje){
    const dataContentful = this.executiveService.executiveModel;
    const logData = {} as RequestSaveLogModel;
    
    logData.indexLogStash = 'log_error';
    logData.field = 'weblog';
    logData.service = 'Asesor';
    logData.value = {} as ValueModel;
    logData.value.Tipo_sesion= 'ASESOR'
    logData.value.channel = 'NWSA_ASESOR'
    logData.value.user = dataContentful.executiveRut;
    logData.value.EventoLog = 'No se puede ejecutar el servicio';
    const entradaLog = {
      rut: this.rutCliente,
      TipoMensaje:"ERROR",
      Codigo:codigo,
      Mensaje:mensaje
    };
    logData.value.EntradaLog = JSON.stringify(entradaLog);
    await this.tracesService.saveLog(logData);

  }
  
  async saveLogPaf(errMessage) {
    const dataContentful = this.executiveService.executiveModel;
    const logData = {} as RequestSaveLogModel;

    logData.indexLogStash = 'log_negocio';
    logData.field = 'weblog';
    logData.value = {} as ValueModel;
    logData.value.SesionCliente = this.executiveService.sessionId;
    logData.value.Operacion = 'Asset Allocation / Ver más detalle';
    logData.value.Categoria = 'Asset Allocation';
    logData.value.Metodo = 'showAsset()';
    logData.value.EventoLog = "Botón Ver más detalle";
    logData.value.Secuencia = 0;
    logData.value.user = dataContentful.executiveRut;
    logData.value.Status = 'ok';
    logData.service = 'getCalculoPaf';
    logData.value.User_red = "";
    logData.value.EntradaLog = {
        rut: this.rutCliente,
    };
    await this.tracesService.saveLog(logData);
}
  showAssetPage(){
    this.saveLogPafInterior('Botón Ver más detalle')
    this.assetData = this.clientePaf;
    this.allocationData.emit(this.clientePaf);
    this.hiddenAsset.emit(true);
  }

  async saveLogPafInterior(errMessage) {
    const dataContentful = this.executiveService.executiveModel;
    const logData = {} as RequestSaveLogModel;

    logData.indexLogStash = 'log_negocio';
    logData.field = 'weblog';
    logData.value = {} as ValueModel;
    logData.value.SesionCliente = this.executiveService.sessionId;
    logData.value.Operacion = 'Asset Allocation / Ver más detalle / Nivel 2';
    logData.value.Categoria = 'Asset Allocation';
    logData.value.Metodo = 'showAssetPage()';
    logData.value.EventoLog = errMessage;
    logData.value.Secuencia = 2;
    logData.value.user = dataContentful.executiveRut;
    logData.value.Status = 'ok';
    logData.service = '';
    logData.value.User_red = "";
    logData.value.EntradaLog = {
        rut: this.rutCliente,
    };
    await this.tracesService.saveLog(logData);
}

  async habilitarAssetAllo() {
    const { enabled, environmentList } = await
      this.contentfulService.getEntryFromFront(environment.KEYS.CONTENTFUL.HABILITAR_ASSET_ALLOCATION);
      const existeAmbiente = environmentList.includes(environment.name);
      this.habilitarAsset = enabled && existeAmbiente;
      return this.habilitarAsset;
  }

  async getPafRuteroVisibility(rut: string) {
    try {
        const ruteroID = environment.KEYS.CMSRUTERO.PAF_SEGUNDO_NIVEL;
        const [dataRutero] = await this.clientRequestService.getRutero(ruteroID);
        const included: boolean = dataRutero ? dataRutero?.active && dataRutero?.ruteroArr?.includes(rut) || !dataRutero?.active : true;
        return included;
    } catch (error) {
        console.log(error);
        return false;
    }
  }

  tipoPersona() {
    try{
      const perfil = sessionStorage.getItem("tipoPersona");
      return perfil.toUpperCase() === "JURIDICA" ? true : false;
    }catch(err){
      console.error(err);
      return false;
    }
  }

  validarProductoAfp() {
    try{
      const productosCliente = JSON.parse(sessionStorage.getItem("balanceCertificate"));
      return productosCliente.some(producto => producto.type.toUpperCase() === "AFP");
    }catch(err){
      console.error(err);
      return false;
    }
  }

  validarMandatoFirmado() {
    try{
      const clientData = JSON.parse(sessionStorage.getItem("clientData"));
      return clientData?.documentation?.some(datos => datos?.key?.toUpperCase() ==="MANDATEAFP" && datos?.result?.toUpperCase() === "FIRMADO") 
    }catch(err){
      console.error(err);
      return false;
    }
  }
}
