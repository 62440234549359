import { Injectable } from '@angular/core';
import { UrlCR } from '@environment';
import { RequestHandlerService } from 'src/app/core/services/request-handler/request-handler.service';
import { LocalStorageKeys } from '../executive/executive.service';

@Injectable({
    providedIn: 'root'
})
export class WealthPartnersService {

    constructor(
        private requestHandler: RequestHandlerService
    ) { }

    async autenticar() {

        const token: string = sessionStorage.getItem(LocalStorageKeys.TOKEN);
        const headers = {
            headers: { authorization: `Bearer ${token}` }
        };

        try {
            const url = `${UrlCR}/sitio-publico/wealth-partners/autenticar-acceso-landing`;
            return await this.requestHandler.doPost<any>(url, {}, headers);
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    async irALandingWealthPartners() {

        try {
            const retorno = await this.autenticar();
            if (retorno.response) {
                window.open(retorno.data.urlLanding, "_blank");
                return;
            }
            throw new Error;

        } catch (error) {
            console.error(error)
            throw error;
        }

    }

}
