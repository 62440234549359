import { Component, Inject, OnInit } from '@angular/core';
import { VersionCheckService } from './shared/services/version/version-check.service';
import { environment } from '@environment';
import * as moment from 'moment';
import { PreviousRouteService } from './shared/services/previous-route/previous-route.service';
import { DOCUMENT } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AppService } from './shared/services/app/app.service';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { MatDialog } from '@angular/material/dialog';
import { MultipleSesionComponent } from './shared/components/multiple-sesion/multiple-sesion.component';
import { ContentfulService, UtilsService } from './shared/services';
import { LoginService } from './features/services';
import { XAuthService } from './shared/services/xauth/xauth.service';
import { FeatureVarsService } from './shared/services/feature-vars/feature-vars.service';

// @ts-ignore
moment.suppressDeprecationWarnings = true;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {

  multipleTabModalActivo = false;

  mutipleTabDetector;

  ambienteActual = environment.name;

  ambientesMensajes = {
    'DEV': { value: 'DESARROLLO', colorClass: 'blue', mostrar: true },
    'QA': { value: 'QA', colorClass: 'green', mostrar: true },
    'PREPROD': { value: 'PREPRODUCCIÓN', colorClass: 'red', mostrar: true }
  }

  usuarioAD;

  loginADObservable;

  multisesionObs;

  sesionState;

  constructor(
    private versionCheckService: VersionCheckService,
    private previousRoute: PreviousRouteService,
    private gtmService: GoogleTagManagerService,
    private appService: AppService,
    @Inject(DOCUMENT) private doc: any,
    public router: Router,
    public dialog: MatDialog,
    public utils: UtilsService,
    private contentfulService: ContentfulService,
    private loginService: LoginService,
    private xAuthService: XAuthService,
    private featureVarsService: FeatureVarsService
  ) {
    this.gtmService.addGtmToDom();
  }

  async ngOnInit() {
    const gestionSesionesConfig = await this.habilitarContentful();
    if (!window.location.origin.includes('localhost')) {
      this.versionCheckService.initVersionCheck(environment.versionCheck);
    }
    this.usuarioAD = this.loginService.obtenerUsuarioAD()?.usuario;
    this.loginADObserver();
    this.previousRoute.startListening();
    this.setGTagManager();
    this.setNoScriptTagManager();
    this.appService.dataDogInit();
    this.appService.logRocketInit();
    this.appService.logRocketSendThirdParty();
    this.activarTabsDetector();
    this.setStateObserver();
  }

  async habilitarContentful() {
    const { enabled, environmentList } = await this.contentfulService.getEntryFromFront(environment.KEYS.CONTENTFUL.HABILITAR_MULTITABS)
    const existeAmbiente = environmentList.includes(environment.name);
    const habilitarTabs = enabled && existeAmbiente;

    return habilitarTabs;

  }

  private setGTagManager() {
    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;
      j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);})
      (window,document,'script','dataLayer','${environment.KEYS.GOOGLE.TAG_MANAGER_ID}');
      `;
    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(s);
  }

  private setNoScriptTagManager() {
    const s = this.doc.createElement('noscript');
    s.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.KEYS.GOOGLE.TAG_MANAGER_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    const body = this.doc.getElementsByTagName('body')[0];
    body.appendChild(s);
  }

  activarTabsDetector() {
    const detector = setInterval(() => {
      const rutasNoConsideradas = ['/auth', '/login'];
      if (!rutasNoConsideradas.includes(this.router.url) && this.isLogin()) {
        clearInterval(this.mutipleTabDetector);
        this.multipleTabsDetector();
        clearInterval(detector);
      }
    }, 5000);
  }

  async multipleTabsDetector() {
    const uuid = uuidv4();
    sessionStorage.setItem('sesionId', uuid);
    localStorage.setItem('sesionId', uuid);
    const codigoVariable = 'VALIDADOR_MULTISESIONES_WEB';
    const { VALIDADOR_MULTISESIONES_WEB } = await this.featureVarsService.getMapped({ codigoVariable });
    const estadoWebAsesor = VALIDADOR_MULTISESIONES_WEB?.svrs?.ESTADO_FRECUENCIA_VALIDADOR_ASESOR;
    if(VALIDADOR_MULTISESIONES_WEB?.estado === 'ACTIVO' && estadoWebAsesor?.estado === 'ACTIVO'){
      const frecuencia = Number(estadoWebAsesor.valor);
      this.multipleTabModalActivo = false;
      this.mutipleTabDetector = setInterval(async () => {
        const rutasNoConsideradas = ['/auth', '/login'];
        if (!rutasNoConsideradas.includes(this.router.url)) {
          const xToken = sessionStorage.getItem("xtoken");
          let tokenValidado: any = { response: false };
  
          try {
            tokenValidado = await this.xAuthService.validarXToken(xToken);
          } catch (err) {
            console.error("[SESION ERRÓNEA]")
          }
  
          if (!tokenValidado?.response) {
            this.alertaMultpleSesiones();
            clearInterval(this.mutipleTabDetector);
            console.log("[SESION INACTIVA]")
          } else {
            const { legacyToken, token } = tokenValidado.data;
            sessionStorage.setItem('token', legacyToken);
            sessionStorage.setItem('xtoken', token ?? xToken);
            console.log("[SESION ACTIVA]");
          }
        }
      }, frecuencia);
    }else{
      console.log("[VALIDADOR SESIONES INACTIVO]");
    }

 
  }

  async alertaMultpleSesiones() {
    this.multipleTabModalActivo = true;
    this.dialog.open(MultipleSesionComponent, {
      backdropClass: 'multi-tabs-bg',
      panelClass: 'multi-tabs',
      disableClose: true,
    });
  }

  setStateObserver(){
    this.sesionState = this.xAuthService.obSesionState().get().subscribe( item => {
      const [val] = item;
      if(val === 'LOGOUT'){
        console.log("[SESION INACTIVA] - DETECTOR CANCELADO");
        clearInterval(this.mutipleTabDetector);
      }
      if(val === 'LOGIN'){
        console.log("[SESION ACTIVA] - DETECTOR INICIADO");
        clearInterval(this.mutipleTabDetector);
        this.activarTabsDetector();
      }
    });

  }

  loginADObserver() {
    this.loginADObservable = this.loginService.loginAdEmitter().get().subscribe(res => {
      this.usuarioAD = this.loginService.obtenerUsuarioAD()?.usuario;
    });
  }

  cerrarAD() {
    localStorage.setItem('datosAd', null);
    this.loginService.signOutSession();
    this.usuarioAD = this.loginService.obtenerUsuarioAD()?.usuario;
    setTimeout(() => {
      location.reload();
    }, 1000);
  }

  isLogin(){
    return sessionStorage.getItem("token")?.length > 0;
  }
}