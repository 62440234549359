export enum LOG_CONFIG {
    DESTINO = 'MDB'
}

export enum LOG_CHATBOT {
    INICIO = 'CHATBOT-ASESOR-FLUJO-INICIAL-ABRIR-CHAT',
    GLOSA_INICIO = 'Acción iniciada cuando el asesor abre el chatbot.',
    CERRAR = 'CHATBOT-ASESOR-FLUJO-INICIAL-CERRAR-CHAT',
    CERRAR_GLOSA = 'Acción gatillada por el asesor al cerrar el chatbot.',
    FEEDBACK_MSG = 'CHATBOT-ASESOR-FLUJO-INICIAL-ENVIAR-FEEDBACK-MSG',
    FEEDBACK_MSG_GLOSA = 'Acción gatillada al presionar pulgar abajo y dar una retroalimentación por la respuesta del bot, que en estos casos son insatisfactorias.',
    FEEDBACK_COMPLETO = 'CHATBOT-ASESOR-FLUJO-INICIAL-ENVIAR-FEEDBACK-SAT',
    FEEDBACK_COMPLETO_GLOSA = 'Acción gatillada por el asesor al terminar la conversación y cerrar el chatbot, el asesor da su apreciación sobre la experiencia con el bot.',
    REINICIO_CHATBOT = 'CHATBOT-ASESOR-FLUJO-INICIAL-REINICIAR-CONV',
    REINICIO_CHATBOT_GLOSA = 'Acción gatillada cuando el asesor cierra una conversación con el bot e inicia una nueva.',
}