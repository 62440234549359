const contratoOperaciones = [
  "Autentificacion",
  "Consolidado",
  "Invertir/Fondos Mutuos",
  "Invertir/FFMM Paso 0",
  "Invertir/FFMM Paso 1",
  "Invertir/FFMM Paso 2",
  "Invertir/Carrito Compra",
  "Invertir/FFMM Confirmación",
  "Invertir/FFMM Respuesta Hub de pago",
  "Invertir/FFMM Tiempo de espera por pago agotado",
  "Invertir/FFMM Pago Exitoso",
  "Invertir/FFMM Pago Rechazado",
  "Cambio de Fondos/APV",
  "CDF/APV Paso 0",
  "CDF/APV Paso 1",
  "CDF/APV Paso 2",
  "CDF/APV Paso 3",
  "CDF/APV Carrito Compra",
  "CDF/APV Confirmación",
  "Cambio de Fondos/Fondos Mutuos",
  "CDF/FFMM Paso 0",
  "CDF/FFMM Paso 1",
  "CDF/FFMM Paso 2",
  "CDF/FFMM Paso 3",
  "CDF/FFMM carrito Compra",
  "CDF/FFMM Confirmación",
  "Rescate/Fondos Mutuos",
  "Rescate/FFMM Paso 0",
  "Rescate/FFMM Paso 1",
  "Rescate/Carrito Compra",
  "Rescate/FFMM Paso 2",
  "Rescate/FFMM Medio de Pago Paso 2",
  "Rescate/FFMM Paso 3",
  "Rescate/57Bis",
  "Rescate/57Bis Paso 1",
  "Rescate/57Bis Paso 2",
  "Rescate/57Bis Paso 3",
  "Seguros/Invertir Paso 0",
  "Seguros/Invertir Paso 1",
  "Seguros/Invertir Paso 2",
  "Invertir/Seguros",
  "Invertir/Seguros Paso 1",
  "Invertir/Seguros Paso 2",
  "Invertir/Seguros Paso 3",
  "Seguros/Poliza Pago Paso 1",
  "Seguros/Distribucion Pagos Futuros",
  "Seguros/Distribucion pagos futuros vida paso 0",
  "Seguros/Distribucion pagos futuros vida paso 1",
  "Seguros/Distribucion pagos futuros vida paso 2",
  "Seguros/Distribucion pagos futuros exitosa",
  "Seguros/Denuncia Siniestro",
  "Seguros/Actualizacion Beneficiarios",
  "Seguros/Cambio Fondos",
  "Seguros/Cambio Fondos Paso 1",
  "Seguros/Cambio Fondos Paso 2",
  "Seguros/Cambio Fondos Paso 3",
  "Seguros/Cambio Fondos Confirmacion",
  "Seguros/Actualizacion Antecedentes",
  "Actualizacion Antecedentes FFMM",
  "Cambio de Fondos/57Bis",
  "CDF/57Bis Paso 1",
  "CDF/57Bis Paso 2",
  "CDF/57Bis Paso 3",
  "Invertir/57Bis",
  "Invertir/57Bis Paso 1",
  "Invertir/57Bis Paso 2",
  "Invertir/57Bis Confirmación",
  "Cierre de Sesion",
  "Clave Transaccional",
  "Clave Transaccional Paso 0",
  "Clave Transaccional Paso 1",
  "Clave Transaccional Paso 2",
  "Mandato AFP",
  "Detalle Fondos Mutuos",
  "Detalle Fondos Mutuos 57Bis",
  "Detalle Fondos Mutuos APV",
  "Detalle Poliza",
  "Detalle Poliza/Pago Paso 1",
  "Detalle Cuenta Dos",
  "Detalle Cuenta Dos 57Bis",
  "Detalle Cotizacion Obligatoria AFP",
  "Detalle Acciones APV",
  "Detalle APV AFP",
  "Detalle Poliza Seguro",
  "Subsaldo APV",
  "Subsaldo Cuenta Dos",
  "Subsaldo Cuenta Dos 57Bis",
  "Solicitud de Clave",
  "Cambio de Clave Transitoria",
  "Detalle Renta Previsional",
  "Detalle Renta Privada",
  "Reactivacion Poliza",
  "Formulario Contacto Sitio Publico - Consulta",
  "Suscripcion PAC-PAT",
  "Formulario Contacto Sitio Publico - Venta",
  "Asesoria TN",
  "Suscripcion Clientes FFMM",
  "Seguro Oncologico",
  "Formulario Contacto Sitio Privado",
  "Suscripcion Online Paso 1",
  "Suscripcion Online Paso 2",
  "Suscripcion Online Paso 3",
  "Suscripcion Online Paso 4",
  "Suscripcion Online Paso 5",
  "Suscripcion Online Paso 6",
  "Webinar",
  "Caida Core",
  "Firma de Contratos CB",
  "Firma de Contratos CB Paso 1",
  "Firma de Contratos CB Paso 2",
  "Firma de Contratos CB Paso 3",
  "Invertir/APV",
  "Invertir/APV Paso 0",
  "Invertir/APV Paso 1",
  "Invertir/APV Paso 2",
  "Invertir/APV Carrito Compra",
  "Invertir/APV Confirmación",
  "Invertir/APV Respuesta Hub de pago",
  "Invertir/APV Tiempo de espera por pago agotado",
  "Invertir/APV Pago Exitoso",
  "Invertir/APV Pago Rechazado",
  "Suscripcion Mensual APV FFMM",
  "Suscripcion Mensual APV FFMM Paso 1",
  "Suscripcion Mensual APV FFMM Paso 2",
  "Suscripcion Mensual APV FFMM Paso 3",
  "Suscripcion Mensual APV FFMM Paso 4",
  "Suscripcion Mensual APV FFMM Paso 5",
  "Popup Administrable",
  "Pershing",
  "Modificacion Suscripcion Mensual FFMM",
  "Rescate/APV",
  "Rescate/APV Paso 0",
  "Rescate/APV Paso 1",
  "Rescate/APV Paso 2",
  "Rescate/APV Paso 3",
  "Rescate/APV Carrito Compra",
  "Rescate/APV Medio de Pago Paso 2",
  "Recomendacion Fondo Estrategia/Paso1",
  "Recomendacion Fondo Estrategia/Paso2",
  "Recomendacion Fondo Estrategia/Paso3",
  "Autentificacion Sura Ejecutivo",
  "Consulta Cliente Sura Ejecutivo",
  "Transacciones y Movimientos",
  "Pershing APV",
  "Modulo Firma Endosos/Paso 0",
  "Modulo Firma Endosos/Paso 1",
  "Modulo Firma Endosos/Paso 2",
  "Modulo Firma Endosos/Paso 3",
  "Modulo Firma Endosos/Paso 4",
  "Modulo Firma Endosos/Paso 5",
  "Generar Informe Inversión",
  "Modulo Firma Endosos",
  "Saldos Graficados",
  "Modificacion Suscripcion Mensual APV FFMM Paso 1",
  "Modificacion Suscripcion Mensual APV FFMM Paso 2",
  "Modificacion Suscripcion Mensual APV FFMM Paso 3",
  "Modificacion Suscripcion Mensual APV FFMM Paso 4",
  "Modificacion Suscripcion Mensual APV FFMM Paso 5",
  "Transacción en proceso",
  "Seguros/CDF On Line Paso 0",
  "Seguros/CDF On Line Paso 1",
  "Seguros/CDF On Line Paso 2",
  "Seguros/CDF On Line Paso 3",
  "Seguros/CDF On Line Paso 4",
  "Seguros/DFF On Line Paso 0",
  "Seguros/DFF On Line Paso 1",
  "Seguros/DFF On Line Paso 2",
  "Seguros/DFF On Line Paso 3",
  "Seguros/DFF On Line Paso 4",
  "Traspaso FFMM APV Paso 0",
  "Traspaso FFMM APV Paso 1",
  "Traspaso FFMM APV Paso 2",
  "Traspaso FFMM APV Paso 3",
  "Traspaso FFMM APV Carrito Compra",
  "Traspaso FFMM APV Paso 4",
  "Ingreso OCV Off Line Paso 0",
  "Ingreso OCV Off Line Paso 1",
  "Ingreso OCV Off Line Paso 2",
  "Ingreso OCV Off Line Paso 3",
  "Seguros/Rescate Flexible Paso 0 Actualización Correo",
  "Seguros/Rescate Flexible Paso 1 Selección Rescate",
  "Seguros/Rescate Flexible Paso 2 Parcial",
  "Seguros/Rescate Flexible Paso 2 Total",
  "Seguros/Rescate Flexible Paso 3 Comprobante",
  "Seguros/Rescate Ahorro NoAPV Paso 0 Actualización Correo",
  "Seguros/Rescate Ahorro NoAPV Paso 1 Selección Rescate",
  "Seguros/Rescate Ahorro NoAPV Paso 2 Tipo Rescate",
  "Seguros/Rescate Ahorro NoAPV Paso 3 Comprobante",
  "Ingreso Ord Pershing Off Line Paso 0",
  "Ingreso Ord Pershing Off Line Paso 1",
  "Ingreso Ord Pershing Off Line Paso 2",
  "Ingreso Ord Pershing Off Line Paso 3",
  "Sus. 100% On Line Paso 1 - Formulario de Identificacion",
  "Sus. 100% On Line Paso 2 - Verificacion Email",
  "Sus. 100% On Line Paso 3 - Verificacion Identidad",
  "Sus. 100% On Line Paso 4 - Comprob. y envio Clave Provisoria",
  "Aporte a Cuenta de Inversion",
  "Aporte a Cuenta de Inversion NO APV Paso 0",
  "Aporte a Cuenta de Inversion NO APV Paso 1",
  "Aporte a Cuenta de Inversion NO APV Paso 2",
  "Aporte a Cuenta de Inversion NO APV Respuesta Hub de pago",
  "Aporte a Cuenta de Inversion NO APV Tiempo de espera por pago agotado",
  "Aporte a Cuenta de Inversion NO APV Pago Exitoso",
  "Aporte a Cuenta de Inversion NO APV Pago Rechazado",
  "Aporte a Cuenta de Inversion APV Paso 0",
  "Aporte a Cuenta de Inversion APV Paso 1",
  "Aporte a Cuenta de Inversion APV Paso 2",
  "Aporte a Cuenta de Inversion APV Respuesta Hub de pago",
  "Aporte a Cuenta de Inversion APV Respuesta Hub de pago",
  "Aporte a Cuenta de Inversion APV Tiempo de espera por pago agotado",
  "Aporte a Cuenta de Inversion APV Pago Exitoso",
  "Aporte a Cuenta de Inversion APV Pago Rechazado",
  "Mis datos",
  "Mis datos/Actualizar",
  "Pershing/Acceso next investor",
  "Modal Acceso/Acepta acceso nueva web",
  "Modal Acceso/No acepta acceso nueva web",
  "Modal Acceso/Cierre modal",
  "Volver al sitio actual",
  "Perfil Inversionista/Huincha home",
  "Perfil Inversionista/Seccion perfil de inversionista",
  "Perfil Inversionista/Actualizar Perfil Paso 1",
  "Perfil Inversionista/Actualizar Perfil Paso 2",
  "Circularizacion Acepto",
  "Circularizacion Rechazo",
  "Recomendación Inversión Busqueda",
  "Recomendación Inversión Resultado",
  "Acciones/Acceso Optimus Cliente",
  "Cartolas/Descarga documento",
  "Cartolas - Acceso Cartolas",
  "Banner/Campanas",
  "Comportamiento de Fondos Paso 0",
  "Comportamiento de Fondos Paso 1",
  "Comportamiento de Fondos Paso 2",
  "Comportamiento de Fondos Error Comparador de Fondos",
  "Error Web",
  "Mis Certificados Paso 0",
  "Mis Certificados Paso 1",
  "Mis Certificados Paso 1 Error",
  "Home/Transacciones",
  "Home/Últimas transacciones",
  "Home/Invertir",
  "Valor Cuota",
  "Valor cuota paso 0",
  "Valor cuota paso 1",
  "Suscripciones paso 0",
  "SURA PASS paso 0",
  "SURA PASS paso 1",
  "SURA PASS paso 1 Error",
  "Suscripciones paso 1",
  "Invertir paso 1",
  "Ficha Cliente Paso 1",
  "Ficha Cliente Paso 2",
  "Ficha Cliente Paso 3",
  "Ficha Cliente Paso 4",
  "Ficha Cliente Paso 4 / Opciones de Cobro",
  "Ficha Cliente",
  "Cambiar otro fondo",
  "Informe de Inversiones",
  "Compra de acciones",
  "Modificación de flujo no apv paso 0",
  "Modificación de flujo no apv paso 1",
  "Modificación de flujo no apv paso 2",
  "Buscador Cliente",
  "Resumen Cliente",
  "Cartera Clientes",
  "Informe de Pershing",
  "Clave SURA",
  "Múltiple Tabs - Multisesión (asesores)",
  "Certificado de Saldos",
  "Saldos Consolidados Home/Descargar excel",
  "Click Acción Notificaciones Asesor",
  "Buscador Cliente Cumplimiento"
] as const;

export { contratoOperaciones };
