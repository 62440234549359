import { Injectable } from "@angular/core";
import { CMSService } from "../cms/cms.service";

@Injectable()
export class PopupService {  
  constructor(private cmsService: CMSService) {}

  getConfigPopup() {
    let idAlert: number = 0;
    return new Promise((res, reject) => {
      this.cmsService
        .getPopupAdministrableGenerico()
        .then((data: any) => {
          data.alertasMercado.forEach(element => {
            element.id = idAlert++;
          });
          res({
            alertasMercado: data.alertasMercado,
            popupAdministrables: data.popupAdministrables,
          });
        })
        .catch((error) => {
          console.error("error obteniendo configuracion popupAdministableGenerico", error);
          reject(error);
        });
    });
  }
}
