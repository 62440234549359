import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'CantidadPipeCustom' })
export class CantidadPipeCustom implements PipeTransform {
  transform(value: any): string {
    return formatearCantidad(value);
  }
}

export function formatearCantidad(value: any): string {
  if (value != null && !isNaN(value)) {
      return Intl.NumberFormat('es-CL', {maximumFractionDigits: 0}).format(value);
  }
  return '';
}
