import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainOrdenCambioFondoCompraComponent } from './components/main-orden-cambio-fondo-compra.component';
import { OrdenCambioFondoCompraComponent } from './orden-cambio-fondo-compra.component';





const routes: Routes = [
  {
    path: '',
    component: MainOrdenCambioFondoCompraComponent,
    children: [
      {
        path: '',
        component: OrdenCambioFondoCompraComponent,
      },
      { path: '**', redirectTo: '' },
    ],
  },
];




@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrdenCambioFondoCompraComponentRoutingModule {}
