import { Component } from '@angular/core';
import { IndicadorClienteService } from '../../../../../shared/services/indicadores/indicador-cliente.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-document-message',
  templateUrl: './document-message.component.html',
  styleUrls: ['./document-message.component.scss']
})
export class DocumentMessageComponent {

  modificacionFichaConfig: boolean = environment?.MODIFICACION_FICHA_CLIENTE;

  constructor(
    public indicadorClienteService: IndicadorClienteService
  ) {}
}
