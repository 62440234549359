import { Component, OnInit, Output, EventEmitter, ViewChild, Input, OnChanges, HostListener, ElementRef } from '@angular/core';
import HPie from 'highcharts/modules/variable-pie';
import * as Highcharts from 'highcharts';
import { FiltroTablaAssetComponent } from './filtro-tabla-asset/filtro-tabla-asset.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { AssetService } from 'src/app/shared/services/asset/asset.service';
import { ExcelService, ExecutiveService } from 'src/app/shared/services';
import { CurrencyPipeCustom } from '../../../../../src/app/shared/pipes';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { RequestSaveLogModel, ValueModel } from 'src/app/shared/models';
import { IndicadorClienteService } from 'src/app/shared/services/indicadores/indicador-cliente.service';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

HPie(Highcharts);

@Component({
  selector: 'app-home-asset-allocation',
  templateUrl: './home-asset-allocation.component.html',
  styleUrls: ['./home-asset-allocation.component.scss'],
  providers: [CurrencyPipeCustom]
})
export class HomeAssetAllocationComponent implements OnInit {

  parametro: any;
  @Output() homeHiddenAsset = new EventEmitter<boolean>();
  @Input() allocationData: any;
  Highcharts = Highcharts;
  clientePaf: any;
  pafRentaFija: any;
  pafRentaVariable: any;
  pafAlternativos: any;
  pafMoneyM: any;
  fechaHoy: any;
  chart: Highcharts.Chart;
  detalleInstrumento: any;
  chartOptions;
  graficoRentaFijaConsolidado;
  graficoRentaFijaNacional;
  graficoRentaFijaInternacional;

  graficoRentaVariableConsolidado;
  graficoRentaVariableNacional;
  graficoRentaVariableInternacional;

  graficoAlternativoConsolidado;
  graficoAlternativoNacional;
  graficoAlternativoInternacional;
  
  isMobile = false;
  currentPage = 1;
  pageSize = 15;
  pageSelected = 1;
  tabla: any;
  largoTabla: any;
  rutCliente: string;
  instrumentosFiltrados: any;
  perfilCliente:string;
  desalineadoRV:boolean;
  tipoDesalineadoRV:string;
  textoDesalineado:string;
  perfilDesac: string;
  datosTabla:any;
  activos:any;
  mostrarGrafico = false;

  mostrarGraficoFijoConsolidado = true;
  mostrarGraficoFijoNacional = false;
  mostrarGraficoFijoInternacional = false;

  mostrarGraficoVariableConsolidado = true;
  mostrarGraficoVariableNacional = false;
  mostrarGraficoVariableInternacional = false;

  mostrarGraficoAlternativoConsolidado = true;
  mostrarGraficoAlternativoNacional = true;
  mostrarGraficoAlternativoInternacional = false;

  colorFijoConsolidado ='';
  colorFijoNacional ='';
  colorFijoInternacional = '';

  colorVariableConsolidado ='';
  colorVariableNacional ='';
  colorVariableInternacional = '';

  colorAlternativoConsolidado ='';
  colorAlternativoNacional ='';
  colorAlternativoInternacional = '';

  @ViewChild('fijoNacionalConsolidado', { static: true }) fijoNacionalRefConsolidado!: ElementRef;
  @ViewChild('fijoNacional', { static: true }) fijoNacionalRef!: ElementRef;
  @ViewChild('fijoInternacional', { static: true }) fijoInternacionalRef!: ElementRef;

  @ViewChild('variableNacionalConsolidado', { static: true }) variableNacionalRefConsolidado!: ElementRef;
  @ViewChild('variableNacional', { static: true }) variableNacionalRef!: ElementRef;
  @ViewChild('variableInternacional', { static: true }) variableInternacionalRef!: ElementRef;

  @ViewChild('alternativoNacionalConsolidado', { static: true }) alternativoNacionalRefConsolidado!: ElementRef;
  @ViewChild('alternativoNacional', { static: true }) alternativoNacionalRef!: ElementRef;
  @ViewChild('alternativoInternacional', { static: true }) alternativoInternacionalRef!: ElementRef;
  
  constructor(public dialog: MatDialog,
    private assetService: AssetService,
    private excelService: ExcelService,
    private currencyPipeCustom: CurrencyPipeCustom,
    private executiveService: ExecutiveService,
    private tracesService: TracesService,
    public indicadorClienteService: IndicadorClienteService
  ) { }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.mostrarGrafico = false;
    this.isMobile = window.innerWidth < 768;
    this.chartOptions = this.setHightChart(this.activos);
    this.mostrarGrafico = true;    
  }

  ngOnInit(): void {
    
    Highcharts.setOptions({
      lang: {
          decimalPoint: ','
      }
    });

    this.assetService.parametroSubject.subscribe((value) => {
      this.parametro = value;
    });

    this.isMobile = window.innerWidth < 768;    
    this.clientePaf = this.parametro;
    this.fechaHoy = moment(new Date()).format("DD/MM/YYYY");
    const saldoPaf = this.clientePaf.responsePaf?.Cliente;
    const perfil = this.clientePaf?.perfilCliente;
    this.desalineadoRV = saldoPaf?.RentaVariable?.Desalineado;
    this.tipoDesalineadoRV = saldoPaf?.RentaVariable?.TipoDesalineado;
    const conCartaTexto = saldoPaf?.RentaVariable?.Carta ? 'Desalineado con carta RV' : 'Desalineado RV';
    const alineado = "Alineado RV"
    this.textoDesalineado = this.desalineadoRV ? `${conCartaTexto} ${this.tipoDesalineadoRV}` : alineado;
    this.perfilCliente = perfil.charAt(0).toUpperCase() + perfil.slice(1).toLowerCase();
    this.detalleInstrumento = this.clientePaf.responsePaf?.DetalleInstrumentos;
    this.instrumentosFiltrados = this.detalleInstrumento;
    this.activos = this.obtenerDetalleActivos(saldoPaf);
    this.activos = this.mapperActivos(this.activos);
    
    const rentaFija = saldoPaf?.RentaFija?.Detalle;
    const rentaVariable = saldoPaf?.RentaVariable?.Detalle;
    const activosAlternativos = saldoPaf?.Alternativos?.Detalle;

    const detalleRentaFijaConsolidado = rentaFija;
    const detalleRentaFijaNacional = rentaFija?.[0]?.Detalle ?? [rentaFija?.[0]];
    const detalleRentaFijaInternacional = rentaFija ? rentaFija[1]?.Detalle : [];
    const detalleRentaVariableNacional = rentaVariable?.[0]?.Detalle ?? [rentaVariable[0]];
    const detalleRentaVariableInternacional = rentaVariable ? rentaVariable[1]?.Detalle : [];
    const detalleAlternativosNacional = activosAlternativos ? activosAlternativos[0]?.Detalle : [];
    const detalleAlternativosInternacional = activosAlternativos ? activosAlternativos[1]?.Detalle : [];

    
    const rentaFijaNacionalConsolidado = this.obtenerConceptoPorcentaje(detalleRentaFijaConsolidado);
    this.graficoRentaFijaConsolidado = this.setHightChartBarra(rentaFijaNacionalConsolidado);
    const rentaFijaNacional = this.obtenerConceptoPorcentaje(detalleRentaFijaNacional);
    this.graficoRentaFijaNacional = this.setHightChartBarra(rentaFijaNacional);
    const rentaFijaInternacional = this.obtenerConceptoPorcentaje(detalleRentaFijaInternacional);
    this.graficoRentaFijaInternacional = this.setHightChartBarra(rentaFijaInternacional);
    const rentaVariableNacionalConsolidado = this.obtenerConceptoPorcentaje(rentaVariable);
    this.graficoRentaVariableConsolidado = this.setHightChartBarra(rentaVariableNacionalConsolidado);
    const rentaVariableNacional = this.obtenerConceptoPorcentaje(detalleRentaVariableNacional);
    this.graficoRentaVariableNacional = this.setHightChartBarra(rentaVariableNacional);
    const rentaVariableInternacional = this.obtenerConceptoPorcentaje(detalleRentaVariableInternacional);
    this.graficoRentaVariableInternacional = this.setHightChartBarra(rentaVariableInternacional);
    const rentaAlternativaNacionalConsolidado = this.obtenerConceptoPorcentaje(activosAlternativos);
    this.graficoAlternativoConsolidado = this.setHightChartBarra(rentaAlternativaNacionalConsolidado);
    const rentaAlternativaNacional = this.obtenerConceptoPorcentaje(detalleAlternativosNacional);
    this.graficoAlternativoNacional = this.setHightChartBarra(rentaAlternativaNacional);
    const rentaAlternativaInternacional = this.obtenerConceptoPorcentaje(detalleAlternativosInternacional);
    this.graficoAlternativoInternacional = this.setHightChartBarra(rentaAlternativaInternacional);

    this.activos = this.activos.filter(objeto => objeto.y !== 0); 
    const instrumentos = this.mapperInstrumentos(this.detalleInstrumento);
    this.instrumentosFiltrados = this.orderFondos(instrumentos);
    this.tabla = this.instrumentosFiltrados;
    this.datosTabla = this.instrumentosFiltrados;
    this.largoTabla = this.tabla.length;
    this.setVariables(saldoPaf); 
    this.chartOptions = this.setHightChart(this.activos);
    this.onResize();
    this.perfilDesac = this.indicadorClienteService.indicadoresCliente?.perfilInversionista;
    this.mostrarActivoFijoNacional('fijoNacionalConsolidado');
    this.mostrarRentaVariableNacional('variableNacionalConsolidado');
    this.mostrarActivoAlternativoNacional('alternativoNacionalConsolidado');

  }

  isSticky(column: string): boolean {
    return column === 'col1' ? true : false;
  }

  showAssetPage() {
    this.homeHiddenAsset.emit(false);
  }

  openModal() {
    const modal = this.dialog.open(FiltroTablaAssetComponent);
    modal.componentInstance.filtrarDatos.subscribe(data => {
      this.filtrarDatos(data);
      modal.close();
    })
  }

  mapperInstrumentos(instrumentos) {

    const detalleInstrumentosMap = [];

    for (const instrumento of instrumentos) {
      detalleInstrumentosMap.push({
        fondo: instrumento?.Fondo,
        serie: instrumento?.serie,
        regimen: instrumento?.Regimen,
        producto: instrumento?.Producto,
        cuenta: instrumento?.TipoCuenta,
        saldo: instrumento?.Saldo,
        rf: instrumento?.RentaFija,
        rv: instrumento?.RentaVariable,
        aa: instrumento?.Alternativos,
        mm: instrumento?.MoneyMarket,
        rf_pesos: instrumento?.PesosRentaFija,
        rv_pesos: instrumento?.PesosRentaVariable,
        aa_pesos: instrumento?.PesosAlternativos,
        mm_pesos: instrumento?.PesosMoneyMarket
      });
    }
    return detalleInstrumentosMap;
  }

  separarPalabra(cadena) {
    return cadena.replace(/([A-Z])/g, ' $1').trim();
  }

  mapperActivos(activos) {

    let conceptos = [];
    for (const key in activos) {
      if (Object.hasOwnProperty.call(activos, key)) {
        const element = activos[key];
          conceptos.push({
            name: this.separarPalabra(element?.Concepto),
            y: element?.Porcentaje,
            monto: this.formatearNumero(element?.Monto)
            
          });
      }
    }
    return conceptos;
  }

  setVariables(saldoPaf) {

    this.pafRentaFija = saldoPaf.RentaFija;
    this.pafRentaVariable = saldoPaf.RentaVariable;
    this.pafAlternativos = saldoPaf.Alternativos;
    this.pafMoneyM = saldoPaf.MoneyMarket;
  }

  exportAsXLSX(): void {

    let nombreCliente: string;
    const now = new Date();
    const data: any[] = [];

    const dataCliente = sessionStorage.getItem('clientData');
    if (dataCliente) {
      const dataClient = JSON.parse(dataCliente);
      this.rutCliente = dataClient.rut.split('.').join('');
      nombreCliente = dataClient.name;
    }
    const date = moment(now).format('DD/MM/YYYY HH:mm');
    const dateNameFile = moment(now).format('DDMMYYYY');

    data.push(
      ['Nombre Cliente:', nombreCliente],
      ['RUT', this.rutCliente],
      ['Fecha', date],
      [''],
      [''],
      ['INSTRUMENTO', 'SERIE', 'PRODUCTO', 'REGIMEN', 'CUENTA', 'SALDO', 'RENTA VARIABLE $', 'RENTA VARIABLE %', 'RENTA FIJA $', 'RENTA FIJA %', 'ACTIVOS ALTERNATIVOS $', 'ACTIVOS ALTERNATIVOS %', 'MONEY MARKET $', 'MONEY MARKET %'],
      );
    this.datosTabla.forEach((element) => {
      data.push([element?.fondo, element?.serie === '' ? '-' : element?.serie, element?.producto, element?.regimen, element?.cuenta === '' ? '-' : element?.cuenta, this.currencyPipeCustom.transform(element?.saldo).toString(),
      this.currencyPipeCustom.transform(element?.rv_pesos).toString(),
      this.currencyPipeCustom.transform(element?.rv).toString() + '%',
      this.currencyPipeCustom.transform(element?.rf_pesos).toString(),
      this.currencyPipeCustom.transform(element?.rf).toString() + '%',
      this.currencyPipeCustom.transform(element?.aa_pesos).toString(),
      this.currencyPipeCustom.transform(element?.aa).toString() + '%',
      this.currencyPipeCustom.transform(element?.mm_pesos).toString(),
      this.currencyPipeCustom.transform(element?.mm).toString() + '%'])
    });
    this.saveLogPaf('ok');
    this.excelService.exportApiExcel(data, `AssetAllocation_${this.rutCliente.replace(/[\.\-]/g, '')}_${dateNameFile}`);

  }

  async saveLogPaf(errMessage) {
    const dataContentful = this.executiveService.executiveModel;
    const logData = {} as RequestSaveLogModel;

    logData.indexLogStash = 'log_negocio';
    logData.field = 'weblog';
    logData.value = {} as ValueModel;
    logData.value.SesionCliente = this.executiveService.sessionId;
    logData.value.Operacion = 'Asset Allocation /Descargar excel';
    logData.value.Categoria = 'Asset Allocation';
    logData.value.Metodo = 'exportAsXLSX()';
    logData.value.EventoLog = "Botón Descargar Excel";
    logData.value.Secuencia = 0;
    logData.value.user = dataContentful.executiveRut;
    logData.value.Status = 'ok';
    logData.service = '';
    logData.value.User_red = "";
    logData.value.EntradaLog = {
      rut: this.rutCliente,
    };
    await this.tracesService.saveLog(logData);
  }

  filtrar(arreglo, fondo, producto, regimen) {

    let filtro = arreglo;
    if (fondo !== null) {
      filtro = filtro.filter(obj => obj.Fondo === fondo);
    }
    if (producto !== null) {
      filtro = filtro.filter(obj => obj.Producto === producto);
    }
    if (regimen !== null) {
      filtro = filtro.filter(obj => obj.Regimen === regimen);
    }
    this.pageSelected = 1;
    return filtro;
  
  }

  orderFondos(fondos) {

    const fondoPrincipal = "SURA";
    fondos.sort((a, b) => {
      const nombreA = a.fondo.toUpperCase();
      const nombreB = b.fondo.toUpperCase();
      if (nombreA.startsWith(fondoPrincipal) && nombreB.startsWith(fondoPrincipal)) {
        return nombreA.localeCompare(nombreB);
      }
      if (nombreA.startsWith(fondoPrincipal)) {
        return -1;
      }
      if (nombreB.startsWith(fondoPrincipal)) {
        return 1;
      }
      return nombreA.localeCompare(nombreB);
    });
    return fondos;

  }

  filtrarDatos(datosFiltrados: any) {
    
    this.tabla = [];
    const fondo = datosFiltrados.fondo !== 'TODOS' ? datosFiltrados.fondo : null;
    const producto = datosFiltrados.producto !== 'TODOS' ? datosFiltrados.producto : null;
    const regimen = datosFiltrados.regimen !== 'TODOS' ? datosFiltrados.regimen : null;
    const resultado = this.filtrar(this.detalleInstrumento, fondo, producto, regimen);
    const instrumentos = this.mapperInstrumentos(resultado);
    const instrumentosOrdenados = this.orderFondos(instrumentos);
    this.instrumentosFiltrados = instrumentosOrdenados;
    this.tabla = instrumentosOrdenados;
    this.largoTabla = this.tabla.length;

  }

  setPageSelected($event) {

    let paginaSeleccionada = $event.srcElement.innerText;
    const fondos = this.orderFondos(this.instrumentosFiltrados);
    const largoFondos = fondos.length;
    const largoPagina = this.pageSize;
    const paginas = Math.ceil(largoFondos / largoPagina);

    const PREV_PAGE = "navigate_before";
    const NEXT_PAGE = "navigate_next";

    paginaSeleccionada = paginaSeleccionada === PREV_PAGE ? this.pageSelected - 1 : 
                         paginaSeleccionada === NEXT_PAGE ? this.pageSelected + 1 : 
                         paginaSeleccionada;
    
    if (paginaSeleccionada > 0 && paginaSeleccionada <= paginas) {
        const datosParaPaginar = {
            fondos,
            paginaSeleccionada,
            largoPagina,
            paginas, 
            largoFondos
        }
        this.paginarDatos(datosParaPaginar);
    }

  }

  paginarDatos(datosParaPaginar) {
    
    const { fondos, paginaSeleccionada, largoPagina, paginas, largoFondos } = datosParaPaginar;
    const inicioFiltro = paginaSeleccionada === '1' ? 0 : largoPagina * (paginaSeleccionada - 1);
    const finalFiltro = inicioFiltro + largoPagina;
    const datosFiltro1 = fondos.slice(0, inicioFiltro);
    const datosFiltro2 = fondos.slice(inicioFiltro, finalFiltro);
    const datosFiltro3 = fondos.slice(finalFiltro, largoFondos);
    
    this.tabla = paginaSeleccionada.toString() === paginas.toString() ? datosFiltro2 : datosFiltro2.concat(datosFiltro1).concat(datosFiltro3);
    this.pageSelected = parseInt(paginaSeleccionada);

  }

  onFiltrarDatos(datosFiltrados: any) {
    this.filtrarDatos(datosFiltrados);
  }
  
  formatearNumero(numero) {
    return numero.toLocaleString('es-ES', { maximumFractionDigits: 0 });
  }
  
  setHightChart(activos){
   
   const desktop = !this.isMobile;

   return {
    legend: {
      enabled: true,
      navigation: {
        enabled: true,
      },
      verticalAlign: desktop ? 'middle': 'bottom' ,
      borderWidth: 0,
      labelFormatter: function () {
        var name = this.name;
        var legendText = name + '<br/>(' + parseFloat(this.percentage).toFixed(2).replace('.',',') + '%)';
        return legendText;
      },
      x: desktop ? 100 : -40,
      y: desktop ? -10 : 20,
      useHTML: true,
      itemWidth: 240,
      symbolWidth: 12,
      symbolHeight: 12,
      symbolRadius: 0,
      itemMarginTop: 2,
      itemMarginBottom: 2,
      itemStyle: {
        color: 'rgb(37,35,35)',
        cursor: 'pointer',
        fontSize: '1rem',   
        fontWeight: '600',
        fontFamily: 'SuraSans'
      }
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: desktop ? 240 : 400,      
      events: {
        redraw: function () {
          setTimeout(() => {
        
        }, 800);
       }
      }
    },
    colors: ['#B7CDC2', '#505D6F', '#FFE947', '#252523', '#776855', '#B2B2B2', '#B7C9D3', 'B799D3'],
    title: {
      text: '',
      align: 'left'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:,.2f}%</b><br> ${point.monto}'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    credits: {
      enabled: false
    },

    plotOptions: {
        pie: {
        size: desktop ? 160 : 180,      
        allowPointSelect: false,
        point: {
          events: {
              legendItemClick: function (e) {
                e.preventDefault();
              }
          }
        },
        center: desktop ? ["10%", "50%"] : ["40%","50%"],
        dataLabels: {
          enabled: false,
          shape: "square"
        },
        minSize: 80
      },
      marker: {
        symbol: 'square',
        radius: 4
      }
    },
    series: [{
      name: 'Porcentaje',
      states: {
          hover: {
              enabled: true
          },
          inactive: {
            opacity: 1,
          },      
      },
      enableMouseTracking: true,
      showInLegend: true,
      colorByPoint: true,
      data: activos
    }]
   }
  }

  setHightChartBarra(detalleActivos:any){
   
    const [categorias, series ] = detalleActivos;
    return {
      chart: {
          type: 'bar',
          width:380,
          marginRight: 18
      },
      xAxis: {
          categories: categorias,
          title: {
              text: ""
          },
          gridLineWidth: 0,
          lineWidth: 0, 
          labels: {
            align:'left',
            reserveSpace: true,
            style:{
              color: '#505D6F',
              cursor: 'pointer',
              fontSize: '12px',   
              fontWeight: '500',
              fontFamily: 'SuraSans'
            }
          }
      },
      yAxis: {
          min: 0,
          max:100,
          labels: {
              overflow: 'left',
              format: '{value} %',
          },
          title: {
            text: ""
          },
          gridLineWidth: 0.5,
          gridLineColor: '#E5E2E2'
      },
      tooltip: {
          formatter: function () {
          return '<span style="color: #53565A;font-family: SuraSans;font-size: 14px;font-weight: bold;letter-spacing: 0;line-height: 17px;">'+this.x+ '</span>'+
                  '<br><span style="color: #172127;font-family: SuraSans;font-size: 24px;font-weight: 600;letter-spacing: 0;line-height: 29px;">'+parseFloat(this.y).toFixed(2).replace('.',',')+'%</span></br>'+
                  '<span style="color: #172127;font-family: SuraSans;font-size: 16px;line-height: 19px;letter-spacing: 0;">$'+this.key+'</span>';
          },
          backgroundColor: '#FFFFFF', 
          borderWidth:1,
          borderColor:'#E5EAEE',
          borderRadius:4
      },
      plotOptions: {
          bar: {
              dataLabels: {
                  enabled: true
              },
              groupPadding: 0.1
          },
          series: {
            pointWidth: 30
          }
      },
      legend: {
         enabled: false
      },
      credits: {
          enabled: false
      },
      title: {
        text: '',
        align: 'left'
      },
      colors: ['#B7CDC2', '#505D6F', '#FFE947', '#252523', '#776855', '#B2B2B2', '#B7C9D3', 'B799D3'],
      series: [{
        dataLabels: [
           {
            format: '{point.y:,.2f} %',
            style: {
              color: '#505D6F',
              fontSize: '12px',   
              fontWeight: '600',
              fontFamily: 'SuraSans'
            }
         }],
         colorByPoint: true,
         data: series
      }]
    }
  }

  mostrarActivoFijoNacional(idSeleccionado:string) {
      if(idSeleccionado){
        this.colorFijoNacional = idSeleccionado === 'fijoNacional' ? 'activo' : 'inactivo';
        this.colorFijoInternacional = idSeleccionado === 'fijoInternacional' ? 'activo' : 'inactivo';
        this.colorFijoConsolidado = idSeleccionado === 'fijoNacionalConsolidado' ? 'activo' : 'inactivo';
        this.mostrarGraficoFijoNacional = idSeleccionado === 'fijoNacional' ? true : false;
        this.mostrarGraficoFijoInternacional = idSeleccionado === 'fijoInternacional' ? true : false;
        this.mostrarGraficoFijoConsolidado = idSeleccionado === 'fijoNacionalConsolidado' ? true : false;
      }      
  }

  mostrarRentaVariableNacional(idSeleccionado:string) {

    if(idSeleccionado){
      this.colorVariableNacional = idSeleccionado === 'variableNacional' ? 'activo' : 'inactivo';
      this.colorVariableInternacional = idSeleccionado === 'variableInternacional' ? 'activo' : 'inactivo';
      this.colorVariableConsolidado = idSeleccionado === 'variableNacionalConsolidado' ? 'activo' : 'inactivo';
      this.mostrarGraficoVariableNacional = idSeleccionado === 'variableNacional' ? true : false;
      this.mostrarGraficoVariableInternacional = idSeleccionado === 'variableInternacional' ? true : false;
      this.mostrarGraficoVariableConsolidado = idSeleccionado === 'variableNacionalConsolidado' ? true : false;
    }      

  }

  mostrarActivoAlternativoNacional(idSeleccionado:string) {
    
    if(idSeleccionado){
      this.colorAlternativoNacional = idSeleccionado === 'alternativoNacional' ? 'activo' : 'inactivo';
      this.colorAlternativoInternacional = idSeleccionado === 'alternativoInternacional' ? 'activo' : 'inactivo';
      this.colorAlternativoConsolidado = idSeleccionado === 'alternativoNacionalConsolidado' ? 'activo' : 'inactivo';
      this.mostrarGraficoAlternativoNacional = idSeleccionado === 'alternativoNacional' ? true : false;
      this.mostrarGraficoAlternativoInternacional = idSeleccionado === 'alternativoInternacional' ? true : false;
      this.mostrarGraficoAlternativoConsolidado = idSeleccionado === 'alternativoNacionalConsolidado' ? true : false;
    }    
  }

  obtenerDetalleActivos(productosPaf) {
    const segundoNivelPaf = [];

    for (const key in productosPaf) {
      if (Array.isArray(productosPaf[key].Detalle)) {
        segundoNivelPaf.push(...productosPaf[key].Detalle);
      }
    }
    return segundoNivelPaf;

  }

  obtenerConceptoPorcentaje(activoPaf) {
    const conceptos = [];
    const porcentajes = [];
    for (const activo of activoPaf) {
      conceptos.push(activo.NombreConcepto || activo.Concepto);
      porcentajes.push(
        {
          y: +activo.Porcentaje.toFixed(2),
          name: this.formatearNumero(activo.Monto)
        }
      )
    }
    return [conceptos, porcentajes];
  }

}
