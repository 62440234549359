import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CoreStatusService {
    public AfpCoreIsAvailable: boolean = true;
    public CorredoraCoreIsAvailable: boolean = true;
    public VidaCoreIsAvailable: boolean = true;
    public FfmmIsAvailable: boolean = true;
    public AccionesIsAvailable: boolean = true;
    private coreListErrors: any[] = [];

    public setCoreError(errors: any[]): void {
        this.coreListErrors = errors;
        this.setStatusCore();
    }
    
    public setStatusCoreTotals(){
        return this.setStatusCore();
    }

    private setStatusCore(){
        this.AfpCoreIsAvailable = this.validaType('AFP') && this.validaType('APV');
        this.CorredoraCoreIsAvailable = this.validaType('CORREDOR_BOLSA');
        this.VidaCoreIsAvailable = this.validaType('INSURANCES');
        this.FfmmIsAvailable = this.validaType('FFMM');
        this.AccionesIsAvailable = this.validaType('Acciones');
        return [this.AfpCoreIsAvailable, this.CorredoraCoreIsAvailable, this.VidaCoreIsAvailable, this.FfmmIsAvailable, this.AccionesIsAvailable];
    }

    private validaType(type: string): boolean {
        const tempL = this.coreListErrors.filter(err => { return err.type.toUpperCase() === type.toUpperCase() });
        return tempL.length === 0;
    }

    public IsFullyAvailableServer(list: any[]): boolean {
        return list
        .filter( obj => obj.description 
            && obj.description === 'No hemos podido cargar la información').length == 0;
    }
    
}
