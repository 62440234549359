import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'mmb-paginador',
  templateUrl: './mmb-paginador.component.html',
  styleUrls: ['./mmb-paginador.component.scss'],
})
export class MmbPaginadorComponent implements OnInit, OnDestroy, OnChanges {

  pagerSub: Subscription;

  @Input() dataLength: number;
  @Input() pageSize: number = 15;
  @Input() pageIndexSelected = 0;
  @Input() id: string;

  rangeDisplay: number = 7;

  @Output() emitPageIndexSelected: EventEmitter<number> = new EventEmitter(null);

  numbers: any[];

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.resetPagerOnRouteChange();
    if(window.innerWidth < 640){
      this.rangeDisplay = 3;
    } 
  }

  ngOnDestroy() {
    if (this.pagerSub) {
      this.pagerSub.unsubscribe();
    }
  }

  ngOnChanges(ch: SimpleChanges): void {
    if (ch.dataLength) {
      if (
        typeof ch.dataLength.currentValue === 'number' &&
        ch.dataLength.currentValue > this.pageSize
      ) {
        const rest = this.dataLength % this.pageSize;
        const nPages = ((this.dataLength - rest) / this.pageSize) + (rest > 0 ? 1 : 0);
        this.numbers = [].constructor(nPages).fill(0).map((o, i) => i);
      } else {
        this.numbers = null;
      }
    }
  }

  resetPagerOnRouteChange() {
    this.pagerSub = this.router.events
    .pipe(filter(ev => ev instanceof NavigationEnd))
    .subscribe((ev: NavigationEnd) => {
        this.pageIndexSelected = 0;
    });
  }

  count(dir) {

    if (this.numbers) {
      switch (dir) {
        case 'up':
          if (this.pageIndexSelected + 1 < this.numbers.length ) {
            this.pageIndexSelected++;
          }
          break;
        case 'down':
          if ( this.pageIndexSelected > 0 ) {
            this.pageIndexSelected--;
          }
          break;
        case 'first':
          this.pageIndexSelected = 0;
          break;
        case 'last':
          this.pageIndexSelected = this.numbers.length - 1;
          break;
        default:
          if (!isNaN(dir)) {
            this.pageIndexSelected = dir;
          }
          break;
      }

      this.emitPageIndexSelected.emit(this.pageIndexSelected);
    }



  }

  _isArray(arr) {
    return Array.isArray(arr);
  }


  displayNumberPages(iterationNumber, pageIndexSelected, maxRangeNumber): boolean {
    if(maxRangeNumber <= this.rangeDisplay){
      return true;
    } 
    iterationNumber = iterationNumber + 1;
    pageIndexSelected = pageIndexSelected + 1;
    const left = maxRangeNumber-pageIndexSelected;
    const isLeftSideDisplay = (iterationNumber < this.rangeDisplay + pageIndexSelected);
    const isRightSideDisplay = (iterationNumber >= pageIndexSelected);
    const displayNumber =  isLeftSideDisplay && isRightSideDisplay;
    if((left < this.rangeDisplay) && ( iterationNumber > (maxRangeNumber-this.rangeDisplay)) ){
      return true;
    } 
    return displayNumber;
  }
}
