import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'telefonoFormato',
})
export class TelefonoPipeFormato implements PipeTransform {
    transform(value: any, args?: any): any {
        let formato = "+"+ value;

        const countryCodeStr = formato.slice(0,3);
        const areaCodeStr = formato.slice(3,4);
        const midSectionStr = formato.slice(4,8);
        const lastSectionStr = formato.slice(8);

        return `${countryCodeStr} ${areaCodeStr} ${midSectionStr} ${lastSectionStr}`;
    }
}
