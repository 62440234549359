// File Saver
import { environment } from '@environment';
import * as FileSaver from 'file-saver';
import * as html2pdf from 'html2pdf.js';
import * as moment from "moment";
import { DireccionBase, DireccionUpdate } from 'src/app/features/models/client-summary/profile-summary.model';
import { ContentManager, ContentManagerInfo } from '../models/content-manager.model';
import {Observable, race, timer} from "rxjs";

export function getInitialsFromName(name: string): string {
    const nameSplit = name?.replace(/  +/g, ' ').split(' ') ?? [];
    const firstInitial = (nameSplit.shift() ?? '').charAt(0);
    const secondInitial = (nameSplit[nameSplit?.length - 2] ?? '').charAt(0);
    return (firstInitial + secondInitial).toUpperCase();
}

export function toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function toTitleCaseLanguageCase(texto) {
    const re = /(^|[^A-Za-zÁÉÍÓÚÜÑáéíóúüñ])(?:([a-záéíóúüñ])|([A-ZÁÉÍÓÚÜÑ]))|([A-ZÁÉÍÓÚÜÑ]+)/gu;
    return texto.replace(re, (m, caracterPrevio, minuscInicial, mayuscInicial, mayuscIntermedias) => {
        const locale = ['es', 'gl', 'ca', 'pt', 'en'];
        // Son letras mayúsculas en el medio de la palabra
        // => llevar a minúsculas.
        if (mayuscIntermedias) return mayuscIntermedias.toLocaleLowerCase(locale);
        // Es la letra inicial de la palabra
        // => dejar el caracter previo como está.
        // => si la primera letra es minúscula, capitalizar
        //    sino, dejar como está.
        return caracterPrevio + (minuscInicial ? minuscInicial.toLocaleUpperCase(locale) : mayuscInicial);
    });
}

export function downloadFile(data, fileName, type): void {
    var binaryString = window.atob(data);
    var binaryLen = binaryString?.length;
    var bytes = new Uint8Array(binaryLen);

    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }

    var blob = new Blob([bytes], { type: type });

    FileSaver.saveAs(blob, fileName);
}

export function convertToPDF(html, options, withPages: boolean = false) {
    if (!withPages) {
        html2pdf().from(html).set(options).save();
    } else {
        html2pdf().from(html).set(options).toPdf().get('pdf').then(function(pdf) {
            var totalPages = pdf.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);
                pdf.setFontSize(10);
                pdf.setTextColor(100);
                pdf.text('Página ' + i + ' de ' + totalPages, (pdf.internal.pageSize.getWidth() / 2.3), (pdf.internal.pageSize.getHeight() - 20));
            }
        }).save();
    }
}

export function isBase64(str): boolean {
    try {
        return btoa(atob(str)) == str;
    } catch (err) {
        return false;
    }
}

export function isCompany(rut: string): boolean {
    rut = rut.replace('.', '').replace('.', '');
    return Number(rut.split('-')[0]) >= 50000000 ? true : false;
}

export function formatParamInfoContentManager(info: ContentManagerInfo): ContentManager {
    const year = moment().format("YYYY");
    const month = moment().format("MM");
    const day = moment().format("DD");
    const hour = moment().format("hh.mm.ss");
    const clientData = sessionStorage.getItem('clientData');
    const clientDataParsed = JSON.parse(clientData);
    const clientName = clientDataParsed.name;
    const rut = clientDataParsed.rut.split('.').join('').split('-');

    return {
        Rut_Cliente: rut[0],
        Nombre_Cliente: clientName,
        Linea__Negocio: info.lineaNegocio,
        Codigo_Transaccion: info.codigoTransaccion ?? "",
        Descripcion_Transaccion: info.descripcionTransaccion,
        Folio_Transaccion: info.folioTransaccion ?? "",
        Codigo_Canal_Ingreso: info.codigoCanalIngreso ?? "",
        Descripcion_Canal_Ingreso: info.descripcionCanalIngreso ?? "",
        Fecha__Ingreso: `${year}-${month}-${day}`,
        Hora__Ingreso: hour,
        Ano__Ingreso: year,
        Mes__Ingreso: month,
        Dia__Ingreso: day,
        Codigo_Usuario_Responsable: info.codigoUsuarioResponsable ?? "",
        Rut_Usuario_Responsable: info.rutUsuarioResponsable ?? "",
        Nombre_Usuario_Responsable: info.nombreUsuarioResponsable ?? "",
        Numero_Producto: info.numeroProducto ?? "",
    };

}

export function transformObjectToString(params: object): string {
    let stringParam: string = "";
    for (let property in params) {
        stringParam = `${stringParam}&${property}=${params[property]}`;
    }
    return stringParam;
}

export function validateEmail(mail: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(mail).toLowerCase());
}

export function validatePhoneNumber(phoneNumber: string): boolean {
    try {
        let phone: any = phoneNumber.split(' ').join('');
        const regex = /^[0-9]*$/;
        const phoneOnlyNumbers = regex.test(phone);
        if (phoneOnlyNumbers && (phone.length === 9)) return true;
        return false;

    } catch (error) {
        return false;
    }
}

export function onlyNumbers(value: string) {
    const regex = /^[0-9]*$/;
    return regex.test(value);
}

export function validateVirtualBank(accountNumber: string, bankCode: number): boolean {
    const santander = environment.BANKS_CODES.SANTANDER;
    const bci = environment.BANKS_CODES.BCI;
    const init = accountNumber.substring(0, 4);
    let valid = true;

    switch (String(bankCode)) {
        case santander:
            if (init === '7100') valid = false;
            break;

        case bci:
            if (init === '7770') valid = false;
            break;
    }
    return valid;
}

export function validateAddress(data: string) {
    const regex = /^[a-zA-Z0-9 #,_.-]*$/;
    return regex.test(data);
}

export function getPropertyNamesContactability(isCompanyParam: boolean) {
    let emailsProperty = 'emails';
    let emailProperty = 'email';
    let direccionesProperty = 'direcciones';
    let direccionProperty = 'direccion';
    let telefonosProperty = 'telefonos';
    let telefonoProperty = 'telefono';

    if (isCompanyParam) {
        emailsProperty = 'emailsEmpresa';
        emailProperty = 'emailEmpresa';
        direccionesProperty = 'direccionesEmpresa';
        direccionProperty = 'direccionEmpresa';
        telefonosProperty = 'telefonosEmpresa';
        telefonoProperty = 'telefonoEmpresa';
    }

    return { emailsProperty, emailProperty, direccionProperty, direccionesProperty, telefonosProperty, telefonoProperty };
}

export function validateProfileSummaryStringField(propertyName: string, item: any) {
    return item[propertyName] && typeof item[propertyName] === 'object' ? '' : item[propertyName];
}

export function validateEmptyAddress(address: DireccionUpdate | DireccionBase) {
    if (Object.entries(address).length === 0) return { isEmpty: true, partialData: false };

    const calle = validateProfileSummaryStringField('calle', address);
    const numeroCalle = validateProfileSummaryStringField('numeroCalle', address);
    const numeroDepto = validateProfileSummaryStringField('numeroDepto', address);
    const codigoRegion = validateProfileSummaryStringField('codigoRegion', address);

    const existOtherData = (calle?.trim() !== '' || numeroCalle?.trim() !== '' || numeroDepto?.trim() !== '') ? true : false;
    const isEmpty = (codigoRegion === '0' && !existOtherData);
    let partialData = existOtherData || address.codigoRegion !== '0';
    if (calle?.trim() !== '' && numeroCalle?.trim() !== '' && numeroDepto?.trim() !== '' && codigoRegion !== '0') partialData = false;
    return { isEmpty, partialData };
}

export function getSeparatePhoneNumberComposition(number: string) {
    const length = number.length;
    let initCodArea = 0;
    let endCodArea = 1;
    let initNumber = 1;
    if (number.charAt(0) !== '9') {
        initCodArea = 0;
        endCodArea = 2;
        initNumber = 2;
    }
    const codigoPais: string = '56';
    const codigoArea: string = number.replace(/ /g, '').substr(initCodArea, endCodArea);
    const numero: string = number.replace(/ /g, '').substr(initNumber, length);
    return { codigoPais, codigoArea, numero };
}

export function  joinFiltersData(...filtersData) {
    let categorias = []
    categorias = categorias.concat(...filtersData)
    categorias = [
        ...new Map(categorias.map(obj => [`${obj.codigo}:${obj.descripcion}`, obj]))
        .values()
    ]
    return categorias.sort((a, b) => a.codigo.localeCompare(b.codigo));
}

export function getDescripcionSegmentoPorId(id: number): string {
    const segmentos = new Map<number, string>([
        [0, "NINGUNO"],
        [1, "ALTOPATRIMONIO"],
        [2, "ALTOVALOR"],
        [3, "RENTASMEDIAS"],
        [4, "RENTASMASIVAS"],
        [5, "PRIME"],
        [6, "SINSEC"],
        [7, "APTOP+"],
        [8, "APTOP"],
        [9, "EMERGENTE"]
    ]);
    return segmentos.get(id) ?? "NOESTABLECIDO";
}

export async function customLastValueFrom<T>(observable: Observable<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        let ultimoValor: T;
        observable.subscribe(
            valor => { ultimoValor = valor },
            error => reject(error),
        );

        timer(3000).toPromise().then(()=> {
            resolve(ultimoValor);
        })
    });
}
