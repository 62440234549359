import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ExecutiveService } from 'src/app/shared/services/executive/executive.service';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() isAsset = false;
  @Output() hiddenAsset = new EventEmitter<boolean>();
  isSupervisor: boolean = false;
  isBoss: boolean = false;

  constructor(executiveService : ExecutiveService, public router: Router) {
    this.isSupervisor = executiveService.executiveModel.executiveProfile.isSupervisor;
    this.isBoss = executiveService.executiveModel.executiveProfile.isBoss;
  }

  showAssetPage(){
    this.hiddenAsset.emit(true);
  }

  buscadorCliente(){
    this.router.navigate(["/main/client-search"]);
  }

}
