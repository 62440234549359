import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientRequestService } from './../../../../../services/client-request/client-request.service';
import { TracesService } from './../../../../../../../app/core/services/traces/traces.service';
import { ExecutiveService, LoadingService } from './../../../../../../shared/services';
import { ConfigIndexLogStash, PassLogModel, SaveLogModel } from '../../../../../../shared/models/request-save-log.model';
import { DataClientModel, PasswordStatus } from 'src/app/features/models';
import { Observable, Subject } from 'rxjs';
import { environment } from '@environment';
import { InputLog } from 'src/app/features/models/log/InputLog';
import * as moment from 'moment';
import { Status } from 'src/app/features/models/log/status';
import { OperacionesContratoLog } from 'src/app/features/models/log/models';

@Component({
    selector: 'app-sura-password-modal',
    templateUrl: './sura-password-modal.component.html',
    styleUrls: ['./sura-password-modal.component.scss'],
})
export class SuraPasswordModalComponent {
    optionsSuraPassword: OptionsSuraPassword;
    selectedEmail: string;
    errorSeleccionEmail: boolean = false;
    step: number = 1;
    flag: string;
    operacion: string;
    tipoClave: string;
    metodo: string;
    title: string;
    subtitle: string;
    titleModal: string;
    icon: string;
    isLoading: boolean = false;
    emailError: boolean = false;

    @Output() statusPassword = new EventEmitter<object>();

    // consulta estados passwords
    dataClient$: Observable<DataClientModel>;
    private unsubscribe$ = new Subject<void>();

    constructor(public dialogRef: MatDialogRef<SuraPasswordModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private clientRequestService: ClientRequestService, private tracesService: TracesService) {
        if (data) {
            this.optionsSuraPassword = data.optionsMessage;
            this.step = this.optionsSuraPassword.step;
            this.flag = this.optionsSuraPassword.flag;
            if (this.flag === 'CT') {
                this.titleModal = 'Obtener Clave Transaccional';
            }
            if (this.flag === 'CS') {
                this.titleModal = 'Obtener Clave SURA';
            }

            if (this.step === 2) {
                this.emailError = true;
                this.icon = 'fail';
            }
            this.selectedEmail = this.optionsSuraPassword.emailList[0].CodigoLineaNegocio;
            let ope: OperacionesContratoLog = `Clave SURA`;
            let cat: OperacionesContratoLog = `Clave SURA`;
            if (this.flag === 'CT') {
                cat = `Clave Transaccional`;
                ope = `Clave Transaccional`;
            }
            const logtemp: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'> = {
                Categoria: cat,
                Operacion: ope,
                Metodo: 'constructor()',
                eventoLog: `Pop up de selección de correo ${cat}`,
                status: Status.OK,
                Secuencia: 3,
                EntradaLog: {
                    listaEmails: this.optionsSuraPassword.emailList,
                },
            };
            this.logData(logtemp, 'log_negocio');
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }

    selectEmail(email) {
        this.selectedEmail = email.correo;
        this.errorSeleccionEmail = false;
    }

    nextStep() {
        switch (this.flag) {
            case 'CS':
                this.operacion = 'Generar Clave Cliente';
                this.tipoClave = 'Clave Sura';
                this.metodo = 'generatePassword';
                this.title = 'La clave fue enviada exitosamente';
                this.subtitle = 'Recuerda: La clave SURA es temporal y tiene una vigencia de 3 días seguidos para activarla.';
                this.generatePassword();
                break;
            case 'CT':
                this.operacion = 'Generar Clave Transaccional Cliente';
                this.tipoClave = 'Clave Transaccional';
                this.metodo = 'generatePasswordTransaccional';
                this.title = 'Clave Transaccional enviada con éxito';
                this.subtitle = 'Recuerda: La clave transaccional es temporal y tiene una vigencia de 3 días seguidos para activarla.';
                this.generatePasswordTransaccional();
                break;
            default:
                break;
        }
        let ope: OperacionesContratoLog = `Clave SURA`;
        let cat: OperacionesContratoLog = `Clave SURA`;
        if (this.flag === 'CT') {
            cat = `Clave Transaccional`;
            ope = `Clave Transaccional`;
        }
        const logtemp: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'> = {
            Categoria: cat,
            Operacion: ope,
            Metodo: 'constructor()',
            eventoLog: `Pop up de selección de correo ${cat}`,
            status: Status.OK,
            Secuencia: 3,
            EntradaLog: {
                listaEmails: this.optionsSuraPassword.emailList,
            },
        };
        this.logData(logtemp, 'log_negocio');
    }

    async generatePassword() {
        this.isLoading = true;
        let data = sessionStorage.getItem('clientData');

        let ope: OperacionesContratoLog = `Clave SURA`;
        let cat: OperacionesContratoLog = `Clave SURA`;
        const logtemp: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'> = {
            Categoria: cat,
            Operacion: ope,
            Metodo: 'generatePassword()',
            eventoLog: `Click en enviar ${cat}`,
            status: Status.OK,
            Secuencia: 4,
            EntradaLog: {
                correoSeleccionado: this.getCorreoSelected(),
            },
        };
        if (data) {
            const dataClient = JSON.parse(data);
            const rut: string = dataClient.rut.split('.').join('');
            try {
                let response;
                if (Number(rut.split('-')[0]) > 50000000 && environment.name !== 'PROD') {
                    response = await this.clientRequestService.postSendPasswordJuridico({ lineaNegocio: this.selectedEmail, rut, rutRepresentante: this.data.rutRepresentante });
                } else {
                    response = await this.clientRequestService.postSendPassword({ businessLine: this.selectedEmail, rut });
                }
                this.step = 2;
                if (response && response.CodigoEstado && response.CodigoEstado === '00') {
                    this.getStatusPasswords(rut, ' SECRETA');
                    this.icon = 'success';
                    this.logData(logtemp, 'log_negocio');
                    logtemp.eventoLog = 'Pop up exitoso Clave SURA'
                    logtemp.Secuencia = 5;
                    this.logData(logtemp, 'log_negocio');
                } else this.icon = 'fail';
            } catch (error) {
                logtemp.status = Status.NOK;
                logtemp.EntradaLog = { ...logtemp.EntradaLog, error };
                this.logData(logtemp, 'log_error');
                this.icon = 'fail';
            }
        } else this.icon = 'fail';

        this.isLoading = false;
    }

    async generatePasswordTransaccional() {
        this.isLoading = true;
        const logtemp: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'> = {
            Categoria: `Clave Transaccional`,
            Operacion: `Clave Transaccional`,
            Metodo: 'generatePasswordTransaccional()',
            eventoLog: `Click en enviar Clave Transaccional`,
            status: Status.OK,
            Secuencia: 4,
            EntradaLog: {
                correoSeleccionado: this.getCorreoSelected(),
            },
        };
        let data = sessionStorage.getItem('clientData');
        if (data) {
            const dataClient = JSON.parse(data);
            const rut = dataClient.rut.split('.').join('');
            const metodoClaveTransaccional = 'SolicitarClave';
            const nuevaClaveTransacional = '';
            const claveTransaccional = '';
            let response = undefined;
            try {
                if (Number(rut.split('-')[0]) > 50000000 && environment.name !== 'PROD') {
                    const body = { lineaNegocio: this.selectedEmail, rut, rutRepresentante: this.data.rutRepresentante };
                    response = await this.clientRequestService.postSendPasswordTransaccionalJuridico(body);
                } else {
                    response = await this.clientRequestService.postSendPasswordTransaccional({ claveTransaccional, metodoClaveTransaccional, lineaNegocio: this.selectedEmail, nuevaClaveTransacional, rut });
                }
                this.step = 2;
                const CodigoEstado = response['SolicitarClaveResult']['Estado']['_text'];
                // deepscan-disable-line INSUFFICIENT_NULL_CHECK
                if (response && CodigoEstado && CodigoEstado === '00') {
                    // deepscan-disable-line
                    this.getStatusPasswords(rut, ' TRANSACCIONAL');
                    this.icon = 'success';
                    this.logData(logtemp, 'log_negocio');
                    logtemp.Secuencia = 5;
                    logtemp.eventoLog = 'Pop up exitoso Clave Transaccional';
                    this.logData(logtemp, 'log_negocio');
                } else this.icon = 'fail';
            } catch (error) {
                logtemp.status = Status.NOK;
                logtemp.EntradaLog = { ...logtemp.EntradaLog, error };
                this.logData(logtemp, 'log_error');
                this.icon = 'fail';
            }
        } else this.icon = 'fail';

        this.isLoading = false;
    }

    async getStatusPasswords(rut: string, glosaTipoClave: string) {
        const listStatusPassword = await this.clientRequestService.getStatusPassword({ rut });

        let itemModify: any[] = [];
        listStatusPassword.forEach((movement: PasswordStatus) => {
            if (movement.glosaTipoClave === 'CLAVE SECRETA') {
                movement.glosaTipoClave = ' SECRETA';
            }
            if (movement.glosaTipoClave === 'CLAVE TRANSACCIONAL') {
                movement.glosaTipoClave = ' TRANSACCIONAL';
            }

            if (glosaTipoClave === movement.glosaTipoClave) {
                if (movement.codigoEstadoClave == 1) {
                    movement.colorStausIcon = 'orange'; // provisoria
                    movement.typeStatusIcon = 'watch_later';
                }
                if (movement.codigoEstadoClave == 2) {
                    movement.colorStausIcon = 'red'; // bloqueada
                    movement.typeStatusIcon = 'priority_high';
                }
                if (movement.codigoEstadoClave == 3) {
                    movement.colorStausIcon = 'green'; // vigente
                    movement.typeStatusIcon = 'done';
                }
                itemModify.push(movement);
            }
        });
        this.clientRequestService.claveSecreta.emit(itemModify);

        let data = sessionStorage.getItem('clientData');
        const dataClient = JSON.parse(data);
        dataClient.statusPaswword.filter((password) => {
            if (password.glosaTipoClave === glosaTipoClave) {
                password.codigoEstadoClave = itemModify[0].codigoEstadoClave;
                password.glosaEstadoClave = itemModify[0].glosaEstadoClave;
                password.colorStausIcon = itemModify[0].colorStausIcon;
                password.typeStatusIcon = itemModify[0].typeStatusIcon;
            }
        });
        sessionStorage.setItem('clientData', JSON.stringify(dataClient));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
    }

    getCorreoSelected() {
        return this.optionsSuraPassword.emailList.find((item) => {
            return item.CodigoLineaNegocio === this.selectedEmail;
        }).CorreoElectronico;
    }

    async logData(log: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...EntradaLog,
            };
            inputLog.Salida = '-';
            inputLog.Tipo_sesion = 'ASESOR';
            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, 'NWSA_ASESOR', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }
}

export type OptionsSuraPassword = {
    emailList?: any[];
    step?: number;
    flag?: string;
};
