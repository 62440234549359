import { ChangeDetectorRef, Component, Input, Optional, Renderer2, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'mmb-radio-button',
  templateUrl: './mmb-radio-button.component.html',
  styleUrls: ['./mmb-radio-button.component.scss']
})

export class MmbRadioButtonComponent implements ControlValueAccessor {

  @Input() label: string;
  @Input() value: string;
  @Input() itemvalue: string;
  @Input() disabled: boolean;
  @Input() name: string;
  @Input() checked: boolean;
  convalor: boolean;
  
  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  
  writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
    this.cd.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange = (valor: string) => {
    this.verifica();
  };

  onTouched = () => {
    this.verifica();
  };

  verifica() {
    if (this.value) {
      this.convalor = true;
    }
  }


  getValidationCss() {
    if (!this.ngControl) return {};

    return {
       /*
        //'ng-invalid': this.ngControl.invalid,
        //'is-invalid': this.ngControl.invalid && this.ngControl.touched,
        //'ng-valid': this.ngControl.valid,
        //'ng-pristine': this.ngControl.pristine,
        */
        'ng-touched': this.ngControl.touched,
        'ng-untouched': this.ngControl.untouched,
        'ng-dirty': this.ngControl.dirty,
    };
}


}
