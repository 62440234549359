import { NgModule } from '@angular/core';
import { RutDirective } from './directives/rut/rut.directive';
import {CommonModule, DecimalPipe} from '@angular/common';
import { sharedComponents } from './components';
import { PortalModule } from '@angular/cdk/portal';
import { CurrencyPipeCustom } from './pipes/currency.pipe';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './modules/material.module';
import { MonthNamePipe } from './pipes/month-name.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { SuraCommonsUiModule } from '@surainvestments/sura-common-ui';
import { LayoutModule } from '@angular/cdk/layout';
import { RutPipe } from './pipes/rut.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { HighlightTextPipe } from './pipes/highlight-text.pipe';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { DialogMessageComponent } from './components/dialog-message/dialog-message.component';
import { PollComponent } from './components/poll/poll.component';
import { SafePipe } from './pipes/safe.pipe';
import { PollButtonComponent } from "./components/poll-button/poll-button.component";
import { TimeoutMessageComponent } from './components/timeout-message/timeout-message.component';
import { RutNameDirective } from './directives/rutName/rut-name.directive';
import { NoSpacesDirective } from './directives/noSpaces/noSpaces.directive';

//mmb implementaciones
import { UsdPipeCustom } from './pipes/usd.pipe';
import { ClpPipeCustom } from './pipes/clp.pipe';
import { CantidadPipeCustom } from './pipes/cantidad.pipe';
import { FechahoraPipeCustom } from './pipes/fechahora.pipe';
import { MmbTituloPaginaComponent } from './components/mmb-titulo-pagina/mmb-titulo-pagina.component';
import { MmbDropdownComponent } from './components/mmb-dropdown/mmb-dropdown.component';
import { MmbRadioButtonComponent } from './components/mmb-radio-button/mmb-radio-button.component';
import { MmbInputMontoComponent } from './components/mmb-input-monto/mmb-input-monto.component';
import { MmbInputTextComponent } from './components/mmb-input-text/mmb-input-text.component';
import { MmbCheckboxComponent } from './components/mmb-checkbox/mmb-checkbox.component';
import { MmbButtonComponent } from './components/mmb-button/mmb-button.component';
import { NumerosDirective } from './directives/numeros/numeros.directive';
import { FechahoraPipeFormato } from './pipes/fechahoraformato.pipe';
import { TelefonoPipeFormato } from './pipes/telefonoformato.pipe';
import { MmbDropdownAutocompleteComponent } from './components/mmb-dropdown-autocomplete/mmb-dropdown-autocomplete.component';
import { VisualizadorClientesComponent } from '../features/components/visualizador-clientes/visualizador-clientes.component';
import { ModalFiltroResponsivoComponent } from './components/modal-filtro-responsivo/modal-filtro-responsivo.component';
import { ModalFiltroInstrumentosComponent } from '../features/components/asesor/pershing/orden-compra/components/modal-filtro-instrumentos/modal-filtro-instrumentos.component';
import { VerPassInputDirective } from './directives/verPassInput/ver-pass-input.directive';
import { MaskCurrencyDirective } from './directives/mask-number/mask-currency.directive';
import {ChatBotAsesorModule} from "./components/chat-bot-asesor/chat-bot-asesor.module";
import {FormatoMontoConDecimalesPipe} from "./pipes/formatoConDecimales.pipe";
import {PercentageOrBlankPipe} from "./pipes/porcentajeOBlanco.pipe";

@NgModule({
  declarations: [
    RutDirective,
    CurrencyPipeCustom,
    MonthNamePipe,
    sharedComponents,
    RutPipe,
    RadioButtonComponent,
    HighlightTextPipe,
    DynamicPipe,
    DialogMessageComponent,
    PollComponent,
    SafePipe,
    PollButtonComponent,
    TimeoutMessageComponent,
    RutNameDirective,
    VerPassInputDirective,
    FechahoraPipeCustom,
    FechahoraPipeFormato,
    TelefonoPipeFormato,
    UsdPipeCustom,
    ClpPipeCustom,
    CantidadPipeCustom,
    MmbTituloPaginaComponent,
    MmbDropdownComponent,
    MmbDropdownAutocompleteComponent,
    MmbRadioButtonComponent,
    MmbInputMontoComponent,
    MmbInputTextComponent,
    MmbCheckboxComponent,
    MmbButtonComponent,
    NumerosDirective,
    NoSpacesDirective,
    VisualizadorClientesComponent,
    ModalFiltroResponsivoComponent,
    ModalFiltroInstrumentosComponent,
    MaskCurrencyDirective,
    FormatoMontoConDecimalesPipe,
    PercentageOrBlankPipe,
    MaskCurrencyDirective
  ],
  imports: [
    CommonModule,
    PortalModule,
    RouterModule,
    MaterialModule,
    OverlayModule,
    LayoutModule,
    CdkTableModule,
    SuraCommonsUiModule,
    ReactiveFormsModule,
    ChatBotAsesorModule
  ],
  exports: [
    RutDirective,
    CurrencyPipeCustom,
    MaterialModule,
    MonthNamePipe,
    sharedComponents,
    SuraCommonsUiModule,
    RutPipe,
    RadioButtonComponent,
    DynamicPipe,
    PollButtonComponent,
    RutNameDirective,
    VerPassInputDirective,
    FechahoraPipeCustom,
    FechahoraPipeFormato,
    TelefonoPipeFormato,
    UsdPipeCustom,
    ClpPipeCustom,
    CantidadPipeCustom,
    NumerosDirective,
    NoSpacesDirective,
    VisualizadorClientesComponent,
    ModalFiltroResponsivoComponent,
    MaskCurrencyDirective,
    FormatoMontoConDecimalesPipe,
    PercentageOrBlankPipe,
    ChatBotAsesorModule
  ],
  entryComponents:[VisualizadorClientesComponent, TimeoutMessageComponent, ModalFiltroInstrumentosComponent ],
  providers:[DecimalPipe]
})
export class SharedModule {}
