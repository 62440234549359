import { Injectable } from '@angular/core';
import { GetLastTransactionModel } from '../../models/client-search/client-search.model';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  BaseMovement,
  Destino,
  MovementDetail,
  MovementFlujo,
  MovementInversion,
  MovementRescate,
  MovementTransaction,
  MovementTraspaso,
  Origen,
  TransactionsCategories,
  TransactionsTypes,
  TransactionTableData,
} from '../../models/client-summary/transactions.model';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { ClientRequestService } from '../client-request/client-request.service';
import { numberToCurrency } from '../../../shared/pipes/currency.pipe';
import { formatPercent } from '@angular/common';
import { ColumnDefinition } from '@surainvestments/sura-common-ui/app/sura-ui-kit/models/table.model';

@Injectable({
  providedIn: 'root',
})
export class ClientTransactionService {
  showLatestTransactions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private clientRequest: ClientRequestService) {}

  getLastTransaction(transactionBody: GetLastTransactionModel): Observable<TransactionTableData[]> {
    const promise = this.clientRequest.getLastTransaction(transactionBody);
    return fromPromise(promise).pipe(
      map((data) => {
        const { apvTransactions, noApvTransactions57Bis, noApvTransactions, flujoTransactions } = data;
        apvTransactions.forEach(apv => {
          apv.typeRegimen = 'APV';
        });
        noApvTransactions57Bis.forEach(bis => {
          bis.typeRegimen = '57BIS';
        });
        noApvTransactions.forEach(rtn => {
          rtn.typeRegimen = 'RTN';
        });
        flujoTransactions.APV.forEach(apv => {
          apv.typeRegimen = 'APV';
        });

        const transactions: TransactionsCategories[] = [
          ...apvTransactions.map(this.mapTransaction('APV fondos mutuos')),
          ...noApvTransactions.map(this.mapTransaction('fondos mutuos')),
          ...noApvTransactions57Bis.map(this.mapTransaction('')),
          ...flujoTransactions.APV.map((item) => ({ ...item, displayName: item.tipoTransaccion })),
        ];
        return transactions
          .map((item) => {
            const { movements, ...rest } = item;
            return {
              ...rest,
              movementDetails: this.getMovementsDetails(item),
            } as TransactionTableData;
          })
          .sort((a, b) => {
            const dateA = moment(a.fechaCierre, 'DD/MM/YYYY').toDate();
            const dateB = moment(b.fechaCierre, 'DD/MM/YYYY').toDate();

            return dateB.getTime() - dateA.getTime();
          });
      }),
    );
  }

  private mapTransaction(text: string) {
    return (item: TransactionsCategories) => {
      if (item.tipoTransaccion === 'Cambio de Fondo') {
        return {
          ...item,
          displayName: `${item.tipoTransaccion}`,
          movementsOrigen: item.movements.map((itemOrigen) => itemOrigen.origen),
          movementsDestino: item.movements.map((itemDestino) => itemDestino.destino),
        };
      }
      return { ...item, displayName: `${item.tipoTransaccion} ${text}` };
    };
  }

  private getMovementsDetails(data: TransactionsCategories) {
    let movementDetails: MovementDetail[] = [
      {
        movementDefinition: this.getMovementDefinitionByType(data.tipoTransaccion),
        movements: data.movements,
      },
    ];

    if (data.tipoTransaccion === 'Cambio de Fondo') {
      movementDetails = [
        {
          movementDefinition: this.origenMovementsColumns,
          movements: data.movementsOrigen,
        },
        {
          movementDefinition: this.destinoMovementsColumns,
          movements: data.movementsDestino,
        },
      ];
    }
    return movementDetails;
  }

  private getMovementDefinitionByType(type: TransactionsTypes): ColumnDefinition<MovementTransaction>[] {
    switch (type) {
      case 'Inversion':
        return this.inversionMovementsColumns;
      case 'Rescate':
        return this.rescateMovementsColumns;
      case 'Suscripcion De Flujo':
        return this.flujoMovementsColumns;
      case 'Traspaso ingreso':
      case 'Traspaso egreso':
        return this.traspasoColumns;
      default:
        return [];
    }
  }

  readonly transactionColumns: ColumnDefinition<TransactionsCategories>[] = [
    { key: 'typeRegimen', type: 'text', columnTitle: 'Régimen', hideSm: true},
    { key: 'displayName', type: 'text', columnTitle: 'Tipo Transacción', sizeColumn: 'large' },
    {
      key: 'monto',
      type: 'text',
      columnTitle: 'Monto',
      align: 'right',
      sizeColumn: 'x-small',
      transformFunc: (value) => numberToCurrency(value.monto),
    },
    { key: 'numeroSolicitud', type: 'text', columnTitle: 'N° Solicitud', align: 'center', hideSm: true },
    {
      key: 'fechaIngreso',
      type: 'text',
      columnTitle: 'Fecha inicio',
      align: 'center',
      hideSm: true,
      sizeColumn: 'x-small',
      transformFunc: (client) => this.validateDate(client.fechaIngreso),
    },
    {
      key: 'fechaCierre',
      type: 'text',
      columnTitle: 'Fecha término',
      align: 'center',
      hideSm: true,
      sizeColumn: 'x-small',
      transformFunc: (client) => this.validateDate(client.fechaCierre),
    },
    {
      key: 'estado',
      type: 'state',
      columnTitle: 'Estado',
      align: 'center',
      sizeColumn: 'x-small',
      determineStateFunc: (data) => this.determineStateFunc(data.estado),
    },
  ];

  readonly baseMovementColumn: ColumnDefinition<BaseMovement>[] = [
    { key: 'fondoName', type: 'text', columnTitle: 'Fondo', sizeColumn: 'large' },
    { key: 'serie', type: 'text', columnTitle: 'Serie', align: 'center' },
    { key: 'regimen', type: 'text', columnTitle: 'Regimen', align: 'center' },
  ];

  readonly inversionMovementsColumns: ColumnDefinition<MovementInversion>[] = [
    ...this.baseMovementColumn,
    {
      key: 'monto',
      type: 'text',
      columnTitle: 'Monto',
      align: 'right',
      transformFunc: (data) => numberToCurrency(data.monto),
    },
    { key: 'fecha', type: 'text', columnTitle: 'Fecha Término', align: 'center' },
    {
      key: 'estado',
      columnTitle: 'Estado',
      align: 'center',
      type: 'state',
      determineStateFunc: (data) => this.determineStateFunc(data.estado),
    },
  ];

  readonly rescateMovementsColumns: ColumnDefinition<MovementRescate>[] = [
    ...this.baseMovementColumn,
    {
      key: 'rescateFraction',
      type: 'text',
      columnTitle: '% Rescate',
      align: 'center',
      transformFunc: (data) => formatPercent(data.rescateFraction / 100, 'es-cl', '1.2-2'),
    },
    {
      key: 'monto',
      type: 'text',
      columnTitle: 'Monto',
      align: 'right',
      transformFunc: (data) => numberToCurrency(data.monto),
    },
    {
      key: 'comision',
      type: 'text',
      columnTitle: 'Comisión',
      align: 'right',
      transformFunc: (data) => numberToCurrency(data.comision),
    },
    { key: 'fechaTermino', type: 'text', columnTitle: 'Fecha Término', align: 'center' },
    {
      key: 'estado',
      columnTitle: 'Estado',
      align: 'center',
      type: 'state',
      determineStateFunc: (data) => this.determineStateFunc(data.estado),
    },
  ];

  readonly flujoMovementsColumns: ColumnDefinition<MovementFlujo>[] = [
    ...this.baseMovementColumn,
    {
      key: 'distributionFraction',
      type: 'text',
      columnTitle: '% Distribución',
      align: 'center',
      transformFunc: (data) => formatPercent(data.distributionFraction / 100, 'es-cl', '1.2-2'),
    },
    {
      key: 'monto',
      type: 'text',
      columnTitle: 'Monto',
      align: 'right',
      transformFunc: (data) => numberToCurrency(data.monto),
    },
    { key: 'fechaTermino', type: 'text', columnTitle: 'Fecha Ingreso', align: 'center' },
    {
      key: 'estado',
      type: 'state',
      columnTitle: 'Estado',
      align: 'center',
      determineStateFunc: (data) => this.determineStateFunc(data.estado),
    },
  ];

  readonly origenMovementsColumns: ColumnDefinition<Origen>[] = [
    { key: 'fondoName', type: 'text', columnTitle: 'Fondo de Origen', sizeColumn: 'large' },
    { key: 'serie', type: 'text', columnTitle: 'Serie', align: 'center' },
    { key: 'regimen', type: 'text', columnTitle: 'Regimen', align: 'center' },
    {
      key: 'comision',
      type: 'text',
      columnTitle: 'Comisión',
      align: 'right',
      transformFunc: (data) => numberToCurrency(data.comision),
    },
    { key: 'noCuotas', type: 'text', columnTitle: 'N° Cuotas', align: 'center' },
    {
      key: 'traspasoFraction',
      type: 'text',
      columnTitle: '% Traspaso',
      align: 'center',
      transformFunc: (data) => formatPercent(data.traspasoFraction, 'es-cl', '1.0-0'),
    },
  ];

  readonly destinoMovementsColumns: ColumnDefinition<Destino>[] = [
    { key: 'fondoName', type: 'text', columnTitle: 'Fondo de Destino', sizeColumn: 'large' },
    { key: 'serie', type: 'text', columnTitle: 'Serie', align: 'center' },
    { key: 'regimen', type: 'text', columnTitle: 'Regimen', align: 'center' },
    {
      key: 'monto',
      type: 'text',
      columnTitle: 'Monto',
      align: 'right',
      transformFunc: (data) => numberToCurrency(data.monto),
    },
    { key: 'fechaCierre', type: 'text', columnTitle: 'Fecha Término', align: 'center' },
    {
      key: 'estado',
      columnTitle: 'Estado',
      align: 'center',
      type: 'state',
      determineStateFunc: (data) => this.determineStateFunc(data.estado),
    },
  ];

  readonly traspasoColumns: ColumnDefinition<MovementTraspaso>[] = [
    { key: 'fondoName', type: 'text', columnTitle: 'Fondo', sizeColumn: 'large' },
    { key: 'serie', type: 'text', columnTitle: 'Serie', align: 'center' },
    {
      key: 'porcentajeFraction',
      type: 'text',
      columnTitle: 'Porcentaje',
      align: 'center',
      transformFunc: (data) => formatPercent(data.porcentajeFraction / 100, 'es-cl', '1.2-2'),
    },
    {
      key: 'estado',
      columnTitle: 'Estado',
      align: 'center',
      type: 'state',
      determineStateFunc: (data) => this.determineStateFunc(data.estado),
    },
  ];

  determineStateFunc(data: string) {
    if (data === 'EN PROCESO') {
      return 'warning';
    }
    if (data === 'TERMINADO') {
      return 'success';
    }
    return 'information';
  }

  validateDate(date: string) {
    return date === '01/01/0001' ? '-' : date;
  }
}
