import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentMessageComponent } from './document-message.component';
import { SuraCommonsUiModule } from '@surainvestments/sura-common-ui';



@NgModule({
  declarations: [
    DocumentMessageComponent
  ],
  imports: [
    CommonModule,
    SuraCommonsUiModule
  ],
  exports: [
    DocumentMessageComponent
  ]
})
export class DocumentMessageModule { }
