import { ExecutiveBannerComponent } from './executive-banner/executive-banner.component';
import { TabComponent } from './tab/tab.component';
import { TabItemComponent } from './tab-item/tab-item.component';
import { LoadingComponent } from './loading/loading.component';
import { ButtonSuraComponent } from './button-sura/button-sura.component';
import { WelcomeMessageComponent } from './welcome-message/welcome-message.component';
import { UserMessageComponent } from './user-message/user-message.component';
import { InitialsNameComponent } from './initials-name/initials-name.component';
import { MobileInputSearchComponent } from './mobile-input-search/mobile-input-search.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SearchListComponent } from './search-list/search-list.component';
import { SearchListItemComponent } from './search-list-item/search-list-item.component';
import { ChipComponent } from './chip/chip.component';

//nuevos componente MMB Software
import { MmbCardDetalleColapseComponent } from './mmb-card-detalle-colapse/mmb-card-detalle-colapse.component';
import { MmbWizzardComponent } from './mmb-wizzard/mmb-wizzard.component';
import { MmbPaginadorComponent } from './mmb-paginador/mmb-paginador.component';
import { MmbTableComponent } from './mmb-table/mmb-table.component';
import { MmbTdComponent } from './mmb-table/mmb-td/mmb-td.component';
import { MmbTrComponent } from './mmb-table/mmb-tr/mmb-tr.component';
import { MmbThComponent } from './mmb-table/mmb-th/mmb-th.component';
import { MmbTituloPaginaComponent } from './mmb-titulo-pagina/mmb-titulo-pagina.component';
import { MmbDropdownComponent } from './mmb-dropdown/mmb-dropdown.component';
import { MmbRadioButtonComponent } from './mmb-radio-button/mmb-radio-button.component';
import { MmbInputTextComponent } from './mmb-input-text/mmb-input-text.component';
import { MmbInputMontoComponent } from './mmb-input-monto/mmb-input-monto.component';
import { MmbCheckboxComponent } from './mmb-checkbox/mmb-checkbox.component';
import { MmbButtonComponent } from './mmb-button/mmb-button.component';
import { MmbDropdownAutocompleteComponent } from './mmb-dropdown-autocomplete/mmb-dropdown-autocomplete.component';
import { MultipleSesionComponent } from './multiple-sesion/multiple-sesion.component';
import { PopupComponent } from './popup/popup.component';
import { AlertMarketComponent } from './alert-market/alert-market.component';
import { LoadPageBlackComponent } from './nws-components/load-page-black/load-page-black.component';

export const sharedComponents = [
  TabComponent,
  TabItemComponent,
  LoadingComponent,
  ButtonSuraComponent,
  ExecutiveBannerComponent,
  UserMessageComponent,
  TabComponent,
  TabItemComponent,
  ButtonSuraComponent,
  ExecutiveBannerComponent,
  WelcomeMessageComponent,
  InitialsNameComponent,
  MobileInputSearchComponent,
  CheckboxComponent,
  SearchListComponent,
  SearchListItemComponent,
  ChipComponent,
  MmbCardDetalleColapseComponent,
  MmbWizzardComponent, 
  MmbPaginadorComponent,
  MmbTableComponent,
  MmbThComponent,
  MmbTrComponent,
  MmbTdComponent,
  MmbTituloPaginaComponent,
  MmbDropdownComponent,
  MmbRadioButtonComponent,
  MmbInputTextComponent,
  MmbInputMontoComponent,
  MmbCheckboxComponent,
  MmbButtonComponent,
  MmbDropdownAutocompleteComponent,
  MultipleSesionComponent,
  PopupComponent,
  AlertMarketComponent,
  LoadPageBlackComponent

];
