import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ResizeEvent } from 'angular-resizable-element';
import { MatDialog } from '@angular/material/dialog';
import { TerminosServicioComponent } from './terminos-servicio/terminos-servicio.component';
import { IHistorial, IModelMensajes, IModelParams, IRespuestaBot } from '../../models/chat-bot.model';
import { ChatBotService } from '../../services';
import * as moment from 'moment';
import { trigger, transition, style, animate } from '@angular/animations';
import { ChatBotLogService } from '../../services/chat-bot/chat-bot-log.service';
import { IDatosAdicionales } from '../../models/log.model';
import { LOG_CHATBOT } from '../../constants/log.constant';
import { v4 as uuidv4 } from 'uuid';
import { AssetService } from '../../services/asset/asset.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-chat-bot-asesor',
    templateUrl: './chat-bot-asesor.component.html',
    styleUrls: ['./chat-bot-asesor.component.scss'],
    animations: [
        trigger('fade', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('300ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate('300ms', style({ opacity: 0 }))
            ])
        ])
    ],
})
export class ChatBotAsesorComponent implements OnInit, AfterViewInit {


    @Input() nombreBot: string;
    @Input() mensajeInicial: string;
    @Input() prompts: string[];
    @Input() consumerID: string;
    @Input() modelParams: IModelParams;

    @ViewChild('btn') button: ElementRef;
    @ViewChildren('listElement') listElements: QueryList<ElementRef>;

    iconoExpand = "";
    iconoCerrar = "";
    iconoMuyMala = "";
    iconoMala = "";
    iconoNeutra = "";
    iconoBien = "";
    iconoMuyBien = "";
    iconoMensajeEntrante = "";
    iconoDetener = "";
    iconoRegenerar = "";
    iconoNoMeGusto = "";
    iconoCopia = "";
    iconoMensajeSaliente = "";
    iconoDocumento = "";
    iconoEnlace = "";
    iconoBoton = "";

    public chatActive = false;
    public abiertoAnimado = false;
    public cerrandoChat = false;

    public anchoBotonesHeader: object = {};
    public style: object = {};

    private isDragging = false;
    private initialY: number;

    chatDeshabilitadoClass = 'filled-btn-white size-s';
    chatHabilitadoClass = 'filled-btn-gris size-s';

    classDinamicaChat: string = null;
    mensajeSaliente: string = '';
    valorPlaceHolderDefault = 'Escribe aquí...';
    valorPlaceHolderEncuesta = '(Opcional) Escribe tu opinión aquí...';
    placeholder: string = this.valorPlaceHolderDefault;

    mensajeFeedBack = '';

    mensajeFeedBackCierre = 'Antes de finalizar el chat, me gustaría conocer tu opinión respecto de mi funcionamiento y ayuda para ti.';

    mensajeFeedBackMensaje = 'Cuéntanos por qué no te ha gustado mi respuesta.';

    mensajeDisclaimer = "Información no siempre precisa. Verificar respuestas"

    mensajeUltimaRespuesta = 'Respuesta actualizada el ';

    cantidadCaracteresMaximos = 1500;

    btnStatusEncuesta = {
        muybien: false,
        bien: false,
        neutro: false,
        mal: false,
        muymal: false,
    };

    seteandoSatisfaccion = false;
    feedBackCompleto = false;

    condicionesAceptadas = false;

    listadoMensajes: IModelMensajes[] = [];
    textoCopia = 'COPIAR';

    chatIniciado = false;

    esperandoRespuesta = false;

    feedBackMensaje = false;
    feedBackHistorial: IHistorial[] = [];
    idConversacionFeedBack: string = null;
    topicoFeedBack: string = null;

    botonElegidoFeedBack: string;

    esperandoCierre: boolean = false;

    reiniciandoChat: boolean = false;

    sessionID = uuidv4() + Math.random().toString(36).substring(2, 15)

    toggleChat(): void {
        this.chatIniciado = true;
        this.chatActive = !this.chatActive;
        if (!this.chatActive) {
            this.abiertoAnimado = false;
        }
    }

    constructor(private renderer: Renderer2, public dialog: MatDialog, private chatBotService: ChatBotService, private logService: ChatBotLogService, private assetService: AssetService, private sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.anchosPorDefecto();
        this.classDinamicaChat = this.chatDeshabilitadoClass;
        this.mensajeFeedBack = this.mensajeFeedBackCierre;

        this.iconoExpand = this.assetService.getImagePath('icono-expand.svg');
        this.iconoCerrar = this.assetService.getImagePath('icono-close.svg');
        this.iconoMuyMala = this.assetService.getImagePath('chatbotia/ICON-4.svg');
        this.iconoMala = this.assetService.getImagePath('chatbotia/ICON-3.svg');
        this.iconoNeutra = this.assetService.getImagePath('chatbotia/ICON-2.svg');
        this.iconoBien = this.assetService.getImagePath('chatbotia/ICON-1.svg');
        this.iconoMuyBien = this.assetService.getImagePath('chatbotia/ICON.svg');
        this.iconoMensajeEntrante = this.assetService.getImagePath('chatbotia/Adb.svg');
        this.iconoDetener = this.assetService.getImagePath('chatbotia/StopCircle.svg');
        this.iconoRegenerar = this.assetService.getImagePath('chatbotia/Replay.svg');
        this.iconoNoMeGusto = this.assetService.getImagePath('chatbotia/ThumbDownAlt.svg');
        this.iconoCopia = this.assetService.getImagePath('chatbotia/CopyAll.svg');
        this.iconoMensajeSaliente = this.assetService.getImagePath('chatbotia/light.svg');
        this.iconoDocumento = this.assetService.getImagePath('chatbotia/File.svg');
        this.iconoEnlace = this.assetService.getImagePath('chatbotia/Link.svg');
        this.iconoBoton = this.assetService.getImagePath('svgviewer-output.svg');
    }

    ngAfterViewInit() {
        this.escucharEventosBoton();
        this.escucharEventosChat();
        this.cargarMensajeBienvenida();
    }

    sanitizarTexto(texto: string): void {
        if (!texto) return;
        setTimeout(() => {
            const markdownInterpretado = document.getElementById(texto);
            this.sanitizer.bypassSecurityTrustHtml(markdownInterpretado.innerHTML);
            this.sanitizer.bypassSecurityTrustUrl(markdownInterpretado.innerHTML);
            this.sanitizer.bypassSecurityTrustResourceUrl(markdownInterpretado.innerHTML);
        }, 10)
    }

    cargarMensajeBienvenida() {
        this.listadoMensajes.push({
            tipoMensaje: 'ENTRANTE',
            contenidoMensaje: this.mensajeInicial,
            esBienvenida: true,
            esperando: false,
            fechaMensaje: null,
            disclaimer: null,
            regenerable: false,
            regenerando: false,
            idMensaje: 'bienvenida',
        });
    }

    openDialog(): void {
        if (this.condicionesAceptadas) {
            this.toggleChat();
        } else {
            const dialogRef = this.dialog.open(TerminosServicioComponent, {
                disableClose: true,
                hasBackdrop: true,
                autoFocus: false,
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log('MODAL TERMINOS DE SERVICIO CERRADO');
                if (result === 'ACEPTO') {
                    const logInicio: IDatosAdicionales = {
                        accion: LOG_CHATBOT.INICIO,
                        glosa: LOG_CHATBOT.GLOSA_INICIO,
                        metodo: 'LOG',
                        payload: {MENSAJE: 'EL USUARIO ACEPTA LAS CONDICIONES DE USO DEL CHATBOT.'},
                        stash: 'log_negocio',
                        tipoLog: 'NEGOCIO',
                        salida: '-',
                        estado: 'OK',
                    }

                    this.logService.guardarLog(logInicio);
                    this.toggleChat();
                    this.condicionesAceptadas = true;
                }
            });
        }
    }

    escucharEventosBoton(): void {
        this.renderer.listen(this.button.nativeElement, 'mousedown', (event) => this.onMouseDown(event));
        this.renderer.listen('document', 'mousemove', (event) => this.onMouseMove(event));
        this.renderer.listen('document', 'mouseup', () => this.onMouseUp());
    }

    onResizeEnd(event: ResizeEvent): void {
        this.style = {
            position: 'fixed',
            left: `${event.rectangle.left}px`,
            top: `${event.rectangle.top}px`,
            width: `${event.rectangle.width}px`,
            height: `${event.rectangle.height}px`,
        };
        this.anchoBotonesHeader['width'] = `${parseInt(this.style['width']) * 0.28}px`;
        if (parseInt(this.style['width']) < 334) {
            this.anchosPorDefecto();
            setTimeout(() => {
                this.escucharEventosBoton();
            }, 200);
        }
        this.scrollToBottom();
    }

    onAnimationEnd(event: AnimationEvent): void {
        if (event.animationName === 'slideInFromRight') {
            this.abiertoAnimado = true;
        }
    }

    minimizarChat(): void {
        this.toggleChat();
        setTimeout(() => {
            this.anchosPorDefecto();
            this.escucharEventosBoton();
        }, 200);
    }

    cerrarChat(): void {
        const chatVacios = this.listadoMensajesVacios()
        if (!chatVacios) {
            this.cerrandoChat = true;
            this.feedBackCompleto = true;
            this.placeholder = this.valorPlaceHolderEncuesta;
            this.generarFeedBackCompleto();
        } else {
            this.cerrandoChat = true;
            if (this.esperandoRespuesta) {
                this.esperandoCierre = true;
                const esperarTermino = setInterval(() => {
                    if (!this.esperandoRespuesta) {
                        clearInterval(esperarTermino);
                        setTimeout(() => {
                            this.esperandoCierre = false;
                            this.resetearChat();
                        }, 200);
                    }
                }, 200);
            } else {
                this.resetearChat();
            }
        }
    }

    private anchosPorDefecto(): void {
        this.style = {};
        this.style['position'] = 'fixed';
        setTimeout(()=> {
            this.style['width'] = '400px !important';
            this.style['right'] = '8px';
            this.style['bottom'] = '8px'
            this.anchoBotonesHeader['width'] = `${parseInt(this.style['width']) * 0.28}px`;
        }, 10)
        this.scrollToBottom();
    }

    enviarMensaje() {
        if(this.reiniciandoChat) return;
        if (!this.mensajeSaliente.trim().length && !this.feedBackCompleto) return;
        if (this.esperandoRespuesta && !this.cerrandoChat) return;
        if (!this.cerrandoChat && !this.feedBackMensaje && !this.feedBackCompleto) {
            // enviar pregunta al servicio
            this.listadoMensajes.push({
                tipoMensaje: 'SALIENTE',
                contenidoMensaje: this.mensajeSaliente,
                esBienvenida: false,
                esperando: false,
                fechaMensaje: null,
                disclaimer: null,
                regenerable: false,
                regenerando: false,
                idMensaje: null,
            });
            this.enviarPregunta();
            setTimeout(() => {
                this.mensajeSaliente = '';
                this.classDinamicaChat = this.chatDeshabilitadoClass;
            }, 200);
        } else {
            
            if(this.feedBackCompleto && !this.seteandoSatisfaccion) return;

            const usuario = sessionStorage.getItem('NombreEjecutivoAsesor') + ' - ' + sessionStorage.getItem('sessionId').split('-')[0];
            let mensajeSalidaFeedBack = '';
            if (this.cerrandoChat && !this.feedBackMensaje) {
                mensajeSalidaFeedBack = this.botonElegidoFeedBack + ' - ' + this.mensajeSaliente;
            } else {
                mensajeSalidaFeedBack = this.mensajeSaliente;
            }
            this.chatBotService.enviarFeedBack(mensajeSalidaFeedBack, this.feedBackHistorial, this.idConversacionFeedBack, this.topicoFeedBack, usuario);
            this.resetearChat();
        }
    }

    validarEntradaMensaje(event) {
        if (this.reiniciandoChat) return;
        const eventCapturado = event as InputEvent;
        this.validarClassBotonEnvio();
        const mensaje = this.mensajeSaliente.trim();
        console.log(event);
        if (eventCapturado.inputType === 'insertLineBreak' || (!eventCapturado.data && eventCapturado.inputType !== ('deleteContentBackward' || 'deleteSoftLineBackward')) && eventCapturado.inputType !== 'insertFromPaste') {
            if (mensaje.length > 0) {
                if (this.esperandoRespuesta) {
                    // EVITA LOS SALTOS DE LINEA EN LA CAJA DE TEXTO MIENTRAS ESPERAS LA RESPUESTA DEL CHAT
                    this.mensajeSaliente = this.mensajeSaliente.split('\n')[0];
                    this.mensajeSaliente = this.acortadorTexto(this.mensajeSaliente);
                    event.target['value'] = this.mensajeSaliente;
                    return;
                } else {
                    this.mensajeSaliente = this.acortadorTexto(this.mensajeSaliente);
                    this.enviarMensaje();
                }
            } else {
                this.mensajeSaliente = '';
                event.target['value'] = '';
                this.validarClassBotonEnvio();
                return;
            }
        }
    }

    validarClassBotonEnvio() {
        if (this.mensajeSaliente && this.mensajeSaliente.length > 0 && !this.esperandoRespuesta) {
            if (this.cerrandoChat && !this.feedBackMensaje && !this.seteandoSatisfaccion) {
                this.classDinamicaChat = this.chatDeshabilitadoClass;
            } else {
                this.classDinamicaChat = this.chatHabilitadoClass;
            }
        } else {
            this.classDinamicaChat = this.chatDeshabilitadoClass;
        }
    }

    private reiniciarIconoSatisfaccion(): void {
        this.btnStatusEncuesta = {
            muybien: false,
            bien: false,
            neutro: false,
            mal: false,
            muymal: false,
        };
    }

    setearEncuesta(boton: string) {
        this.seteandoSatisfaccion = true;
        this.classDinamicaChat = this.chatHabilitadoClass;
        this.reiniciarIconoSatisfaccion();
        this.btnStatusEncuesta[boton] = true;
        this.botonElegidoFeedBack = boton;
    }

    enviarEncuesta() {
        if (this.feedBackCompleto && this.botonElegidoFeedBack) {
            const logFeedbackCompleto: IDatosAdicionales = {
                accion: LOG_CHATBOT.FEEDBACK_COMPLETO,
                glosa: LOG_CHATBOT.FEEDBACK_COMPLETO_GLOSA,
                metodo: 'LOG',
                payload: {MENSAJE: 'EL USUARIO ENVIA FEEDBACK COMPLETO.'},
                stash: 'log_negocio',
                tipoLog: 'NEGOCIO',
                salida: '-',
                estado: 'OK',
            }
            this.logService.guardarLog(logFeedbackCompleto);
            this.enviarMensaje();
        } else {
            const logFeedbackMensaje: IDatosAdicionales = {
                accion: LOG_CHATBOT.FEEDBACK_MSG,
                glosa: LOG_CHATBOT.FEEDBACK_MSG_GLOSA,
                metodo: 'LOG',
                payload: {MENSAJE: 'EL USUARIO ENVIA FEEDBACK MENSAJE.'},
                stash: 'log_negocio',
                tipoLog: 'NEGOCIO',
                salida: '-',
                estado: 'OK',
            }
            this.logService.guardarLog(logFeedbackMensaje);
            this.enviarMensaje();
        }
    }

    resetearChat() {
        if (this.feedBackMensaje) {
            setTimeout(() => {
                this.mensajeFeedBack = this.mensajeFeedBackCierre;
                this.mensajeSaliente = '';
                this.topicoFeedBack = null;
                this.idConversacionFeedBack = null;
                this.feedBackHistorial.length = 0;
                this.feedBackMensaje = false;
                this.cerrandoChat = false;
                this.feedBackCompleto = false;
                this.validarClassBotonEnvio();
            },100)
        } else {
            this.toggleChat();
            setTimeout(() => {
                this.escucharEventosBoton();
                this.condicionesAceptadas = false;
                this.seteandoSatisfaccion = false;
                this.classDinamicaChat = this.chatDeshabilitadoClass;
                this.reiniciarIconoSatisfaccion();
                this.placeholder = this.valorPlaceHolderDefault;
                this.listadoMensajes.length = 0;
                this.feedBackCompleto = false;
                this.cargarMensajeBienvenida();
                this.anchosPorDefecto();
                this.mensajeSaliente = '';
                this.cerrandoChat = false;
                this.validarClassBotonEnvio();
                this.sessionID = uuidv4() + Math.random().toString(36).substring(2, 15);

                const logCierre: IDatosAdicionales = {
                    accion: LOG_CHATBOT.CERRAR,
                    glosa: LOG_CHATBOT.CERRAR_GLOSA,
                    metodo: 'LOG',
                    payload: {MENSAJE: 'EL USUARIO CIERRA EL CHATBOT.'},
                    stash: 'log_negocio',
                    tipoLog: 'NEGOCIO',
                    salida: '-',
                    estado: 'OK',
                }
                this.logService.guardarLog(logCierre);

            }, 100);
        }
    }

    onMouseDown(event: MouseEvent) {
        this.isDragging = true;
        const rect = this.button.nativeElement.getBoundingClientRect();
        this.initialY = event.clientY - rect.top;
    }

    onMouseMove(event: MouseEvent) {
        if (this.isDragging) {
            const newY = event.clientY - this.initialY;
            this.renderer.setStyle(this.button.nativeElement, 'top', `${newY}px`);
        }
    }

    onMouseUp() {
        this.isDragging = false;
    }

    scrollToBottom() {
        if (this.listElements) {
            const elements = this.listElements.toArray();
            if (elements.length > 0) {
                const lastElement = elements[elements.length - 1].nativeElement;
                lastElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        }
    }

    private async enviarPregunta(): Promise<void> {
        try {
            if (this.esperandoRespuesta) {
                return;
            }

            this.quitarEdicionMensajesAnteriores();
            this.listadoMensajes.push({
                tipoMensaje: 'ENTRANTE',
                contenidoMensaje: '',
                esBienvenida: false,
                esperando: true,
                fechaMensaje: null,
                disclaimer: null,
                regenerable: false,
                regenerando: false,
                idMensaje: null,
            });
            const prompt = this.obtenerPrompt(this.prompts);
            this.esperandoRespuesta = true;
            const mensajeEntrante: IRespuestaBot = await this.chatBotService.enviarMensaje(this.mensajeSaliente, this.consumerID, prompt, this.modelParams, this.sessionID);
            if (this.condicionesAceptadas) {
                this.listadoMensajes[this.listadoMensajes.length - 1].contenidoMensaje = mensajeEntrante.data.respuesta;
                this.listadoMensajes[this.listadoMensajes.length - 1].idMensaje = mensajeEntrante.data.idMensaje;
                this.listadoMensajes[this.listadoMensajes.length - 1].question = mensajeEntrante.data.pregunta;
                this.listadoMensajes[this.listadoMensajes.length - 1].topic = mensajeEntrante.data.topic;
                this.listadoMensajes[this.listadoMensajes.length - 1].fechaMensaje = this.mensajeUltimaRespuesta + this.generarFechaActualizacion();
                this.listadoMensajes[this.listadoMensajes.length - 1].disclaimer = this.mensajeDisclaimer;
                this.listadoMensajes[this.listadoMensajes.length - 1].regenerable = true;
                this.listadoMensajes[this.listadoMensajes.length - 1].esperando = false;
                this.listadoMensajes[this.listadoMensajes.length - 1].regenerando = false;
            }
            setTimeout(() => {
                this.esperandoRespuesta = false;
            }, 300);
        } catch (e) {
            console.log(e.message);
            this.listadoMensajes.pop();
            this.listadoMensajes.push({
                tipoMensaje: 'ENTRANTE',
                contenidoMensaje: 'No tengo una respuesta para eso, puedes reintentarlo o probar con otra pregunta.',
                esBienvenida: false,
                esperando: false,
                regenerando: false,
                idMensaje: 'front_exception_captured',
                fechaMensaje: null,
                disclaimer: this.mensajeDisclaimer,
                regenerable: true,
                topic: 'exception',
                question: this.mensajeSaliente,
            });
            this.esperandoRespuesta = false;
        }
    }

    private obtenerPrompt(lista: string[]): string {
        const indiceAleatorio = Math.floor(Math.random() * lista.length);
        return lista[indiceAleatorio];
    }

    private escucharEventosChat() {
        this.listElements.changes.subscribe(() => {
            this.scrollToBottom();
        });
    }

    copiarTexto(contenidoMensaje: string) {
        const textArea = document.createElement('textarea');
        textArea.value = contenidoMensaje;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    async reintentar(indice: number) {
        if (this.listadoMensajes[indice].regenerando) {
            this.detenerRegeneracion(indice);
        } else {
            this.listadoMensajes[indice].esperando = true;
            this.listadoMensajes[indice].regenerando = true;
            this.esperarMensajeGenerado(indice);
        }
    }

    detenerRegeneracion(indice: number) {
        this.listadoMensajes[indice].regenerando = false;
        this.listadoMensajes[indice].esperando = false;
    }

    esperarMensajeGenerado = async (indice: number): Promise<void> => {
        try {
            const prompt = this.obtenerPrompt(this.prompts);
            const preguntaAnterior = this.listadoMensajes[indice - 1].contenidoMensaje;
            const mensajeReintento = await this.chatBotService.enviarMensaje(preguntaAnterior, this.consumerID, prompt, this.modelParams, this.sessionID);

            if (this.listadoMensajes[indice].regenerando) {
                this.listadoMensajes[indice].contenidoMensaje = mensajeReintento.data.respuesta;
                this.listadoMensajes[indice].idMensaje = mensajeReintento.data.idMensaje;
                this.listadoMensajes[indice].question = mensajeReintento.data.pregunta;
                this.listadoMensajes[indice].topic = mensajeReintento.data.topic;
                this.listadoMensajes[indice].fechaMensaje = this.mensajeUltimaRespuesta + this.generarFechaActualizacion();
                this.listadoMensajes[indice].disclaimer = this.mensajeDisclaimer;
                this.listadoMensajes[indice].esperando = false;
                this.listadoMensajes[indice].regenerando = false;
            }

        } catch (e) {
            console.log('ERROR AL REGENERAR PREGUNTA: ', e.message);
            this.listadoMensajes[indice].contenidoMensaje = 'No es posible regenerar la respuesta, prueba reintentar o hacer una nueva pregunta.';
            this.listadoMensajes[indice].regenerando = false;
            this.listadoMensajes[indice].esperando = false;
            this.esperandoRespuesta = false;
        }
    };

    feedBack(contenidoMensaje: string, idMensaje: string, pregunta: string, topico: string) {
        // RETORNAR LANGUAGE ID CON RESPUESTA
        // TOMAR LA ULTIMA PREGUNTA HECHA A AL BOT, OSEA EL ULTIMO MENSAJE SALIENTE.
        this.mensajeSaliente = '';
        this.mensajeFeedBack = this.mensajeFeedBackMensaje;
        this.feedBackMensaje = true;
        this.cerrandoChat = true;
        this.feedBackHistorial.push({ question: pregunta, answer: contenidoMensaje });
        this.topicoFeedBack = topico;
        this.idConversacionFeedBack = idMensaje;
    }

    generarFeedBackCompleto() {
        try {
            const listadoSalida = this.listadoMensajes.filter(({
                                                                   tipoMensaje,
                                                                   esBienvenida,
                                                                   regenerando,
                                                                   esperando,
                                                               }) => tipoMensaje === 'ENTRANTE' && !esBienvenida && !regenerando && !esperando);
            const lastIndex = listadoSalida.length - 1;
            this.mensajeSaliente = '';
            for (let i = 0; i <= lastIndex; i++) {
                const { contenidoMensaje, question, idMensaje, topic } = listadoSalida[i];
                this.feedBackHistorial.push({ answer: contenidoMensaje, question });
                if (i === lastIndex) {
                    this.idConversacionFeedBack = idMensaje;
                    this.topicoFeedBack = topic;
                }
            }
        } catch (e) {
            this.feedBackHistorial.length = 0;
            console.log('ERROR AL OBTENER LISTADO DE MENSAJES PARA FEEDBACK: ', e.message);
        }
    }

    generarFechaActualizacion(): string {
        return moment(new Date()).format('DD/MM/YYYY HH:mm:ss');
    }

    quitarEdicionMensajesAnteriores(): void {
        for (let i = 0; this.listadoMensajes.length > i; i++) {
            this.listadoMensajes[i].regenerable = false;
        }
    }

    listadoMensajesVacios(): boolean {
        const cantidadMensajesValidos = this.listadoMensajes.filter(m => !m.esBienvenida && m.tipoMensaje === 'ENTRANTE' && !m.regenerando && !m.esperando);
        const mensajesVacios = !cantidadMensajesValidos.length;
        return mensajesVacios
    }

    /**
     * limita la cantidad de caracteres de entrada segun variable
     * @param text
     */
    acortadorTexto(text: string): string {
        try {
            if (text.length > this.cantidadCaracteresMaximos) {
                return text.slice(0, this.cantidadCaracteresMaximos);
            }
            return text;
        } catch (e) {
            console.log('ERROR AL PROCESAR TEXTO :' + e.message);
            return text;
        }
    }

    nuevoChat() {
        this.reiniciandoChat = true;
        if(this.esperandoRespuesta){
            const esperarTerminoRespuesta = setInterval(() => {
                if (!this.esperandoRespuesta) {
                    clearInterval(esperarTerminoRespuesta);
                    setTimeout(() => {
                        this.sessionID = uuidv4() + Math.random().toString(36).substring(2, 15);
                        this.reiniciandoChat = false;
                        this.listadoMensajes = [];
                        const logNuevoChat: IDatosAdicionales = {
                            accion: LOG_CHATBOT.REINICIO_CHATBOT,
                            glosa: LOG_CHATBOT.REINICIO_CHATBOT_GLOSA,
                            metodo: 'LOG',
                            payload: {MENSAJE: 'EL USUARIO CREA UN NUEVO HILO DE CONVERSACION.'},
                            stash: 'log_negocio',
                            tipoLog: 'NEGOCIO',
                            salida: '-',
                            estado: 'OK',
                        }
                        this.logService.guardarLog(logNuevoChat);
                    }, 10);
                }
            }, 200);
        } else {
            this.listadoMensajes = [];
            this.condicionesAceptadas = true;
            this.seteandoSatisfaccion = false;
            this.sessionID = uuidv4() + Math.random().toString(36).substring(2, 15);
            this.classDinamicaChat = this.chatDeshabilitadoClass;
            const logNuevoChat: IDatosAdicionales = {
                accion: LOG_CHATBOT.REINICIO_CHATBOT,
                glosa: LOG_CHATBOT.REINICIO_CHATBOT_GLOSA,
                metodo: 'LOG',
                payload: {MENSAJE: 'EL USUARIO CREA UN NUEVO HILO DE CONVERSACION.'},
                stash: 'log_negocio',
                tipoLog: 'NEGOCIO',
                salida: '-',
                estado: 'OK',
            }
            this.logService.guardarLog(logNuevoChat);
            setTimeout(()=>{
                this.reiniciandoChat = false;
            }, 500)
        }
    }
}
