import { RequestSaveLogModel } from '../../../shared/models/request-save-log.model';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { RequestHandlerService } from '../request-handler/request-handler.service';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import * as moment from 'moment';
import * as publicIp from 'public-ip';
import { DetalleLogTransaccion, InputLog } from 'src/app/features/models/log/InputLog';
import { contratoCategoria } from 'src/app/features/models/log/log.contrato.categoria';
import { contratoOperaciones } from 'src/app/features/models/log/log.contrato.operaciones';
import { Log } from 'src/app/features/models/log/log';
import { DetalleAccion } from 'src/app/features/models/log/DetalleAccion.interface';
import { RegisterLog } from 'src/app/features/models/log/RegisterLog';
import { ConfigIndexLogStash } from 'src/app/features/models/log/models';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class TracesService {
    constructor(
        // private clientRequestService: ClientRequestService,
        private requestHandler: RequestHandlerService,
        private deviceService: DeviceDetectorService,
        private activeRoute: ActivatedRoute,
    ) {}

    async saveLog(requestSaveLog: RequestSaveLogModel): Promise<any> {
        requestSaveLog.service = requestSaveLog.service ? requestSaveLog.service : 'NWS_ASESOR';
        requestSaveLog.value.at = moment().local().format();
        requestSaveLog.value.Tipo_sesion = 'ASESOR';

        let ipCliente = '';
        if (!sessionStorage.getItem('ipCliente')) {
            ipCliente = await this.getIp();
            sessionStorage.setItem('ipCliente', ipCliente);
        } else {
            ipCliente = sessionStorage.getItem('ipCliente');
        }

        requestSaveLog.value.Host_ip = ipCliente;

        const deviceInfo: DeviceInfo = await this.deviceService.getDeviceInfo();
        requestSaveLog.value.Tipo_dispositivo = deviceInfo.deviceType;
        requestSaveLog.value.Modelo_dispositivo = deviceInfo.userAgent;
        requestSaveLog.value.Sistema_operativo = deviceInfo.os;
        requestSaveLog.value.Browser = deviceInfo.browser;

        requestSaveLog.value.channel = 'NWSA_ASESOR';
        requestSaveLog.value.Fecha = moment().local().format();
        requestSaveLog.value.Salida = !requestSaveLog.value.Salida ? '-' : requestSaveLog.value.Salida;
        requestSaveLog.value.Status = !requestSaveLog.value.Status ? 'OK' : requestSaveLog.value.Status;

        return this.requestHandler.doPost<any>(`${environment.ENDPOINTS.BFF.BFF_CLIENTTRACES}/traces/saveLog`, requestSaveLog);
    }

    async getIp(): Promise<string> {
        return publicIp.v4();
    }

    public registerLog(inputLog: InputLog, channel: string, service: string, field: string, rutTransaccion: string = null, indexLogStash?: ConfigIndexLogStash, detalleLogTransaccion: DetalleLogTransaccion[] = []): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                const clientData = JSON.parse(sessionStorage.getItem('clientData'));
                const rut = JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut;
                const idSession = sessionStorage.getItem('idSesion');
                if (rut) {
                    if (!inputLog.Categoria || !contratoCategoria.includes(inputLog.Categoria)) {
                        console.error('NO SE INGRESO LA CATEGORIA O NO RESPETAMOS EL CONTRATO DE ELLAS');
                        throw new Error('NO SE INGRESO LA CATEGORIA O NO RESPETAMOS EL CONTRATO DE ELLAS');
                    }

                    if (!inputLog.Operacion || !contratoOperaciones.includes(inputLog.Operacion)) {
                        console.error('NO SE INGRESO LA OPERACIÓN O NO RESPETAMOS EL CONTRATO DE ELLAS');
                        throw new Error('NO SE INGRESO LA OPERACIÓN O NO RESPETAMOS EL CONTRATO DE ELLAS');
                    }
                    const log: Log = new Log();
                    log.DetalleAccionExtra = inputLog.DetalleAccionExtra;
                    log.Operacion = inputLog.Operacion;
                    log.Secuencia = inputLog.Secuencia;
                    log.Metodo = inputLog.Metodo;
                    log.EntradaLog = {
                        ...inputLog.EntradaLog,
                        rut: inputLog.EntradaLog.rut?inputLog.EntradaLog.rut:JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                        fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                        isExecutive: true,
                        usuarioAsesor: sessionStorage.getItem('executiveUsername') ?? '',
                    };
                    log.detalleLogTransaccionSURA = detalleLogTransaccion ? JSON.stringify(detalleLogTransaccion) : '';
                    log.Salida = inputLog.Salida;
                    log.status = inputLog.status;
                    log.Servicio = inputLog.Servicio;
                    log.extra = inputLog.extra;
                    log.Categoria = inputLog.Categoria;
                    log.Linea_negocio = inputLog.Linea_negocio || '';
                    log.Detalle_accion = {} as DetalleAccion;
                    log.SesionCliente = sessionStorage.getItem('idSesion') || idSession;
                    const deviceInfo: DeviceInfo = await this.deviceService.getDeviceInfo();
                    log.Tipo_dispositivo = deviceInfo.deviceType;
                    log.Fecha = new Date();
                    log.at = new Date();
                    log.Tipo_sesion = 'ASESOR';
                    log.Host_ip = sessionStorage.getItem('ipCliente') || (await publicIp.v4());
                    log.Modelo_dispositivo = deviceInfo.userAgent;
                    log.Sistema_operativo = deviceInfo.os;
                    log.Browser = deviceInfo.browser;
                    // const userInfo = JSON.parse(sessionStorage.getItem('clientInfoLog'));
                    // if (userInfo) {
                    //     log.nombreCliente = userInfo.nombre;
                    //     log.rutCliente = userInfo.rut;
                    // }
                    log.rutEjecutivo = rut;
                    log.rutTransaccion = rutTransaccion || '';
                    log.rutAsesor = rut;
                    log.rutCliente = clientData?.rut || '';
                    log.eventoLog = inputLog.eventoLog;
                    const registerLog: RegisterLog = new RegisterLog();
                    registerLog.data = log;
                    registerLog.channel = 'NWSA_ASESOR';
                    registerLog.field = field;
                    let serviceValid = 'NWSA_ASESOR';
                    if (indexLogStash === 'log_transaccion') {
                        serviceValid = 'TRXA';
                    }
                    if (indexLogStash === 'log_error') {
                        serviceValid = 'ERRA';
                    }
                    registerLog.service = serviceValid;
                    registerLog.indexLogStash = indexLogStash || 'log_negocio';
                    await this.SaveLog(registerLog);
                    resolve({
                        logged: true,
                    });
                } else {
                    resolve('not signed in');
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    SaveLog(registerLog: RegisterLog) {
        try {
            return new Promise((resolve, reject) => {
                const endpoint = `${environment.ENDPOINTS.BFF.BFF_CLIENTTRACES}/traces/saveLog`;
                registerLog.data = Object.assign({}, registerLog.data, {
                    user: sessionStorage.getItem('executiveUsername'),
                    at: new Date(),
                    channel: registerLog.channel,
                });
                const body = {
                    service: registerLog.service,
                    field: registerLog.field || 'weblog',
                    value: registerLog.data,
                    indexLogStash: registerLog.indexLogStash || 'log_negocio',
                };
                this.requestHandler.doPost(endpoint, body).then(
                    (list: any) => {
                        resolve(list);
                    },
                    () => {
                        reject('Ha ocurrido un error al conectar con el servicio trazas de sura');
                    },
                );
            });
        } catch (e) {}
    }
}
