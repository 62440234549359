import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';

@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
})
export class TabItemComponent implements OnInit {
  @Input()
  public title: string;

  @ViewChild(TemplateRef, { static: true })
  _content: TemplateRef<any>;

  private _contentPortal: TemplatePortal;

  public get content(): TemplatePortal {
    return this._contentPortal;
  }

  constructor(private _viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this._contentPortal = new TemplatePortal(this._content, this._viewContainerRef);
  }
}
