import { HttpErrorResponse, HttpEvent, HttpHandler, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageKeys } from '../../../shared/services/executive/executive.service';
import { LoadingService } from '../../../shared/services/loading/loading.service';

@Injectable({
    providedIn: 'root',
})
export class InterceptorService {
    constructor(private router: Router, private loadingService: LoadingService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = sessionStorage.getItem(LocalStorageKeys.TOKEN);
        const xtoken: string = sessionStorage.getItem(LocalStorageKeys.XTOKEN);

        let request = req;
        let idCliente: string = '';
        const idSession: string = sessionStorage.getItem("idSesion");
        const localData = sessionStorage.getItem("clientData");
        if (localData){
            const clientData = JSON.parse(localData);
            idCliente = clientData?.rut?.split('.').join('');
        }

        if (token) {

            if(request.url.indexOf("/accesos-ambientes") < 0){
                request = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                        'X-Authorization': `Bearer ${xtoken}`
                    }
                });
            }

            if(!request.url.includes(environment.ENDPOINTS.BFF.BFF_ORDENES)) {
                if(idSession){
                    if (request.method === "POST") {
                        request = request.clone({
                        body: { ...req.body, idSession, idCliente },
                        });
                    } else if (request.method === "GET") {
                        request = request.clone({
                            params: (req.params ? req.params : new HttpParams()).set("idSession", idSession).set("idCliente", idCliente),
                        });
                    }
                }
            }
        }

        return next.handle(request).pipe(
            // tap((event: HttpEvent<any>) => {
            //     if (event instanceof HttpResponse) {
            //     }
            // }),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.loadingService.hideLoading();

                    this.router.navigateByUrl('/login');

                    localStorage.clear();
                    sessionStorage.clear();
                }

                throw new HttpErrorResponse({
                    status: err.status,
                    statusText: err.message,
                });
            }),
        );
    }
}
