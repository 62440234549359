import { Injectable } from '@angular/core';
import { ClientPortfolioModel, SelectedOptions, SupervisorData } from '../../models/client-portfolio/client-portfolio.model';
import { rutFormat } from 'rut-helpers';
import { toTitleCase } from '../../../shared/helpers/utils';
import { ClientRequestService } from '../client-request/client-request.service';
import { ClientService } from '../client/client.service';
import { ClientPortfolioRequestService } from '../client-portfolio-request/client-portfolio-request.service';
import { filter, map, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { SearchItem } from '../../../shared/components/search-list/search-list.component';
import { ColumnDefinition } from '@surainvestments/sura-common-ui/app/sura-ui-kit/models/table.model';
import { ClientSearchService } from 'src/app/shared/services/client-search/client-search.service';
import { ConfigIndexLogStash, RuteroCMS } from 'src/app/shared/models';
import { InputLog } from '../../models/log/InputLog';
import { Status } from '../../models/log/status';
import * as moment from 'moment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { ExecutiveService } from 'src/app/shared/services';
@Injectable({
    providedIn: 'root',
})
export class ClientPortfolioService {
    supervisorsData: BehaviorSubject<SupervisorData[]> = new BehaviorSubject<SupervisorData[]>(undefined);

    public readonly clientColumnDefinition: ColumnDefinition<ClientPortfolioModel>[] = [
        {
            key: 'NombreSupervisor',
            type: 'text',
            columnTitle: 'Supervisor',
            sizeColumn: 'large',
            transformFunc: (client) => toTitleCase(client.NombreSupervisor),
            sortFunc: (a, b) => {
                return a.NombreSupervisor.localeCompare(b.NombreSupervisor);
            },
        },
        {
            key: 'NombreEjecutivo',
            type: 'text',
            columnTitle: 'Asesor',
            sizeColumn: 'large',
            transformFunc: (client) => toTitleCase(client.NombreEjecutivo),
            sortFunc: (a, b) => {
                return a.NombreEjecutivo.localeCompare(b.NombreEjecutivo);
            },
        },
        {
            key: 'RutCliente',
            type: 'link',
            columnTitle: 'Rut',
            transformFunc: (client) => rutFormat(client.RutCliente),
            clickCell: async (client) => {
                const regexSpace = /\s+/g;
                client.NombreCliente = client.NombreCliente.trim().replace(regexSpace, ' ');
                const isClientAGF = await this.clientSearchService.validateClientAGF(client.RutCliente, this.listClientsAGF);
                if(isClientAGF) return;
                sessionStorage.setItem('clientInfoLog', JSON.stringify({
                    nombre: client.NombreCliente,
                    rut: client.RutCliente
                }));
                await this.navigateToClientResume(client.RutCliente);
            },
            sortFunc: (a, b) => {
                const [aRut] = a.RutCliente.split('-');
                const [bRut] = b.RutCliente.split('-');
                return parseInt(bRut, 10) - parseInt(aRut, 10);
            },
        },
        {
            key: 'NombreCliente',
            type: 'text',
            columnTitle: 'Nombre',
            sizeColumn: 'large',
            transformFunc: (client) => toTitleCase(client.NombreCliente),
            sortFunc: (a, b) => {
                return a.NombreCliente.localeCompare(b.NombreCliente);
            },
        },
        {
            key: 'SaldoAFP',
            type: 'text',
            sizeColumn: 'small',
            columnTitle: 'AFP',
            align: 'center',
            transformFunc: (client) => this.transformProducts(client.SaldoAFP),
            sortFunc: this.orderProducts('SaldoAFP'),
            computedCellClass: this.computeProductsClass('SaldoAFP'),
        },
        {
            key: 'SaldoFFMM',
            type: 'text',
            sizeColumn: 'small',
            columnTitle: 'FFMM',
            align: 'center',
            transformFunc: (client) => this.transformProducts(client.SaldoFFMM),
            sortFunc: this.orderProducts('SaldoFFMM'),
            computedCellClass: this.computeProductsClass('SaldoFFMM'),
        },
        {
            key: 'SaldoVida',
            type: 'text',
            sizeColumn: 'small',
            columnTitle: 'Vida',
            align: 'center',
            transformFunc: (client) => this.transformProducts(client.SaldoVida),
            sortFunc: this.orderProducts('SaldoVida'),
            computedCellClass: this.computeProductsClass('SaldoVida'),
        },
        {
            key: 'SaldoCB',
            type: 'text',
            sizeColumn: 'small',
            columnTitle: 'Corredora',
            align: 'center',
            transformFunc: (client) => this.transformProducts(client.SaldoCB),
            sortFunc: this.orderProducts('SaldoCB'),
            computedCellClass: this.computeProductsClass('SaldoCB'),
        },
        {
            key: 'button',
            type: 'button',
            buttonIcon: 'open_in_new',
            align: 'right',
            columnTitle: '',
            cellClass: 'button-blue',
            sizeColumn: 'x-small',
            tooltip: {
                type: 'dark',
                direction: 'top',
                title: 'Ir al sitio del cliente',
                customClass: 'small-tooltip',
            },
            clickCell: async (a) => {
                const isClientAGF = await this.clientSearchService.validateClientAGF(a.RutCliente, this.listClientsAGF);
                if(isClientAGF) return;
                await this.clientService.goToSiteClient({
                    rut: a.RutCliente,
                    nombre: a.NombreCliente,
                    apellidoPaterno: a.NombreCliente.split(' ')[0],
                    apellidoMaterno: a.NombreCliente.split(' ')[1],
                },'');
            },
        },
    ];

    selectFilter: Subject<SelectedOptions> = new Subject<SelectedOptions>();
    listClientsAGF: RuteroCMS;

    public loading = false;
    public sinCartera = false;

    constructor(private clientRequest: ClientRequestService, 
        private clientService: ClientService, 
        private clientPortfolioService: ClientPortfolioRequestService, 
        private activatedRoute: ActivatedRoute, 
        private router: Router,
        private clientSearchService: ClientSearchService,
        private tracesService: TracesService,
        private executiveService: ExecutiveService,
    ) {
        this.selectFilter.next({ executive: null, supervisor: null });
        this.clientSearchService.clientsAFG.subscribe( (data : RuteroCMS) => {
            this.listClientsAGF = data;
        });
    }

    public getClientPortfolio(executiveRut: string): Observable<[ClientPortfolioModel[], SearchItem[]]> {
        return this.clientPortfolioService.clientPortfolio$.pipe(
            filter((data) => {
                this.sinCartera = false;
                if (!data || data?.length === 0) {
                    this.sinCartera = true;
                    this.loading = false;
                }
                return data?.length > 0 && this.clientPortfolioService.getOwnerOfTheData(executiveRut);
            }),
            map((data) => {
                return [
                    data,
                    data.map((item) => ({
                        searchValue: item.NombreCliente,
                        id: item.RutCliente,
                    })),
                ];
            }),
        );
    }

    public async navigateToClientResume(rut: string) {
        const logtemp: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status"> = {
            Categoria: "Cartera Clientes",
            Operacion: "Cartera Clientes",
            Metodo: "navigateToClientResume()",
            eventoLog: "Click en Rut",
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
              accion: `Click en Rut redireccionar rut: "${ rut }"`,
            },
          };
          this.logData(
            logtemp,
            "log_negocio",
          );
        this.clientRequest.clientData.next(null);
        await this.router.navigate(['/main/client-portfolio/summary', rut], {
            relativeTo: this.activatedRoute,
        });
    }

    transformProducts(value: boolean) {
        return value ? 'SI' : 'NO';
    }

    orderProducts(key: keyof ClientPortfolioModel) {
        return (a, b) => {
            return a[key] === b[key] ? 0 : a[key] ? -1 : 1;
        };
    }

    computeProductsClass(key: keyof ClientPortfolioModel) {
        return (a: ClientPortfolioModel) => (a[key] ? 'green' : 'gray');
    }

    setSupervisorsData(allData: ClientPortfolioModel[]): SupervisorData[]{
        if(!allData) {
            this.clientPortfolioService.clientPortfolio$
            .pipe(take(1))
            .subscribe( (data) => { allData = data });
        }
        
        const supervisors = allData?.map( data => {
            return {
                RutSupervisor: data.RutSupervisor,
                NombreSupervisor: data.NombreSupervisor,
                RutCliente: data.RutCliente
            }
        })
        this.supervisorsData.next(supervisors);
        return supervisors;
    }
    async logData(log: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status">, indexLogStash: ConfigIndexLogStash) {
      try {
        const { EntradaLog, ...logData } = log;
  
        let inputLog: InputLog = new InputLog();
        inputLog.DetalleAccionExtra = {};
        inputLog.Categoria = log.Categoria;
        inputLog.Operacion = log.Operacion;
        inputLog.EntradaLog = {
          rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
          fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
          ...EntradaLog,
        };
        inputLog.Salida = "-";
        inputLog.Secuencia = 0;
        inputLog.Servicio = "";
        inputLog.Tipo_sesion = "ASESOR";
        inputLog.extra = {};
  
        inputLog = {
          ...inputLog,
          ...logData,
        };
        await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
      } catch (error) {
        console.error("error: ", error);
      }
    }
}
