import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalGenericoOrdenes } from '../../../../../../models/ordenes/pershing/orden.modelo';

@Component({
    selector: 'app-confirma-modal',
    templateUrl: './confirma-modal.component.html',
    styleUrls: ['./confirma-modal.component.scss'],
})
export class ConfirmaModalComponentPershing {
    
    public configuracionModal: ModalGenericoOrdenes;

    constructor(
      public dialogRef: MatDialogRef<ConfirmaModalComponentPershing>, 
      @Inject(MAT_DIALOG_DATA) public data: any) {

        if (data) {
          this.configuracionModal = data.optionsMessage;
        } else {
          this.configuracionModal = {
            titulo: "¿ Estas seguro de algo ?",
            mensaje : "El mensaje debe ir aqui",
            labelacepta: "Si, acepto",
            labelarechaza: "No, rechazo" 
          }
        }

      }

    closeDialog(resultado: string) {
        this.dialogRef.close(resultado);
    }

    goToOk() {
      this.closeDialog('OK');
    }

    goToCancel() {
      this.closeDialog(null);
    }
  
   
}
