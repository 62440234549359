import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-menu-collapse',
  templateUrl: './side-menu-collapse.component.html',
  styleUrls: ['./side-menu-collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuCollapseComponent {
  @Input() text: string;
  @Input() subtitle: string;

  public collapse = false;

  toggleCollapse() {
    this.collapse = !this.collapse;
  }
}
