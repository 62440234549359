import {Injectable} from '@angular/core';
import {RequestHandlerService} from '../../../core/services/request-handler/request-handler.service';
import {environment} from '@environment';
import {ExecutiveData, ExecutiveModel} from '../../models/executive.model';
import {LastLoginModel} from '../../../features/models/client-summary/client-summary.model';
import {LastSessionModel} from '../../models/last-session.model';
import {LoginResponseModel} from '../../../features/models/login/login.model';

@Injectable({
    providedIn: 'root',
})
export class ExecutiveService {
    // Todo: revisar todo esto más adelante
    executiveModel: ExecutiveModel;
    lastLogin: LastLoginModel;
    sessionId: string;

    constructor(private requestHandler: RequestHandlerService) {
        if (sessionStorage.getItem(LocalStorageKeys.DATA_CONTENTFUL)) {
            this.executiveModel = JSON.parse(sessionStorage.getItem(LocalStorageKeys.DATA_CONTENTFUL));
        }

        if (sessionStorage.getItem(LocalStorageKeys.DATA_EXECUTIVE)) {
            this.executiveModel.executiveData = JSON.parse(sessionStorage.getItem(LocalStorageKeys.DATA_EXECUTIVE));
        }

        if (sessionStorage.getItem(LocalStorageKeys.ID_SESSION)) {
            this.sessionId = sessionStorage.getItem(LocalStorageKeys.ID_SESSION);
        }

        this.lastLogin = JSON.parse(sessionStorage.getItem(LocalStorageKeys.LAST_SESSION));
    }

    public setLastSession(lastSession: LastSessionModel) {
        sessionStorage.setItem(LocalStorageKeys.LAST_SESSION, JSON.stringify(lastSession));
        this.lastLogin = lastSession;
    }

    public setLoginData(resp: LoginResponseModel, dataContentful: ExecutiveModel, dataExecutive: ExecutiveData, sessionId) {
        sessionStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, resp.refreshToken);

        sessionStorage.setItem(LocalStorageKeys.DATA_CONTENTFUL, JSON.stringify(dataContentful));
        this.executiveModel = dataContentful;

        sessionStorage.setItem(LocalStorageKeys.DATA_EXECUTIVE, JSON.stringify(dataExecutive));
        this.executiveModel.executiveData = dataExecutive;

        sessionStorage.setItem(LocalStorageKeys.ID_SESSION, sessionId);
        this.sessionId = sessionId;
    }

    public setToken(token: string) {
        sessionStorage.setItem(LocalStorageKeys.TOKEN, token);
    }

    public async getDataExecutive(dni: string): Promise<ExecutiveData> {
        try {
            return await this.requestHandler.doPost<ExecutiveData>(`${environment.ENDPOINTS.BFF.BFF_ASESORES}/ws-ryq/data-executive`, { dni });
        } catch (error) {
            return {
                ApellidoMaternoEjecutivo: "",
                ApellidoPaternoEjecutivo: "",
                CodigoCanalEjecutivo: "0",
                CodigoEstadoServicio: "01",
                CodigoPerfil: "0",
                CodigoRedEjecutivo: "",
                CodigoSucursalEjecutivo: "0",
                DVEjecutivo: "",
                DescripcionPerfil: "",
                DetalleEstadoServicio: "TRANSACCIÓN SIN RETORNO DE DATOS",
                MailEjecutivo: "",
                MensajeEstadoServicio: "TRANSACCIÓN EXITOSA SIN INFORMACIÓN",
                NombreCanalEjecutivo: "",
                NombreEjecutivo: "",
                NombreSucursalEjecutivo: "",
                RutEjecutivo: "0",
                TelefonoEjecutivo: ""
            }
        }
    }

    /**
     * Recupera la cartea de asesores segun rut de usuario conectado
     * @param rut
     * @returns
     */
    public async getCarteraExecutive(rut: string): Promise<any> {
        return this.requestHandler.doGet<any>(`${environment.ENDPOINTS.BFF.BFF_ASESORES}/executive-customers/${rut}`);
    }
}

export enum LocalStorageKeys {
    TOKEN = 'token',
    XTOKEN = 'xtoken',
    REFRESH_TOKEN = 'refreshToken',
    ID_SESSION = 'idSesion',
    DATA_CONTENTFUL = 'dataContentful',
    DATA_EXECUTIVE = 'dataExecutive',
    LAST_SESSION = 'lastSession',
    PREVIOUS_URL = 'previousUrl',
}
