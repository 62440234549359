// Angular Core
import { Component } from '@angular/core';

// Services
import { LoginService } from 'src/app/features/services';
import { ExecutiveService, MenuService } from 'src/app/shared/services';

@Component({
    selector: 'app-navbar-account-button',
    templateUrl: './navbar-account-button.component.html',
    styleUrls: ['./navbar-account-button.component.scss'],
    providers: [MenuService],
})
export class NavbarAccountButtonComponent {
    hoverUser = false;

    activePopup = false;
    hiddenNumber= false;

    constructor(private readonly loginService: LoginService, public readonly executive: ExecutiveService, private menuService: MenuService) {}

    onLogOutSesion() {
        this.loginService.signOutSession();
    }

    getLastSesion(){
        return sessionStorage.getItem('last_session');
    }

    toggleMenuWindow() {
        this.activePopup = this.activePopup ? false : true;
        this.menuService.toogleMenuWindow();
    }

    hiddenModalMouseleave(){ 
        this.activePopup = this.activePopup ? false : true;
    }

    hidePhone(){
        this.hiddenNumber = this.hiddenNumber ? false : true;
    }

}
