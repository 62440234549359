import { Component, ViewChild, ElementRef, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
    @Input() types: string;
    @Input() sizeMaxMb: number;
    @Input() data: any;
    @Input() message: string;
    @Input() messageFile: boolean = false;

    @Output() response = new EventEmitter<any>();
    @Output() messageAlert = new EventEmitter<boolean>();

    files: any[] = [];
    disable: boolean = false;
    fileAccept: any[] = [];
    typeAccept: any[] = [];

    progress = 0;

    ngOnInit() {
        this.typeAccept = this.types.split(',');
    }

    onFileDropped($event) {
        this.messageFile = false;
        this.prepareFilesList($event);
    }

    fileBrowseHandler(files) {
        this.messageFile = false;
        this.prepareFilesList(files);
    }

    deleteFile(index: number) {
        if (this.files[index].progress < 100) {
            return;
        }
        this.progress = 0;
        this.disable = false;
        this.messageFile = false;
        this.files.splice(index, 1);
        this.messageAlert.emit(false);
        this.response.emit({ info: undefined, data: undefined });
    }

    uploadFilesSimulator(index: number) {
        this.messageFile = false;
        this.messageAlert.emit(true);
        this.disable = true;
        setTimeout(() => {
            if (index === this.files.length) {
                return;
            } else {
                const progressInterval = setInterval(() => {
                    if (this.files[index]?.progress >= 100) {
                        clearInterval(progressInterval);
                        this.uploadFilesSimulator(index + 1);
                        const reader = new FileReader();
                        reader.readAsDataURL(this.files[0]);
                        reader.onload = async () => {
                            this.response.emit({ info: this.data, data: reader.result });
                        };
                    } else {
                        this.progress += 5;
                        this.files[index].progress += 5;
                    }
                }, 200);
            }
        }, 1000);
    }

    prepareFilesList(files: Array<any>) {
        this.files = [];
        this.progress = 0;
        if (!this.validatorFile(files)) {
            this.messageFile = true;
            this.message = 'El formato del archivo no es permitido.';
        } else {
            if (this.validatorSize(files)) {
                this.messageFile = false;
                for (const item of files) {
                    item.progress = 0;
                    this.files.push(item);
                }

                this.fileDropEl.nativeElement.value = '';
                this.uploadFilesSimulator(0);
            } else {
                this.messageFile = true;
                this.message = 'El peso del archivo supera el límite permitido (5MB).';
            }
        }
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    validatorFile(files) {
        let extension = '';
        if (files.length > 0) {
            const statusFileType = files[0].type.split('/');
            const statusFileName = files[0].name.split('.');

            if (statusFileType[0] === '') {
                extension = statusFileName[statusFileName.length - 1];
            } else {
                extension = statusFileType[1];
            }

            const statusFileAccept = this.typeAccept.find((x) => x.trim().toLowerCase() === extension.trim().toLowerCase());
            if (statusFileAccept) {
                return true;
            }
        }
        return false;
    }

    validatorSize(files) {
        if (files[0].size === 0) {
            return false;
        }
        const size = this.sizeMaxMb * 1048576;

        if (files[0].size <= size) {
            return true;
        } else {
            return false;
        }
    }
}
