import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { getInitialsFromName } from '../../helpers/utils';

@Component({
    selector: 'app-initials-name[fullName]',
    templateUrl: './initials-name.component.html',
    styleUrls: ['./initials-name.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitialsNameComponent implements OnChanges {
    @Input() fullName: string;
    initials = '';

    ngOnChanges(): void {
        this.initials = getInitialsFromName(this.fullName);
    }
}
