import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mmb-titulo-pagina',
  templateUrl: './mmb-titulo-pagina.component.html',
  styleUrls: ['./mmb-titulo-pagina.component.scss']
})
export class MmbTituloPaginaComponent implements OnInit {
  isMobile: boolean = false;
    
  @Input() goBack = true;
  @Input() load = false;
  @Input() nombrecliente = '';
  @HostListener("window:resize", ["$event"])
  onResize() {
      this.isMobile = window.innerWidth < 768;        
  }
  ngOnInit(): void {
    this.onResize();

  }
}
