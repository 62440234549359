import { DataClientComponent } from './data-client/data-client.component';
import { LatestTransactionsComponent } from './latest-transactions/latest-transactions.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductsBalancesComponent } from './products-balances/products-balances.component';
import { ProfitabilityComponent } from './profitability/profitability.component';
import { TotalBalancesComponent } from './total-balances/total-balances.component';
import { TransitBalanceComponent } from './transit-balance/transit-balance.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { MovementsDetailDialogComponent } from './movements-detail-dialog/movements-detail-dialog.component';
import { OrderEntryComponent } from './order-entry/order-entry.component';
import { MainClientSummaryComponent } from './main-client-summary/main-client-summary.component';
import { InvestmentReportComponent } from './investment-report/investment-report.component';
import { ClientSummaryComponent } from '../client-summary.component';
import { PasswordsComponent } from './passwords/passwords.component';
import { ChangeProfileComponent } from './change-profile/change-profile.component';
import { SuraPasswordModalComponent } from './passwords/sura-password-modal/sura-password-modal.component';
import { CustomStepperComponent } from './custom-stepper/custom-stepper.component';
import { QuestionsProfileComponent } from './change-profile/questions-profile/questions-profile.component';
import { ItemsProfileComponent } from './change-profile/questions-profile/items-profile/items-profile.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { ResumeProfileComponent } from './change-profile/resume-profile/resume-profile.component';
import { ProfileSummaryComponent } from './profile-summary/profile-summary.component';
import { OptimusMessageComponent } from './optimus-message/optimus-message.component';
import { AssetAllocationComponent } from './asset-allocation/asset-allocation.component';
import { ClienteFallecidoMessageComponent } from './cliente-fallecido/cliente-fallecido.component'; 
export const CLIENT_SUMMARY_COMPONENTS = [
    DataClientComponent,
    LatestTransactionsComponent,
    ProductListComponent,
    ProductsBalancesComponent,
    ProfitabilityComponent,
    TotalBalancesComponent,
    TransitBalanceComponent,
    FilterDialogComponent,
    MovementsDetailDialogComponent,
    OptimusMessageComponent,
    OrderEntryComponent,
    MainClientSummaryComponent,
    ClientSummaryComponent,
    InvestmentReportComponent,
    PasswordsComponent,
    ChangeProfileComponent,
    SuraPasswordModalComponent,
    CustomStepperComponent,
    QuestionsProfileComponent,
    ItemsProfileComponent,
    FileUploadComponent,
    ResumeProfileComponent,
    ProfileSummaryComponent,
    AssetAllocationComponent,
    ClienteFallecidoMessageComponent
];

export const ENTRY_CLIENT_SUMMARY_COMPONENTS = [FilterDialogComponent, MovementsDetailDialogComponent, SuraPasswordModalComponent];
