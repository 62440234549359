import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { BreakpointObserver } from '@angular/cdk/layout';
import { debounceTime, delay, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SearchItem, SearchListComponent } from '../../../components/search-list/search-list.component';
import { Subject } from 'rxjs';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ClientRequestService } from '../../../../features/services/client-request/client-request.service';
import { ExecutiveService } from '../../../services/executive/executive.service';
import { ClientPortfolioRequestService } from '../../../../features/services/client-portfolio-request/client-portfolio-request.service';
import { ClientService, UserMessage } from '../../../../features/services/client/client.service';
import { ClientSearchModel, ClientSearchService, LoadingService, UtilsService } from 'src/app/shared/services';
import { rutOrNameValidator } from '../../../validators/rutName.validator';
import * as rutHelpers from 'rut-helpers';
import { RuteroCMS } from '../../../../shared/models/cms.model'

@Component({
  selector: 'app-navbar-search-client-portfolio',
  templateUrl: './navbar-search-client-portfolio.component.html',
  styleUrls: ['./navbar-search-client-portfolio.component.scss'],
})
export class NavbarSearchClientPortfolioComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('search') searchListComponent: SearchListComponent;
  @ViewChild('deskTopOrigin') deskTopOrigin: CdkOverlayOrigin;
  @ViewChild('mobileOrigin') mobileOrigin: CdkOverlayOrigin;

  clientSearchInput = new FormControl('', {
    validators: [rutOrNameValidator],
    updateOn: 'change',
  });
  showMobileSearch = false;
  hideDesktopSearch = false;
  currentOrigin: CdkOverlayOrigin;
  currentPosition: 'fromOrigin' | 'global' = 'fromOrigin';
  width = '100%';
  searchList: SearchItem[] = [];
  isSupervisor: boolean = false;
  isBoss: boolean = false;
  placeholderText: string = 'Buscar Cliente';
  listClientsAGF: RuteroCMS

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private breakPointObserver: BreakpointObserver,
    private router: Router,
    private clientPortfolio: ClientPortfolioRequestService,
    private clientRequest: ClientRequestService,
    private activatedRoute: ActivatedRoute,
    private executive: ExecutiveService,
    private clientService: ClientService,
    private clientSearchService: ClientSearchService,
    private loadingService: LoadingService,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {
    const executiveModel = this.executive.executiveModel;
    this.isSupervisor = executiveModel.executiveProfile.isSupervisor;
    this.isBoss = executiveModel.executiveProfile.isBoss;
    this.clientPortfolio.getExecutivePortfolio().then();

    this.clientSearchService.clientsAFG.subscribe((data: RuteroCMS) => {
      this.listClientsAGF = data;
    })

    this.clientPortfolio.clientPortfolio$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.searchList = data.map((client) => ({
        searchValue: client.NombreCliente,
        id: client.RutCliente,
      }));
    });

    this.activatedRoute.queryParamMap.pipe(takeUntil(this.unsubscribe$), delay(0)).subscribe((query) => {
      if (query.has('search')) {
        const search = query.get('search');
        if (!search) {
          this.clientSearchInput.setValue('');
        }
      }
    });

    this.hideDesktopSearch = this.formatUrl(window.location.pathname) === '/main/client-portfolio' && !this.isSupervisor && !this.isBoss;
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.hideDesktopSearch = this.formatUrl(e.url) === '/main/client-portfolio' && !this.isSupervisor && !this.isBoss;
      }
    });

    this.clientSearchInput.valueChanges
      .pipe(debounceTime(100), distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(async (value: string) => {
        const url = this.router.url.split('?')[0];
        if (!value && url === '/main/client-portfolio') {
          await this.router.navigate(['/main/client-portfolio'], { queryParams: null });
        }
      });

    if (this.isSupervisor || this.isBoss) this.placeholderText = 'Buscar por nombre o rut';
  }

  formatUrl(url: string) {
    return url.split('?')[0];
  }

  async searchClient(value: string = null) {
    this.utilsService.limpiarVariablesPershing();
    if (!value) {
      value = this.clientSearchInput?.value;
    }
    this.toggleSearch();
    this.searchListComponent?.closeOverlay();

    const searchedValue = value?.replace('.', '').replace('-', '').trim();


    if (!searchedValue || isNaN(Number(searchedValue.replace('K', '')))) {
      value = !searchedValue ? null : value;

      const client = this.searchList.filter(clientItem => clientItem.searchValue === value);
      if (client.length <= 1) {
        const isClientAGF = await this.clientSearchService.validateClientAGF(client[0]?.id, this.listClientsAGF);
        if (isClientAGF) return;
      }

      await this.router.navigate(['/main/client-portfolio'], { queryParams: { search: value } });
    } else {
      const rut = value.split('.').join('');
      const isClientAGF = await this.clientSearchService.validateClientAGF(rut, this.listClientsAGF);
      if (isClientAGF) return;

      this.searchClientByRut(value);
    }
  }

  async selectedItem(item: SearchItem) {

    const isClientAGF = await this.clientSearchService.validateClientAGF(item.id, this.listClientsAGF);
    if (isClientAGF) return;
    this.clientRequest.clientData.next(null);
    this.toggleSearch();
    this.searchListComponent?.closeOverlay();
    //Este navigate se hace para forzar el cambio de ruta, tal vez buscar una mejor solución
    await this.router.navigate(['./'], {
      skipLocationChange: true,
    });
    await this.router.navigate(['/main/client-portfolio/summary', item.id], {
      relativeTo: this.activatedRoute,
    });
  }

  toggleSearch() {
    this.showMobileSearch = !this.showMobileSearch;
    if (!this.showMobileSearch) {
      this.searchListComponent?.closeOverlay();
    }
  }

  async clearSearch() {
    this.clientSearchInput.setValue('');
    const url = this.router.url.split('?')[0];
    if (url === '/main/client-portfolio') {
      await this.searchClient('');
    } else {
      this.searchListComponent?.closeOverlay();
    }
  }

  ngAfterViewInit(): void {
    this.breakPointObserver
      .observe('(max-width: 768px)')
      .pipe(takeUntil(this.unsubscribe$), delay(0))
      .subscribe((val) => {
        if (val.matches) {
          this.currentOrigin = this.mobileOrigin;
          this.currentPosition = 'global';
        } else {
          this.currentOrigin = this.deskTopOrigin;
          this.currentPosition = 'fromOrigin';
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribe$.unsubscribe();
  }

  async searchClientByRut(value?) {
    const dni = typeof value !== 'undefined' ? value : this.clientSearchInput.value;
    this.clientSearchInput.setValue(dni);
    this.clientSearchInput.updateValueAndValidity();
    let userMessage: UserMessage;
    if (this.clientSearchInput.valid && rutHelpers.rutValidate(dni)) {
      this.loadingService.showLoading(false);
      this.clientRequest.clientData.next(null);

      this.clientSearchService
        .clientSearch(dni)
        .then(async (clientSearchModel: ClientSearchModel) => {
          if (!clientSearchModel.dataSigned) {
            userMessage = {
              isError: true,
              useMessage: 'Si el cliente es de AFP Capital debe firmar el mandato de consolidación de saldos',
              useTitle: 'Mandato AFP sin firmar:',
              userMessageType: "error"
            }
            this.clientService.searchResult.next(userMessage);

            await this.router.navigate(['/main/client-portfolio']);
            this.clientSearchInput.setValue('');
            this.loadingService.hideLoading();
            return;
          }
          this.clientRequest.clientData.next(clientSearchModel.dataClient);

          await this.router.navigate(['./'], {
            skipLocationChange: true,
          });

          await this.router.navigate(['/main/client-portfolio/summary', clientSearchModel.dni]);
          this.clientSearchInput.setValue('');
          this.toggleSearch();
        })
        .catch(async (error) => {
          if (error.code === 'notFound') {
            this.clientService.searchResult.next({
              isError: false,
              mainMessage: `Lo sentimos, no hemos encontrado un resultado para <br><b>${dni}</b><br> Inténtalo nuevamente`,
            });
            await this.router.navigate(['/main/client-portfolio']);
          }
          this.clientSearchInput.setValue('');
          this.loadingService.hideLoading();
        });
    }
  }
}
