import { Injectable } from '@angular/core';
import { getPropertyNamesContactability } from 'src/app/shared/helpers/utils';
import { BussinesLineData, BussinesLineDataCompany, LastContactabilityUpdateObs } from '../../models/client-summary/profile-summary.model';
import { BusinessLine, ClientRequestService } from '../client-request/client-request.service';

@Injectable({
  providedIn: 'root'
})
export class ClientContactabilityService {
  constructor(private clientRequestService: ClientRequestService) { }

  async getInitialAddressOptions(profileSummaryData: BussinesLineData | BussinesLineDataCompany, isCompany: boolean) {
    const { direccionesProperty, direccionProperty } = getPropertyNamesContactability(isCompany);

    let addresses = this.formatAddressData(profileSummaryData, isCompany);
    if (!addresses) return profileSummaryData;
    let comunaPromises = [];
    let ciudadesPromises = [];

    addresses.forEach((address, i) => {
      if (address.estado === '1') {
        const classifierTypeCity = address.codigoRegion;
        const classifierTypeCommune = address.codigoCiudad;
        ciudadesPromises.push(this.clientRequestService.getAddressDomain(8, classifierTypeCity));
        comunaPromises.push(this.clientRequestService.getAddressDomain(10, classifierTypeCommune));
      } else {
        ciudadesPromises.push({});
        comunaPromises.push({});
      }

    });
    const communes: any = await Promise.allSettled(comunaPromises);
    const cities: any = await Promise.allSettled(ciudadesPromises);
    addresses.forEach((address, i) => {
      address.comunas = communes[i].status === 'fulfilled' ? communes[i].value : [{ descripcionTipo: address.comuna, tipoValor: address.codigoComuna }];
      address.ciudades = cities[i].status === 'fulfilled' ? cities[i].value : [{ descripcionTipo: address.ciudad, tipoValor: address.codigoCiudad }];
      if (Array.isArray(address.comunas)) address.comunas = this.addDefaultOption(address.comunas, i, 'comuna');
      if (Array.isArray(address.ciudades)) address.ciudades = this.addDefaultOption(address.ciudades, i, 'ciudad');
    });
    profileSummaryData[direccionesProperty][direccionProperty] = addresses;
    return profileSummaryData;
  }

  formatAddressData(profileSummaryData, isCompany) {
    const { direccionesProperty, direccionProperty } = getPropertyNamesContactability(isCompany);
    let addresses = profileSummaryData[direccionesProperty][direccionProperty];
    let properties = 0;
    if (addresses) properties = Array.isArray(addresses) ? addresses.length : Object.entries(addresses).length;
    if (properties === 0) return null;

    if (!Array.isArray(addresses)) addresses = [addresses];
    return addresses;
  }

  addDefaultOption(arrayOptions: any, i: number, type: string) {
    const existFirstOptionCity = arrayOptions?.find(cityItem => cityItem.tipoValor === '0');
    if (!existFirstOptionCity) {
      arrayOptions?.unshift({
        "identificadorDominio": type === 'ciudad' ? "8" : "10",
        "tipoValor": "0",
        "descripcionTipo": "SELECCIONA...",
        "codigoHomologacion": "0"
      });
    }
    return arrayOptions;
  }

  addDefaultOptionBank(arrayOptions: any, type: string) {
    const existFirstOptionBank = arrayOptions?.find(bankItem => bankItem.tipoValor === '0');
    if (!existFirstOptionBank) {
      arrayOptions?.unshift({
        "identificadorDominio": type === 'banco' ? "16" : "18",
        "tipoValor": "0",
        "descripcionTipo": "SELECCIONA...",
        "codigoHomologacion": "0"
      });
    }
    return arrayOptions;
  }

  async getLastContactabilityUpdate(rut: string) {
    rut = rut.split('.').join('').split('-')[0];
    const updatedData: LastContactabilityUpdateObs = { ffmm: null, vida: null };

    const response = await Promise.allSettled([
        this.clientRequestService.getLastContactabilityUpdate(rut, BusinessLine.FFMM),
        this.clientRequestService.getLastContactabilityUpdate(rut, BusinessLine.VIDA)
    ]);

    response.forEach((res, index) => {
      if (res.status === 'fulfilled') {
          const data = response[index] as PromiseFulfilledResult<any>;
          if (index === 0) updatedData.ffmm = data.value;
          else updatedData.vida = data.value;
      }
    });

    this.clientRequestService.lastContactabilityUpdate.next(updatedData);
}

}
