import { InputLog } from "./InputLog";
import { TipoPersonaContratoLog } from "./models";

export class Log extends InputLog {
  SesionCliente: string;

  /* tslint:disable-next-line:variable-name */
  Tipo_dispositivo: string;

  Fecha: Date;

  at: Date;

  /* tslint:disable-next-line:variable-name */
  Host_ip: string;

  /* tslint:disable-next-line:variable-name */
  Modelo_dispositivo: string;

  /* tslint:disable-next-line:variable-name */
  Sistema_operativo: string;

  Browser: string;

  rutCliente: string;

  rutEjecutivo: string;

  rutTransaccion: string;

  rutAsesor: string;

  nombreCliente: string;

  tipoPersona: TipoPersonaContratoLog;
}

export class MiAsesor {
  ObtenerEjecutivoAsesorResult: string;
}
