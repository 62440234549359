import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-filtro-responsivo',
  templateUrl: './modal-filtro-responsivo.component.html',
  styleUrls: ['./modal-filtro-responsivo.component.scss']
})
export class ModalFiltroResponsivoComponent implements OnInit {

  @Input() title;

  constructor() { }

  ngOnInit(): void {
  }

}
