import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'UsdPipeCustom' })
export class UsdPipeCustom implements PipeTransform {
  transform(value: any, decimales: number = 2): string {
    return formatearNumeroDolares(value, decimales);
  }
}

export function formatearNumeroDolares(value: any, decimales: number): string {
  if (value != null && !isNaN(value)) {
      return 'USD $' + Intl.NumberFormat('es-CL', {maximumFractionDigits: decimales}).format(value);
  }
  return '';
}
