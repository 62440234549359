// Angular Core
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

// Moment
import * as moment from 'moment';
import { Moment } from 'moment';

// Models
import { TransactionsCategories, TransactionTableData } from '../../../../models/client-summary/transactions.model';
import { GetLastTransactionModel } from '../../../../models/client-search/client-search.model';
import { ColumnDefinition } from '@surainvestments/sura-common-ui/app/sura-ui-kit/models/table.model';
import { DataClientModel } from 'src/app/features/models';

// RxJS
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';

// Angular CDK
import { BreakpointObserver } from '@angular/cdk/layout';

// Services
import { DialogService } from '../../../../../shared/services/dialog/dialog.service';
import { ClientTransactionService } from '../../../../services/client-transaction/client-transaction.service';
import { ExcelService } from '../../../../../shared/services';

// Components
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { MovementsDetailDialogComponent, MovementsDetailDialogData } from '../movements-detail-dialog/movements-detail-dialog.component';
import { SelectItem } from '@surainvestments/sura-common-ui/app/sura-ui-kit/components/select/select.component';

// Enums
import { TypeTransactionEnum } from '../../../../models/enums/TypeTransaction.enum';

// Pipes
import { CurrencyPipeCustom } from 'src/app/shared/pipes';
import { TypeResumenEnum } from 'src/app/features/models/enums/typeResumen.enum';
import { AliasInputLogType, InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import { TracesService } from 'src/app/core/services/traces/traces.service';

@Component({
    selector: 'app-latest-transactions',
    templateUrl: './latest-transactions.component.html',
    styleUrls: ['./latest-transactions.component.scss'],
    providers: [ExcelService, CurrencyPipeCustom],
})
export class LatestTransactionsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() rut = '';

    balancesAPV: any[] = [];
    balancesNOAPV: any[] = [];

    itemsRegimen: SelectItem[] = [
        { label: 'Todos', value: null },
        { label: 'RTN', value: 'RTN' },
        { label: 'APV', value: 'APV' },
        { label: '57BIS', value: '57BIS' },
    ];

    itemsInversion: SelectItem[] = [
        { label: 'Todas', value: null },
        { label: 'Cambio de Fondos', value: 'Cambio de fondo' },
        { label: 'Inversión', value: 'Inversion' },
        { label: 'Rescate', value: 'Rescate' },
        { label: 'Suscripción de Flujo', value: 'Suscripcion de flujo' },
        { label: 'Traspaso Ingreso', value: 'Traspaso ingreso' },
        { label: 'Traspaso Egreso', value: 'Traspaso egreso' },
    ];

    itemsStates: SelectItem[] = [
        { label: 'Todas', value: null },
        { label: 'En proceso', value: 'EN PROCESO' },
        { label: 'Terminado', value: 'TERMINADO' },
        { label: 'Inicio', value: 'INICIO' },
    ];

    transactionColumns: ColumnDefinition<TransactionsCategories>[];

    dataTable: TransactionTableData[] = [];

    private searchData$: BehaviorSubject<GetLastTransactionModel>;
    private unsubscribe$ = new Subject<void>();

    loading = true;
    isSmallScreen = false;
    form: FormGroup;
    typeTransaction = TypeTransactionEnum;
    typeRegimen = TypeResumenEnum;

    constructor(
        private formBuilder: FormBuilder,
        private breakPointObserver: BreakpointObserver,
        private dialogService: DialogService,
        private clientTransaction: ClientTransactionService,
        private excelService: ExcelService,
        private currencyPipeCustom: CurrencyPipeCustom,
        private tracesService: TracesService,
    ) {
        this.form = this.formBuilder.group({
            typeRegimen: [null, []],
            typeTransaction: [null, []],
            state: [null, []],
            dateFrom: [moment().clone(), []],
            dateTo: [moment().clone(), []],
        });

        this.breakPointObserver
            .observe('(max-width: 768px)')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                this.isSmallScreen = val.matches;
            });
    }

    ngOnInit() {
        this.clientTransaction.showLatestTransactions.pipe(takeUntil(this.unsubscribe$)).subscribe( showData => {
            if(showData) this.loadInitialData();
        })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.rut && this.searchData$) {
            const dateStart = moment().clone().subtract({ month: 6 }).startOf('month');
            const dateEnd = moment().clone();
            this.searchData$.next(this.getSearchData(dateStart, dateEnd));
        }
    }

    loadInitialData() {
        this.transactionColumns = this.clientTransaction.transactionColumns;

        this.searchData$ = new BehaviorSubject<GetLastTransactionModel>(this.getSearchData(moment().clone().subtract({ month: 6 }).startOf('month'), moment().clone()));

        this.searchData$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((data) => !!data.rut),
                tap(() => (this.loading = true)),
                switchMap((data) =>
                    this.clientTransaction.getLastTransaction(data).pipe(
                        catchError((e) => {
                            this.loading = false;
                            console.error(e);
                            return of([]);
                        }),
                    ),
                ),
                tap(() => (this.loading = false)),
            )
            .subscribe((data) => {
                let result = [];
                result = data;
                const regimen = this.form.get('typeRegimen').value;
                if (regimen) {
                    const searchData = result.filter((registers) => registers.typeRegimen === regimen);
                    result = searchData;
                }
                this.dataTable = this.addMovementsAmount(result);
            });
    }

    openFilterDialog() {
        const dialogRef = this.dialogService.openDialog(FilterDialogComponent, {
            panelClass: 'full-panel',
            data: {
                formValue: this.form.value,
            },
        });

        dialogRef.afterClosed().subscribe((data: GetLastTransactionModel) => {
            if (data) {
                this.form.setValue(data);
                this.search();
            }
        });
    }

    openMovementDetailDialog(data: TransactionTableData) {
        if (!this.isSmallScreen) {
            return;
        }

        const dataDialog: MovementsDetailDialogData = {
            data: data,
            transactionDefinition: this.transactionColumns.filter((item) => item.key !== 'displayName'),
        };

        this.dialogService.openDialog(MovementsDetailDialogComponent, {
            panelClass: 'full-panel',
            data: dataDialog,
        });
    }

    search() {
        if (this.loading) {
            return;
        }
        const dateStart = this.form.get('dateFrom').value as Moment;
        const dateEnd = this.form.get('dateTo').value as Moment;
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: "Resumen Cliente",
            Operacion: "Resumen Cliente",
            Metodo: "search()",
            eventoLog: "click boton buscar (en ultimas transacciones)",
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton buscar (en ultimas transacciones) redireccionar',
            },
        };
        this.logData(
            logtemp,
            "log_negocio",
        );
        this.searchData$.next(this.getSearchData(dateStart, dateEnd));
    }

    private getSearchData(dateStart: Moment, dateEnd: Moment) {
        return {
            rut: this.rut,
            state: this.form.get('state').value,
            typeTransaction: this.form.get('typeTransaction').value,
            dateFromYYYYMMDD: dateStart?.format('yyyyMMDD'),
            dateToYYYYMMDD: dateEnd?.format('yyyyMMDD'),
        };
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();

        this.searchData$.complete();
        this.searchData$.unsubscribe();
    }

    addMovementsAmount(movements: TransactionTableData[]): TransactionTableData[] {
        let total: number = 0;
        movements.forEach((movement: any) => {
            total = 0;
            if (this.compareTypeOfMovement(movement, 'cdf')) {
                for (const t of movement.movementsDestino) {
                    total += t.monto ? t.monto : 0;
                }
                movement.monto = total;
            } else if (this.compareTypeOfMovement(movement, 'inv') || this.compareTypeOfMovement(movement, 'res')) {
                movement.movementDetails.forEach((transactions) => {
                    for (const t of transactions.movements) {
                        total += t.monto ? t.monto : 0;
                    }
                });
                movement.monto = total;
            }
        });
        return movements;
    }

    public compareTypeOfMovement(transaction, type): boolean {
        if (transaction && typeof transaction.tipoTransaccion === 'string' && typeof this.typeTransaction[type] === 'string') {
            return transaction.tipoTransaccion.trim().toLowerCase() === this.typeTransaction[type].trim().toLowerCase();
        }
        return false;
    }

    exportAsXLSX(): void {
        const dataClient: DataClientModel = JSON.parse(sessionStorage.getItem('clientData'));
        const now = new Date();
        const dateExcel = moment(now).format('DD/MM/YYYY HH:mm:ss');
        const data: any[] = [];

        data.push(
            ['Nombre Cliente', dataClient.name],
            ['RUT', dataClient.rut],
            ['Saldos al Día', dateExcel],
            ['Correo Electrónico', dataClient.clientInfo[0].text],
            ['', '', '', '', '', ''],
            ['TRANSACCIONES EN FONDOS MUTUOS'],
            ['REGIMEN', 'TIPO TRANSACCION', 'MONTO ($)', 'No. SOLICITUD', 'FECHA TERMINO', 'ESTADO'],
        );

        this.dataTable.forEach((element) => {
            data.push([element.typeRegimen, element.tipoTransaccion, element.monto, element.numeroSolicitud, element.fechaCierre, element.estado]);
        });
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: "Resumen Cliente",
            Operacion: "Resumen Cliente",
            Metodo: "exportAsXLSX()",
            eventoLog: "click boton excel (en ultimas transacciones)",
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton excel (en ultimas transacciones) redireccionar',
            },
        };
        this.logData(
            logtemp,
            "log_negocio",
        );
        const dateNameFile = moment(now).format('DDMMYYYY');
        this.excelService.exportAsExcelFile(data, `MisProductos_${dataClient.rut.replace(/[\.\-]/g, '')}_${dateNameFile}`);
    }
    async logData(log: Partial<InputLog> & Pick<InputLog, AliasInputLogType>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                ...EntradaLog,
            };
            inputLog.Salida = "-";
            inputLog.Secuencia = 0;
            inputLog.Servicio = "";
            inputLog.Tipo_sesion = "ASESOR";
            inputLog.extra = {};

            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
        } catch (error) {
            console.error("error: ", error);
        }
    }
}
