import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PrivateContainerComponent } from './private-container/private-container.component';
import { PublicContainerComponent } from './public-container/public-container.component';
import { AuthGuardService } from './shared/services/auth-guard/auth-guard.service';

const routes: Routes = [
    {
        path: 'main',
        component: PrivateContainerComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: 'client-search',
                loadChildren: () => import('./features/components/client-search/client-search.module').then((m) => m.ClientSearchPageModule),
                data: { breadcrumb: 'Buscador de clientes', icon: 'search' },
            },
            {
                path: 'cumplimiento',
                loadChildren: () => import('./features/components/compliance/compliance.module').then((m) => m.ComplianceModule),
            },
            {
                path: 'client-portfolio',
                loadChildren: () => import('./features/components/client-portfolio/client-portfolio.module').then((m) => m.ClientPortfolioModule),
                data: { breadcrumb: 'Cartera de clientes', icon: 'contacts', supervisorTitle: { breadcrumb: 'Equipo de trabajo', icon: 'groups' } },
            },
            {
                path: 'surapass',
                children: [
                    {
                        path: 'surapass-transacciones',
                        loadChildren: () => import('./features/components/surapass/consulta-transacciones/consulta-transacciones.module').then((m) => m.ConsultaTransaccionesModule),
                    },
                    {
                        path: 'surapass-transaccion/:id',
                        loadChildren: () => import('./features/components/surapass/detalle-transaccion/detalle-transaccion.module').then((m) => m.DetalleTransaccionModule),
                    },
                ],
            },
        ],
    },
    {
        path: 'login',
        component: PublicContainerComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./features/components/auth/login/login.module').then((m) => m.LoginPageModule),
            },
        ],
    },
    {
        path: 'switch',
        loadChildren: () => import('./features/components/switch/switch.module').then((m) => m.SwitchModule),
    },
    {
        path: '**',
        redirectTo: 'login',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
        }),
    ],

    exports: [RouterModule],
})
export class AppRoutingModule {}
