import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { RequestHandlerService } from 'src/app/core/services/request-handler/request-handler.service';
import { LocalStorageKeys } from '../executive/executive.service';

@Injectable({
  providedIn: 'root'
})
export class PafService {

  constructor(
    private requestHandler: RequestHandlerService
  ) { }

  async getCalculoPaf(rutCliente:string) {

    
    const token: string = sessionStorage.getItem(LocalStorageKeys.TOKEN);
    
    const headers = {
      headers: { authorization: `Bearer ${token}` },
    };
    const body = {
      rut:rutCliente,
      mostrarDetalleInstrumentos:true
    }
    
    try {
      const url = `${environment.ENDPOINTS.BFF.BFF_CLIENTBALANCES}/fondos/calculo-paf`;
      return await this.requestHandler.doPost<any>(url,body, headers);

    } catch (error) {
      throw error;
    
    }
  
  }

}
