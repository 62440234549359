import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../../shared/shared.module';
import { CompliancePageRoutingModule } from './compliance-routing.module';
import { CompliancePage } from './compliance.page';
import { MainComplianceComponent } from './components/main-compliance/main-compliance.component';
import { CdkTableModule } from '@angular/cdk/table';
import { UtilService } from './services/util.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CompliancePageRoutingModule,
    CdkTableModule,
  ],
  providers: [UtilService],
  declarations: [CompliancePage, MainComplianceComponent],
})
export class ComplianceModule {}
