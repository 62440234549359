import { ChangeDetectorRef, Component, Input, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'mmb-dropdown',
    templateUrl: './mmb-dropdown.component.html',
    styleUrls: ['./mmb-dropdown.component.scss'],
})
export class MmbDropdownComponent implements ControlValueAccessor {
    @Input() title;
    @Input() value = '';
    @Input() id;
    @Input() required = false;
    @Input() MensajeError;
    @Input() MensajeInfo = false;
    @Input() extend;
    @Input() disabled? = false;
    @Input() loading? = false;
    convalor: boolean;

    constructor(@Self() public ngControl: NgControl, private cd: ChangeDetectorRef) {
        if (this.ngControl) {
            ngControl.valueAccessor = this;
        }
    }

    writeValue(value: any): void {
        this.value = value;
        this.onChange(value);
        this.cd.markForCheck();
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.cd.markForCheck();
    }

    onChange = (valor: string) => {
        this.value = valor;
    };
    onTouched = () => {
        if (this.value) {
            this.convalor = true;
        }
    };

    onCambia($event) {
        this.writeValue($event.target.value);
    }

    getValidationCss() {
        if (!this.ngControl) return {};

        return {
            //'ng-invalid': this.ngControl.invalid,
            //'is-invalid': this.ngControl.invalid && this.ngControl.touched,
            //'ng-valid': this.ngControl.valid,
            'ng-touched': this.ngControl.touched,
            'ng-untouched': this.ngControl.untouched,
            //'ng-pristine': this.ngControl.pristine,
            'ng-dirty': this.ngControl.dirty,
        };
    }
}
