import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectItem } from '@surainvestments/sura-common-ui/app/sura-ui-kit/components/select/select.component';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent implements OnInit {
  itemsRegimen: SelectItem[] = [
    { label: 'Todas', value: null },
    { label: 'RTN', value: 'RTN' },
    { label: 'APV', value: 'APV' },
    { label: '57BIS', value: '57BIS' },
  ];
  itemsInversion: SelectItem[] = [
    { label: 'Todas', value: null },
    { label: 'Cambio de Fondos', value: 'Cambio de fondo' },
    { label: 'Inversión', value: 'Inversion' },
    { label: 'Rescate', value: 'Rescate' },
    { label: 'Suscripción de Flujo', value: 'Suscripcion de flujo' },
    { label: 'Traspaso Ingreso', value: 'Traspaso ingreso' },
    { label: 'Traspaso Egreso', value: 'Traspaso egreso' },
  ];
  itemsStates: SelectItem[] = [
    { label: 'Todas', value: null },
    { label: 'En proceso', value: 'EN PROCESO' },
    { label: 'Terminado', value: 'TERMINADO' },
    { label: 'Inicio', value: 'INICIO' },
  ];

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: FilterDialogData,
    private dialogRef: MatDialogRef<FilterDialogComponent>,
  ) {
    this.form = this.fb.group({
      typeRegimen: [null, []],
      typeTransaction: [null, []],
      state: [null, []],
      dateFrom: [moment().clone(), []],
      dateTo: [moment().clone(), []],
    });
  }

  ngOnInit() {
    this.form.setValue(this.data.formValue);
  }

  search() {
    this.dialogRef.close(this.form.value);
  }
}

export interface FilterDialogData {
  formValue: any;
}
