import { Component, Input, OnInit } from '@angular/core';
import { ClientBalanceModel } from '../../../../models/client-summary/client-summary.model';
import { ClientService } from 'src/app/features/services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-transit-balance',
  templateUrl: './transit-balance.component.html',
  styleUrls: ['./transit-balance.component.scss'],
})
export class TransitBalanceComponent implements OnInit {
  clientBalance: ClientBalanceModel[] = [];
  totalTransitAP = 0;
  listAP: ClientBalanceModel[] = [];
  listIF: ClientBalanceModel[] = [];
  totalTransitIF = 0;
  totalTransit = 0;
  unsubscribe$ = new Subject();
  constructor(private clientService: ClientService ){}
  ngOnInit() {
    /*
    Ahorro previsional
    APV Fondos Mutuos

    Inversion Financiera
    Fondos Mutuos
    Fondos Mutuos 57 Bis
     */
    this.getTransitBalances();
  }

  private getTransitBalances() {
    try {
      this.clientService
      .getTransitBalance()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
       this.totalTransit = 0;
       this.totalTransitAP = 0;
       this.totalTransitIF = 0;
           if(data){
           this.clientBalance = data;
           this.listAP = this.clientBalance.filter((data) => data.prevision === 'APV'); // Ahorro previsional
           this.listIF = this.clientBalance.filter((data) => data.prevision === 'NOAPV'); // Inversion Financiera
       
           for (const total of this.clientBalance) {
             this.totalTransit += total.amountInTransit;
           }
           for (const transitAP of this.listAP) {
             this.totalTransitAP += transitAP.amountInTransit;
           }
           for (const transitIF of this.listIF) {
             this.totalTransitIF += transitIF.amountInTransit;
           }
         }
      });
    } catch (error) {
      console.log(error);
    }
   }
}
