import {Component, Input, OnInit} from '@angular/core';
import {ClientRequestService, ClientService} from "../../../../services";
import {takeUntil} from "rxjs/operators";
import * as utils from "../../../../../shared/helpers/utils";
import {Observable, Subject} from "rxjs";
import {
  Consolidado,
  DataClientModel,
  IRespuestaConfiguracion, Nacional,
  RentabilidadCompleta,
  RentabilidadGeneral
} from "../../../../models";
import {Status} from "../../../../models/log/status";
import {InputLog} from "../../../../models/log/InputLog";
import {ConfigIndexLogStash} from "../../../../../shared/models";
import * as moment from "moment/moment";
import {ActivatedRoute, Router} from "@angular/router";
import {GtmService} from "../../../../../shared/services";
import {TracesService} from "../../../../../core/services/traces/traces.service";

@Component({
  selector: 'app-paf-profitability-card',
  templateUrl: './paf-profitability-card.component.html',
  styleUrls: ['./paf-profitability-card.component.scss']
})
export class PafProfitabilityCardComponent implements OnInit {

  @Input() profitabilityClient: IRespuestaConfiguracion

  constructor(private clientService: ClientService,
              private clientRequestService: ClientRequestService,
              private router: Router,
              private gtmService: GtmService,
              private tracesService: TracesService,
              private activateRouter: ActivatedRoute) { }

  loadingProfitability = true;
  mandatoFirmado = false;

  variables = {
    consolidado: true,
    nacional: false,
    internacional: false,
  };

  colorFijoConsolidado = 'activo';
  colorFijoNacional = 'inactivo';
  colorFijoInternacional = 'inactivo';

  datosClienteSubscripcion: Observable<DataClientModel>;
  datosCliente: DataClientModel;
  isProductPershing = false;

  rentabilidadGeneral: RentabilidadGeneral[]

  rentabilidadConsolidado: RentabilidadCompleta[];
  rentabilidadNacional: RentabilidadCompleta[];
  rentabilidadInternacional: RentabilidadCompleta[];

  private unSubscribe$ = new Subject();

  descripcionCard: string = "Los saldos no incluye los productos de AFP.";
  sinDatos: boolean = false;
  titleMessage = null
  bodyMessage = null

  async ngOnInit(): Promise<void> {
    await this.loadProfitability()
    this.validarDatosCLiente();
  }

  async loadProfitability(): Promise<void> {

    this.titleMessage = 'Rentabilidad No disponible:';
    this.bodyMessage = 'La rentabilidad está siendo revisada y por ahora no se encuentra disponible.';

    console.log(this.profitabilityClient)

    this.sinDatos = !this.profitabilityClient.datosGananciaPerdida

    this.rentabilidadGeneral = this.profitabilityClient.datosGananciaPerdida.rentabilidad as unknown as RentabilidadGeneral[]
    this.mandatoFirmado  = this.rentabilidadGeneral[0]['MANDATO_FIRMADO'];
    const rentabilidadPAF: RentabilidadGeneral = this.rentabilidadGeneral.reduce((accumulator, currentValue) => {
      let key = Object.keys(currentValue)[0];
      accumulator[key] = currentValue[key];
      return accumulator;
    }, {}) as RentabilidadGeneral;
    const consolidado: Consolidado = rentabilidadPAF.CONSOLIDADO;
    const nacional: Nacional = rentabilidadPAF.NACIONAL;
    const internacional  = rentabilidadPAF.INTERNACIONAL;
    this.rentabilidadConsolidado = consolidado.rentabilidad.filter(item => item.mostrar === true).sort((a, b) => a.orden - b.orden);
    this.rentabilidadNacional = nacional.rentabilidad.filter(item => item.mostrar === true).sort((a, b) => a.orden - b.orden);
    this.rentabilidadInternacional = internacional.rentabilidad.filter(item => item.mostrar === true).sort((a, b) => a.orden - b.orden);

    this.loadingProfitability = false;
  }

  validarOpcionActiva(){
    this.colorFijoConsolidado = this.variables.consolidado ? 'activo' : 'inactivo';
    this.colorFijoNacional = this.variables.nacional ? 'activo' : 'inactivo';
    this.colorFijoInternacional = this.variables.internacional ? 'activo' : 'inactivo';
  }

  mostrarTipoDeVariacion(tipoVariacion: string) {
    this.setVariable(tipoVariacion)
  }

  setVariable(clickedVariable) {
    if (clickedVariable in this.variables) {
      for (const variable in this.variables) {
        this.variables[variable] = false;
      }
      this.variables[clickedVariable] = true;
    } else {
      console.error('Invalid variable name:', clickedVariable);
      return;
    }
    this.validarOpcionActiva();
  }

  validarDatosCLiente(){
    this.clientRequestService.clientBalances.pipe(takeUntil(this.unSubscribe$)).subscribe((data: any) => {
      this.isProductPershing = false;
      if (data) {
        for (let prod of data) {
          if (prod.codigoCb == 'APV_PERSHING' || prod.codigoCb == 'NOAPV_PERSHING' || prod.type === 'RIA') {
            this.isProductPershing = true;
          }
        }
      }
    });
  }

  async navigateToInvestmentReport() {
    await this.logData(
        {
          Categoria: "Resumen Cliente",
          Operacion: "Resumen Cliente",
          Metodo: "navigateToInvestmentReport()",
          eventoLog: "click crear informe inversiones",
          status: Status.OK,
          Secuencia: 0,
          EntradaLog: {
            accion: 'click crear informe inversiones redireccionar',
          },
        },
        "log_negocio",
    );
    await this.router.navigate(['informe-plataforma-nacional'], {
      relativeTo: this.activateRouter,
    });

    this.gtmService.pushTagEventoInteractivo('evento-interactivo', 'Resumen Cliente', 'Click', 'Generar Informe');
  }
  async navigateToPershingReport() {
    await this.router.navigate(['informe-plataforma-internacional'], {
      relativeTo: this.activateRouter,
    });
    const logtemp: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status"> = {
      Categoria: "Resumen Cliente",
      Operacion: "Resumen Cliente",
      Metodo: "navigateToPershingReport()",
      eventoLog: "click crear informe pershing",
      status: Status.OK,
      Secuencia: 0,
      EntradaLog: {
        accion: 'click crear informe pershing redireccionar',
      },
    };
    this.logData(
        logtemp,
        "log_negocio",
    );
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.unsubscribe();
  }

  async logData(log: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status">, indexLogStash: ConfigIndexLogStash) {
    try {
      const { EntradaLog, ...logData } = log;

      let inputLog: InputLog = new InputLog();
      inputLog.DetalleAccionExtra = {};
      inputLog.Categoria = log.Categoria;
      inputLog.Operacion = log.Operacion;
      inputLog.EntradaLog = {
        rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
        fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        ...EntradaLog,
      };
      inputLog.Salida = "-";
      inputLog.Secuencia = 0;
      inputLog.Servicio = "";
      inputLog.Tipo_sesion = "ASESOR";
      inputLog.extra = {};

      inputLog = {
        ...inputLog,
        ...logData,
      };
      await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
    } catch (error) {
      console.error("error: ", error);
    }
  }

}
