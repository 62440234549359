import { Injectable } from '@angular/core';

// XLSX
import * as XLSX from 'xlsx';
import * as FileSaver from "file-saver";

// Utils
import * as utils from 'src/app/shared/helpers/utils';
import { DocumentsService } from '../documents/documents.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const BEGIN_INFO_DATA = 3
const END_INFO_DATA = 7
const BEGIN_ASSET_ALLOCATION = 6


@Injectable()
export class ExcelService {

    constructor(public documents: DocumentsService) { }

    async b64ToBlob(b64, type) {
        const response = await fetch(`data:${type};base64,${b64}`);
        const blob = await response.blob()
        return blob;
    };

    async downloadExcel(body: any) {
        try {
            const excelBase64 = await this.documents.getExcel(body)
            let blobData = await this.b64ToBlob(excelBase64.response, EXCEL_TYPE)
            FileSaver.saveAs(blobData, body?.nombreArchivo + EXCEL_EXTENSION);
            return excelBase64
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    public exportApiExcel(json: any[], excelFileName: string, esFormatoNuevo?: boolean): void {
        let payload = this.ExcelStructureToJson(json, excelFileName)
        this.downloadExcel(payload)
    }


    ExcelStructureToJson(json, excelFileName) {
        let excelName = excelFileName.split('_');
        if (excelName[0] === 'AssetAllocation') {
            return this.excelAssetAllocation(json, excelFileName);
        }

        let tituloExcel = json[1]
        let datos = []
        let counter = 0
        let informacionAdicional = this.setDatosAdicionales(json.slice(BEGIN_INFO_DATA, END_INFO_DATA))
        const tableRaw = json.slice(END_INFO_DATA)
        for (let iterator of tableRaw) {
            iterator = (iterator) ? iterator : ['']
            let paramsExcel = {
                data: iterator,
                lengthIterator: iterator.length,
                isDataAvailable: !!iterator[0],
                dataIterator: iterator[0],
                previousData: tableRaw[counter - 1] || 'primerDato'
            }

            let cellStyles = 'espacio'

            if (paramsExcel.lengthIterator == 1 && paramsExcel.isDataAvailable) {
                cellStyles = 'negrita'
            }

            if (paramsExcel.lengthIterator > 1 && paramsExcel.previousData.length == 1) {
                cellStyles = 'cabeceras'
            }

            if (paramsExcel.lengthIterator > 1 && paramsExcel.previousData.length > 1 && paramsExcel.isDataAvailable) {
                cellStyles = 'cuerpoTabla'
            }

            if (paramsExcel.lengthIterator > 1 &&
                !paramsExcel.isDataAvailable &&
                paramsExcel.data.some(valor => valor.toString().search(/total/gi) >= 0)) {
                cellStyles = 'totales'
            }
            paramsExcel['styles'] = cellStyles
            datos.push(paramsExcel)
            counter++
        }
        let cabeceras = datos.find((value) => value.styles == 'cabeceras')
        let cuerpoTabla = datos.find((value) => value.styles == 'cuerpoTabla')
        let cabecerasInfo = cabeceras.data.map((value, indice) => ({ tipo: typeof (cuerpoTabla.data[indice]), nombre: value }))
        return { datos, cabeceras: cabecerasInfo, informacionAdicional, nombreArchivo: excelFileName, tituloExcel }
    }

    excelAssetAllocation(json, excelFileName) {
        let datos = []
        let styles = 'negrita'
        for (let index = 0; index < 4; index++) {
            datos.push({
                data: json[index],
                styles: styles
            })
        }
        datos.push({
            data: json[5],
            styles: 'cabeceras'
        })

        const tableRaw = json.slice(BEGIN_ASSET_ALLOCATION)
        for (let iterator of tableRaw) {
            datos.push({
                data: iterator,
                styles: 'cuerpoTabla'
            })
        }

        return { datos, nombreArchivo: excelFileName }
    }

    private setDatosAdicionales(datosAdicionalesRaw) {
        let datosAdicionales = {}
        for (const iterator of datosAdicionalesRaw) {
            if (iterator[0].toUpperCase().search('Nombre Cliente') == 0) {
                datosAdicionales[iterator[0]] = JSON.parse(sessionStorage.getItem("clientData")).name;
            } else {
                datosAdicionales[iterator[0]] = iterator[1]
            }
        }
        return datosAdicionales
    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        /* merge cells A1:B1 */
        const merge1 = { s: { r: 5, c: 0 }, e: { r: 5, c: 1 } };
        const merge2 = { s: { r: 5, c: 2 }, e: { r: 5, c: 3 } };
        const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);

        if (!worksheet['!merges']) {
            worksheet['!merges'] = [];
        }

        worksheet['!merges'].push(merge1);
        worksheet['!merges'].push(merge2);

        worksheet.A1.s = {
            bgColor: 'red',
        };

        const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'base64' });

        utils.downloadFile(excelBuffer, excelFileName + EXCEL_EXTENSION, EXCEL_TYPE);
    }

}
