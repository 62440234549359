import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../../features/models/enums/currency.enum';
import { CountryCode } from '../../features/models/enums/countryCode.enum';

@Pipe({ name: 'currencyPipeCustom' })
export class CurrencyPipeCustom implements PipeTransform {
  transform(value: any, currencyCode = 'CL'): string {
    return numberToCurrency(value, currencyCode);
  }
}

export function numberToCurrency(value: any, currencyCode = 'CL'): string {
  if (value != null && !isNaN(value)) {
    if (currencyCode === CountryCode.CL) {
      return Currency[currencyCode] + Intl.NumberFormat('de-DE').format(value).toString().split(',')[0];
    }
  }
  return '';
}
