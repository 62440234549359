import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ClpPipeCustom' })
export class ClpPipeCustom implements PipeTransform {
  transform(value: any): string {
    return formatearNumeroPesos(value);
  }
}

export function formatearNumeroPesos(value: any): string {
  if (value != null && !isNaN(value)) {
      if (Number.isInteger(value)) {
        return '$' + Intl.NumberFormat('es-CL', {maximumFractionDigits: 0}).format(value);
      } else {
        return '$' + Intl.NumberFormat('es-CL', {maximumFractionDigits: 2}).format(value);
      }
  }
  return '';
}
