import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SideButtonComponent } from './side-button/side-button.component';
import { SideMenuCollapseComponent } from './side-menu-collapse/side-menu-collapse.component';
import { NavbarAccountButtonComponent } from './navbar-account-button/navbar-account-button.component';
import { NavbarButtonNotifactionsComponent } from './navbar-button-notifactions/navbar-button-notifactions.component';
import { NavbarSearchCustomersComponent } from './navbar-search-customers/navbar-search-customers.component';

export const menuComponents = [
  SidebarComponent,
  NavbarComponent,
  SideButtonComponent,
  SideMenuCollapseComponent,
  NavbarAccountButtonComponent,
  NavbarButtonNotifactionsComponent,
  NavbarSearchCustomersComponent,
];
