import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'mmb-card-detalle-colapse',
  templateUrl: './mmb-card-detalle-colapse.component.html',
  styleUrls: ['./mmb-card-detalle-colapse.component.scss'],
})
export class MmbCardDetalleColapseComponent implements OnChanges {
  @Output() tooglechange = new EventEmitter<boolean>();
  @Input() margen: boolean = true;
  @Input() collapsable: boolean;
  @Input() title: string;
  @Input() icon: string;
  @Input() header: string;
  @Input() bgcolor = '#ffffff';
  @Input() toggle;
  @Input() sync = false;
  @Input() total;

  @Input() noBoxShadow ?: boolean;

  GetVisible() {
    if (this.collapsable) {
      return this.toggle;
    }
    return true;
  }

  Toggle() {
    this.toggle = !this.toggle;
    this.tooglechange.emit(this.toggle);
  }

  ngOnChanges(ss: SimpleChanges) {
    if (ss.margen) {
      this.margen = ss.margen.currentValue;
    }
    if (ss.collapsable) {
      this.collapsable = ss.collapsable.currentValue;
    }
    if (ss.title) {
      this.title = ss.title.currentValue;
    }
    if (ss.header) {
      this.header = ss.header.currentValue;
    }
    if (ss.icon) {
      this.icon = ss.icon.currentValue;
    }
  }

}
