import { MenuItemModel } from '../../../models/menu-item.model';
import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Input() isCollapsed = false;
  @Input() menuItems$: Observable<MenuItemModel[]>;

  constructor(public readonly router: Router) {}
}
