import { Component, Input } from '@angular/core';
import { OrdenesPershingService } from 'src/app/features/services/ordenes/pershing/ordenes.pershing.service';
import { DialogService } from 'src/app/shared/services';
import { ModalGenericoOrdenes, SolicitudPershingJson } from '../../../../../../models/ordenes/pershing/orden.modelo';
import { ConfirmaModalComponentPershing } from '../confirma-modal/confirma-modal.component';

@Component({
    selector: 'app-detalle-ordenes-ingresadas',
    templateUrl: './detalle-ordenes-ingresadas.component.html',
    styleUrls: ['./detalle-ordenes-ingresadas.component.scss'],
})
export class DetalleOrdenesIngresadasComponent {

    //solicitud general
    @Input() esperandoMultipass: boolean;
    @Input() solicitud: SolicitudPershingJson;

    constructor(private ordenesService: OrdenesPershingService, private dialogService: DialogService) {}

    //filtra los instrumentos
    filtrarInstrumentos(instrumentos, origendestino){
        return instrumentos.filter(x => x['origen-destino'] == origendestino);
    }

    //elininar una orden (compra, venta, o cambio de fondos)
    goToEliminarOrden(orden) {
        if (this.esperandoMultipass) return;
        
        //configura el modal para ejecutar
        let optionsMessage: ModalGenericoOrdenes = {
            titulo: '¿ Estás seguro de eliminar Orden ?',
            mensaje: 'Una vez eliminada no se podrá recuperar y deberás ingresarla nuevamente.',
            labelacepta: 'Si, la quiero eliminar',
            labelarechaza: 'No, La quiero conservar',
        };

        const config = {
            panelClass: ['modal-confirmacion-box'],
            disableClose: false,
            data: { optionsMessage },
        };

        //llama al modal
        let dialogRef = this.dialogService.openDialog(ConfirmaModalComponentPershing, config);

        //evaua el resultado del modal
        dialogRef
        .afterClosed()
        .subscribe((data: any) => {
            if (data != null && data === 'OK') {
                
                //se elimina la orden seleccionada
                this.solicitud.ordenes.splice(this.solicitud.ordenes.findIndex(item => item.id === orden.id), 1)

                 
                //actualiza la solicitud con los datos incorporados
                sessionStorage.setItem(this.ordenesService.STORAGE_PERSHING_CARRO_FINAL, JSON.stringify(this.solicitud));


            }
        });

      


    }
}
