import { Component } from '@angular/core';

@Component({
  selector: 'app-public-container',
  templateUrl: './public-container.component.html',
  styleUrls: ['./public-container.component.scss'],
})
export class PublicContainerComponent {

}
