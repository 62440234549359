import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.scss'],
})
export class UserMessageComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() message: string;
  @Input() messageType: messageType = 'info';
  @Input() showError = false;
  @Input() showCloseButton = true;
  @Output() closeButton = new EventEmitter();

  htmlMessage: SafeHtml;
  constructor(private domSanitizer: DomSanitizer) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.htmlMessage = this.domSanitizer.bypassSecurityTrustHtml(this.message);
  }

  ngOnInit(): void {
    this.htmlMessage = this.domSanitizer.bypassSecurityTrustHtml(this.message);
  }

  get icon() {
    if (this.messageType === 'warning') {
      return 'error';
    }
    return this.messageType;
  }

  onCloseButton() {
    this.showError = !this.showError;
    this.closeButton.emit();
  }
}

export type messageType = 'error' | 'info' | 'warning';
