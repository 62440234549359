import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { isArray } from 'rxjs/internal-compatibility';
import {
    BussinesLineData,
    Email,
    Emails,
    Telefono,
    Telefonos,
    Direccion,
    BussinesLineDataCompany,
    EmailsEmpresa,
    TelefonosEmpresa,
    AddressDomain,
    UpdateContactabilityDTO,
    ValidationBusinessAddress,
    ProfileSummaryData,
    ResumenCliente,
} from '../../../../../../features/models/client-summary/profile-summary.model';
import { ClientRequestService } from '../../../../../../features/services';
import { LoadingService } from '../../../../../../shared/services';
import { validatePhoneNumber, validateVirtualBank, onlyNumbers, validateEmail, validateAddress, getPropertyNamesContactability, validateEmptyAddress, getSeparatePhoneNumberComposition } from '../../../../../../shared/helpers/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AliasInputLogType, InputLog } from 'src/app/features/models/log/InputLog';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { Status } from 'src/app/features/models/log/status';

@Component({
    selector: 'app-tab-content',
    templateUrl: './tab-content.component.html',
    styleUrls: ['./tab-content.component.scss'],
})
export class TabContentComponent implements OnChanges {
    data: any;
    @Input() addressDomainData: AddressDomain[];
    @Input() currentTab: number = 0;
    @Input() layoutBanks: boolean = false;
    @Input() tabNumber: number = 0;
    @Input() isCompany: boolean = false;
    @Input() visibleDisabled: boolean = true;
    @Input() isVida: boolean = false;
    @Input() isCb: boolean = false;
    @Input() businessAddressErrorList: any = [];
    @Input() banksErrorList: any = [];
    @Input() emailsErrorList: any = [];
    @Input() phonesErrorList: any = [];
    @Input() clientInfo: ResumenCliente;

    @Output() addVoto = new EventEmitter<boolean>();
    @Output() dataToSave = new EventEmitter<UpdateContactabilityDTO>();
    @Output() bussinesLine = new EventEmitter<string>();
    @Output() maxLengthFields = new EventEmitter<any>();
    @Output() existValidationErrors = new EventEmitter<boolean>();
    @Output() backupData = new EventEmitter<BussinesLineData | BussinesLineDataCompany>();

    @Input() showData: boolean = false;
    dataRecord: any;
    emails: Email[];
    phones: Telefono[];
    addresses: Direccion[];
    editAddresses: Direccion[];
    existValidAddress: boolean;
    existValidBankAccounts: boolean;
    @Input() buttonEnabled: boolean;
    formPerfil: FormGroup;

    disbledDropdown = true;

    regiones: any[] = [];
    ciudades: any;
    comunas: any[] = [];
    regionesComercial: any[] = [];
    ciudadesComercial: any;
    comunasComercial: any[] = [];

    bancos: any[] = [];
    tipoCuenta: any[] = [];
    idCiudad: any;
    ciudadesArray: any = [];
    comunaArray: any = [];

    valorComuna: any;
    banco: any;
    cuenta: any;
    numeroCuenta: number;
    disabled: boolean;

    recordBody: any;
    modifyData: boolean;
    emailValid: any;
    phoneValid: Telefono[];
    buttonDeleteBanckDisabled: boolean = false;
    buttonAddBanckDisabled: boolean = false;

    private unSubscribe$ = new Subject();
    buttonaddBanco: boolean = false;

    error: boolean = false;
    emailNoDataError: boolean = false;
    phoneMobileNoDataError: boolean = false;
    bankNoDataError: boolean = false;
    bankNoDataErrorMessage: string;
    message: string = 'Este campo es requerido';
    messageNumber: string = 'Este campo es requerido';
    maxLength: number = 15;
    maxLengthLow: number = 10;
    maxLengthMid: number = 40;
    maxLengthHigh: number = 80;
    currentAddresses: any;
    currentAccount: any;
    businessLine: string = 'Cb';
    currentlyNoAccounts: boolean = false;
    backup: any;

    bancoAdd = {
        id: '0',
        idCuenta: '0',
        idBanco: '0',
        banco: 'SELECCIONA...',
        numero: '',
        idTipo: '0',
        descripcionTipo: 'SELECCIONA...',
        estado: '1',
        principal: '0',
        idMoneda: '1',
        moneda: 'CLP',
        idOptimus: '0',
        dominio: {
            identificadorDominio: '16',
            tipoValor: '0',
            descripcionTipo: 'SELECCIONA...',
            codigoHomologacion: '0',
        },
        dominioMoneda: {
            identificadorDominio: '23',
            tipoValor: '1',
            descripcionTipo: 'CLP',
            codigoHomologacion: '0',
        },
        error: [],
    };
    constructor(
        private clientRequestService: ClientRequestService, 
        public loadingService: LoadingService, 
        private tracesService: TracesService,

        ) {}

    ngOnInit() {
        this.formPerfil = new FormGroup({
            firstName: new FormControl(),
            numeroCuenta: new FormControl(),
            bancoName: new FormControl(),
            valor1: new FormControl(),
        });
        this.businessLine = this.isVida ? 'Vida' : 'Cb';
        this.clientRequestService.contactabilityData.pipe(takeUntil(this.unSubscribe$)).subscribe((resp: ProfileSummaryData) => {
            if (this.isVida) {
                this.backup = resp.vida;
                this.data = resp.vida;
            } else {
                this.backup = resp.cb;
                this.data = resp.cb;
            }

            if (this.data) this.setInitData();
        });
    }

    ngOnChanges() {
        this.comunas = [];
        this.comunasComercial = [];
        this.comunaArray = [];
        this.ciudades = [];
        this.ciudadesComercial = [];
        this.ciudadesArray = [];
    }

    ngOnDestroy(): void {
        this.unSubscribe$.next();
        this.unSubscribe$.unsubscribe();
    }

    async setInitData() {
        this.backupData.emit(this.backup);
        if (this.isCompany) {
            const data: BussinesLineDataCompany = <BussinesLineDataCompany>this.data;
            this.addBusinessAddress();
            this.addAccountBank();
            sessionStorage.setItem(`misDatosBkp${this.businessLine}`, JSON.stringify(data));
            this.recordBody = JSON.parse(sessionStorage.getItem(`misDatosBkp${this.businessLine}`));
            this.initData(data);
        } else {
            const data: BussinesLineData = <BussinesLineData>this.data;
            this.addBusinessAddress();
            this.addAccountBank();
            sessionStorage.setItem(`misDatosBkp${this.businessLine}`, JSON.stringify(data));
            this.recordBody = JSON.parse(sessionStorage.getItem(`misDatosBkp${this.businessLine}`));
            this.initData(data);
        }
        this.clearErrors();
        this.updateDataToSave();
    }

    getRegionFromString(str, i) {
        const obj = this.addressDomainData[i].data.filter((item) => {
            return item.descripcionTipo.toUpperCase() === str.toUpperCase();
        });
        return obj.length === 1 ? obj[0] : {};
    }

    getRegionFromId(id, i) {
        const obj = this.addressDomainData[i].data.filter((item) => {
            return item.tipoValor.toUpperCase() === id.toUpperCase();
        });
        return obj.length === 1 ? obj[0] : {};
    }

    async selectCiudad(event, amount, i, arrayGroup: string, arrayName: string, type: string) {
        const cityArrayName = type === 'comercial' ? 'ciudadesComercial' : 'ciudades';
        this[cityArrayName] = [];
        for (let index = 0; index < amount; index++) {
            if (!this.ciudadesArray[index]) {
                this.ciudadesArray.push({
                    id: index,
                    data: [],
                });
            }
        }
        if (this.ciudadesArray[i].id === i) {
            this.ciudadesArray[i].data = [];
        }
        let tipoClasificador: any = event;
        this[cityArrayName] = await this.getCities(tipoClasificador, i, amount);
        this.addDefaultOptionCity(cityArrayName, i);
        this[cityArrayName][i].data = this.sortArray('descripcionTipo', this[cityArrayName][i].data);
        if (Array.isArray(this[cityArrayName][i].data)) this.addresses[i].ciudades = this[cityArrayName][i].data;

        // agrega nuevo valor de region
        this.agregarData(event, i, arrayGroup, arrayName, tipoClasificador);
        this.validateField(event, 'address', i, 'region');

        //agrega valor de ciudad y comuna
        await this.selectComuna('0', amount, i, 'direccion', 'comuna', type);
    }

    initArrayCities(amount: number) {
        for (let index = 0; index < amount; index++) {
            if (!this.ciudadesArray[index]) {
                this.ciudadesArray.push({
                    id: index,
                    data: [],
                });
            }
        }
    }

    async getCities(tipoClasificador: string, i: any, amount: number) {
        let response: any = [];
        if (tipoClasificador !== '0') response = await Promise.allSettled([this.clientRequestService.getAddressDomain(8, tipoClasificador)]);
        if (this.ciudadesArray.length === 0) this.initArrayCities(amount);
        let data: any = response[0]?.value;
        if (data && data.length > 0) {
            this.ciudadesArray.forEach((ciudad) => {
                data.forEach((element) => {
                    if (ciudad.id === i) {
                        this.ciudadesArray[i].data.push(element);
                    }
                });
            });
        }
        var uniqueArray = this.removeDuplicates(this.ciudadesArray[i].data, 'tipoValor');
        this.ciudadesArray[i].data = uniqueArray;
        return this.ciudadesArray;
    }

    async selectComuna(event, amount, i, arrayGroup: string, arrayName: string, type: string, current?: any) {
        let cityArrayName = 'ciudades';
        let communeArrayName = 'comunas';

        if (type === 'comercial') {
            cityArrayName = 'ciudadesComercial';
            communeArrayName = 'comunasComercial';
        }

        this[communeArrayName] = [];
        this.comunaArray = [];

        const address: any = this.addresses[i];
        if (!this[cityArrayName][i]) {
            this[cityArrayName].push({
                id: i,
                data: address.ciudades,
            });
        }
        for (let index = 0; index < amount; index++) {
            if (!this.comunaArray[index]) {
                this.comunaArray.push({
                    id: index,
                    data: [],
                });
            }
        }
        if (this.comunaArray[i].id === i) {
            this.comunaArray[i].data = [];
        }

        // agrega nuevo valor de ciudad
        let value = this[cityArrayName][i]?.data ? this[cityArrayName][i].data.filter((ciudad) => ciudad.tipoValor === event) : [{ descripcionTipo: current?.codigoCiudad }];
        if (value.length > 0 && arrayName === 'ciudad') {
            this.agregarData(value[0].descripcionTipo, i, arrayGroup, arrayName, event);
        }
        this.validateField(event, 'address', i, 'ciudad');

        // agrega nuevo valor de comuna
        this[communeArrayName] = await this.getCommunes(event, arrayName, i, current);
        this.addDefaultOptionCommune(communeArrayName, i);
        this[communeArrayName] = this.sortArray('descripcionTipo', this[communeArrayName]);
        if (Array.isArray(this[communeArrayName][i].data)) this.addresses[i].comunas = this[communeArrayName][i].data;

        if (this[communeArrayName].length >= 1) {
            this.agregarData(this[communeArrayName][i]?.data[0]?.descripcionTipo ?? '', i, arrayGroup, 'comuna', this[communeArrayName][i]?.data[0]?.tipoValor ?? '0');
        }
        this.validateField(this[communeArrayName][i]?.data[0]?.tipoValor ?? '0', 'address', i, 'comuna');
        return this[communeArrayName];
    }

    async getCommunes(event: string, arrayName: string, i, current?: any) {
        const currentValueType = current?.codigoCiudad;
        const classifierType = isNaN(Number(event)) ? currentValueType : event;
        let response: any = [];
        if (classifierType !== '0') response = await Promise.allSettled([this.clientRequestService.getAddressDomain(10, classifierType)]);
        let data: any = response[0]?.value;
        if (data && data.length > 0) {
            this.comunaArray.forEach((comuna) => {
                if (comuna.id === i) {
                    data.forEach((element) => {
                        if (!this.comunaArray[i].data.includes(element)) this.comunaArray[i].data.push(element);
                    });
                }
            });
        }
        var uniqueArray = this.removeDuplicates(this.comunaArray[i]?.data, 'tipoValor');
        this.comunaArray[i].data = uniqueArray;
        return this.comunaArray;
    }

    async selectComunaMultiple(event, amount, i, arrayGroup: string, arrayName: string, type: string) {
        const communeArrayName = type === 'comercial' ? 'comunasComercial' : 'comunas';
        const addresses: any = this.addresses[i];
        if (!this[communeArrayName][i]) {
            this[communeArrayName].splice(i, 0, {
                id: i,
                data: addresses.comunas || [],
            });
        }
        let value = this[communeArrayName][i]?.data.filter((ciudad) => ciudad.tipoValor === event);
        if (!value) return;
        this.agregarData(value[0]?.descripcionTipo, i, arrayGroup, arrayName, value[0]?.tipoValor);
        this.validateField(value[0]?.tipoValor ?? '0', 'address', i, 'comuna');
    }

    idSelection(description, id) {
        return description + '-' + id;
    }

    removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject = {};

        for (var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    agregarBanco() {
        let countBanco: number = 0;
        const newId = this.recordBody.cuentasCorrientes.cuentaCorriente.length;
        this.recordBody.cuentasCorrientes.cuentaCorriente.forEach((banco) => {
            if (banco.estado !== '0' && !this.isVida) {
                countBanco++;
            }
        });

        if (countBanco < 3 && !this.isVida) {
            const newAccount = { ...this.bancoAdd, id: `new-${newId}` };
            this.recordBody.cuentasCorrientes.cuentaCorriente.push(newAccount);
            this.buttonDeleteBanckDisabled = false;
            countBanco++;
        }
        if (countBanco === 3 && !this.isVida) {
            this.buttonAddBanckDisabled = true;
        }
        this.updateDataToSave();
    }

    removeBanco(id) {
        let countBanco: number = 0;

        this.recordBody.cuentasCorrientes.cuentaCorriente.forEach((banco) => {
            if (banco.estado === '1' && !this.isVida) {
                countBanco++;
            }
        });
        if (countBanco < 2 && !this.isVida) {
            this.buttonDeleteBanckDisabled = true;
        }

        if (countBanco > 1 && !this.isVida) {
            this.buttonDeleteBanckDisabled = false;
            this.recordBody.cuentasCorrientes.cuentaCorriente = this.recordBody.cuentasCorrientes.cuentaCorriente.map((item) => {
                if (item.id === id) {
                    item.error = [];
                    item.estado = '0';
                }
                return item;
            });
            countBanco--;
            this.buttonAddBanckDisabled = false;
        }

        if (countBanco === 1 && !this.isVida) {
            this.buttonDeleteBanckDisabled = true;
        }
        this.countErrors();
        this.updateDataToSave();
    }

    agregarData(event: any, i: number, arrayGroup: string, arrayName: string, id?: string) {
        switch (arrayGroup) {
            case 'correo':
                this.addDataEmail(event, i, arrayName);
                break;
            case 'telefono':
                this.addDataPhone(event, i, arrayName);
                break;
            case 'banco':
                this.addDataBank(event, i, arrayName);
                break;
            case 'direccion':
                this.addDataAddress(event, i, arrayName, id);
                break;
            default:
                break;
        }
        this.updateDataToSave();
    }

    addDataEmail(event: any, i: number, arrayName: string) {
        const { emailsProperty, emailProperty } = getPropertyNamesContactability(this.isCompany);
        for (let index = 0; index < this.recordBody[emailsProperty][emailProperty].length; index++) {
            const element = this.recordBody[emailsProperty][emailProperty][index];
            if (i === index) {
                element[arrayName] = event;
            }
        }
    }

    addDataPhone(event: any, i: number, arrayName: string) {
        const { telefonosProperty, telefonoProperty } = getPropertyNamesContactability(this.isCompany);
        for (let index = 0; index < this.recordBody[telefonosProperty][telefonoProperty].length; index++) {
            const element = this.recordBody[telefonosProperty][telefonoProperty][index];
            if (i === index) {
                const { codigoPais, codigoArea, numero } = getSeparatePhoneNumberComposition(event);
                element.codigoPais = codigoPais;
                element.codigoArea = codigoArea;
                element.numero = numero;
                element[arrayName] = event;
            }
        }
    }

    addDataBank(event: any, i: number, arrayName: string) {
        for (let index = 0; index < this.recordBody.cuentasCorrientes.cuentaCorriente.length; index++) {
            const element = this.recordBody.cuentasCorrientes.cuentaCorriente[index];
            if (i === index) {
                let idBanco = [{ tipoValor: '0', identificadorDominio: '(SIN BANCO)', codigoHomologacion: '0' }];
                if (event !== '(SIN BANCO)') idBanco = this.addressDomainData[5].data.filter((banco) => banco.descripcionTipo === event);
                switch (arrayName) {
                    case 'banco':
                        element[arrayName] = event;
                        element.idBanco = idBanco[0].tipoValor;
                        element.dominio = {
                            identificadorDominio: idBanco[0].identificadorDominio,
                            tipoValor: idBanco[0].tipoValor,
                            descripcionTipo: event,
                            codigoHomologacion: idBanco[0].codigoHomologacion,
                        };
                        break;
                    case 'descripcionTipo':
                        const accountType = this.addressDomainData[6].data.find((banco) => banco.tipoValor === event);
                        element[arrayName] = accountType.descripcionTipo;
                        element.idTipo = accountType.tipoValor;

                        break;
                    case 'numero':
                        element[arrayName] = event;
                        break;
                    case 'moneda':
                        element[arrayName] = event;
                        let dataMoneda = this.addressDomainData[7].data.filter((moneda) => moneda.descripcionTipo === event);
                        element.idMoneda = dataMoneda[0].tipoValor;
                        element.dominioMoneda = {
                            identificadorDominio: dataMoneda[0].identificadorDominio,
                            tipoValor: dataMoneda[0].tipoValor,
                            descripcionTipo: dataMoneda[0].descripcionTipo,
                            codigoHomologacion: dataMoneda[0].codigoHomologacion,
                        };

                        break;
                    default:
                        break;
                }
            }
        }
    }

    addDataAddress(event: any, i: number, arrayName: string, id?: string) {
        const { direccionesProperty, direccionProperty } = getPropertyNamesContactability(this.isCompany);
        if (Array.isArray(this.recordBody[direccionesProperty][direccionProperty])) {
            const element = this.recordBody[direccionesProperty][direccionProperty][i];
            switch (arrayName) {
                case 'region':
                    element.region = this.regiones.find((region) => region.tipoValor === event)?.descripcionTipo || '';
                    element.codigoRegion = id;
                    break;
                case 'ciudad':
                    element.ciudad = event;
                    element.codigoCiudad = id;
                    this.addresses[i].codigoCiudad = id;
                    break;
                case 'comuna':
                    element.comuna = event;
                    element.codigoComuna = id;
                    this.addresses[i].codigoComuna = id;
                    break;
                case 'calle':
                    element.calle = event;
                    break;
                case 'numero':
                    element.numeroCalle = event;
                    break;
                case 'dvp':
                    element.numeroDepto = event;
                    break;
                default:
                    break;
            }
            this.recordBody[direccionesProperty][direccionProperty][i] = element;
        } else {
            switch (arrayName) {
                case 'region':
                    this.recordBody[direccionesProperty][direccionProperty].codigoRegion = id;
                    this.recordBody[direccionesProperty][direccionProperty].region = event;
                    break;
                case 'ciudad':
                    this.recordBody[direccionesProperty][direccionProperty].codigoCiudad = id;
                    this.recordBody[direccionesProperty][direccionProperty].ciudad = event;
                    break;
                case 'comuna':
                    this.recordBody[direccionesProperty][direccionProperty].codigoComuna = id;
                    this.recordBody[direccionesProperty][direccionProperty].comuna = event;
                    break;
                case 'calle':
                    this.recordBody[direccionesProperty][direccionProperty].calle = event;
                    break;
                case 'numero':
                    this.recordBody[direccionesProperty][direccionProperty].numeroCalle = event;
                    break;
                case 'dvp':
                    this.recordBody[direccionesProperty][direccionProperty].numeroDepto = event;
                    break;

                default:
                    break;
            }
        }
    }

    getTypeEmail(type) {
        let description = '';
        switch (type) {
            case '1':
                description = 'Particular';
                break;
            case '2':
                description = 'Comercial';
                break;
        }
        return description;
    }

    getTypeOfPhone(des: string): string {
        if (des === '1') {
            return 'Fijo';
        } else {
            return 'Móvil';
        }
    }

    getTypeBankAccount(type: string): string {
        let description = '';
        switch (type) {
            case 'CUCO':
                description = 'Corriente';
                break;
            case 'CUAH':
                description = 'Ahorro';
                break;
            case 'CUVI':
                description = 'Vista';
                break;
            default:
                description = type;
                break;
        }
        return description;
    }

    getTypeBankId(name: string): string {
        let description = '';
        switch (name) {
            case 'Corriente':
                description = 'CUCO';
                break;
            case 'Ahorro':
                description = 'CUAH';
                break;
            case 'Vista':
                description = 'CUVI';
                break;
        }
        return description;
    }

    validateEmptyField(field: any): boolean {
        if (!field) return true;

        if (typeof field === 'object') {
            if (Object.keys(field).length === 0) return true;
            else return false;
        } else if (field.trim() === '') return true;

        return false;
    }

    filterEmails(dataEmail: Emails | EmailsEmpresa) {
        const { emailsProperty, emailProperty } = getPropertyNamesContactability(this.isCompany);
        let { emails, newEmail, type } = this.formatEmailData(dataEmail);
        const emailValid = emails?.filter((email) => email && email.estadoEmail === '1' && email.tipoCorreo === type) ?? [];
        if (emailValid?.length === 0) {
            const noValidEmails: any[] = emails.filter((item) => item.estadoEmail === '0' && item.tipoCorreo === type);
            if (noValidEmails.length === 0) {
                if (emails.length === 0) {
                    emails = [newEmail];
                } else {
                    emails.push(newEmail);
                }
            } else {
                const index = emails.findIndex((item) => item.estadoEmail === '0' && item.tipoCorreo === type);
                newEmail = { ...newEmail, idOptimus: emails[index].idOptimus };
                emails[index] = newEmail;
            }
        }
        this.emails = emails;
        this.data[emailsProperty][emailProperty] = JSON.parse(JSON.stringify(emails));
        this.recordBody[emailsProperty][emailProperty] = JSON.parse(JSON.stringify(emails));
        this.emailValid = emails?.filter((email) => email && email.estadoEmail === '1' && email.tipoCorreo === type);
    }

    formatEmailData(dataEmail) {
        let data: any = dataEmail;
        if (!isArray(data) && Object.keys(data).length === 0) data = this.isCompany ? { emailEmpresa: [] } : { email: [] };
        const { emailProperty } = getPropertyNamesContactability(this.isCompany);
        const type = this.isCompany ? '2' : '1';
        let newEmail: any = {
            correo: '',
            estadoEmail: '1',
            idOptimus: '0',
            tipoCorreo: type,
            error: false,
        };

        const clientEmail = data[emailProperty];
        const emails = isArray(clientEmail) ? clientEmail : [clientEmail];
        return { emails, newEmail, type };
    }

    filterPhones(data: Telefonos | TelefonosEmpresa) {
        const clientPhone = 'telefono' in data ? data.telefono : data.telefonoEmpresa;
        const { telefonosProperty, telefonoProperty } = getPropertyNamesContactability(this.isCompany);
        if (clientPhone) {
            this.phones = isArray(clientPhone) ? clientPhone : [clientPhone];
        } else {
            this.phones = [];
            this.setNewPhone();
        }
        this.phoneValid = this.phones?.filter((phone) => phone && phone.estado === '1');
        if (this.phoneValid && this.phoneValid.length === 0) {
            this.setNewPhone();
            this.phoneValid = this.phones?.filter((phone) => phone && phone.estado === '1');
        }
        this.data[telefonosProperty][telefonoProperty] = JSON.parse(JSON.stringify(this.phones));
    }

    setNewPhone() {
        const { telefonosProperty, telefonoProperty } = getPropertyNamesContactability(this.isCompany);
        let newPhone: any = {
            codigoArea: '',
            codigoPais: '56',
            estado: '1',
            idOptimus: '0',
            numero: '',
            tipo: '1',
            tipoDesc: 'Personal',
            error: false,
        };

        let personalPhone = this.phones.find((item) => item.tipo === '1');
        let comercialPhone = this.phones.find((item) => item.tipo === '4');

        if (personalPhone) {
            const index = this.phones.findIndex((item) => item.tipo === '1');
            if (!personalPhone.estado || personalPhone.estado === '0') {
                const newPersonal: any = { ...newPhone, idOptimus: personalPhone.idOptimus ?? '0' };
                this.phones[index] = newPersonal;
            } else {
                const currentData = this.phones[index];
                this.getValidPhoneData(currentData, index);
            }
        } else {
            if (Array.isArray(this.data[telefonosProperty][telefonoProperty])) {
                this.phones.push(newPhone);
            } else {
                this.phones = [newPhone];
            }
        }

        if (comercialPhone) {
            const newComercial: any = { ...newPhone, idOptimus: comercialPhone.idOptimus ?? '0', tipo: '4', tipoDesc: 'Particular' };
            if (comercialPhone.estado === '0') {
                const index = this.phones.findIndex((item) => item.tipo === '4');
                this.phones[index] = newComercial;
            }
        } else {
            this.phones.push({ ...newPhone, tipo: '4', tipoDesc: 'Particular' });
        }
    }

    getValidPhoneData(currentData: Telefono, index: number) {
        if (!currentData.numero || typeof currentData.numero !== 'string') this.phones[index].numero = '';
        if (!currentData.codigoPais || typeof currentData.codigoPais !== 'string') this.phones[index].codigoPais = '56';
        if (!currentData.codigoArea || typeof currentData.codigoArea !== 'string') this.phones[index].codigoArea = '';
        if (!currentData.idOptimus || typeof currentData.idOptimus !== 'string') this.phones[index].idOptimus = '0';
    }

    sortAddresses(data: Direccion[] | Direccion): Direccion[] {
        let addresses = isArray(data) ? data : [data];
        addresses = addresses.filter((item) => item);

        return addresses.sort((a, b) => {
            if (a.tipoDireccion < b.tipoDireccion) {
                return 1;
            }
            if (a.tipoDireccion > b.tipoDireccion) {
                return -1;
            }
            return 0;
        });
    }

    sortArray(field: string, data: any[] | any) {
        let list = isArray(data) ? data : [data];
        list = list.filter((item) => item);

        return list.sort((a, b) => {
            if (a[field] == b[field]) return 0;
            if (a[field] == 'SELECCIONA...') return -1;
            if (b[field] == 'SELECCIONA...') return 1;

            if (a[field] > b[field]) {
                return 1;
            }
            if (a[field] < b[field]) {
                return -1;
            }
            return 0;
        });
    }

    getClassLayout(tipoDireccion: string): string {
        // tipoDireccion: 1 -> Particular | 2 -> Comercial
        let positionAddressByType = '3'; // direccion particular
        if (tipoDireccion.toLowerCase() === 'comercial') positionAddressByType = '4';

        if (this.layoutBanks) return `module-content content${positionAddressByType}`;
        else return `module-content ${positionAddressByType}`;
    }

    async initData(data: BussinesLineData | BussinesLineDataCompany) {
        this.loadingService.showLoading();
        const { emailsProperty, direccionProperty, direccionesProperty, telefonosProperty } = getPropertyNamesContactability(this.isCompany);
        this.regiones = JSON.parse(JSON.stringify(this.addressDomainData[1].data));
        this.regionesComercial = JSON.parse(JSON.stringify(this.addressDomainData[1].data));
        this.getAddressName();
        this.filterEmails(data[emailsProperty]);
        this.filterPhones(data[telefonosProperty]);
        this.addresses = !this.visibleDisabled ? this.sortAddresses(this.recordBody[direccionesProperty][direccionProperty]) : this.sortAddresses(data[direccionesProperty][direccionProperty]);
        this.existValidAddress = this.addresses?.filter((address) => address.estado === '1').length >= 1;

        let accounts = this.data.cuentasCorrientes?.cuentaCorriente;
        accounts = isArray(accounts) ? accounts : [accounts];
        this.existValidBankAccounts = accounts?.filter((address) => address.estado === '1').length >= 1;
        this.showData = true;
        this.maxLengthFields.emit({
            maxLength: this.maxLength,
            maxLengthLow: this.maxLengthLow,
            maxLengthMid: this.maxLengthMid,
            maxLengthHigh: this.maxLengthHigh,
        });
        this.loadingService.hideLoading();
    }

    getAddressName() {
        let address = 'direcciones' in this.data ? this.data.direcciones?.direccion : this.data.direccionesEmpresa?.direccionEmpresa;
        if (!address) return;

        if (isArray(address)) {
            address.forEach((element, index) => {
                this.setAddressType(index);
                this.setAddressDescription('pais', element.codigoPais, index);
                this.setAddressDescription('region', element.codigoRegion, index);
                this.setAddressDescription('ciudad', element.codigoCiudad, index);
                this.setAddressDescription('comuna', element.codigoComuna, index);
            });
        } else {
            this.setAddressType();
            this.setAddressDescription('pais', address.codigoPais);
            this.setAddressDescription('region', address.codigoRegion);
            this.setAddressDescription('ciudad', address.codigoCiudad);
            this.setAddressDescription('comuna', address.codigoComuna);
        }
    }

    getAddressTypeDescription(domain: string, codeDomain: string): string {
        if (!domain || !codeDomain || !this.addressDomainData) return null;

        const domainList = this.addressDomainData.filter((item) => item.dominio === domain)[0];
        return domainList.data.filter((domainItem) => domainItem.tipoValor === codeDomain)[0]?.descripcionTipo;
    }

    setAddressDescription(propertyName: string, code: string, index?: number) {
        let direccionesProperty = 'direcciones';
        let direccionProperty = 'direccion';

        if (this.isCompany) {
            direccionesProperty = 'direccionesEmpresa';
            direccionProperty = 'direccionEmpresa';
        }

        if (index || index === 0) {
            this.data[direccionesProperty][direccionProperty][index][propertyName] = this.getAddressTypeDescription(propertyName, code);
        } else {
            this.data[direccionesProperty][direccionProperty][propertyName] = this.getAddressTypeDescription(propertyName, code);
        }
    }

    setAddressType(index?: number) {
        let direccionesProperty = 'direcciones';
        let direccionProperty = 'direccion';

        if (this.isCompany) {
            direccionesProperty = 'direccionesEmpresa';
            direccionProperty = 'direccionEmpresa';
        }
        const isArrayAddress = index || index === 0;
        let tipoDireccion = this.data[direccionesProperty][direccionProperty].tipoDireccion;
        let tipo = this.data[direccionesProperty][direccionProperty].tipo;

        if (isArrayAddress) {
            tipoDireccion = this.data[direccionesProperty][direccionProperty][index].tipoDireccion;
            tipo = this.data[direccionesProperty][direccionProperty][index].tipo;
        }

        const nuevoTipoDireccion = tipo === '1' ? 'particular' : 'comercial';
        const nuevoTipo = tipoDireccion?.toLowerCase().trim() === 'particular' ? '1' : '2';

        if (isArrayAddress) {
            if (!tipoDireccion) {
                this.data[direccionesProperty][direccionProperty][index].tipoDireccion = nuevoTipoDireccion;
            }
            if (!tipo) {
                this.data[direccionesProperty][direccionProperty][index].tipo = nuevoTipo;
            }
        } else {
            if (!tipoDireccion) {
                this.data[direccionesProperty][direccionProperty].tipoDireccion = nuevoTipoDireccion;
            }
            if (!tipo) {
                this.data[direccionesProperty][direccionProperty].tipo = nuevoTipo;
            }
        }
    }

    async activeInputs() {
        this.clearErrors();
        const data: BussinesLineData = <BussinesLineData>this.data;
        sessionStorage.setItem(`misDatosBkp${this.businessLine}`, JSON.stringify(data));
        this.recordBody = JSON.parse(sessionStorage.getItem(`misDatosBkp${this.businessLine}`));
        const { telefonosProperty, telefonoProperty } = getPropertyNamesContactability(this.isCompany);
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: 'Mis datos',
            Operacion: 'Mis datos/Actualizar',
            Metodo: 'activeInputs()',
            eventoLog: 'Click en Botón Modificar Datos',
            status: Status.OK,
            Secuencia: 2,
        };
        
        if (this.visibleDisabled) {
            this.recordBody[telefonosProperty][telefonoProperty].forEach((fono) => {
                fono.numeroFinal = fono.codigoArea + fono.numero;
            });
            if (!this.isVida) {
                let count = 0;
                this.recordBody.cuentasCorrientes.cuentaCorriente.forEach((element) => {
                    element.error = [];
                    element.message = '';
                    if (element.estado === '1') count++;

                    let bancos = this.addressDomainData[5].data.filter((banco) => banco.descripcionTipo === element.banco);
                    element.dominio = {
                        identificadorDominio: bancos[0]?.identificadorDominio ?? '',
                        tipoValor: bancos[0]?.tipoValor ?? '',
                        descripcionTipo: bancos[0]?.descripcionTipo ?? '',
                        codigoHomologacion: bancos[0]?.codigoHomologacion ?? '',
                    };
                });
                if (count === 1) {
                    this.buttonDeleteBanckDisabled = true;
                } else {
                    this.buttonDeleteBanckDisabled = false;
                }
            }
        }
        logtemp.EntradaLog = {
            ...logtemp.EntradaLog,
            misDatosBkp: this.recordBody,
        }
        this.logData(logtemp, 'log_negocio');
        this.updateDataToSave();
    }

    validateField(input: any, type: string, index: number, field?: string) {
        try {
            switch (type) {
                case 'phone':
                    this.validateFieldPhone(input, index);
                    break;

                case 'banco':
                    this.validateFieldBank(input, index, field);
                    break;

                case 'email':
                    this.validateFieldEmail(input, index);
                    break;

                case 'address':
                    this.validateFieldAddress(input, index, field);
                    break;
            }

            this.countErrors();
        } catch (error) {
            console.warn(error);
        }
    }

    validateFieldEmail(input: any, index: number) {
        let error = false;
        error = !validateEmail(input);
        if (index || index === 0) {
            this.emails[index].error = error;
            this.emailsErrorList = this.emailsErrorList?.filter((i) => i !== index);
        } else {
            this.emailNoDataError = error;
            this.emailsErrorList = [];
        }
    }

    validateFieldPhone(input: any, index: number) {
        let error = false;
        error = !validatePhoneNumber(input);
        if (index || index === 0) {
            this.phones[index].error = error;
            this.phonesErrorList = this.phonesErrorList?.filter((i) => i !== index);
        } else {
            this.phonesErrorList = [];
        }
    }

    validateFieldBank(input: any, index: number, field?: string) {
        if (index === undefined) return;

        let isVirtual = false;
        this.banksErrorList = [];
        let account = this.recordBody.cuentasCorrientes.cuentaCorriente[index];
        switch (field) {
            case 'banco':
                if (input === 'SELECCIONA...') account.error.push('banco');
                else {
                    account.error = account.error.filter((itemError) => itemError !== 'banco');
                    input = account.numero;
                    const res = this.setBankError(input, account, field);
                    isVirtual = res.isVirtual;
                    account = res.account;
                }
                this.setBankMessages(isVirtual, 'banco');
                break;
            case 'numero':
                const resNumber = this.setBankError(input, account, field);
                isVirtual = resNumber.isVirtual;
                account = resNumber.account;
                this.setBankMessages(isVirtual, 'numero');
                break;
            case 'descripcionTipo':
                if (input === '0') account.error.push('descripcionTipo');
                else account.error = account.error.filter((itemError) => itemError !== 'descripcionTipo');
                break;
        }
        this.recordBody.cuentasCorrientes.cuentaCorriente[index] = account;
    }

    setBankMessages(isVirtual: boolean, type: string) {
        if (type === 'banco') {
            this.message = isVirtual ? 'No están permitidas cuentas virtuales' : 'Ingrese un banco válido';
            if (isVirtual) this.messageNumber = 'No están permitidas cuentas virtuales';
        } else {
            this.messageNumber = isVirtual ? 'No están permitidas cuentas virtuales' : 'Ingrese un número de cuenta válido';
            if (isVirtual) this.message = 'No están permitidas cuentas virtuales';
        }
    }

    setBankError(input, account, field) {
        let error = false;
        const bank = account.idBanco;
        error = !onlyNumbers(input);
        const isVirtual = !validateVirtualBank(input, Number(bank));
        if (field === 'numero' && input.trim() === '') error = true;
        if (error || isVirtual) {
            account.error.push(field);
        } else {
            account.error = account.error.filter((itemError) => itemError !== field);
            if (field === 'banco' && input.trim() !== '') account.error = account.error.filter((itemError) => itemError !== 'numero');
            if (field === 'numero' && bank !== '0') account.error = account.error.filter((itemError) => itemError !== 'banco');
        }
        return { error, isVirtual, account };
    }

    validateFieldAddress(input: any, index: number, field: string) {
        let error = false;
        error = !validateAddress(input);
        if (this.addresses[index].tipo === '1' && input.trim() === '') error = true;
        if (this.addresses[index].tipo === '1' && input === '0') {
            if (field === 'region' || field === 'ciudad' || field === 'comuna') error = true;
        }

        if (index || index === 0) {
            if (!this.addresses[index].errorList) this.addresses[index].errorList = [];
            if (error) this.addresses[index].errorList.push(field);
            else {
                this.addresses[index].errorList = this.addresses[index].errorList.filter((item) => item !== field);
                this.resetErrorListBusinessAddress(index, field);
            }
        }
    }

    resetErrorListBusinessAddress(index: number, field: string) {
        if (this.businessAddressErrorList.length > 0 && this.businessAddressErrorList[index]) {
            if (this.addresses[index].tipo === '2') {
                this.businessAddressErrorList[index].errorList = [];
            } else {
                this.businessAddressErrorList[index].errorList = this.businessAddressErrorList[index].errorList.filter((item) => item !== field);
            }
        }
    }

    updateDataToSave() {
        if (this.currentTab !== this.tabNumber) return;
        let dataModified = JSON.parse(JSON.stringify(this.recordBody));
        let current = JSON.parse(JSON.stringify(this.currentAddresses));
        dataModified = this.validateDataToSave(current, dataModified);

        this.dataToSave.emit(dataModified);
        const bussinesLine = this.currentTab === 0 && this.isCb ? 'cb' : 'vida';
        this.bussinesLine.emit(bussinesLine);
        this.countErrors();
    }

    validateDataToSave(current, dataModified) {
        const optionalList = this.isRequiredBusinessAddress();
        current = Array.isArray(current) ? current : [current];
        // direcciones
        const optional = optionalList.filter((item) => !item.isRequired);
        const { direccionesProperty, direccionProperty } = getPropertyNamesContactability(this.isCompany);
        if (optional.length > 0) {
            const comercial = current.filter((item) => item.tipo === '2');
            if (comercial.length > 0) {
                optional.forEach((optionalItem) => {
                    if (!Array.isArray(this.currentAddresses)) {
                        const index = dataModified[direccionesProperty][direccionProperty].findIndex((modifiedDataitem) => (modifiedDataitem.tipo = '2'));
                        dataModified[direccionesProperty][direccionProperty][index] = current;
                    } else {
                        dataModified[direccionesProperty][direccionProperty][optionalItem.index] = this.currentAddresses[optionalItem.index];
                    }
                });
            } else {
                optional.forEach((item) => {
                    dataModified[direccionesProperty][direccionProperty].splice(item.index, 1);
                });
            }
        }

        dataModified[direccionesProperty][direccionProperty].forEach((address) => {
            delete address.ciudades;
            delete address.comunas;
        });

        // cuentas corrientes
        dataModified.cuentasCorrientes.cuentaCorriente = dataModified?.cuentasCorrientes?.cuentaCorriente
            ?.filter((item) => item.estado === '1' || (item.estado === '0' && !item.id.includes('new')))
            .map((item) => {
                if (item.id.includes('new')) item.id = '0';
                return item;
            });
        return dataModified;
    }

    countErrors() {
        const phones = this.phones?.filter((phone) => phone.error);
        const accounts = this.recordBody?.cuentasCorrientes?.cuentaCorriente?.filter((account) => account.error?.length > 0);
        const emails = this.emails?.filter((email) => email.error);
        let addresses = 0;
        this.addresses?.forEach((address, index) => {
            if (address.errorList?.length > 0) addresses++;
        });

        if (phones?.length > 0 || accounts?.length > 0 || emails?.length > 0 || addresses > 0) this.existValidationErrors.emit(true);
        else this.existValidationErrors.emit(false);
    }

    clearErrors() {
        const { direccionProperty, direccionesProperty, telefonosProperty, telefonoProperty, emailsProperty, emailProperty } = getPropertyNamesContactability(this.isCompany);
        this.error = false;
        this.emailNoDataError = false;
        this.phoneMobileNoDataError = false;
        this.bankNoDataError = false;
        this.bankNoDataErrorMessage = '';

        this.data[direccionesProperty][direccionProperty] = JSON.parse(JSON.stringify(this.currentAddresses));
        if (!Array.isArray(this.data[direccionesProperty][direccionProperty])) {
            this.data[direccionesProperty][direccionProperty].errorList = [];
        } else {
            this.addresses?.map((item) => {
                item.errorList = [];
                return item;
            });
            this.businessAddressErrorList.map((item) => {
                item.errorList = [];
                return item;
            });
            this.data[direccionesProperty][direccionProperty].map((item) => {
                item.errorList = [];
                return item;
            });
        }
        this.resetAddress();
        this.addBusinessAddress();

        if (!Array.isArray(this.data[telefonosProperty][telefonoProperty])) {
            if (this.data[telefonosProperty][telefonoProperty]) {
                this.data[telefonosProperty][telefonoProperty].error = false;
            } else {
                this.data[telefonosProperty][telefonoProperty] = [];
                this.data[telefonosProperty][telefonoProperty].push({ error: false });
            }
        } else {
            this.data[telefonosProperty][telefonoProperty].map((item) => {
                item.error = false;
                return item;
            });
        }

        if (!Array.isArray(this.data[emailsProperty][emailProperty])) {
            this.data[emailsProperty][emailProperty].error = false;
        } else {
            this.data[emailsProperty][emailProperty].map((item) => {
                item.error = false;
                return item;
            });
        }

        if (!this.isVida) {
            if (!Array.isArray(this.data.cuentasCorrientes.cuentaCorriente)) {
                this.data.cuentasCorrientes.cuentaCorriente.error = false;
                this.recordBody.cuentasCorrientes.cuentaCorriente.error = false;
            } else {
                this.data.cuentasCorrientes.cuentaCorriente = this.data.cuentasCorrientes.cuentaCorriente.map((item) => {
                    item.error = [];
                    return item;
                });
                this.recordBody.cuentasCorrientes.cuentaCorriente = this.data.cuentasCorrientes.cuentaCorriente.map((item) => {
                    item.error = [];
                    return item;
                });
            }
        }
        this.resetAccountBank();
        this.addAccountBank();
        this.phonesErrorList = [];
        this.emailsErrorList = [];
    }

    addBusinessAddress() {
        const { direccionProperty, direccionesProperty } = getPropertyNamesContactability(this.isCompany);
        let address = this.data[direccionesProperty];
        if (Object.keys(address).length !== 0) address = address[direccionProperty];
        else address = [];

        let newAddress = {
            calle: '',
            ciudad: '',
            codigoCiudad: '0',
            codigoComuna: '0',
            codigoPais: '104',
            codigoRegion: '0',
            comuna: '',
            ciudades: [
                {
                    identificadorDominio: '8',
                    tipoValor: '0',
                    descripcionTipo: 'SELECCIONA...',
                    codigoHomologacion: '0',
                },
            ],
            comunas: [
                {
                    identificadorDominio: '10',
                    tipoValor: '0',
                    descripcionTipo: 'SELECCIONA...',
                    codigoHomologacion: '0',
                },
            ],
            estado: '1',
            idOptimus: '0',
            numeroCalle: '',
            numeroDepto: '',
            pais: 'CHILE',
            region: '(SIN REGION)',
            tipo: '2',
            tipoDireccion: 'Comercial',
        };
        let newParticularAddress = JSON.parse(JSON.stringify({ ...newAddress, tipo: '1', tipoDireccion: 'Particular' }));

        if (!this.currentAddresses) {
            this.currentAddresses = JSON.parse(JSON.stringify(address));
        }
        if (!Array.isArray(address) && Object.entries(address).length > 0) {
            address = [address];
            this.data[direccionesProperty][direccionProperty] = address;
        }

        if (address.length > 0) {
            const existParticularAddress = address.filter((item) => item.tipo === '1').length;
            const existBusinessAddress = address.filter((item) => item.tipo === '2').length;

            this.setNewAddress('1', existParticularAddress, newParticularAddress, address);
            this.setNewAddress('2', existBusinessAddress, newAddress, address);
        } else {
            this.data[direccionesProperty][direccionProperty] = [newAddress, newParticularAddress];
        }
    }

    setNewAddress(type: string, exist: boolean, newAddressData: any, address: any[]) {
        const { direccionProperty, direccionesProperty } = getPropertyNamesContactability(this.isCompany);
        if (!exist) {
            // no existe direccion
            this.data[direccionesProperty][direccionProperty].push(newAddressData);
        } else {
            // existe pero no esta vigente
            const isActive = address.filter((item) => item.estado === '1' && item.tipo === type);
            if (isActive.length === 0) {
                newAddressData.idOptimus = this.data[direccionesProperty][direccionProperty].find((element) => element.tipo === type)?.idOptimus ?? '0';
                this.data[direccionesProperty][direccionProperty] = this.data[direccionesProperty][direccionProperty].filter((item) => item.tipo !== type);
                this.data[direccionesProperty][direccionProperty].push(newAddressData);
            }
        }
    }

    isRequiredBusinessAddress(): ValidationBusinessAddress[] {
        let list = [];
        let addresses = [];
        const { direccionesProperty, direccionProperty } = getPropertyNamesContactability(this.isCompany);
        let recorded = this.recordBody[direccionesProperty][direccionProperty];

        if (!Array.isArray(recorded)) addresses.push(recorded);
        else addresses = [...recorded];
        addresses.forEach((address, index) => {
            const isEmpty = validateEmptyAddress(address).isEmpty;
            if (address?.tipo === '2' && isEmpty) list.push({ index, isRequired: false });
            else list.push({ index, isRequired: true });
        });
        if (!Array.isArray(recorded) && list.length > 0) list[0].index = null;

        return list;
    }

    resetAddress() {
        if (!this.backup) return;
        const { direccionProperty, direccionesProperty } = getPropertyNamesContactability(this.isCompany);
        const address = JSON.parse(JSON.stringify(this.backup[direccionesProperty][direccionProperty] ?? []));
        this.data[direccionesProperty][direccionProperty] = address ?? [];
    }

    resetAccountBank() {
        if (!this.backup) return;
        const accounts = JSON.parse(JSON.stringify(this.backup.cuentasCorrientes.cuentaCorriente ?? []));
        this.data.cuentasCorrientes.cuentaCorriente = accounts ?? [];
    }

    addAccountBank() {
        let data = this.backup ? JSON.parse(JSON.stringify(this.backup.cuentasCorrientes?.cuentaCorriente || {})) : this.data?.cuentasCorrientes?.cuentaCorriente;
        if (!Array.isArray(data)) {
            const arrayData = [];
            arrayData.push(data);
            data = arrayData;
        }
        const accounts = JSON.parse(JSON.stringify(data ?? [{}]));
        const valid = accounts.filter((address) => address.estado === '1');
        const noValid = accounts.filter((address) => address.estado === '0');

        if (valid.length === 0 && noValid.length > 0) {
            this.currentlyNoAccounts = true;
            if (!this.currentAccount) this.currentAccount = JSON.parse(JSON.stringify(accounts));
            const accountToModify = accounts.find((address) => address.estado === '0');
            const index = accounts.findIndex((item) => (item.id = accountToModify.id));
            const newAccount = { ...this.bancoAdd, id: accountToModify.id, idBanco: '0', banco: '' };
            this.data.cuentasCorrientes.cuentaCorriente[index] = newAccount;
        } else if (valid.length === 0 && noValid.length === 0) {
            this.currentlyNoAccounts = true;
            if (!this.currentAccount) this.currentAccount = [];

            const newAccount = { ...this.bancoAdd, id: 'new-0', idBanco: '0', banco: '' };
            this.data.cuentasCorrientes.cuentaCorriente = [newAccount];
        } else {
            if (!this.currentAccount) this.currentAccount = JSON.parse(JSON.stringify(accounts));
        }
    }

    addDefaultOptionCity(cityArrayName: string, i: number) {
        const existFirstOptionCity = this[cityArrayName][i]?.data.find((cityItem) => cityItem.tipoValor === '0');
        if (!existFirstOptionCity) {
            this[cityArrayName][i]?.data.unshift({
                identificadorDominio: '8',
                tipoValor: '0',
                descripcionTipo: 'SELECCIONA...',
                codigoHomologacion: '0',
            });
        }
    }

    addDefaultOptionCommune(communeArrayName: string, i: number) {
        const existFirstOptionCommune = this[communeArrayName][i]?.data.find((communeItem) => communeItem.tipoValor === '0');
        if (!existFirstOptionCommune) {
            this[communeArrayName][i]?.data.unshift({
                identificadorDominio: '10',
                tipoValor: '0',
                descripcionTipo: 'SELECCIONA...',
                codigoHomologacion: '0',
            });
        }
    }

    getVisibilityEmail(data: Email): boolean {
        if (data.estadoEmail === '1') {
            if (this.isCompany) {
                return data.tipoCorreo === '2';
            } else {
                return data.tipoCorreo === '1';
            }
        }
        return false;
    }

    validateBirthday(data: any) {
        let date = data.representanteLegal ? data.representanteLegal.fechaNacimiento : data.fechaNacimiento;
        if (!date) return null;
        else {
            date = date.trim();
            if (date === '') return null;
            else return date;
        }
    }

    getValidField(data: string) {
        if (!data) return 'Sin Información';
        else {
            data = data.trim();
            if (data === '') return 'Sin Información';
            else return data;
        }
    }

    getAddressTitle(type: string, index: number): string {
        const code = type === 'comunas' ? 'codigoComuna' : 'codigoCiudad';
        const title = this.addresses[index][type]?.find((data) => data.tipoValor === this.addresses[index][code])?.descripcionTipo;
        return title || 'Sin Información';
    }

    async logData(log: Partial<InputLog>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.Linea_negocio = this.currentTab == 1 ? 'VIDA' : 'CB'; // DEPENDE DEL TAB
            inputLog.Secuencia = log.Secuencia || 0;
            inputLog.eventoLog = log.eventoLog;
            inputLog.EntradaLog = {
                ...EntradaLog,
            };
            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, '', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }
}
