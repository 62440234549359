import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
    name: 'formatoMontoConDecimales'
})
export class FormatoMontoConDecimalesPipe implements PipeTransform {

    constructor(private decimalPipe: DecimalPipe) {}

    transform(value: number, decimales?: number, esDolar?: boolean): string {
        if (isNaN(value)) {
            return "-";
        }
        let monto = Math.abs(value);
        let signo = "";
        if (value < 0) {
            signo = "-";
        }
        let montoFormateado = "$" + monto.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
        if (signo !== "") {
            montoFormateado = "-" + montoFormateado;
        }
        if (decimales != null && !isNaN(decimales)) {
            const val = parseFloat(montoFormateado.replace(/[$,]/g, ''));
            montoFormateado = this.decimalPipe.transform(val, `1.${decimales}-${decimales}`, "es-CL");
            if(esDolar) {
                montoFormateado = 'USD '+ montoFormateado
            } else {
                montoFormateado ='$'+ montoFormateado
            }
        }
        return montoFormateado;
    }
}
