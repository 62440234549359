import {Injectable} from '@angular/core';
import {RequestHandlerService} from '../../../core/services/request-handler/request-handler.service';
import {UrlCR, environment} from '@environment';
import {LoginBodyModel} from '../../models';
import {ReCaptchaService} from '../../../shared/services/re-captcha/re-captcha.service';
import {LoginResponseModel, RefreshTokenModel} from '../../models/login/login.model';
import {Router} from '@angular/router';
import {AppService} from '../../../shared/services/app/app.service';
import {InputLog} from '../../models/log/InputLog';
import {Status} from '../../models/log/status';
import * as moment from 'moment';
import {ConfigIndexLogStash} from '../../models/log/models';
import {TracesService} from 'src/app/core/services/traces/traces.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { bufferCount, map } from 'rxjs/operators';
import { XAuthService } from 'src/app/shared/services/xauth/xauth.service';

@Injectable()
export class LoginService {

    public varLoginAdEmitter = new BehaviorSubject(false);

    constructor(
        private requestHandler: RequestHandlerService,
        private reCaptchaService: ReCaptchaService,
        private router: Router,
        private appService: AppService,
        private tracesService: TracesService,
        private httpClient: HttpClient,
        private xAuthService: XAuthService
    ) {
    }

    async signIn(data: LoginBodyModel): Promise<any> {
        const tokenRecaptcha = await this.reCaptchaService.executeAction('login');
        const inputData = {
          usuario: data.username,
          clave: data.password,
          tokenRecaptcha
        };
        const url = `${UrlCR}/auth/tokenizer/login-asesores`;
        return this.httpClient.post(url, inputData).pipe(map((item:any) => item.data)).toPromise();
    }

    async refreshToken(data: RefreshTokenModel): Promise<RefreshTokenModel> {
        const url = `${environment.ENDPOINTS.BFF.BFF_ASESORES}/auth/refresh-token`;
        return this.requestHandler.doPost<LoginResponseModel>(url, data);
    }

    public async signOutSession() {
        const logtemp: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status"> = {
            Categoria: "Resumen Cliente",
            Operacion: "Resumen Cliente",
            Metodo: "signOutSession()",
            eventoLog: "click boton cerrar session",
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton cerrar session',
            },
        };
        await this.logData(
            logtemp,
            "log_negocio",
        );
        const enabledByEnv = sessionStorage.getItem('logrocketEnabled');
        const datosAd =  localStorage.getItem('datosAd');
        const xToken = sessionStorage.getItem('xtoken');
        localStorage.clear();
        sessionStorage.clear();
        this.appService.setUserLoggedIn(false);
        this.appService.closeDatadogSession();
        sessionStorage.setItem('logrocketEnabled', enabledByEnv);
        localStorage.setItem('datosAd', datosAd);
        this.xAuthService.obSesionState().set("LOGOUT");
        this.xAuthService.borrarXToken(xToken);
        this.router.navigate(['/auth/login']);
    }

    async logData(log: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status">, indexLogStash: ConfigIndexLogStash) {
        try {
            const {EntradaLog, ...logData} = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                ...EntradaLog,
            };
            inputLog.Linea_negocio = "VIDA";
            inputLog.Salida = "-";
            inputLog.Secuencia = 0;
            inputLog.Servicio = "";
            inputLog.Tipo_sesion = "ASESOR";
            inputLog.extra = {};

            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
        } catch (error) {
            console.error("error: ", error);
        }
    }

    loginAdEmitter() {
        return {
          set: (status) => {
            this.varLoginAdEmitter.next(status);
          },
          get: (): Observable<any> => {
            return this.varLoginAdEmitter.asObservable().pipe(bufferCount(1));
          },
        };
    }

    obtenerUsuarioAD(){
        try{
          return JSON.parse(localStorage.getItem('datosAd'));
        }catch(err){
          return null;
        }
    }

    async loginAD(usuario: string, clave: string){
        try{
          const url = `${UrlCR}/nwsc/autenticacion`;
          return await this.httpClient.post(url, { usuario, clave , origen: 'ASESOR' }).toPromise();
        }catch(err){
            this.appService.alertaMensaje("Aceptar", "Error", "Se produjo un error al validar acceso a la Web de Asesor");
        }
      }

    async obtenerPermisosPorAmbientes(token: string, id: string, origen: string = 'asesor'){
        try{
          const url = `${UrlCR}/nwsc/accesos-ambientes/configuracion/usuarios/${id}/${origen}`;
          return await this.httpClient.get(url, { headers: { Authorization: `Bearer ${token}`}}).toPromise();
        }catch(err){
            this.appService.alertaMensaje("Aceptar", "Error", "Se produjo un error al obtener los permisos de acceso a la Web de Asesor");
          return null;
        }
    }

    async puedeConsultarValor(valor: string, origen: string){
        if(environment.AMBIENTE_PROTEGIDO && environment.name !== 'PROD'){
          try{
            const ivalor = origen === 'asesor' ? valor.split("@")[0] : valor ;
            const usuarioAD = this.obtenerUsuarioAD();
            const res:any = await this.obtenerPermisosPorAmbientes(usuarioAD.token, usuarioAD.usuario.id, origen);
            const { data } = res;
            return data.accesoGlobal || data.valoresPermitidos.some( item => item?.toUpperCase() === ivalor?.toUpperCase() );
          }catch(err){
            console.log(err)
            return false;
          }
        }else{
          return true;
        }
    }

    async asignarVariablesChatbotDev() {
        const variablesDev: any = await this.httpClient.get('../../../../assets/json/token_dev.json').toPromise();
        sessionStorage.setItem('token_dev', variablesDev.token);
        sessionStorage.setItem('legacyToken_dev', variablesDev.legacyToken);
        sessionStorage.setItem('url_chatbot_dev', variablesDev.url_chatbot_dev);
    }
}
