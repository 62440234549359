import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ClientRequestService } from '../../features/services/client-request/client-request.service';
import { ExecutiveData } from '../models';
import { LocalStorageService } from '../services';
import { ExecutiveService } from '../services/executive/executive.service';
import { OrdenesCorredoraService } from 'src/app/features/services/ordenes/corredora/ordenes.corredora.service';
import { OrdenesDivisasService } from 'src/app/features/services/ordenes/divisas/ordenes.divisas.service';
import { OrdenesPershingService } from 'src/app/features/services/ordenes/pershing/ordenes.pershing.service';
import { DatePipe } from '@angular/common';
import jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';
import { SessionStorageService } from 'src/app/features/services/session-storage/session-storage.service';

export interface ListModals {
    name: string;
    value: HTMLIonModalElement;
  }

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
private modals: ListModals[] = [];
modalOpen = { ayudaEjecutivo: false };

    constructor(
        private localStorageService: LocalStorageService,
        private executiveService: ExecutiveService,
        private clientRequestService: ClientRequestService,
        private pershingService: OrdenesPershingService,
        private corredoraService: OrdenesCorredoraService,
        private divisasService: OrdenesDivisasService,
        private datepipe: DatePipe,
        private sessionStorageService: SessionStorageService
    ) {}

    public async getExecutiveEmails() {
        const localData = sessionStorage.getItem("clientData");
        const clientData = JSON.parse(localData);
        const clientRut = clientData?.rut?.split('.').join('');
        let transactionExecutive: ExecutiveData =  this.localStorageService.get('dataExecutive', true);

        if(!transactionExecutive) {
            transactionExecutive = await this.executiveService.getDataExecutive(clientRut?.split('-')[0]);
        }

        const assignedExecutive = await this.clientRequestService.getExecutiveClient({rut: clientRut});
        const emailTransaction = environment.production ? transactionExecutive?.MailEjecutivo : `${sessionStorage.getItem('executiveUsername')}@sura.cl`;
        // falta obtener el rut del asesor asignado al cliente
        return {
            assignedExecutive: {
                rut: undefined,
                email: assignedExecutive?.MailEjecutivo,
            },
            transactionExecutive: {
                rut: `${transactionExecutive?.RutEjecutivo}-${transactionExecutive?.DVEjecutivo}`,
                email: emailTransaction,
            }
        }

    }

    public limpiarVariablesPershing() {
         // Elimina la solicitud y el archivo de pershing
         sessionStorage.removeItem(this.pershingService.STORAGE_PERSHING_CUENTA_SELECCIONADA);
         sessionStorage.removeItem(this.pershingService.STORAGE_PERSHING_CARRO_FINAL);
         sessionStorage.removeItem(this.pershingService.STORAGE_PERSHING_COMPRA_CARRO);
         sessionStorage.removeItem(this.pershingService.STORAGE_PERSHING_VENTA_CARRO);
         this.pershingService.archivobase64 = null;
         sessionStorage.removeItem(this.pershingService.STORAGE_PERSHING_SESSION);

         // Elimina la solicitud y el archivo de corredora
         sessionStorage.removeItem(this.corredoraService.STORAGE_CORREDORA_CARRO_FINAL);
         sessionStorage.removeItem(this.corredoraService.STORAGE_CORREDORA_COMPRA_VENTA_CARRO);
         this.sessionStorageService.remove(this.corredoraService.STORAGE_CORREDORA_ARCHIVO_CONTENT_MANAGER);
         sessionStorage.removeItem(this.corredoraService.STORAGE_CORREDORA_SESSION);

         // Elimina la solicitud y el archivo fx
         sessionStorage.removeItem(this.divisasService.STORAGE_DIVISAS_CARRO_FINAL);
         sessionStorage.removeItem(this.divisasService.STORAGE_DIVISAS_COMPRA_VENTA_CARRO);
         sessionStorage.removeItem(this.divisasService.STORAGE_DIVISAS_ARCHIVO_CONTENT_MANAGER);
         sessionStorage.removeItem(this.divisasService.STORAGE_DIVISAS_SESSION);
    }

    getApiConfig(){
        try{
          return JSON.parse(localStorage.getItem('apiConfig'));
        }catch(err){
          return null;
        }
      }

      appendModal(modal: HTMLIonModalElement): void {
        const aux = this.getModalStructure(modal);
        const exist = this.getIndexModalByName(aux.name);
    
        if (exist) {
          this.modals.push(aux);
        }
      }
    
      deleteModal(modal: HTMLIonModalElement): void {
        const aux = this.getModalStructure(modal);
        const target = this.getIndexModalByName(aux.name);
    
        if (target) {
          this.modals = this.modals.splice(target, 1);
        }
      }
    
      getModalStructure(modal: HTMLIonModalElement): ListModals {
        return {
          name: modal.component.toString().replace("class", "").split("{")[0].replace(" ", ""),
          value: modal,
        };
      }
    
      getIndexModalByName(name: string): number {
        const func = (modal) => modal.name === name;
        return this.modals.findIndex(func);
      }

      formatearFechaAperturaCierre(datosAdicionalesFecha){
        try {
          const { fechaServicio, horaApertura, horaCierre } = datosAdicionalesFecha;
          const fechaSERVERX = new Date(Date.parse(fechaServicio));
          const fechaSERVER =  this.formatearFechaHora(fechaSERVERX);
          const fechaAperturaX = this.formatearFechaUTC(fechaSERVER, horaApertura)
          const fechaCierreX = this.formatearFechaUTC(fechaSERVER, horaCierre)
          const fechaApertura = this.formatearFechaHora(fechaAperturaX)
          const fechaCierre = this.formatearFechaHora(fechaCierreX);
          return { fechaSERVER, fechaApertura, fechaCierre }
        } catch (error) {
          console.log(error);
          throw error;
        }
      }

      formatearFechaUTC(fecha, hora){
        try {
          return new Date(this.datepipe.transform(fecha, 'yyyy-MM-dd')+ 'T' +hora + ':00Z');
        } catch (error) {
          console.log(error);
          throw error;
        }
      }
      
      formatearFechaHora(fecha){
        try {
          return new Date(fecha.getUTCFullYear(),fecha.getUTCMonth(),fecha.getUTCDate(),fecha.getUTCHours(),fecha.getUTCMinutes(),fecha.getUTCSeconds());
        } catch (error) {
          console.log(error);
          throw error; 
        }
      }

    encriptarData(data: any){
        try {
            const jsonData = JSON.stringify(data);
            const dataToken = jwt_decode(sessionStorage.getItem("token")) as any;
            const key = dataToken.sesion;
            return CryptoJS.AES.encrypt(jsonData, key).toString();
        } catch (error) {
            console.log(error);
        }
    }
}