import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { menuComponents } from './index';
import { MaterialModule } from '../material.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { SharedModule } from '../../shared.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbModule } from 'angular-crumbs';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { NavbarSearchClientPortfolioComponent } from './navbar-search-client-portfolio/navbar-search-client-portfolio.component';

@NgModule({
  declarations: [menuComponents, BreadcrumbComponent, NavbarSearchClientPortfolioComponent],
  imports: [
    CommonModule,
    MaterialModule,
    OverlayModule,
    SharedModule,
    BreadcrumbModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [menuComponents, BreadcrumbComponent],
})
export class LayoutModule {}
