import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject } from 'rxjs';


interface CloseDialog {
  cerrarDialogo: boolean;
}
@Injectable({
  providedIn: 'root',
})

export class DialogService {

  public closeDialogSource = new Subject<CloseDialog>();
  closeDialog$ = this.closeDialogSource.asObservable()

  private readonly defaultConfig = {
    closeOnNavigation: true,
    backdropClass: 'modal-backdrop-background',
    data: "color"
  };
  constructor(private matDialog: MatDialog) {}

  openDialog<T>(CustomComponent, dialogConfig?: MatDialogConfig) {
    let config = this.defaultConfig;

    if (dialogConfig) {
      config = Object.assign(dialogConfig);
      config.closeOnNavigation = true;
      config.backdropClass = 'modal-backdrop-background';      
    }

    return this.matDialog.open<T>(CustomComponent, config);
  }

  closeDialog() {
    this.closeDialogSource.next({cerrarDialogo: true});
  }
  
}