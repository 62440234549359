import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CoreStatusService } from '../../../../../../../src/app/core/services/coreStatus.services';
import { ClientBalanceModel } from '../../../../models/client-summary/client-summary.model';

@Component({
    selector: 'app-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListComponent implements OnChanges {
    @Input() productList: ClientBalanceModel[];
    @Input() showError: boolean;
    public totalBalance = 0;

    constructor(private coreStatusService: CoreStatusService) {}

    ngOnChanges(): void {
        const products = this.productList.filter( item => !item.estado || item.estado.toLowerCase() === 'vigente' );
        this.totalBalance = products.reduce((prev, curr) => prev + (curr.amount ?? 0), 0);
    }
    IsFullyAvailableServer(): boolean {
        return this.coreStatusService.IsFullyAvailableServer(this.productList);
    }
}
