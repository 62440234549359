// Angular
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {

    set(key: string, data: any, isJson: boolean = false): void {
        try {
            if (isJson) {
                return sessionStorage.setItem(key, JSON.stringify(data));
            } else {
                return sessionStorage.setItem(key, data);
            }
        } catch (e) {
            console.error('Error guardando en localstorage', e);
        }
    }

    get(key: string, isJson: boolean = false): any {
        try {
            if (isJson) {
                return JSON.parse(sessionStorage.getItem(key));
            } else {
                return sessionStorage.getItem(key);
            }
        } catch (e) {
            console.error('Error obteniendo valor desde localstorage', e);
            return null;
        }
    }

    remove(key: string): void {
        try {
            return sessionStorage.removeItem(key);
        } catch (e) {
            console.error('Error removiendo llave desde localstorage', e);
        }
    }

    clear(): void {
        try {
            return localStorage.clear();
        } catch (e) {
            console.error('Error limpiando localstorage desde localstorage', e);
        }
    }
}
