import { UserMessage } from "../../features/services/client/client.service";
import { OptionsMessage } from "../components/dialog-message/dialog-message.component";

export const mandatoSuscritoError: UserMessage = {
  isError: true,
  useTitle: 'Mandato AFP sin firmar:',
  useMessage: 'Si el cliente es de AFP Capital debe firmar el mandato de consolidación de saldos',
  userMessageType: "error"
};


export const initialMessage = 'Realiza la búsqueda de tu cliente ingresando: <br> RUT persona o RUT empresa.';


export const duplicateSession: OptionsMessage = {
  showButton: false,
  icon: "assets/img/icono-escudo-info.svg",
  showIcon: true,
  titleMessage: "Recuerda.",
  message: "Las sesiones duplicadas no estan permitidas"
}
