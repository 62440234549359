import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import { ClientRequestService } from 'src/app/features/services';
import { TransversalService } from 'src/app/features/services/transversal/transversal.service';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import { CambioPerfilService } from 'src/app/shared/services/cambio-perfil/cambio-perfil.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';

@Component({
    selector: 'app-resume-profile',
    templateUrl: './resume-profile.component.html',
    styleUrls: ['./resume-profile.component.scss'],
})
export class ResumeProfileComponent implements OnInit {
    @Input() data: any;
    @Input() name: string;
    @Input() rut: string;

    description: string;
    caracteristicas: any;
    dataActualizarPerfil:any;
    result;

    constructor(
        private loadingService: LoadingService, 
        private clientRequestService: ClientRequestService, 
        private router: Router, 
        private activateRouter: ActivatedRoute,
        private tracesService: TracesService,
        private cambioPerfilService: CambioPerfilService, 
        private transversalService: TransversalService
        ) {}
    async ngOnInit() {
        this.obtenerSimulacion();
        if (!this.result.salida) {
            alert('error');
        } else {
            this.loadingService.showLoading();
            this.result.salida.perfiles.perfil.forEach((element) => {
                if (element.tipoRegimen === 'Inversionista') {
                    this.description = element.descripcion;
                    let clientData = JSON.parse(sessionStorage.getItem('clientData'));
                    clientData.profile.type = this.description.toUpperCase();
                    sessionStorage.setItem('clientData', JSON.stringify(clientData));
                    this.caracteristicas = element.caracteristicas.caracteristica;
                    const rut = clientData?.rut.replace(/\./g, '');
                    this.dataActualizarPerfil = {
                        idperfil_calculado: this.description?.toUpperCase(),
                        idperfil_aceptado: this.description?.toUpperCase(),
                        rut
                    }
                }
            });
            await this.transversalService.actualizarPerfilInversionista(this.dataActualizarPerfil);
            this.loadingService.hideLoading();
        }
        this.logData({
            Categoria: 'Perfil Inversionista',
            Operacion: 'Perfil Inversionista/Actualizar Perfil Paso 2',
            Metodo: 'async ngOnInit()',
            eventoLog: `Carga inicial pagina de resultado`,
            status: Status.OK,
            Secuencia: 2,
            EntradaLog: {
                perfilCalculado: this.description,
            }
        }, 'log_negocio');
    }

    resultProfileQuestions(data: any) {
        return this.clientRequestService.postObtenerPerfil(data);
    }

    resultProfileEmails(data: any) {
        return this.clientRequestService.postObtenerEmails(data);
    }

    async goToResumeClient() {
        await this.router.navigate([`../`], {
            relativeTo: this.activateRouter,
        });
    }

    async logData(log: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;
            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...EntradaLog,
            };
            inputLog.Salida = '-';
            inputLog.Tipo_sesion = 'ASESOR';
            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, 'NWSA_ASESOR', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }

    obtenerSimulacion(){
        this.cambioPerfilService.datosSimulacion.subscribe(response => {
           this.result = response;
        });
    }

}
