import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appLinkify]'
})
export class LinkifyDirective implements OnChanges {
    @Input('appLinkify') text: string;

    constructor(private el: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.text && this.text) {
            this.linkifyText();
        }
    }

    private linkifyText(): void {
        this.el.nativeElement.innerHTML =  this.text
    }
}