import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrdenesPershingService } from 'src/app/features/services/ordenes/pershing/ordenes.pershing.service';
import { LoadingService } from 'src/app/shared/services';
import { ClienteActivoDto, CuentaCustodioInstrumentoVentaJson, InstrumentoCompraPershingJson, MensajeBackend, ParametroSistemaPershingJson, SaldoCustodioCtaPershingJson } from '../../../../models/ordenes/pershing/orden.modelo';

@Component({
    selector: 'app-orden-cambio-fondo-compra',
    templateUrl: './orden-cambio-fondo-compra.component.html',
    styleUrls: ['./orden-cambio-fondo-compra.component.scss'],
})
export class OrdenCambioFondoCompraComponent implements OnInit {
    click = false;
    counterFilter = true;

    //WIZZARD
    linea = {
        steps: [],
        currentStep: null,
    };

    //CUENTA SELECCIONADA
    cuenta: SaldoCustodioCtaPershingJson;
    carrito: InstrumentoCompraPershingJson[];
    instrumentos: InstrumentoCompraPershingJson[];
    preview: InstrumentoCompraPershingJson[];
    totalinstrumentos: number;

    custodioventa: CuentaCustodioInstrumentoVentaJson[];
    custodioMoneyMarket: number;
    custodiocompratotalUSD: number;
    custodiofondoreservaUSD: number;
    parametrospershing: ParametroSistemaPershingJson[];

    mensaje: MensajeBackend = {
        messageContent: '',
        messageType: 'error',
        messageTitle: 'Algo salio mal',
        showMessage: false,
    };

    tooltipmsgok: string;
    tooltipmsgerror: string;

    constructor(private router: Router, private loadingService: LoadingService, private ordenesService: OrdenesPershingService) {
        this.loadingService.showLoading();
    }

    ngOnInit(): void {
        this.linea.steps = ['Ingreso de órdenes', 'Ingresar fondos de compra'];
        this.linea.currentStep = 1;
        //carga cuenta seleccionada
        let dataJson = sessionStorage.getItem(this.ordenesService.STORAGE_PERSHING_CUENTA_SELECCIONADA);
        this.cuenta = JSON.parse(dataJson);

        //recupera parametros de sistema
        this.ordenesService.GetParametros().then((parametros) => {
            if (parametros.codigo === 1) {
                this.parametrospershing = parametros.parametros;

                //RECUPERA EL CARRO DE VENTA
                let carroVenta = sessionStorage.getItem(this.ordenesService.STORAGE_PERSHING_VENTA_CARRO);
                this.custodioventa = JSON.parse(carroVenta) || [];
                this.custodioMoneyMarket = this.calculoMoneyMarket();
                this.custodiofondoreservaUSD = this.calculoFondoReserva();
                this.custodiocompratotalUSD = this.calculoTotalDisponible();

                //carga los instrumentos de la cuenta seleccionada
                this.buscarInstrumentos(this.cuenta['cuenta-preferente']);
            } else {
                var msg: MensajeBackend = {
                    messageContent: 'Problemas para recuperar parametros del sistema de pershing',
                    messageType: 'error',
                    messageTitle: 'Algo salio mal',
                    showMessage: true,
                };
                this.mensaje = msg;
            }
        });
    }

    /**
     * Calculo de Money Market
     * @returns
     */
    calculoMoneyMarket() {
        return this.cuenta['money-market-usd'];
    }

    scrollTo(el: Element): void {
        if (el) {
            console.log('Scroll page');
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    /**
     * Calculo de Fondo de Reserva
     * @returns
     */
    calculoFondoReserva() {
        let RESERVA_MONEY: number = 0;
        let PORC_DESC_MONEYM_APV = this.parametrospershing.find((o) => o.id === 'PORC_DESC_MONEYM_APV')['valor-numerico'];
        let PROC_DESC_MONEYM_REG = this.parametrospershing.find((o) => o.id === 'PROC_DESC_MONEYM_REG')['valor-numerico'];
        if (this.cuenta['tipo-regimen'] === 'APV') {
            RESERVA_MONEY = this.cuenta['money-market-usd'] * (PORC_DESC_MONEYM_APV / 100);
        } else {
            RESERVA_MONEY = this.cuenta['money-market-usd'] * (PROC_DESC_MONEYM_REG / 100);
        }
        let TOTAL_ORIGINAL: number = 0;
        let TOTAL_INGRESADO: number = 0;
        let TOTAL_COMISIONES: number = 0;
        this.custodioventa.forEach((sumar) => {
            TOTAL_ORIGINAL += sumar.cuotas * sumar['valor-cuota-usd'];
            TOTAL_INGRESADO += sumar.agregadoTotalInstruccionUSD;
            TOTAL_COMISIONES += sumar.montocomisionUSD;
        });
        return RESERVA_MONEY + TOTAL_COMISIONES;
    }

    /**
     * Calculo de Fondo de Reserva
     * @returns
     */
    calculoTotalDisponible() {
        let TOTAL_INGRESADO: number = 0;
        this.custodioventa.forEach((sumar) => {
            TOTAL_INGRESADO += sumar.agregadoTotalInstruccionUSD;
        });
        return TOTAL_INGRESADO;
    }

    /**
     * Buscar instrumentos
     * @param preferente
     * @param nombreinstrumento
     * @param categoria
     * @param manager
     * @param recomendacion
     */
    buscarInstrumentos(preferente: boolean, nombreinstrumento?: string, categoria?: string, manager?: string, recomendacion?: string) {
        //LOG DE ACCION
        this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 1', 'OrdenCambioFondoCompraComponent - buscarInstrumentos()', 'ok', `Consulta instrumentos para cambio de fondos`);

        //consulta datos de instrumentos disponibles
        this.ordenesService
            .GetInstrumento(preferente ? 'P': 'N', nombreinstrumento, categoria, manager, recomendacion)
            .then((data) => {
                if (data.codigo === 1) {
                    //LOG DE ACCION
                    this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 1', 'OrdenCambioFondoCompraComponent - buscarInstrumentos()', 'ok', `Instrumentos consultados OK`);
                    let instrumentoTemp = <InstrumentoCompraPershingJson[]>data.instrumentos || [];
                    this.buscarInstrumentosMixtos(instrumentoTemp, nombreinstrumento, categoria, manager, recomendacion);
                
                } else {
                    var msg: MensajeBackend = {
                        messageContent: data.resultado,
                        messageType: 'error',
                        messageTitle: 'Algo salio mal',
                        showMessage: true,
                    };

                    this.mensaje = msg;
                    this.loadingService.hideLoading();

                    //LOG DE ACCION
                    this.ordenesService.saveLogAccionAsesor(
                        'Ingreso Ordenes Pershing Off Line',
                        'Ingreso Ord Pershing Off Line Paso 1',
                        'OrdenCambioFondoCompraComponent - buscarInstrumentos()',
                        'error',
                        `Problema al consultar instrumentos de cambio de fondo`,
                        data,
                    );
                }
            })
            .catch((error) => {
                var msg: MensajeBackend = {
                    messageContent: 'No hemos logrado consultar los instrumentos disponibles para comprar. intente mas tarde',
                    messageType: 'error',
                    messageTitle: 'Algo salio mal',
                    showMessage: true,
                };
                this.mensaje = msg;
                this.loadingService.hideLoading();

                //LOG DE ACCION
                this.ordenesService.saveLogAccionAsesor(
                    'Ingreso Ordenes Pershing Off Line',
                    'Ingreso Ord Pershing Off Line Paso 1',
                    'OrdenCambioFondoCompraComponent - buscarInstrumentos()',
                    'error',
                    `Error al consultar instrumentos de cambio de fondo`,
                    error,
                );
            });
    }

       /**
     * Buscar instrumentos
     * @param preferente
     * @param nombreinstrumento
     * @param categoria
     * @param manager
     * @param recomendacion
     */
        buscarInstrumentosMixtos(instrumentosOrigen: InstrumentoCompraPershingJson[], nombreinstrumento?: string, categoria?: string, manager?: string, recomendacion?: string) {
            //LOG DE ACCION
            this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 1', 'OrdenCambioFondoCompraComponent - buscarInstrumentosMixtos()', 'ok', `Consulta instrumentos para cambio de fondos`);
    
            //consulta datos de instrumentos disponibles
            this.ordenesService
                .GetInstrumento('M', nombreinstrumento, categoria, manager, recomendacion)
                .then((data) => {
                    if (data.codigo === 1) {
                        //LOG DE ACCION
                        this.ordenesService.saveLogAccionAsesor('Ingreso Ordenes Pershing Off Line', 'Ingreso Ord Pershing Off Line Paso 1', 'OrdenCambioFondoCompraComponent - buscarInstrumentosMixtos()', 'ok', `Instrumentos consultados OK`);
                        let instrumentoTemp = <InstrumentoCompraPershingJson[]>data.instrumentos || [];
                        let instrumentoOrdenado = [...instrumentosOrigen, ...instrumentoTemp];
                        this.instrumentos = instrumentoOrdenado.sort((n1, n2) => {
                            if (n1['fondo-descripcion'] > n2['fondo-descripcion']) {
                                return 1;
                            }
    
                            if (n1['fondo-descripcion'] < n2['fondo-descripcion']) {
                                return -1;
                            }
                            return 0;
                        });
                        this.instrumentos = this.quitarInstrumentosCarroVenta(instrumentoOrdenado);

    
                        this.carrito = [];
                        this.carcaActualizaCarro();
    
                        //CARGA TOTALES Y PREVIEW INICIAL
                        this.totalinstrumentos = this.instrumentos.length;
                        this.preview = this.instrumentos.slice(0 * 10, 0 * 10 + 10);
    
                        this.loadingService.hideLoading();
                    } else {
                        var msg: MensajeBackend = {
                            messageContent: data.resultado,
                            messageType: 'error',
                            messageTitle: 'Algo salio mal',
                            showMessage: true,
                        };
    
                        this.mensaje = msg;
                        this.loadingService.hideLoading();
    
                        //LOG DE ACCION
                        this.ordenesService.saveLogAccionAsesor(
                            'Ingreso Ordenes Pershing Off Line',
                            'Ingreso Ord Pershing Off Line Paso 1',
                            'OrdenCambioFondoCompraComponent - buscarInstrumentosMixtos()',
                            'error',
                            `Problema al consultar instrumentos de cambio de fondo`,
                            data,
                        );
                    }
                })
                .catch((error) => {
                    var msg: MensajeBackend = {
                        messageContent: 'No hemos logrado consultar los instrumentos disponibles para comprar. intente mas tarde',
                        messageType: 'error',
                        messageTitle: 'Algo salio mal',
                        showMessage: true,
                    };
                    this.mensaje = msg;
                    this.loadingService.hideLoading();
    
                    //LOG DE ACCION
                    this.ordenesService.saveLogAccionAsesor(
                        'Ingreso Ordenes Pershing Off Line',
                        'Ingreso Ord Pershing Off Line Paso 1',
                        'OrdenCambioFondoCompraComponent - buscarInstrumentosMixtos()',
                        'error',
                        `Error al consultar instrumentos de cambio de fondo`,
                        error,
                    );
                });
        }

    /**
     * Quita instrumentos contenidos en la Venta
     * @param intrumentos
     * @returns
     */
    quitarInstrumentosCarroVenta(intrumentos: InstrumentoCompraPershingJson[]) {
        this.custodioventa.forEach((itemventa) => {
            const indiceInstrumentoBuscado = intrumentos.findIndex((el) => el['fondo-ticker'] === itemventa.ticker);
            if(indiceInstrumentoBuscado >= 0){
                intrumentos.splice(indiceInstrumentoBuscado,1);
            }
        });
        return intrumentos;
    }

    /**
     * Actualiza el contenido del carro
     */
    carcaActualizaCarro() {
        //RECUPERA EL CARRO DE COMPRA
        let carroCompra = sessionStorage.getItem(this.ordenesService.STORAGE_PERSHING_COMPRA_CARRO);
        let carroup: InstrumentoCompraPershingJson[] = JSON.parse(carroCompra) || [];

        //VERIFICA SI HAY ALGO EN EL STORAGE Y EL OBSEERVABLE ESTA VACIO PARA CARGAR CON STORAGE (VALIDACION SOLO CUANDO SE REFRESCA EL BROWSER)

        if (this.carrito != undefined && this.carrito.length == 0) {
            if (carroup != undefined && carroup.length > 0) {
                this.carrito = carroup;
            }
        }

        //actualiza las cuentas agregadas
        if (this.carrito != undefined) {
            this.carrito.forEach((itemcarro) => {
                this.instrumentos.forEach((instruall) => {
                    if (instruall['fondo-ticker'] === itemcarro['fondo-ticker']) {
                        instruall.agregado = true;
                        instruall.seleccionado = true;
                    }
                });
            });
        }
    }

    toggleClick() {
        this.click = !this.click;
    }

    mensajeProcesaBackend(data) {
        this.mensaje = data;
    }

    tooltipmsgokSet(msg) {
        this.tooltipmsgok = msg;
    }
    tooltipmsgerrorSet(msg) {
        this.tooltipmsgerror = msg;
    }
    goToBack() {
        this.router.navigateByUrl(`main/client-search/summary/${this.validarClienteActivo().rut}/pershing-selector-cuenta/orden-cambio-fondo`);
    }

    /**
     * Consulta el cliente conectado de la ultima session en el Storage
     * @returns Cliente conectado
     */
    validarClienteActivo(): ClienteActivoDto {
        let data = sessionStorage.getItem('clientData');
        if (data) {
            const dataClient = JSON.parse(data);
            let rutlimpio = dataClient.rut.split('.').join('');
            let rutPartes = rutlimpio.split('-');
            let nombre = dataClient.name;
            return {
                rut: rutlimpio,
                rutparteentera: Number(rutPartes[0]),
                rutdigitoverificador: rutPartes[1],
                email: this.recuperaEmail(dataClient),
                nombre: nombre,
            };
        } else {
            this.router.navigate(['main/client-search']);
            return undefined;
        }
    }

    recuperaEmail(dataClient) {
        let email = undefined;
            
        if (dataClient.clientInfo) {
            dataClient.clientInfo.forEach( artsa => {
                if (artsa.icon === 'mail') {
                    email = artsa.text;
                }
            })
        }
        if (dataClient.companyInfo) {
            dataClient.companyInfo.forEach( artsb => {
                if (artsb.icon === 'mail') {
                    email = artsb.text;
                }
            })
        }
        if (!email) email = 'sincorreo@sura.cl';
        return email;
    }
}
