import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    IFeedBackGenerado, IHistorial,
    IModelParams,
    IPreguntaGenerada,
    IRespuestaBot,
    IRespuestaConfiguracion,
} from '../../models/chat-bot.model';
import {  urlMsCR } from '@environment';
import { FeatureVarsService } from '../feature-vars/feature-vars.service';

@Injectable({
    providedIn: 'root',
})
export class ChatBotService {

    public terminosCondicionesChatBot: string;

    constructor(private httpClient: HttpClient, private featureVarsService: FeatureVarsService) {
    }

    public async evaluarConfiguracionChatBot(): Promise<IRespuestaConfiguracion> {
        try {
            const urlMS = this.obtenerUrl;
            const URL = `${urlMS}/chatbot/operaciones/evaluar-estado-chatbot`;
            this.setTerminoCondicionesChatBot();
            return this.httpClient.get<IRespuestaConfiguracion>(URL).toPromise();
        } catch (error) {
            console.log(error);
        }
    }

    public enviarMensaje(mensaje: string, comsumerId: string, prompt: string, modelParams: IModelParams, session: string): Promise<IRespuestaBot> {
            const urlMS = this.obtenerUrl;
            const URL = `${urlMS}/chatbot/operaciones/enviar-mensaje`;
            const body: IPreguntaGenerada = {
                temperatura: modelParams,
                promptId: prompt,
                consumerId: comsumerId,
                pregunta: mensaje,
                sessionChat: session
            };
            return this.httpClient.post<IRespuestaBot>(URL, body).toPromise();
    }

    public enviarFeedBack(comentario: string, historial: IHistorial[], idLenguaje, topico: string, usuario: string) {
        const urlMS = this.obtenerUrl;
        const URL = `${urlMS}/chatbot/operaciones/enviar-feedback`;
        const body: IFeedBackGenerado = {
            comentario: comentario,
            historial: historial,
            languageId: idLenguaje,
            topico: topico,
            usuario: usuario
        }
        return this.httpClient.post(URL, body).toPromise();
    }



    get obtenerUrl(){
        return urlMsCR;
    }

    private async setTerminoCondicionesChatBot(){
        try {
            if(!this.terminosCondicionesChatBot){
                const codigoVariable = 'CHATBOT_ASESOR_CONFIG';
                const { CHATBOT_ASESOR_CONFIG } = await this.featureVarsService.getMapped({ codigoVariable });
                const TERMINOS_CONDICIONES = CHATBOT_ASESOR_CONFIG['svrs']['TERMINOS_CONDICIONES']['valor'];
                if(TERMINOS_CONDICIONES){
                    this.terminosCondicionesChatBot = TERMINOS_CONDICIONES;
                } else {
                    this.terminosCondicionesChatBot = '';
                }
            } else { return; }
        } catch (err) {
            this.terminosCondicionesChatBot = '';
            console.log('NO ES POSIBLE CARGAR TERMINOS Y CONDICIONES CHATBOT, ' + err.message);
        }
    }
}