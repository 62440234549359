// Angular Core
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// RxJA
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Services
import { ClientService, ClientRequestService } from 'src/app/features/services';
import { DialogService, GtmService } from 'src/app/shared/services';

// Models
import { IRespuestaConfiguracion } from '../../../../models/client-summary/profitability.model';

// Components
import { DialogMessageComponent, OptionsMessage } from '../../../../../shared/components/dialog-message/dialog-message.component';

import * as utils from '../../../../../shared/helpers/utils';

import { DataClientModel } from 'src/app/features/models';
import { InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import * as moment from 'moment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
@Component({
    selector: 'app-profitability',
    templateUrl: './profitability.component.html',
    styleUrls: ['./profitability.component.scss'],
})
export class ProfitabilityComponent implements OnInit, OnDestroy {

    signedMandate: boolean;
    isBlacklist: boolean;
    isProductPershing: boolean;
    titleMessage: string;
    bodyMessage: string;

    blacklistEmitterSubscription;
    mostarMessage: boolean = false;
    loadedBlackList: boolean = false;
    loadedMandatoAFP: boolean = false;

    private unSubscribe$ = new Subject();
    blackListEmitter = new Subject();

    clientData: DataClientModel;
    dataClient$: Observable<DataClientModel>;

    @Input() profitabilityClient: IRespuestaConfiguracion

    constructor(
        private clientService: ClientService,
        private clientRequestService: ClientRequestService,
        private router: Router,
        private activateRouter: ActivatedRoute,
        private dialogService: DialogService,
        private gtmService: GtmService,
        private tracesService: TracesService,
    ) { }

    ngOnInit() {
        this.blacklistEmitterSubscription = this.fnBlacklistEmiter()
            .get()
            .subscribe(() => {
                if (this.loadedBlackList && this.loadedMandatoAFP) {
                    this.mostarMessage = false;
                    if (typeof this.signedMandate !== 'undefined') {
                        if ((this.isBlacklist && !this.signedMandate) || (!this.isBlacklist && !this.signedMandate)) {
                            // esta en blacklist y no firmo el mandato AFP
                            this.titleMessage = 'Variación de inversiones:';
                            this.bodyMessage = 'Infórmale a tu cliente que debe firmar el Mandato AFP para poder visualizar las Variaciones de inversiones';
                            this.mostarMessage = true;
                            
                        }
                        if (this.isBlacklist && this.signedMandate) {
                            // esta en blacklist y firmo el mandato AFP
                            this.titleMessage = 'Rentabilidad No disponible:';
                            this.bodyMessage = 'La rentabilidad está siendo revisada y por ahora no se encuentra disponible.';
                            this.mostarMessage = true;
                        }
                        
                    }
                }
            });
        const datosGananciaPerdida = this.profitabilityClient.datosGananciaPerdida;
        const validarDatosGananciaPerdida = (datosGananciaPerdida && datosGananciaPerdida.error) ? null : datosGananciaPerdida; 
        this.isBlacklist = !validarDatosGananciaPerdida;
        this.loadedBlackList = true;
        this.fnBlacklistEmiter().set(null);


        this.dataClient$ = this.clientService.getDataClient();
        this.dataClient$.pipe(takeUntil(this.unSubscribe$)).subscribe((data) => {
            const tieneProductosAFP = data.products?.some(item => item?.displayName === 'AFP' && item?.exists);
            this.clientData = data;
            if (!utils.isCompany(this.clientData.rut)) {
                // < 50000000 = persona natural
                // solo se hace esta validacion, si es persona natural, las empresas no firman mandato
                this.clientRequestService.signMandate.pipe(takeUntil(this.unSubscribe$)).subscribe((dataMandate) => {
                    this.signedMandate =  !tieneProductosAFP ? true : (dataMandate?.estado == "SinSolicitud" ? true : dataMandate?.mandatoSuscrito);
                    this.loadedMandatoAFP = true;
                    this.fnBlacklistEmiter().set(null);
                });
            } else {
                this.signedMandate = true;
            }
        });

        this.clientRequestService.clientBalances.pipe(takeUntil(this.unSubscribe$)).subscribe((data: any) => {
            this.isProductPershing = false;
            if (data) {
                for (let prod of data) {
                    if (prod.codigoCb == 'APV_PERSHING' || prod.codigoCb == 'NOAPV_PERSHING' || prod.type === 'RIA') {
                        this.isProductPershing = true;
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.unSubscribe$.next();
        this.unSubscribe$.unsubscribe();
        this.blacklistEmitterSubscription.unsubscribe();
    }

    async navigateToInvestmentReport() {
        await this.logData(
            {
                Categoria: "Resumen Cliente",
                Operacion: "Resumen Cliente",
                Metodo: "navigateToInvestmentReport()",
                eventoLog: "click crear informe inversiones",
                status: Status.OK,
                Secuencia: 0,
                EntradaLog: {
                    accion: 'click crear informe inversiones redireccionar',
                },
            },
            "log_negocio",
        );
        await this.router.navigate(['informe-plataforma-nacional'], {
            relativeTo: this.activateRouter,
        });
        
        this.gtmService.pushTagEventoInteractivo('evento-interactivo', 'Resumen Cliente', 'Click', 'Generar Informe');
    }

    async navigateToPershingReport() {
        await this.router.navigate(['informe-plataforma-internacional'], {
            relativeTo: this.activateRouter,
        });
        const logtemp: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status"> = {
            Categoria: "Resumen Cliente",
            Operacion: "Resumen Cliente",
            Metodo: "navigateToPershingReport()",
            eventoLog: "click crear informe pershing",
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click crear informe pershing redireccionar',
            },
        };
        this.logData(
            logtemp,
            "log_negocio",
        );
    }

    formatMonto(monto) {
        if (monto.toString().indexOf('-') !== -1) {
            monto = monto.split('-').join('');
            monto = '-' + monto;
        }

        return monto;
    }

    openDialog(message: string) {
        const optionsMessage: OptionsMessage = {
            textButton: 'Entendido',
            buttonStyle: 'outline-grey',
            showButton: true,
            sizeButton: 'lg',
            icon: 'assets/img/icono-escudo-info.svg',
            showIcon: true,
            titleMessage: 'Importante.',
            message,
        };
        const config = {
            panelClass: 'modal-md',
            disableClose: true,
            data: {
                optionsMessage,
            },
        };
        this.dialogService.openDialog(DialogMessageComponent, config);
    }

    fnBlacklistEmiter() {
        return {
            get: () => this.blackListEmitter.asObservable(),
            set: (value) => this.blackListEmitter.next(value),
        };
    }
    async logData(log: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status">, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                ...EntradaLog,
            };
            inputLog.Salida = "-";
            inputLog.Secuencia = 0;
            inputLog.Servicio = "";
            inputLog.Tipo_sesion = "ASESOR";
            inputLog.extra = {};

            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
        } catch (error) {
            console.error("error: ", error);
        }
    }
}
