import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-sura',
  templateUrl: './button-sura.component.html',
  styleUrls: ['./button-sura.component.scss'],
})
export class ButtonSuraComponent {
  @Input() text: string;
  @Input() buttonStyle: ButtonStyle;
  @Input() size: ButtonSize;
  @Input() icon: string;
  @Input() disabled: boolean;
  @Input() type: ButtonType = 'button';
}

export type ButtonSize = 'sm' | 'md' | 'xs' | 'lg';

export type ButtonStyle = 'txt' | 'outline-grey' | 'outline-blue' | 'transparent-btn-grey' | 'transparent-btn-link' | 'disabled';
export type ButtonType = 'button' | 'submit';
