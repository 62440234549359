export class InputMapLog {
    rut: string;
    valueLog: string;
}

export class ValueLog {

    accion: string;

    glosa?: string;

    tipoLog: LoggerTypes["type"];

    estado: EstadosLog["type"];

    servicio?: string;

    metodo?: LoggerMethods["type"];

    entrada?: any;

    salida?: any;

    stash?: any;

    destino?: BDLogsType["type"];

    tipoSesion: OperadorTypes["type"];

    repo?: string;
}

export class Entrada {
    entradaLog: EntradaLog;

    detalleLog: any;

    lineaNegocio: LineaNegocioTypes["type"];

    sessionCliente: string;

    tipoDispositivo: tipoDispositivoTypes["type"];

    fecha: Date;

    hostIP: string;

    modeloDispositivo: string;

    sistemaOperativo: string;

    navegador: string;

    rutCliente: string;

    rutEjecutivo: string;

    rutAsesor: string;

    nombreCliente: string;

    tipoPersona: string;

    canal: string;

    servicio: string;
}

export class EntradaLog {
    esEjecutivo: boolean;

    usuarioAsesor: string;

    fechaHoraOp: string;
}

export class LoggerTypes {
    type: "NEGOCIO" | "ERROR" | "TECNICO" | "FUNCIONAL" | "INFORMATIVO" | "OTROS" | "TRANSACCIONAL";
}

export class OperadorTypes {
    type: "CLIENTE" | "ASESOR" | "OPERACIONES" | "NEGOCIO" | "ADMINISTRATIVO" | "OTROS" | "NO DEFINIDO";
}

export class EstadosLog {
    type: "OK" | "NOK";
}

export class LoggerMethods {
    type: "LOG" | "ERROR" | "DEBUG" | "WARN" | "INFO" | "VERBOSE";
}

export class BDLogsType {
    type: "MDB" | "ELK" | "BOTH";
}

export class LineaNegocioTypes {
    type: ["VIDA", "FFMM", "AFP", "", "PERSHING"];
}

export class tipoDispositivoTypes {
    type: ["ANDROID", "IOS", "DESKTOP"];
}

export interface StashTypes {
    type: "log_negocio" | "log_tecnico" | "log_transaccion" | "log_error" | ""
}