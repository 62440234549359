import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nws-load-page-black',
  templateUrl: './load-page-black.component.html',
  styleUrls: ['./load-page-black.component.scss'],
})
export class LoadPageBlackComponent implements OnInit {

  constructor() {
    //vacio
  }

  ngOnInit() {
    //vacio
  }

}
