import { DecimalPipe } from '@angular/common';
import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Modifica el valor agregando puntos para los miles y una coma para los decimales.
 * @param {Number} decimales Cantidad de decimales. Por defecto *0*.
 */
@Directive({
  selector: '[appMaskCurrency]',
})
export class MaskCurrencyDirective {

  @Input() decimales: number = 0;

  decimalPipe = new DecimalPipe('es-CL');

  get digitsInfo() {
    return `1.0-${this.decimales}`;
  }

  constructor(
    private ngControl: NgControl
  ) { }
  
  @HostListener('blur') onBlur() {
    this.ngControl?.control?.markAsTouched();
  }

  @HostListener('change', ['$event']) onChange(event: InputEvent) {
    if (!(event.target instanceof HTMLInputElement)) {
      console.warn('La directiva MaskCurrencyDirective *appMaskCurrency* debe estar asociada a un input!');
      return;
    }
    
    const valorDigitado: string = event?.data;

    const value: string = event.target.value;

    const valorProcesado = this.procesarValores(value, valorDigitado);

    if (valorProcesado === false) return;

    event.target.value = valorProcesado;
    this.ngControl?.control?.setValue(valorProcesado);
  }

  /**
   * Procesa el valor y repara en todos los casos posibles al ingresar decimales.
   * @param {String} value Valor del input
   * @param {String} valorDigitado Valor que se digitó
   * @returns {String} Retorna el valor procesado o *false* si se requiere que no se cambie el valor.
   */
  private procesarValores(value: string, valorDigitado: string): string | false {
    if (!value) return false;

    if (this.decimales > 0) {
      value = this.soloNumeroComaPunto(value);
      const valorDecimales = this.checkDecimales(valorDigitado, value);
      if (valorDecimales === false) return false;
      value = valorDecimales;
    } else {
      value = this.soloNumeroPunto(value);
    }

    return this.transform(value);
  }

  private checkDecimales(valorDigitado: string, value: string) {

    const cantidadComas = value.split(',').length - 1;

    if (valorDigitado === ',' && (cantidadComas <= 1)) return false;

    if (cantidadComas > 1) {
      value = this.removerComas(value);
      return value;
    }

    if (this.checkCerosFinales(value)) return false;

    if (value === ',') {
      return '0,';
    }

    return value;
  }

  /** Cuando las comas son mayor a 1, se deben remover*/
  private removerComas(value: string) {
    value = this.transform(value.split(',')[0]) || '0';
    return value + ',';
  }

  private soloNumeroPunto(value: string) {
    value = value.replace(/[^\d.]/g, '');
    return value;
  }

  private soloNumeroComaPunto(value: string) {
    value = value.replace(/[^\d,.]/g, '');
    return value;
  }

  private transform(value: string) {
    value = value.replace(/\./g, '').replace(/,/g, '.');
    try {
      value = this.decimalPipe.transform(value, this.digitsInfo);
    } catch (error) {
      console.warn(error);
      value = '';
    }
    return value;
  }

  /** Deja que ingrese 0 en los decimales, siempre y cuando esté dentro de la cantidad de decimales */
  checkCerosFinales(value: string) {
    const [, decimales] = value.split(',');
    if (this.decimales > decimales?.length && decimales?.endsWith('0')) {
      return true;
    }
    return false;
  }

}
