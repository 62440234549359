import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VerPassInput } from './ver-pass-input.enum';
@Directive({
  selector: '[appVerPassInput]',
})
export class VerPassInputDirective implements OnChanges {
    private _estaMostrando = false;
    private _span: any;
    private _mostrarIcon = VerPassInput.mostrarIcon;
    private _ocultarIcon = VerPassInput.ocultarIcon;
    private _value: string = '';
    private _changes: any;
    @Input() set passwordValue( valor: string) {        
        if(!this._changes?.passwordValue?.previousValue) return;
        this._value = valor;
        this._span.style.cursor = (valor.length === 0) ? 'auto' : 'pointer';
    }
    constructor(private el:ElementRef){
        this._span = document.createElement('span');
        this._value = this.el.nativeElement.value
        this._span.innerHTML = this._ocultarIcon       
        this.setStyles(this._span)
        this._span.addEventListener('click', () => {
          this.toggle(this._span);
        });
        this.el.nativeElement.parentElement.appendChild(this._span)
      }

      ngOnChanges(changes: SimpleChanges): void {
        this._changes = changes;
      }

      setStyles(span: HTMLElement) {
        span.style.position = 'absolute';
        span.style.right = '5%';
        span.style.bottom = '1.9em';
        span.style.padding = '0.55em';
        span.style.zIndex = '100';
        span.style.cursor = 'pointer';
      }

      toggle(span: HTMLElement) {
        if(this._value.length === 0 && this._changes?.passwordValue?.currentValue?.length === 0) return;
        const input = this.el.nativeElement
        this._estaMostrando = !this._estaMostrando;
        if (this._estaMostrando) {
          input.setAttribute('type', 'text');
          span.innerHTML = this._mostrarIcon
        } else {
         input.setAttribute('type', 'password');
         span.innerHTML = this._ocultarIcon
        }
      }
}
