import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GtmService {

  constructor(private gtmService: GoogleTagManagerService) { }

  pushTag(event: string, page: string, pagename: string, isLogin?: boolean): void {
    
    let gtmTag = {
        event,
        page,
        pagename
    } as any;

    if (isLogin) {
        gtmTag.userID = JSON.parse(sessionStorage.getItem('executiveRut'));
    }

    this.gtmService.pushTag(_.pickBy(gtmTag));
  }


  pushTagEventoInteractivo(event: string, eventoCategoria: string,
    eventoAccion: string, eventoEtiqueta: string): void {
    const gtmTag = {
    'event': event,
    'evento-interactivo-categoria': eventoCategoria,
    'evento-interactivo-accion': eventoAccion,
    'evento-interactivo-etiqueta': eventoEtiqueta
    } as any;

    this.gtmService.pushTag(_.pickBy(gtmTag));
  }


}
