import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mmb-button',
  templateUrl: './mmb-button.component.html',
  styleUrls: ['./mmb-button.component.scss']
})
export class MmbButtonComponent implements OnInit {

  classesList =[]
  @Input() text: string;
  @Input() buttonStyle: ButtonStyle;
  @Input() modoMovil: boolean = false;
  @Input() movilCuadrado: boolean = false;
  @Input() size: ButtonSize;
  @Input() icon: string;
  @Input() iconShowDesktop: boolean = true;
  @Input() disabled: boolean;
  @Input() type: ButtonType = 'button';
  @Input() loading?: boolean = false;

  ngOnInit() {
    if (this.buttonStyle != null) this.classesList.push(this.buttonStyle);
    if (this.size != null) this.classesList.push(this.size);
    if (this.modoMovil) {
      if (this.movilCuadrado) {
        this.classesList.push("boton-modo-movil-cuadrado");
      } else {
        this.classesList.push("boton-modo-movil-normal");
      }
    }
    if (this.disabled) {
      this.classesList.push("disabled");
    }
    if (!this.iconShowDesktop) this.classesList.push("icono-desactivar-desktop");
   
  }
}

export type ButtonSize = 'sm' | 'md' | 'xs' | 'lg';

export type ButtonStyle = 'txt' | 'outline-grey' | 'outline-blue' | 'transparent-btn-grey' | 'transparent-btn-link';
export type ButtonType = 'button' | 'submit';
