import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appFormatNoSpaces]',
})
export class NoSpacesDirective {
  @Output() onenter: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('keyup', ['$event']) onBlur(ev: Event) {
    const htmlInputElement: HTMLInputElement = ev.target as HTMLInputElement;
    if (!htmlInputElement.value) {
      htmlInputElement.value = '';
    }else {
      htmlInputElement.value = htmlInputElement.value?.split(" ").join("");
    }

    htmlInputElement.dispatchEvent(new Event('input'));
  }

  @HostListener('keydown.enter', ['$event']) onKeydownHandler(_: KeyboardEvent) {
    this.onenter.emit('enter');
  }
}
