import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
    
    public configuracionModal: ModalOptions;

    constructor(
      public dialogRef: MatDialogRef<ConfirmModalComponent>, 
      @Inject(MAT_DIALOG_DATA) public data: any) {

        if (data) {
          this.configuracionModal = data.optionsMessage;
        } else {
          this.configuracionModal = {
            titulo: "¿ Estas seguro ?",
            mensaje : "",
            labelacepta: "CONFIRMAR",
            labelarechaza: "CANCELAR" 
          }
        }

      }

    closeDialog(resultado: string) {
        this.dialogRef.close(resultado);
    }

    goToOk() {
      this.closeDialog('OK');
    }

    goToCancel() {
      this.closeDialog(null);
    }
  
    
  }
  
  export type ModalOptions =  {
    titulo: string,
    mensaje: string,
    labelacepta: string,
    labelarechaza: string,
  };