import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ClientFilterDataService {

    constructor() { }

    getDatosCliente(datosCliente) {
        try {
            const nombreCompleto = datosCliente.NombreCliente.toUpperCase();
            const nombres = this.getNombreCliente(nombreCompleto);
            const largo_nombres = nombres.length;
            let nombre = '';
            let apellido = '';
    
            switch (largo_nombres) {
                case 1:
                    nombre = nombres[0];
                    break;
                case 2:
                    nombre = nombres[0];
                    break;
                case 3:
                    nombre = nombres[0] + ' ' + nombres[1];
                    break;
                case 4:
                    nombre = nombres[0] + ' ' + nombres[1];
                    break;
                case 5:
                    nombre = nombres[0] + ' ' + nombres[1] + ' ' + nombres[2];
                    break;
                case 6:
                    nombre = nombres[0] + ' ' + nombres[1] + ' ' + nombres[2];
                    break;
                case 7:
                    nombre = nombres[0] + ' ' + nombres[1] + ' ' + nombres[2] + ' ' + nombres[3];
                    break;
                default:
                    nombre = nombres[0] + ' ' + nombres[1];
                    break;
            }
            let apellidoArray = nombreCompleto.split(nombre);
            apellido = apellidoArray[1];
            nombre = nombre;
            return !apellido ? { nombre: 'SIN DATO', apellido: 'SIN DATO' }:{ nombre: nombre, apellido: apellido };
        } catch (error) {
            console.error(error)
        }
    }

    getNombreCliente(nombreCompleto) {
        try {
            const palabras_especiales = ['DA', 'DE', 'DEL', 'LA', 'LAS', 'LOS', 'MAC', 'MC', 'VAN', 'VON', 'Y', 'I', 'SAN', 'SANTA'];
            const fullname = nombreCompleto.split(' ').filter((x) => x !== '');
            let prev = '';
            let nombres: any[] = [];
            fullname.forEach(item => {
                if (item.includes(palabras_especiales)) {
                    prev += item + ' ';
                } else {
                    nombres.push(prev + item);
                    prev = '';
                }
            });
            return nombres;
                
        } catch (error) {
         console.error(error)
        }
    }
}
