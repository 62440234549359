import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { AliasInputLogType, InputLog } from 'src/app/features/models/log/InputLog';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import { DatosCliente } from '../interfaces/datos-cliente.interface';

@Injectable({
    providedIn: 'root',
})
export class LogComplianceService {

    constructor(
        private tracesService: TracesService
    ) { }

    async logData(entradaLog: DatosCliente, log: Partial<InputLog> & Pick<InputLog, AliasInputLogType>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;
            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: entradaLog.rutCliente.split('.').join(''),
                nombre: entradaLog.nombreCliente,
                apellido: entradaLog.apellidoCliente,
                fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                res: true,
                ...EntradaLog,
            };
            inputLog.Salida = "-";
            inputLog.Secuencia = 0;
            inputLog.Servicio = "";
            inputLog.Tipo_sesion = "ASESOR";
            inputLog.extra = {};

            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
            return;
        } catch (error) {
            console.error("error: ", error);
        }
    }

}
