import { Injectable } from "@angular/core";
import * as contentful from "contentful";
import { RequestHandlerService } from "../../../core/services/request-handler/request-handler.service";
import { environment } from "@environment";
import { InternalDniResponse, SuraActionsExecutive } from "../../models/contentful.model";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class ContentfulService {
  private client = contentful.createClient({
    space: environment.KEYS.CONTENTFUL.SPACE_ID,
    accessToken: environment.KEYS.CONTENTFUL.TOKEN,
  });

  constructor(
    private requestHandler: RequestHandlerService,
    private http: HttpClient
  ) {
  }

  public getDataFromEntryId(entryId?: string) {
    const url = `${environment.ENDPOINTS.BFF.BFF_ASESORES}/contentful/entry-id/${entryId}`;
    return this.requestHandler.doGet<any>(url);
  }

  public getShowPollService(){
    return this.getEntryFromFront('7vgeKEzSumbkjpkhBCMpAG');
  }

  public  validateInternalDni(dni: string) {
    const dniNumber = Number(dni.replace(".", "").replace(".", "").replace("-", ""));
    const url = `${environment.ENDPOINTS.BFF.BFF_ASESORES}/contentful/can-serch-dni/${dniNumber}`;
    return this.requestHandler.doGet<InternalDniResponse>(url);
  }

  public async getEntryFromFront(entryId: string) {
    
    /**
     * DADO QUE ES MUY COMPLICADO, SE ESTABLECE ALMACENAR EN SESION STORAGE TODOS LOS CONTENTFUL 
     * ID QUE SE LLAMEN, ASÍ MEJORAR LA DISPONIBILIDAD DE LA WEB DE ASESOR.
     */
    const identificador = "contentfulId-" + entryId;

    try{
      if(sessionStorage.getItem(identificador)){
        return JSON.parse(sessionStorage.getItem(identificador));
      }
    }catch(err){
      console.log("Se consulta Entry Id:", entryId)
    }

    try {
      const response: any = await this.client.getEntry(entryId);
      const fields = response?.fields;
      sessionStorage.setItem(identificador, JSON.stringify(fields));
      return fields;
    } catch (error) {
      console.error(error);
      return null;
    }
  }


}
