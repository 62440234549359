import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { collapseOnLeaveAnimation, expandOnEnterAnimation, fadeInExpandOnEnterAnimation, fadeInOnEnterAnimation, fadeOutCollapseOnLeaveAnimation, fadeOutOnLeaveAnimation } from "angular-animations";
import { IAnimationOptions } from "angular-animations/common/interfaces";
import * as moment from "moment";
import { TracesService } from "src/app/core/services/traces/traces.service";
import { AliasInputLogType, InputLog } from "src/app/features/models/log/InputLog";
import { Status } from "src/app/features/models/log/status";
import { ConfigIndexLogStash } from "../../models";

const durationAnimation = 200;
const optionsAnimations: IAnimationOptions = {
  duration: durationAnimation,
  delay: 0,
};

@Component({
  selector: "app-alert-market",
  templateUrl: "./alert-market.component.html",
  animations: [
    fadeInExpandOnEnterAnimation(optionsAnimations),
    expandOnEnterAnimation(optionsAnimations),
    collapseOnLeaveAnimation(optionsAnimations),
    fadeOutCollapseOnLeaveAnimation(optionsAnimations),
    fadeInOnEnterAnimation(optionsAnimations),
    fadeOutOnLeaveAnimation(optionsAnimations),
  ],
  styleUrls: ["./alert-market.component.scss"],
})
export class AlertMarketComponent implements OnInit {
  @Input() alert: any[];

  expand = false;

  public isActive = false;

  constructor(private changeDetectorRef: ChangeDetectorRef, private tracesService: TracesService) { }

  ngOnInit(): void { }

  ngOnChanges() {
    if (this.alert) {
      this.getIsActive();
    }
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  async getIsActive() {
    try {
      this.isActive = true;

    } catch (error) {
      this.isActive = false;

    }
  }

  alertExpand() {
    this.expand = !this.expand;
  }

  close(id: number) {
    const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
      Categoria: "Notificaciones Asesor",
      Operacion: "Click Acción Notificaciones Asesor",
      Metodo: "close()",
      eventoLog: "Click Acción Notificaciones Asesor",
      status: Status.OK,
      Secuencia: 1,
      EntradaLog: {
        accion: this.alert[id],
        idnotificacion: id,
        detallenotificacion: this.alert[id]?.epigrafe
      },
    };
    this.logData(
      logtemp,
      "log_negocio",
    );

    this.alert = this.alert.filter((item) => item.id !== id)
    let alertas = { alertasMercado: this.alert, popupAdministrables: [] }
    sessionStorage.setItem("alertas", JSON.stringify(alertas));

  }

  async logData(log: Partial<InputLog> & Pick<InputLog, AliasInputLogType>, indexLogStash: ConfigIndexLogStash) {
    try {
      const { EntradaLog, ...logData } = log;

      let inputLog: InputLog = new InputLog();
      inputLog.DetalleAccionExtra = {};
      inputLog.Categoria = log.Categoria;
      inputLog.Operacion = log.Operacion;
      inputLog.EntradaLog = {
        rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
        fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        ...EntradaLog,
      };
      inputLog.Salida = "-";
      inputLog.Secuencia = 0;
      inputLog.Servicio = "";
      inputLog.Tipo_sesion = "ASESOR";
      inputLog.extra = {};

      inputLog = {
        ...inputLog,
        ...logData,
      };
      await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
    } catch (error) {
      console.error("error: ", error);
    }
  }

}
