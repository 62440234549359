import { Component, Input } from '@angular/core';

@Component({
  selector: 'mmb-tr',
  templateUrl: './mmb-tr.component.html',
  styleUrls: ['./mmb-tr.component.scss'],
})
export class MmbTrComponent {

  @Input() addHover ?: boolean = false;
  @Input() itemcard ?: boolean = false;
  @Input() bordeitem ?: boolean = true;
  @Input() hiddenmovil ?: boolean = false;

  addHoverMode() {
    return this.addHover ? 'cont-rows rows-det hover-tr' : 'cont-rows rows-det';
  }
}
