// Angular Core
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Environment
import { environment } from '@environment';

// Models
import { InvestmentReportResponseInterface } from 'src/app/features/models';

@Injectable({
    providedIn: 'root',
})
export class InvestmentReportService {
    private URL_BFF = environment.ENDPOINTS.BFF.BFF_ASESORES;

    constructor(private readonly http: HttpClient) {}

    public getInvestmentReport(rut: string, period: string, date: string, accountName: string): Promise<InvestmentReportResponseInterface> {
        return new Promise((resolve, reject) => {
            this.http
                .get<InvestmentReportResponseInterface>(`${this.URL_BFF}/investment-report/${rut}/${period}/${date}/${accountName}`, {})
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public getInvestmentPershingReport(rut: string, period: string, date: string, accountName: string): Promise<InvestmentReportResponseInterface> {
        return new Promise((resolve, reject) => {
            this.http
                .get<InvestmentReportResponseInterface>(`${this.URL_BFF}/investment-report/pershing-report/${rut}/${period}/${date}/${accountName}`, {})
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
