// Angular Core
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// Services
import { ClientRequestService } from 'src/app/features/services';

// Validators
import { rutEmpresaValidator, rutValidator } from '../../../shared/validators/rut.validator';
import { ClientSearchService, GtmService, LoadingService } from 'src/app/shared/services';
import { AliasInputLogType, InputLog } from '../../models/log/InputLog';
import { Status } from '../../models/log/status';
import { Compliance } from './enums/compliance.enum';
import { LogComplianceService } from './services/log-compliance.service';
import { DatosCliente } from './interfaces/datos-cliente.interface';
import { UtilService } from './services/util.service';
import { mensajePorDefecto, mensajesCompliance } from './constants/mensaje-compliance.const';
import { nombreValidator } from 'src/app/shared/validators/nombre.validator';
import { DatosProductoBELModel } from '../../models';

@Component({
    selector: 'app-compliance',
    templateUrl: './compliance.page.html',
    styleUrls: ['./compliance.page.scss'],
})

export class CompliancePage {
    mensajeInicioDesktop: string = Compliance.MensajeInicioDesktop;
    mensajeInicioMobile: string = Compliance.MensajeInicioMobile;
    botonVisible: boolean = false;
    rutClienteLimpio: string = '';
    mensajeCompliance: string;
    activarBoton: boolean = false;
    disabledForm: boolean = false;
    showMessage: boolean = false;
    datosCliente: DatosCliente;
    nombreCliente: string;
    apellidoCliente: string;
    rutCliente: string;
    tipoMensaje: string;
    tituloMensaje: string;
    mandato: boolean;

    logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
        Categoria: "Buscador Cliente Cumplimiento",
        Operacion: "Buscador Cliente Cumplimiento",
        Linea_negocio: "VIDA",
        Metodo: "searchClient()",
        eventoLog: "Click boton buscar",
        status: Status.OK,
        Secuencia: 0,
        EntradaLog: {
            accion: 'Click boton buscar buscando',
        },
    };

    cumplimientoForm = new FormGroup({
        nombreCliente: new FormControl({ value: '', disabled: false }, [Validators.required, nombreValidator]),
        apellidoCliente: new FormControl({ value: '', disabled: false }, [Validators.required, nombreValidator]),
        rutCliente: new FormControl({ value: '', disabled: false }, [rutValidator, rutEmpresaValidator]),
    });

    constructor(
        private clientRequestService: ClientRequestService,
        private loaderService: LoadingService,
        private gtmService: GtmService,
        private logCompliance: LogComplianceService,
        private utilService: UtilService,
        private clientSearchService: ClientSearchService
    ) { }

    async searchClient() {
        if (this.activarBoton) {
            return;
        }
        if (!this.cumplimientoForm.valid) {
            this.utilService.validateAllFormFields(this.cumplimientoForm);
            return;
        }

        this.datosCliente = this.cumplimientoForm.value;
        this.nombreCliente = this.datosCliente.nombreCliente;
        this.apellidoCliente = this.datosCliente.apellidoCliente;
        this.rutCliente = this.datosCliente.rutCliente;

        const mandato = await this.clientRequestService.getSignedMandate({ rut: this.datosCliente.rutCliente });
        this.mandato = mandato?.mandatoSuscrito;

        let [rut, dv] = this.utilService.limpiarRutCliente(this.datosCliente.rutCliente);
        this.rutClienteLimpio = `${rut}-${dv}`;
        this.loaderService.showLoading(false);

        this.clientRequestService.searchClientCompliance({ rut: this.rutClienteLimpio })
            .then((clientSearchModel: any) => {
                this.filtrarDatosCliente(clientSearchModel);
            }).catch((error) => {
                console.log(error)
                this.gtmService.pushTagEventoInteractivo('evento-interactivo', 'Buscar estado Cumplimiento', 'Click', 'Sin Resultados');
                this.logtemp.status = Status.NOK
                this.logCompliance.logData(
                    this.cumplimientoForm.value,
                    this.logtemp,
                    "log_error",
                );
                const codigoMarcaCompliance = error.status === 403 ? 6 : 5;
                this.obtenerMensajeCompliance(this.rutClienteLimpio, codigoMarcaCompliance);
                this.botonVisible = true;
            });
    }

    filtrarDatosCliente(datosGuc) {
        const productosGuc = datosGuc.DatosProductoGeneral.DatosProductoBEL;
        if (this.clientSearchService.validarSegmento(datosGuc) && datosGuc.EstadoCliente === "V") {
            const listAFP: DatosProductoBELModel[] = productosGuc.filter((item) => {
                return item.DescripcionLinea === 'AFP' || item.DescripcionLinea === 'COL';
            });
            if (listAFP.length === datosGuc.DatosProductoGeneral.DatosProductoBEL.length && this.mandato === false) {
                this.getCumplimientoNoCliente(this.rutCliente, this.nombreCliente, this.apellidoCliente);
            } else {
                this.getCumplimientoCliente(this.rutCliente, this.nombreCliente, this.apellidoCliente);
            }
        } else {
            this.getCumplimientoNoCliente(this.rutCliente, this.nombreCliente, this.apellidoCliente);
        }
        this.accionFormulario(true);

        this.logCompliance.logData(
            this.cumplimientoForm.value,
            this.logtemp,
            "log_negocio",
        );
        this.gtmService.pushTagEventoInteractivo('evento-interactivo', 'Buscar estado Cumplimiento', 'Click', 'Buscar');
    }

    async getCumplimientoCliente(rutCliente: string, nombreCliente: string, apellidoCliente: string) {
        try {
            let [rutClienteLimpio] = this.utilService.limpiarRutCliente(rutCliente);
            const accionInversion = await this.clientRequestService.getAccionInversion(rutClienteLimpio, nombreCliente, apellidoCliente);
            const codigoMarcaCompliance = !accionInversion.compliance.marcaCompliance ? 1 : 2;
            this.obtenerMensajeCompliance(rutCliente, codigoMarcaCompliance);
        } catch (error) {
            this.gtmService.pushTagEventoInteractivo('evento-interactivo', 'Buscar estado Cumplimiento Cliente', 'Click', 'Sin Resultados');
            this.logtemp.status = Status.NOK
            this.logCompliance.logData(
                this.cumplimientoForm.value,
                this.logtemp,
                "log_error",
            );
            this.obtenerMensajeCompliance();
            this.botonVisible = true;
        }
    }

    async getCumplimientoNoCliente(rutCliente: string, nombreCliente: string, apellidoCliente: string) {
        try {
            nombreCliente = nombreCliente.toLocaleUpperCase();
            apellidoCliente = apellidoCliente.toLocaleUpperCase();
            const accionInversionNoCliente = await this.clientRequestService.getAccionInversionNoCliente(this.rutClienteLimpio, nombreCliente, apellidoCliente);
            const marcaCompliance = accionInversionNoCliente?.marcaCompliance;
            const codigoMarcaCompliance = marcaCompliance ? 3 : 4;
            this.obtenerMensajeCompliance(rutCliente, codigoMarcaCompliance);
        } catch (error) {
            this.gtmService.pushTagEventoInteractivo('evento-interactivo', 'Buscar estado Cumplimiento No Cliente', 'Click', 'Sin Resultados');
            this.logtemp.status = Status.NOK
            this.logCompliance.logData(
                this.cumplimientoForm.value,
                this.logtemp,
                "log_error",
            );
            this.obtenerMensajeCompliance();
            this.botonVisible = true;
        }
    }

    resetFormulario() {
        this.showMessage = false;
        this.mensajeCompliance = '';
        this.cumplimientoForm.reset();
        this.accionFormulario(false);
    }

    obtenerMensajeCompliance(rut?: string, codigoMensaje?: number) {
        let [datosMensaje] = mensajesCompliance(rut).filter(mensaje => mensaje.id === codigoMensaje);
        let mensajes: any = datosMensaje ?? mensajePorDefecto();
        this.mensajeCompliance = mensajes.mensaje;
        this.tipoMensaje = mensajes.color;
        this.tituloMensaje = mensajes.titulo;
        this.accionFormulario(true);
        this.loaderService.hideLoading();
    }

    accionFormulario(activarFormulario: boolean) {
        if (!activarFormulario) {
            this.cumplimientoForm.enable();
            this.botonVisible = false;
            this.activarBoton = false;
            this.showMessage = false;
        } else {
            this.cumplimientoForm.disable();
            this.botonVisible = true;
            this.activarBoton = true;
            this.showMessage = true;
        }
    }

    onCloseButton() {
        this.resetFormulario();
    }
}
