import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeAssetAllocationComponent } from './home-asset-allocation.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { SuraCommonsUiModule } from '@surainvestments/sura-common-ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HighchartsChartModule } from 'highcharts-angular';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { FiltroTablaAssetComponent } from './filtro-tabla-asset/filtro-tabla-asset.component';
import {MatDialogModule} from '@angular/material/dialog';
import { LayoutModule } from 'src/app/shared/modules/layout/layout.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HighchartsChartModule,
    FormsModule, 
    ReactiveFormsModule,
    RouterModule,
    SuraCommonsUiModule,
    LayoutModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule
  ],
  declarations: [
    HomeAssetAllocationComponent,
    FiltroTablaAssetComponent
  ],
  exports:[
    HomeAssetAllocationComponent,
    FiltroTablaAssetComponent
  ],
  entryComponents:[
    FiltroTablaAssetComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class HomeAssetAllocationModule { }
