import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightText',
})
export class HighlightTextPipe implements PipeTransform {
  transform(value: string, query: string): unknown {
    if (!query) {
      return value;
    }
    return value.replace(new RegExp(query, 'gi'), (match) => {
      return '<span class="highlight-text">' + match + '</span>';
    });
  }
}
