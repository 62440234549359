import { ClientService } from './client/client.service';
import { LoginService } from './login/login.service';

export * from './client/client.service';
export * from './client-portfolio/client-portfolio.service';
export * from './client-portfolio-request/client-portfolio-request.service';
export * from './client-request/client-request.service';
export * from './client-transaction/client-transaction.service';
export * from './investment-report/investment-report.service';
export * from './login/login.service';
export * from './client-contactability/client-contactability.service';
export * from './client-popup/client-popup.service';

export const AppServices = [LoginService, ClientService];
