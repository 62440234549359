
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services';

@Component({
  selector: "app-multiple-sesion",
  templateUrl: "./multiple-sesion.component.html",
  styleUrls: ["./multiple-sesion.component.scss"],
})
export class MultipleSesionComponent implements OnInit {

  constructor(private appService: AppService) {}

  ngOnInit() {}

  cerrar(){
    this.appService.closeMultisesion();
  }

}