import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { TokenRecaptchaModel } from '../../../features/models';

@Injectable({
  providedIn: 'root',
})
export class ReCaptchaService {
  constructor(private reCaptchaV3Service: ReCaptchaV3Service) {}

  public async executeAction(action: string): Promise<any> {
    return await this.reCaptchaV3Service.execute(`${action}_nwse_bff`).toPromise()
  }
}
