import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ColumnDefinition,
  SortStateType,
} from '@surainvestments/sura-common-ui/app/sura-ui-kit/models/table.model';
import { Pagination } from '@surainvestments/sura-common-ui/app/sura-ui-kit/components/pagination/pagination.component';

//TODO: Este servicio debe ser eliminado
@Injectable()
export class TableService<T> {
  private readonly renderedData$ = new BehaviorSubject<T[]>([]);
  private readonly pagination$ = new BehaviorSubject<Pagination>(null);

  private readonly sortState = new Map<keyof T, SortStateType>();

  private readonly defaultColumn: ColumnDefinition<T> = {
    align: 'left',
    sizeColumn: 'medium',
    default: '-',
    key: null,
    type: null,
    columnTitle: null,
  };

  public data() {
    return combineLatest([this.renderedData$, this.pagination$]).pipe(
      map(([data, pagination]) => {
        if (pagination === null) {
          return data;
        }
        if (data?.length === 0) {
          return [];
        }
        return data.slice(pagination.startPagination, pagination.endPagination);
      }),
    );
  }

  public sortList(col: ColSort<T>, dataSource: ReadonlyArray<T>, sortValue?: SortStateType) {
    this.resetStates(col);
    const sortState = sortValue ? sortValue : this.sortState.get(col.key);
    let sortedData: T[];
    switch (sortState) {
      case 'asc':
        sortedData = [...dataSource].sort(col.sortFunc);
        this.sortState.set(col.key, 'desc');
        break;
      case 'desc':
        sortedData = [...dataSource].sort((a, b) => {
          return col.sortFunc(a, b) * -1; // Invierte la comparación
        });
        this.sortState.set(col.key, 'default');
        break;
      case 'default':
        sortedData = [...dataSource];
        this.sortState.set(col.key, 'asc');
        break;
    }
    this.renderedData$.next(sortedData);
  }

  private resetStates(col: ColSort<T>) {
    Array.from(this.sortState.keys()).forEach((key) => {
      if (key === col.key) {
        return;
      }
      this.sortState.set(key, 'asc');
    });
  }

  public mapColumns(columns: ColumnDefinition<T>[]) {
    return columns.map((col) => ({ ...this.defaultColumn, ...col }));
  }

  public getSortStateIcon(col: ColumnDefinition<T>): TypeIcons {
    if (!this.sortState.has(col.key)) {
      this.sortState.set(col.key, 'asc');
    }
    const state = this.sortState.get(col.key);
    switch (state) {
      case 'asc':
        return 'unfold_more';
      case 'desc':
        return 'expand_less';
      case 'default':
        return 'expand_more';
    }
  }

  public getColumnsNames(columns: ColumnDefinition<T>[]): string[] {
    return columns.map((col, index) => `${col.key.toString()}-${index}`);
  }

  public destroy() {
    this.renderedData$.complete();
    this.renderedData$.unsubscribe();

    this.pagination$.complete();
    this.pagination$.unsubscribe();
  }

  set pagination(pag: Pagination) {
    this.pagination$.next(pag);
  }

  set renderedData(data: T[]) {
    this.renderedData$.next(data);
  }

  get sortStateMap() {
    return this.sortState;
  }
}

export type TypeIcons = 'unfold_more' | 'expand_less' | 'expand_more';
export type ColSort<T> = { key: keyof T; sortFunc: (a: T, b: T) => number };
