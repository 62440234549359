import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'monthNamePipe' })
export class MonthNamePipe implements PipeTransform {
  transform(monthNumber: number): string {
    return monthNumberToString(monthNumber);
  }
}

export const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
export const nameMonthEnglish = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function monthNumberToString(monthNumber: number): string {
  if (monthNumber != null) {
    return monthNames[monthNumber];
  }
  return '';
}
export function monthNameEnglishToSpanish(monthName: string): string {
  if (monthName != null) {
    const index = nameMonthEnglish.findIndex((x) => x === monthName);
    if (index >= 0) {
      return monthNames[index];
    }
  }
  return '';
}
