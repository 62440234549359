import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'porcentajeOBlanco'
})
export class PercentageOrBlankPipe implements PipeTransform {

    transform(valor: string | number): string {
        const valorReplace = String(valor).replace(',', '.')
        let valorPorcentaje = Number(valorReplace);
        if (isNaN(valorPorcentaje) || valorPorcentaje === Infinity || valorPorcentaje === -Infinity) {
            return '';
        }
        valorPorcentaje = valorPorcentaje;
        return `${valorPorcentaje.toFixed(2).replace('.', ',')}%`;
    }

}
