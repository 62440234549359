import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrdenesPershingService } from 'src/app/features/services/ordenes/pershing/ordenes.pershing.service';
import { DialogService } from 'src/app/shared/services';
import { ChipFilter, EFilterParams, IDChip, InstrumentoCompraPershingJson, MensajeBackend, ParametroSistemaPershingJson, SaldoCustodioCtaPershingJson } from '../../../../../../models/ordenes/pershing/orden.modelo';
import { ModalFiltroInstrumentosComponent } from '../../components/modal-filtro-instrumentos/modal-filtro-instrumentos.component';

@Component({
    selector: 'app-detalle-instrumentos-compra-seleccionar',
    templateUrl: './detalle-instrumentos-compra-seleccionar.component.html',
    styleUrls: ['./detalle-instrumentos-compra-seleccionar.component.scss'],
})
export class DetalleInstrumentosCompraSeleccionarComponent implements OnInit {
    //CARRO DE COMPRA
    @Input() cuenta: SaldoCustodioCtaPershingJson;
    @Input() carrito: InstrumentoCompraPershingJson[];
    @Input() instrumentos: InstrumentoCompraPershingJson[];
    @Input() instrumentosfiltrados: InstrumentoCompraPershingJson[];
    @Input() largopagina: number;
    @Input() totalinstrumentos: number;
    @Input() custodiocompratotalUSD: number;
    @Input() parametrospershing: ParametroSistemaPershingJson[];

    //mensaje resultado ejecucion servicios
    @Output() mensaje = new EventEmitter<MensajeBackend>();
    @Output() tooltipmsgok = new EventEmitter<string>();
    @Output() tooltipmsgerror = new EventEmitter<string>();

    //FILTROS
    filtromanager?: string;
    filtrocategoria?: string;
    filtrorecomendacion?: string;
    filtronombreinstrumento?: string;
     //largo de pagina
     filtrosSelected: ChipFilter[] = [];

    //largo de pagina

    paginaactiva: number = 0;

    buscarForm: FormGroup;
    compraForm: FormGroup;

    tipoCompra = [
        { id: 'P', valor: '%' }, //porcentae
        { id: 'M', valor: 'USD$' }, //monto
        //{ id: 'C', valor: '#' }, //cuotas
    ];

    public MONTO_MINIMO: number = 1;
    public MONTO_MAXIMO: number = 9999999999;
    public PORCENTAJE_MINIMO: number = 1;
    public PORCENTAJE_MAXIMO: number = 100;
    public USD_INGRESO: number;

    //FORM CONTROLES
    public nombrefondo = new FormControl('', null); //[Validators.required]);
    public tipoingreso = new FormControl('', [Validators.required]);
    public montocandidadporcentaje = new FormControl('', [Validators.required, this.validateMinValue(this.MONTO_MINIMO), this.validateMaxValue(this.MONTO_MAXIMO)]);

    vistaMovil: boolean = false;

    @HostListener("window:resize", ["$event"])
    onResize($event) {
        this.vistaMovil = $event.target.innerWidth < 768;
    }

    constructor(private dialogService: DialogService, private ordenesService: OrdenesPershingService, private fb: FormBuilder) {}

    ngOnInit(): void {
        //busca insturmentos segurn cuenta preferente
        this.inicializaFormulario();
    }

    inicializaFormulario() {
        //inicializa formulario
        this.compraForm = this.fb.group({
            tipoingreso: this.tipoingreso,
            montocandidadporcentaje: this.montocandidadporcentaje,
        });
        this.compraForm.setValue({
            tipoingreso: 'P',
            montocandidadporcentaje: null,
        });

        this.buscarForm = this.fb.group({
            nombrefondo: this.nombrefondo,
        });
    }

    /**
     * Cambia el tipo de compra
     * @param $event
     */
    tipoIngresoChange(instrumento): void {
        if (this.tipoingreso.value === 'P') {
            let cuandoDisponible = Number(this.custodiocompratotalUSD);
            let cuandoCarro = Number(this.totalcarritoUSD());
            let porcentajeEquivalencia = (100 * cuandoCarro) / cuandoDisponible;
            this.PORCENTAJE_MINIMO = 1;
            this.PORCENTAJE_MAXIMO = Number((100 - porcentajeEquivalencia).toFixed(2));
            if (this.PORCENTAJE_MINIMO > this.PORCENTAJE_MAXIMO) this.PORCENTAJE_MINIMO = this.PORCENTAJE_MAXIMO;
            this.USD_INGRESO = 0;
            this.montocandidadporcentaje.setValidators([Validators.required, this.validateMinValue(this.PORCENTAJE_MINIMO), this.validateMaxValue(this.PORCENTAJE_MAXIMO)]);
            this.montocandidadporcentaje.updateValueAndValidity();
        }
        if (this.tipoingreso.value === 'M') {
            this.MONTO_MINIMO = 1;
            this.MONTO_MAXIMO = Number((this.custodiocompratotalUSD - this.totalcarritoUSD()).toFixed(2));
            if (this.MONTO_MINIMO > this.MONTO_MAXIMO) this.MONTO_MINIMO = this.MONTO_MAXIMO;
            this.USD_INGRESO = 0;
            this.montocandidadporcentaje.setValidators([Validators.required, this.validateMinValue(this.MONTO_MINIMO), this.validateMaxValue(this.MONTO_MAXIMO)]);
            this.montocandidadporcentaje.updateValueAndValidity();
        }
        this.actualizaValorIngresado(this.montocandidadporcentaje.value);
    }

    /**
     * Cambia el valor del monto
     * @param $event
     */
    montoCantidadPorcentajeChange($event) {
        let valortmp = Number($event.target.value.replaceAll('.', '').replaceAll(',', '.'));
        this.actualizaValorIngresado(valortmp);
    }

    totalcarritoUSD() {
        let totalUSD: number = 0;
        if (this.carrito.length > 0) {
            this.carrito.forEach((inst) => {
                totalUSD += inst.agregadoTotalInstruccionUSD;
            });
        }
        return totalUSD;
    }

    /**
     * Actualiza el valor
     * @param valortmp
     */
    actualizaValorIngresado(valortmp) {
        if (this.tipoingreso.value === 'P') {
            this.USD_INGRESO = this.custodiocompratotalUSD * (valortmp / 100);
        }
        if (this.tipoingreso.value === 'M') {
            this.USD_INGRESO = valortmp ? valortmp: 0;
        }
    }

    //agrega instrumento
    async showAgregarInstrumentoBtn(item) {
        this.closeItems();
        this.compraForm.setValue({
            tipoingreso: 'P',
            montocandidadporcentaje: null,
        });
        item.seleccionado = true;
        this.tipoIngresoChange(item);
    }

    //activa el aegragr
    async showAgregarInstrumento(control, item) {
        this.closeItems();
        this.compraForm.setValue({
            tipoingreso: 'P',
            montocandidadporcentaje: null,
        });
        this.compraForm.markAsUntouched();
        item.seleccionado = control.checked;
        this.tipoIngresoChange(item);
    }

    //cierra los demas items
    closeItems() {
        this.instrumentos.forEach((object) => {
            if (!object.agregado) object.seleccionado = false;
        });
    }


    //valida formulario y procesa
    goToAgregaInstrumento(instrumentoadd) {
        if (this.compraForm.valid) {

            let CTA_TD6: boolean = this.cuenta['numero-cuenta'].startsWith('TD6') ? true: false;
            let CTA_TD9: boolean = this.cuenta['numero-cuenta'].startsWith('TD9') ? true: false;
            let COMISION_USD: number = 0;
            if (CTA_TD6) COMISION_USD = this.parametrospershing.find(o => o.id === 'CARGO_USD_CUENTA_TD6')['valor-numerico']; 
            if (CTA_TD9) COMISION_USD = this.parametrospershing.find(o => o.id === 'CARGO_USD_CUENTA_TD9')['valor-numerico']; 
         

            //SETEA CAMPOS Y AGREGA
            instrumentoadd.agregado = true;
            instrumentoadd.tipocompra = this.tipoingreso.value;
            instrumentoadd.agregadoMontoCantidad = Number(this.montocandidadporcentaje.value.replaceAll('.', '').replaceAll(',', '.'));
            instrumentoadd.montocomisionUSD = COMISION_USD;
            if (instrumentoadd.tipocompra === 'P') {
                //porcenaje
                let valor = this.custodiocompratotalUSD * (instrumentoadd.agregadoMontoCantidad / 100);
                instrumentoadd.agregadoTotalInstruccionUSD = valor - COMISION_USD;
            }
            if (instrumentoadd.tipocompra === 'M') {
                //monto
                instrumentoadd.agregadoTotalInstruccionUSD = instrumentoadd.agregadoMontoCantidad - COMISION_USD;
            }
            //SUBE
            this.carrito.push(instrumentoadd);

            //carga en el storage
            sessionStorage.setItem(this.ordenesService.STORAGE_PERSHING_COMPRA_CARRO, JSON.stringify(this.carrito));

            this.tooltipmsgok.emit('¡ Orden agregada !');
            setTimeout(() => {
                this.tooltipmsgok.emit(null);
            }, 2000);

            //LOG DE ACCION
            this.ordenesService.saveLogAccionAsesor(
                'Ingreso Ordenes Pershing Off Line', 
                'Ingreso Ord Pershing Off Line Paso 1', 
                'DetalleInstrumentosCompraSeleccionarComponent - goToAgregaInstrumento()', 'ok', `Agrega instrumento de cambio de fondo destino`, instrumentoadd);
    

        } else {
            this.formularioNoValido();
        }
    }

    formularioNoValido() {
            //Verifica los campos
            if (!this.tipoingreso.valid) {
                this.tipoingreso.markAsTouched();
            } else {
                this.tipoingreso.markAsUntouched();
            }
            if (!this.montocandidadporcentaje.valid) {
                this.montocandidadporcentaje.markAsTouched();
            } else {
                this.montocandidadporcentaje.markAsUntouched();
            }
    }

    scrollTo(el: Element): void {
        if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    //cambio de pagina
    public async cambiarPagina(pageIndex: number) {
        this.paginaactiva = pageIndex;
        this.instrumentosfiltrados = this.recuperaInstrumentosFiltradosYPaginados();
    }

    //filtra el listado por nombre
    filtrarPorNombre() {
        if (this.buscarForm.valid) {
            this.paginaactiva = 0;
            this.filtronombreinstrumento = undefined;
            this.instrumentosfiltrados = this.recuperaInstrumentosFiltradosYPaginados();
        } else {
            //Verifica los campos
            if (!this.nombrefondo.valid) {
                this.nombrefondo.markAsTouched();
            } else {
                this.nombrefondo.markAsDirty();
            }
        }
    }

    /**
     * Solo entrega los instrumentos filtrados
     * @returns
     */
    recuperaInstrumentosFiltradosYPaginados() {
        let resultado: InstrumentoCompraPershingJson[] = [];
       
        if (this.nombrefondo.value != undefined && this.nombrefondo.value.length > 0) {
            this.filtronombreinstrumento = this.nombrefondo.value;
            this.buscarForm.setValue({
                nombrefondo: null
            });  
        }

        //CARGA UNIVERSO DE INSTRUMENTOS Y LUEGO APLICARAN LOS FILTROS
        if (this.instrumentos != undefined) {
            resultado = this.instrumentos;
            if (this.filtrocategoria) resultado = resultado.filter((item) => item['fondo-categoria-sura'] === this.filtrocategoria);
            if (this.filtromanager) resultado = resultado.filter((item) => item['fondo-manager'] === this.filtromanager);
            if (this.filtrorecomendacion) resultado = resultado.filter((item) => item['fondo-tipo-recomendacion'] === this.filtrorecomendacion);
            if (this.filtronombreinstrumento) resultado = resultado.filter((item) => item['fondo-descripcion'].toLowerCase().includes(this.filtronombreinstrumento.toLowerCase()));
        }
        this.totalinstrumentos = resultado.length;
        this.cargarFiltros();
        return resultado.slice(this.paginaactiva * this.largopagina, this.paginaactiva * this.largopagina + this.largopagina);
    }

    /**
     * Carga los filtros
     */
     cargarFiltros() {
        this.filtrosSelected = [];

         //Agrega el filtro nombre instrumento
         if (this.filtronombreinstrumento) {
            const index = this.filtrosSelected.length;
            this.filtrosSelected.push({
            text: this.filtronombreinstrumento,
            index,
            id: IDChip.param,
            paramKey: 'nombre' as EFilterParams,
            });
         }
         //Agrega el filtro categoria
         if (this.filtrocategoria) {
            const index = this.filtrosSelected.length;
            this.filtrosSelected.push({
            text: this.filtrocategoria,
            index,
            id: IDChip.param,
            paramKey: 'categoria' as EFilterParams,
            });
         }
          //Agrega el filtro manager
          if (this.filtromanager) {
            const index = this.filtrosSelected.length;
            this.filtrosSelected.push({
            text: this.filtromanager,
            index,
            id: IDChip.param,
            paramKey: 'manager' as EFilterParams,
            });
         }
        //Agrega el filtro recomendacion
        if (this.filtrorecomendacion) {
            const index = this.filtrosSelected.length;
            this.filtrosSelected.push({
            text: this.filtrorecomendacion,
            index,
            id: IDChip.param,
            paramKey: 'recomendacion' as EFilterParams,
            });
        }
    }

    /**
     * Limpia el filtrado de datos
     */
    limpiarFiltro() {
        this.filtrocategoria = null;
        this.filtromanager = null;
        this.filtrorecomendacion = null;
        this.filtronombreinstrumento = null;
        this.instrumentosfiltrados = this.recuperaInstrumentosFiltradosYPaginados();
    }

    /**
     * Elimina un filtro
     * @param item 
     */
     async eliminaFiltro(item: ChipFilter) {
        if (item.paramKey === 'nombre') {
            this.filtronombreinstrumento = null;
            this.instrumentosfiltrados = this.recuperaInstrumentosFiltradosYPaginados();
        }
        if (item.paramKey === 'categoria') {
            this.filtrocategoria = null;
            this.instrumentosfiltrados = this.recuperaInstrumentosFiltradosYPaginados();
        }
        if (item.paramKey === 'manager') {
            this.filtromanager = null;
            this.instrumentosfiltrados = this.recuperaInstrumentosFiltradosYPaginados();
        }
        if (item.paramKey === 'recomendacion') {
            this.filtrorecomendacion = null;
            this.instrumentosfiltrados = this.recuperaInstrumentosFiltradosYPaginados();
        }
    }

    //abre modal
    async modalFiltro() {
        const dialogRef = this.dialogService.openDialog(ModalFiltroInstrumentosComponent, {
            position: { right: '0', top: '0' },
            panelClass: ['modal-derecha'], //'modal-fullscreen', //modal-derecha
            data: {
                manager: this.filtromanager,
                categoria: this.filtrocategoria,
                recomendacion: this.filtrorecomendacion,
            },
        });

        //procesa resultado del modal
        dialogRef.afterClosed().subscribe((data: any) => {
            if (data && data.aplicar) {
                //aplica el filtro
                this.filtromanager = data.manager;
                this.filtrocategoria = data.categoria;
                this.filtrorecomendacion = data.recomendacion;
                this.paginaactiva = 0;

                this.instrumentosfiltrados = this.recuperaInstrumentosFiltradosYPaginados();
            }
        });
    }

    public validateMinValue(minValue: number) {
        return (currentControl: AbstractControl): { [key: string]: any } => {
            let isValid = true;
            const currentNumber = currentControl?.value ? currentControl?.value : '';
            if (currentNumber != '' && Number(currentNumber.replaceAll('.', '').replaceAll(',', '.')) < minValue) {
                isValid = false;
            }
            return isValid ? null : { min: true };
        };
    }

    public validateMaxValue(maxValue: number) {
        return (currentControl: AbstractControl): { [key: string]: any } => {
            let isValid = true;
            const currentNumber = currentControl?.value ? currentControl?.value : '';
            if (Number(currentNumber.replaceAll('.', '').replaceAll(',', '.')) > maxValue) {
                isValid = false;
            }
            return isValid ? null : { max: true };
        };
    }
}
