import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrdenCarroComponent } from './orden-carro.component';
import { MainOrdenCarroComponent } from './components/main-orden-carro.component';
import { OrdenCarroComponentRoutingModule } from './orden-carro-routing.module';
import { DetalleOrdenesIngresadasComponent } from './components/detalle-ordenes-ingresadas/detalle-ordenes-ingresadas.component';
import { ConfirmaModalComponentCorredora } from './components/confirma-modal/confirma-modal.component';
import { UploadArchivoRespaldoComponent } from './components/upload-archivo-respaldo/upload-archivo-respaldo.component';


@NgModule({
  declarations: [
    OrdenCarroComponent,
    MainOrdenCarroComponent,
    DetalleOrdenesIngresadasComponent,
    ConfirmaModalComponentCorredora,
    UploadArchivoRespaldoComponent
  ],
  imports: [
    OrdenCarroComponentRoutingModule,
    CommonModule,
    SharedModule
  ]
})
export class OrdenCarroModuleCorredora { }
