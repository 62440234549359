import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientRequestService } from '../../../features/services/client-request/client-request.service';
import { ExecutiveService } from '../../services/executive/executive.service';
import { XAuthService } from '../../services/xauth/xauth.service';

interface ExecutiveBannerModel {
  executiveName: string;
  clientName: string;
}

@Component({
  selector: 'app-executive-banner',
  templateUrl: './executive-banner.component.html',
  styleUrls: ['./executive-banner.component.scss'],
})
export class ExecutiveBannerComponent implements OnInit {
  @Input() executiveBannerData: ExecutiveBannerModel;
  @Input() data;
  @Input() clienteProfile;
  @Input() token;

  nombreCliente : string;
  rut: string;
  perfilCliente: string;
  nombreEjecutivo: string;



  constructor(private clientRequest: ClientRequestService,
              public readonly executive: ExecutiveService,
              private xAuthService: XAuthService
    //private router: Router
    ) {}

  ngOnInit() {
    const {datosCliente, clienteProfile} = this.data;
    this.nombreCliente = datosCliente?.Nombre ?? '';
    this.rut = this.formatRut(datosCliente?.RutCliente) ?? '';
    this.perfilCliente = clienteProfile ?? '';
    const valueExecutiveData = this.clientRequest?.clientExecutive?.value;
    this.nombreEjecutivo = datosCliente?.NombreEjecutivo ?? '';
  }

  formatRut(rut) {
    let rutPuntos = "";
    rut = rut.toUpperCase();
    const actual = rut.replace(/^0+/, "");
    if (actual !== "" && actual.length > 1) {
      const sinPuntos = actual.replace(/\./g, "");
      const actualLimpio = sinPuntos.replace(/-/g, "");
      const inicio = actualLimpio.substring(0, actualLimpio.length - 1);
      let i = 0;
      let j = 1;
      for (i = inicio.length - 1; i >= 0; i--) {
        const letra = inicio.charAt(i);
        rutPuntos = letra + rutPuntos;
        if (j % 3 === 0 && j <= inicio.length - 1) {
          rutPuntos = "." + rutPuntos;
        }
        j++;
      }
      const dv = actualLimpio.substring(actualLimpio.length - 1);
      rutPuntos = rutPuntos + "-" + dv;
    }

    return rutPuntos;
  }

  cerrar(){
    this.xAuthService.borrarXToken(this.token)
  }
  
}
