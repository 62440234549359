import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { isArray } from 'rxjs/internal-compatibility';
import { ContentfulService, DialogService, ExecutiveService, UtilsService } from 'src/app/shared/services';
import { ContentManagerService } from '../../../../../shared/services/content-manager/content-manager.service';
import { DataClientModel } from '../../../../../features/models/client-summary/data-client.model';
import * as utils from '../../../../../shared/helpers/utils';
import { LoadingService } from '../../../../../shared/services/loading/loading.service';
import {
    AddressDomain,
    BussinesLineData,
    BussinesLineDataCompany,
    CuentaCorriente,
    DireccionPJ,
    DireccionPN,
    DireccionUpdate,
    Email,
    EmailBase,
    LastContactabilityUpdateObs,
    LastContactabilityUpdateResponse,
    NotificationEmails,
    ProfileSummaryData,
    Tabs,
    TelefonoBase,
    UpdateContactabilityDTO,
} from '../../../../models/client-summary/profile-summary.model';
import { ClientRequestService } from './../../../../services/client-request/client-request.service';
import { environment } from '@environment';
import { ConfirmModalComponent, ModalOptions } from '../../../../../shared/components/confirm-modal/confirm-modal.component';
import { ClientContactabilityService } from 'src/app/features/services/client-contactability/client-contactability.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Status } from 'src/app/features/models/log/status';
import { AliasInputLogType, InputLog } from 'src/app/features/models/log/InputLog';
import * as moment from 'moment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { ConfigIndexLogStash } from 'src/app/shared/models';
@Component({
    selector: 'app-profile-summary',
    templateUrl: './profile-summary.component.html',
    styleUrls: ['./profile-summary.component.scss'],
})
export class ProfileSummaryComponent implements OnInit {
    tabs: Tabs[] = [];
    profileSummaryData: ProfileSummaryData = {
        cb: null,
        vida: null,
        resumenCliente: [
            {
                sexo: '',
                estadoCivil: '',
                nacionalidad: '',
                lineaNegocio: 'cb',
            },
            {
                sexo: '',
                estadoCivil: '',
                nacionalidad: '',
                lineaNegocio: 'vida',
            },
        ],
    };
    clientInfoCb: any;
    clientInfoVida: any;

    title: string = '';
    title2: string = 'Para finalizar, elige el archivo con la autorización del cliente';
    subtitle: string = 'Los formatos permitidos son: .msg, .eml, .png, .jpg, .gif, .ppt, .doc, .pdf, .xls y su peso máximo es de 5MB';
    visibleDisabled: boolean = true;

    addressDomainData: AddressDomain[] = null;
    addressDomainDataVida: AddressDomain[] = null;
    dataClient: DataClientModel;
    currentTab: number = 0;
    showContent: boolean = false;
    propertiesCardData: any = {
        nationality: '-',
        dateOfBirth: '-',
        gender: '-',
        civilStatus: '-',
        representativeName: null,
        representativeRut: null,
    };
    layoutBanks = {
        cb: false,
        vida: false,
    };
    isCompany: boolean = false;
    dataToSave: UpdateContactabilityDTO;
    bussinesLine: string;
    authorizationFile: string;
    authorizationMessage: string;
    messageService: any = {
        showAlertMessage: false,
        state: 'succes',
        messageState: '',
        message: '',
        material: 'check_circle',
        tabActive: 0,
    };
    enabledByEnv: boolean = false;
    showByProfile: boolean = false;
    showData: boolean = false;
    maxLengthFields: any;
    existValidationErrors: boolean = false;
    currentDataCb: BussinesLineData | BussinesLineDataCompany;
    currentDataVida: BussinesLineData | BussinesLineDataCompany;
    businessAddressErrorList: DireccionPJ[] | DireccionPN[] = [];
    banksErrorList: any[] = [];
    emailsErrorList: any[] = [];
    phonesErrorList: any[] = [];
    lastUpdateAllData: LastContactabilityUpdateObs;
    lastUpdate: any = {
        date: null,
        executive: null,
    };
    private unSubscribe$ = new Subject();

    constructor(
        private clientRequestService: ClientRequestService,
        public loadingService: LoadingService,
        private location: Location,
        private utilsService: UtilsService,
        private contentManagerService: ContentManagerService,
        private dialogService: DialogService,
        private contentfulService: ContentfulService,
        private executiveService: ExecutiveService,
        private clientContactabilityService: ClientContactabilityService,
        private tracesService: TracesService,
    ) {
        this.loadingService.showLoading();
    }

    async ngOnInit() {
        this.clientRequestService.lastContactabilityUpdate.pipe(takeUntil(this.unSubscribe$)).subscribe((lastUpdateData: LastContactabilityUpdateObs) => {
            this.lastUpdateAllData = lastUpdateData;
        });
        this.getData();
        await this.visibilityByConfiguration();
    }

    ngOnDestroy(): void {
        this.unSubscribe$.next();
        this.unSubscribe$.unsubscribe();
    }

    tabSelected(event: any) {
        this.currentTab = event.index;
        this.visibleDisabled = true;
        this.authorizationFile = undefined;
        this.authorizationMessage = undefined;
        this.messageService.showAlertMessage = false;
        this.showLastUpdateByBusinessLine();
    }

    showLastUpdateByBusinessLine() {
        this.lastUpdate = {
            date: null,
            executive: null,
        };

        if (this.tabs.length === 0) return;

        const line = this.tabs.find((data) => data.index === this.currentTab);
        const lineName = line.txtTabs.toUpperCase().trim();
        let historyData = null;

        if (lineName.includes('CORREDORA') && this.lastUpdateAllData.ffmm) {
            historyData = this.lastUpdateAllData.ffmm.Resultado?.HistorialOperacionesBE;
        } else if (lineName.includes('VIDA') && this.lastUpdateAllData.vida) {
            historyData = this.lastUpdateAllData.vida.Resultado?.HistorialOperacionesBE;
        }

        if (historyData) {
            this.lastUpdate.executive = historyData.NombreUsuarioModificacion;
            this.lastUpdate.date = historyData.FechaModificacion;
        }
    }

    async getData() {
        let data = sessionStorage.getItem('clientData');
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: 'Mis datos',
            Operacion: 'Mis datos/Actualizar',
            Metodo: 'getData()',
            eventoLog: 'Carga Inicial',
            status: Status.OK,
            Secuencia: 1,
        };

        if (data) {
            this.dataClient = JSON.parse(data);
            const rut = this.dataClient.rut.split('.').join('');
            this.isCompany = utils.isCompany(rut);
            // type 1 = persona juridica | 2 = persona natural
            const params = {
                rut,
                type: utils.isCompany(rut) ? 1 : 2,
            };
            logtemp.EntradaLog = {
                ... logtemp.EntradaLog,
                parametros: params,
            }
            try {
                this.clientContactabilityService.getLastContactabilityUpdate(this.dataClient.rut);
                const response = await Promise.allSettled([this.clientRequestService.getSummaryOfTheClientProfile(params), this.clientRequestService.getAddressDomainData('13'), this.clientRequestService.getAddressDomainData('2')]);
                let domainDataCb;
                let domainDataVida;
                const listErrorResp: any[] = [];
                response.forEach((res, index) => {
                    if (res.status === 'fulfilled') {
                        const resData = response[index] as PromiseFulfilledResult<any>;
                        if (index === 0) {
                            this.profileSummaryData = resData.value;
                            this.clientInfoCb = resData.value.resumenCliente.find((item) => item.lineaNegocio === 'cb');
                            this.clientInfoVida = resData.value.resumenCliente.find((item) => item.lineaNegocio === 'vida');
                        } else if (index === 1) domainDataCb = resData.value;
                        else domainDataVida = resData.value;
                    } else {
                        listErrorResp.push(res);
                    }
                });

                this.addressDomainData = await this.getInitDataDomain(domainDataCb);
                this.addressDomainDataVida = await this.getInitDataDomain(domainDataVida);

                await this.getInitialAddressOptions();
                this.clientRequestService.contactabilityData.next(this.profileSummaryData);
                this.createTabs();
                this.validateBankData();
                if (listErrorResp.length > 0) {
                    logtemp.status = Status.NOK;
                    logtemp.EntradaLog = {
                        ...logtemp.EntradaLog,
                        errors: listErrorResp,
                    };
                }
                this.logData(logtemp, logtemp.status === Status.OK ? 'log_negocio' : 'log_error');
            } catch (error) {
                logtemp.status = Status.NOK;
                logtemp.EntradaLog = {
                    ...logtemp.EntradaLog,
                    error,
                };
                this.logData(logtemp, 'log_error');
            }
            this.showLastUpdateByBusinessLine();
            this.showContent = true;
            this.loadingService.hideLoading();
        } else {
            this.location.back();
            logtemp.eventoLog = 'Carga Inicial';
            logtemp.EntradaLog = {
                ...logtemp.EntradaLog,
                error: 'no se obtuvieron datos.',
            };
            this.logData(logtemp, 'log_error');
        }
    }

    async getInitialAddressOptions() {
        if (this.profileSummaryData.vida) this.profileSummaryData.vida = await this.clientContactabilityService.getInitialAddressOptions(this.profileSummaryData.vida, this.isCompany);
        if (this.profileSummaryData.cb) this.profileSummaryData.cb = await this.clientContactabilityService.getInitialAddressOptions(this.profileSummaryData.cb, this.isCompany);
    }

    async getInitDataDomain(dataDomain) {
        const bankOptions = dataDomain[5].data;
        const accountTypeOptions = dataDomain[6].data;
        dataDomain[5].data = await this.clientContactabilityService.addDefaultOptionBank(bankOptions, 'banco');
        dataDomain[6].data = await this.clientContactabilityService.addDefaultOptionBank(accountTypeOptions, 'banco');
        return dataDomain;
    }

    createTabs() {
        this.tabs = [];
        if (this.profileSummaryData?.cb) {
            this.tabs.push({
                index: 0,
                txtTabs: 'CORREDORA',
            });
        }

        if (this.profileSummaryData?.vida) {
            this.tabs.push({
                index: !this.profileSummaryData?.cb ? 0 : 1,
                txtTabs: 'SEGUROS DE VIDA',
            });
        }
    }

    getCompanyCardData() {
        let representativeName: string = null;
        let representativeRut: string = null;

        const cbData = this.profileSummaryData?.cb ? this.profileSummaryData.cb['representanteLegal'] : null;
        const vidaData = this.profileSummaryData?.vida ? this.profileSummaryData.vida['representanteLegal'] : null;

        if (cbData) {
            representativeName = 'nombre' in cbData ? `${cbData.nombre} ${this.profileSummaryData.cb.apellidoPaterno} ${this.profileSummaryData.cb.apellidoMaterno}` : null;
            representativeRut = 'rut' in cbData && cbData.rut.numero !== '' ? `${cbData.rut.numero}-${cbData.rut.dv}` : null;
        } else if (vidaData) {
            representativeName = 'nombre' in vidaData && vidaData.nombre !== '' ? `${vidaData.nombre} ${this.profileSummaryData.vida.apellidoPaterno} ${this.profileSummaryData.vida.apellidoMaterno}` : null;
            representativeRut = 'rut' in vidaData && vidaData.rut.numero !== '' ? `${vidaData.rut.numero}-${vidaData.rut.dv}` : null;
        }

        return { representativeName, representativeRut, cbData, vidaData };
    }

    validateBankData() {
        let corredora = this.profileSummaryData.cb?.cuentasCorrientes?.cuentaCorriente;
        let vida = this.profileSummaryData.vida?.cuentasCorrientes?.cuentaCorriente;

        corredora = this.validateEmtyArray(corredora);
        vida = this.validateEmtyArray(vida);

        if (corredora && corredora.length > 1) {
            this.profileSummaryData.cb.cuentasCorrientes.cuentaCorriente = corredora.slice(0, 3);
            this.layoutBanks.cb = true;
        } else if (corredora && corredora.length === 1) this.profileSummaryData.cb.cuentasCorrientes.cuentaCorriente = corredora;

        if (vida && vida.length > 1) {
            this.profileSummaryData.vida.cuentasCorrientes.cuentaCorriente = vida.slice(0, 3);
            this.layoutBanks.vida = true;
        } else if (vida && vida.length === 1) this.profileSummaryData.vida.cuentasCorrientes.cuentaCorriente = vida;
    }

    validateField(dataCorredora: any, dataVida: any, field: string): string | null {
        if (dataCorredora[field] !== '') {
            return dataCorredora[field];
        } else if (dataVida && dataVida[field] !== '') return dataVida[field];
        else return null;
    }

    validateEmtyArray(data: any): any[] {
        let response = isArray(data) ? data : [data];
        return response.filter((item) => item);
    }

    addVoto(event) {
        if (event) {
            this.visibleDisabled = true;
        } else {
            this.visibleDisabled = false;
        }
        this.messageService.showAlertMessage = false;
    }

    statusVisible() {
        this.visibleDisabled = true;
        this.authorizationFile = undefined;
        this.authorizationMessage = undefined;
    }

    responseFile(event) {
        this.authorizationFile = event.data;
    }

    getClientNameByBusinessLine(bussinesLine: string) {
        let data = this.profileSummaryData.cb;
        if (bussinesLine === 'vida') {
            data = this.profileSummaryData.vida;
        }

        if (this.isCompany) {
            return data.razonSocial.trim();
        } else {
            return `${data.nombres.trim()} ${data.apellidoPaterno.trim()} ${data.apellidoMaterno.trim()}`;
        }
    }

    async save() {
        
        if (this.existValidationErrors) return;
        if (!this.authorizationFile) {
            this.authorizationMessage = 'Se debe adjuntar un documento para modificar los datos.';
            return;
        } else this.authorizationMessage = undefined;

        this.dataToSave = this.validateBody(this.dataToSave);
        if (!this.dataToSave) {
            this.setResponseMessage(false);
            this.statusVisible();
            window.scrollTo(0, 0);
            return;
        }

        this.loadingService.showLoading();
        this.dataToSave.tipoRegistro = this.bussinesLine;
        this.dataToSave.rut = this.dataToSave.rut.numero + '-' + this.dataToSave.rut.dv;
        this.dataToSave.tipoUsuario = this.isCompany ? 1 : 2;
        this.dataToSave.nombreCliente = this.getClientNameByBusinessLine(this.bussinesLine);

        this.dataToSave.emailsNotificacion = await this.getEmailsNotificacion();
        const logtemp: Partial<InputLog> = {
            Categoria: 'Mis datos',
            Operacion: 'Mis datos/Actualizar',
            Metodo: 'save()',
            eventoLog: 'Click en Botón Guardar Datos',
            status: Status.OK,
            Secuencia: 3,
        };
        try {
            logtemp.EntradaLog = {
                ...logtemp.EntradaLog,
                datosCliente: this.dataToSave,
            }
            this.logData(logtemp, 'log_transaccion');
            const res = await this.clientRequestService.postUpdateContactabilityData(this.dataToSave);
            if (res.response) {
                await this.saveFile();
                this.setResponseMessage(true);
            } else {
                this.setResponseMessage(false);
            }
            this.statusVisible();
            logtemp.eventoLog = 'Respuesta Actualización.';
            logtemp.EntradaLog = {
                ...logtemp.EntradaLog,
                updateResp: res,
            }
            this.logData(logtemp, 'log_negocio');
            await this.getData();
            this.showData = false;
        } catch (error) {
            logtemp.EntradaLog = {
                ...logtemp.EntradaLog,
                error
            }
            this.logData(logtemp, 'log_error');
            this.setResponseMessage(false);
            this.statusVisible();
        }

        window.scrollTo(0, 0);
        this.loadingService.hideLoading();
    }

    async getEmailsNotificacion(): Promise<NotificationEmails> {
        const data: NotificationEmails = {
            nombreCliente: '',
            emailsCliente: [],
            emailAsesor: '',
            emailAsesorAsignado: '',
        };
        let emailsProperty = 'emails';
        let emailProperty = 'email';

        if (this.isCompany) {
            emailsProperty = 'emailsEmpresa';
            emailProperty = 'emailEmpresa';
        }

        try {
            const executiveEmailData = await this.utilsService.getExecutiveEmails();

            let currentEmails: Email[] = this.bussinesLine === 'cb' ? this.profileSummaryData.cb[emailsProperty][emailProperty] : this.profileSummaryData.vida[emailsProperty][emailProperty];

            currentEmails = currentEmails.filter((email) => email && email.estadoEmail === '1');
            const currentEmailList = currentEmails.map((email) => {
                return email.correo.toLowerCase();
            });

            const newEmails: Email[] = this.dataToSave[emailsProperty][emailProperty].filter((email: Email) => {
                if (email && email.estadoEmail === '1' && !currentEmailList.includes(email.correo.toLowerCase())) {
                    return email;
                }
            });
            const newEmailList = newEmails.map((email) => {
                return email.correo.toLowerCase();
            });

            data.nombreCliente = this.dataClient.name;
            data.emailsCliente = [...currentEmailList, ...newEmailList];
            data.emailAsesor = executiveEmailData.transactionExecutive.email;
            data.emailAsesorAsignado = executiveEmailData.assignedExecutive.email;
            return data;
        } catch (error) {
            return data;
        }
    }

    async saveFile() {
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
        Categoria: 'Mis datos',
        Operacion: 'Mis datos/Actualizar',
        Metodo: 'saveFile()',
        eventoLog: 'Guardado de documento de autorizacion.',
        status: Status.OK,
        Secuencia: 4,
        EntradaLog: {
            datosCliente: this.dataToSave
        },
    };
        const executiveUsername = sessionStorage.getItem('executiveUsername');
        let asesor = sessionStorage.getItem('dataContentful');
        let dataAsesor = JSON.parse(asesor);
        let executiveRut = dataAsesor.executiveRut;
        let executiveName = dataAsesor.executiveName;

        const infoParam = {
            lineaNegocio: 'WMAN',
            codigoTransaccion: '2',
            descripcionTransaccion: 'MisDatos',
            folioTransaccion: '0',
            codigoCanalIngreso: '1',
            descripcionCanalIngreso: 'Sura Asesor',
            codigoUsuarioResponsable: executiveUsername,
            rutUsuarioResponsable: executiveRut,
            nombreUsuarioResponsable: executiveName,
            numeroProducto: '0',
        };

        let contentInfo = utils.formatParamInfoContentManager(infoParam);
        const info = contentInfo;
        
        const authorizationDocument = this.authorizationFile.split(',');
        logtemp.EntradaLog = {
            ...logtemp.EntradaLog,
            info,
            contentInfo
        }

        try {
            await this.contentManagerService.postContentManager({ info, data: `${authorizationDocument[0]},${authorizationDocument[1]}` });
            this.logData(logtemp, 'log_negocio');
        } catch (error) {
            logtemp.status = Status.NOK;
            logtemp.EntradaLog = {
                ...logtemp.EntradaLog,
                error
            }
            this.logData(logtemp, 'log_error');
        }
    }

    openConfirmModal() {
        this.messageService.showAlertMessage = false;
        const existErrorsBusinessAddress = this.validateFieldsBusinessAddress();
        const existErrorsBankAccounts = this.validateFieldsBanks();
        const existErrorsPhones = this.validateFieldsPhone();
        const existErrorsEmails = this.validateFieldsEmail();
        if (this.existValidationErrors || existErrorsBusinessAddress || existErrorsBankAccounts || existErrorsPhones || existErrorsEmails) return;
        if (!this.authorizationFile) {
            this.authorizationMessage = 'Se debe adjuntar un documento para modificar los datos.';
            return;
        } else this.authorizationMessage = undefined;

        let optionsMessage: ModalOptions = {
            titulo: 'Modificación de datos',
            mensaje: '¿Estás seguro de realizar los cambios?',
            labelacepta: 'CONFIRMAR',
            labelarechaza: 'CANCELAR',
        };
        const config = {
            panelClass: ['modal-mobile-bottom'],
            disableClose: false,
            data: { optionsMessage },
        };

        let dialogRef = this.dialogService.openDialog(ConfirmModalComponent, config);

        dialogRef.afterClosed().subscribe((data: any) => {
            if (data != null && data === 'OK') {
                this.save();
            }
        });
    }

    async visibilityByConfiguration() {
        const config = await this.contentfulService.getEntryFromFront(environment.KEYS.CONTENTFUL.UPDATE_CONTACTABILITY);
        this.showByProfile = this.executiveService.executiveModel.executiveProfile.allowTransaction;
        const environmentList = config.environmentList.environments;
        if (config.enabled && environmentList.includes(environment.name)) {
            this.enabledByEnv = true;
        }
    }

    setResponseMessage(success: boolean) {
        if (success) {
            this.messageService = {
                showAlertMessage: true,
                state: 'succes',
                messageState: 'Modificación exitosa',
                message: 'Los cambios realizados se han guardado.',
                material: 'check_circle',
            };
        } else {
            this.messageService = {
                showAlertMessage: true,
                state: 'error',
                messageState: 'Algo salió mal',
                message: 'Los cambios realizados no se han guardado.',
                material: 'error',
            };
        }
    }

    validateBody(data: UpdateContactabilityDTO): UpdateContactabilityDTO {
        this.convertPropertiesToArray();
        const original: any = this.bussinesLine === 'vida' ? this.currentDataVida : this.currentDataCb;
        try {
            const { emailsProperty, emailProperty, direccionProperty, direccionesProperty, telefonosProperty, telefonoProperty } = utils.getPropertyNamesContactability(this.isCompany);
            // Valida numero de cuentas
            let accounts: any = this.validateBodyBanks(data, original);

            // Valida emails
            const emails = this.validateBodyEmail(data, original);

            // Valida direcciones
            const address = this.validateBodyAddress(data, original);

            // Valida telefono
            const phones = this.validateBodyPhone(data, original);

            data.cuentasCorrientes.cuentaCorriente = accounts ?? {};
            data[emailsProperty][emailProperty] = emails;
            data[direccionesProperty][direccionProperty] = address;
            data[telefonosProperty][telefonoProperty] = phones;

            return data;
        } catch (error) {
            console.warn(error);
            return null;
        }
    }

    convertPropertiesToArray() {
        const { emailsProperty, emailProperty, direccionProperty, direccionesProperty, telefonosProperty, telefonoProperty } = utils.getPropertyNamesContactability(this.isCompany);

        if (!Array.isArray(this.dataToSave[direccionesProperty][direccionProperty])) {
            this.dataToSave[direccionesProperty][direccionProperty] = [this.dataToSave[direccionesProperty][direccionProperty]];
        }
        if (!Array.isArray(this.dataToSave.cuentasCorrientes.cuentaCorriente)) {
            this.dataToSave.cuentasCorrientes.cuentaCorriente = [this.dataToSave.cuentasCorrientes.cuentaCorriente];
        }
        if (!Array.isArray(this.dataToSave[emailsProperty][emailProperty])) {
            this.dataToSave[emailsProperty][emailProperty] = [this.dataToSave[emailsProperty][emailProperty]];
        }
        if (!Array.isArray(this.dataToSave[telefonosProperty][telefonoProperty])) {
            this.dataToSave[telefonosProperty][telefonoProperty] = [this.dataToSave[telefonosProperty][telefonoProperty]];
        }
    }

    validateFieldsBusinessAddress(): number {
        const { direccionProperty, direccionesProperty } = utils.getPropertyNamesContactability(this.isCompany);
        const data = this.dataToSave;
        let existErrors = 0;
        const recordedData = !Array.isArray(data[direccionesProperty][direccionProperty]) ? [data[direccionesProperty][direccionProperty]] : data[direccionesProperty][direccionProperty];
        recordedData.forEach((item) => {
            if (item.estado === '1') {
                item = this.setErrorsBusinessAddress(item);
                existErrors = existErrors + (item.errorList ? item.errorList.length : 0);
            }
        });
        this.businessAddressErrorList = recordedData;
        return existErrors;
    }

    setErrorsBusinessAddress(item: any) {
        const { isEmpty, partialData } = utils.validateEmptyAddress(item);
        if (item.tipo === '1') {
            if (partialData || isEmpty) {
                item = this.countErrors(item);
            } else item.errorList = [];
        }
        if (item.tipo === '2') {
            if (partialData) {
                item = this.countErrors(item);
            } else if (isEmpty) item.errorList = [];
        }
        return item;
    }

    countErrors(item) {
        const list = [];
        if (utils.validateProfileSummaryStringField('calle', item).trim() === '') list.push('calle');
        if (utils.validateProfileSummaryStringField('numeroCalle', item).trim() === '') list.push('numeroCalle');
        if (utils.validateProfileSummaryStringField('numeroDepto', item).trim() === '') list.push('numeroDepto');
        if (utils.validateProfileSummaryStringField('codigoRegion', item).trim() === '0') list.push('region');
        if (utils.validateProfileSummaryStringField('codigoCiudad', item).trim() === '0') list.push('ciudad');
        if (utils.validateProfileSummaryStringField('codigoComuna', item).trim() === '0') list.push('comuna');
        item.errorList = [...list];
        return item;
    }

    validateFieldsBanks(): number {
        if (this.bussinesLine === 'vida') return 0;
        const data = this.dataToSave;
        let existErrors = 0;
        let list = [];
        const recordedData: any = !Array.isArray(data.cuentasCorrientes.cuentaCorriente) ? [data.cuentasCorrientes.cuentaCorriente] : data.cuentasCorrientes.cuentaCorriente;
        recordedData.forEach((item, index) => {
            if (item.estado === '1') {
                this.countBankErrors(item);
                if (item.errorList.length > 0) existErrors++;
            }
        });
        this.banksErrorList = recordedData;
        return existErrors;
    }

    countBankErrors(item) {
        const list = [];
        if (utils.validateProfileSummaryStringField('idBanco', item).trim() === '0') list.push('idBanco');
        if (utils.validateProfileSummaryStringField('idTipo', item).trim() === '0') list.push('descripcionTipo');
        if (utils.validateProfileSummaryStringField('banco', item).trim() === '') list.push('banco');
        if (utils.validateProfileSummaryStringField('moneda', item).trim() === '') list.push('moneda');
        if (utils.validateProfileSummaryStringField('numero', item).trim() === '') list.push('numero');
        item.errorList = [...list];
        return item;
    }

    validateFieldsPhone(): number {
        const data = this.dataToSave;
        let existErrors = 0;
        let list = [];
        const { telefonosProperty, telefonoProperty } = utils.getPropertyNamesContactability(this.isCompany);
        const recordedData: any = !Array.isArray(data[telefonosProperty][telefonoProperty]) ? [data[telefonosProperty][telefonoProperty]] : data[telefonosProperty][telefonoProperty];
        recordedData.forEach((item, index) => {
            if (item.estado === '1' && (item.numero.trim() === '' || item.codigoArea.trim() === '')) {
                list.push(index);
                existErrors++;
            }
        });
        this.phonesErrorList = list;
        return existErrors;
    }

    validateFieldsEmail(): number {
        const data = this.dataToSave;
        let existErrors = 0;
        let list = [];
        const { emailsProperty, emailProperty } = utils.getPropertyNamesContactability(this.isCompany);
        const recordedData: any = !Array.isArray(data[emailsProperty][emailProperty]) ? [data[emailsProperty][emailProperty]] : data[emailsProperty][emailProperty];
        recordedData.forEach((item, index) => {
            if (item.estadoEmail === '1' && item.correo?.trim() === '') {
                list.push(index);
                existErrors++;
            }
        });
        this.emailsErrorList = list;
        return existErrors;
    }

    validateBodyBanks(data, original) {
        let accounts: any;
        if (this.bussinesLine === 'vida') {
            accounts = original.cuentasCorrientes.cuentaCorriente;
            if (!Array.isArray(accounts)) accounts = [accounts ?? {}];
        } else {
            accounts = data.cuentasCorrientes.cuentaCorriente.map((item, index) => {
                if (item.numero.length > this.maxLengthFields.maxLength || !utils.onlyNumbers(item.numero) || !utils.validateVirtualBank(item.numero, Number(item.idBanco))) {
                    const originalValue = original.cuentasCorrientes.cuentaCorriente.filter((cta: CuentaCorriente) => cta.id === item.id);
                    item.numero = originalValue ? originalValue[0].numero : '';
                }
                return item;
            });

            accounts = accounts.filter((cta) => cta.numero !== '');
            if (accounts.length === 0) accounts = original.cuentasCorrientes.cuentaCorriente.filter((cta: CuentaCorriente) => cta.principal === '1');
        }
        return accounts;
    }

    validateBodyEmail(data, original): EmailBase[] {
        const { emailsProperty, emailProperty } = utils.getPropertyNamesContactability(this.isCompany);
        return data[emailsProperty][emailProperty].map((item: EmailBase, index) => {
            if (item.correo.length > this.maxLengthFields.maxLengthHigh || !utils.validateEmail(item.correo)) {
                item.correo = original[emailsProperty][emailProperty][index]?.correo ?? '';
            }
            return item;
        });
    }

    validateBodyPhone(data, original): TelefonoBase[] {
        const { telefonosProperty, telefonoProperty } = utils.getPropertyNamesContactability(this.isCompany);
        return data[telefonosProperty][telefonoProperty].map((item: TelefonoBase, index) => {
            if (String(item.codigoPais) !== '56') item.codigoPais = '56';
            if (item.codigoArea.length > 2 || !utils.onlyNumbers(item.codigoArea)) {
                item.codigoArea = original[telefonosProperty][telefonoProperty][index].codigoArea;
            }
            if (!utils.validatePhoneNumber(`${item.codigoArea}${item.numero}`)) {
                item.numero = original[telefonosProperty][telefonoProperty][index].numero;
            }
            return item;
        });
    }

    validateBodyAddress(data, original): DireccionUpdate[] {
        const { direccionProperty, direccionesProperty } = utils.getPropertyNamesContactability(this.isCompany);
        const recordedData = !Array.isArray(data[direccionesProperty][direccionProperty]) ? [data[direccionesProperty][direccionProperty]] : data[direccionesProperty][direccionProperty];
        return recordedData
            .map((item: DireccionUpdate, index) => {
                if (item.estado === '1') {
                    const { isEmpty, partialData } = utils.validateEmptyAddress(item);
                    const previousData = original[direccionesProperty][direccionProperty];

                    if (isEmpty || (!isEmpty && partialData)) {
                        item = this.setOriginalAddressData(item, partialData, isEmpty, original, previousData, index);
                    } else {
                        item.calle = this.validAddressField(item, 'calle', this.maxLengthFields.maxLengthMid, original, index);
                        item.numeroCalle = this.validAddressField(item, 'numeroCalle', this.maxLengthFields.maxLengthLow, original, index);
                        item.numeroDepto = this.validAddressField(item, 'numeroDepto', this.maxLengthFields.maxLengthLow, original, index);
                    }
                }
                return item;
            })
            .filter((item) => item.partialData !== true);
    }

    validAddressField(item, field, length, original, index) {
        const { direccionProperty, direccionesProperty } = utils.getPropertyNamesContactability(this.isCompany);
        if (item[field]?.length > length || !utils.validateAddress(item[field])) return original[direccionesProperty][direccionProperty][index][field];
        else return item[field];
    }

    setOriginalAddressData(item: DireccionUpdate, partialData: boolean, isEmpty: boolean, original: any, previousData: any, index: any) {
        const { direccionProperty, direccionesProperty } = utils.getPropertyNamesContactability(this.isCompany);
        if (item.tipo === '1') {
            item = Array.isArray(previousData) ? previousData[index] : previousData;
        } else {
            // tipo 2
            const businessAddress = original[direccionesProperty][direccionProperty][index];
            if (businessAddress) item = original[direccionesProperty][direccionProperty][index];
            else item.partialData = partialData || isEmpty;
        }
        return item;
    }

    async logData(log: Partial<InputLog>, indexLogStash: ConfigIndexLogStash) {
        try {
            let inputLog: InputLog = new InputLog();
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.eventoLog = log.eventoLog;
            inputLog.Linea_negocio = this.currentTab == 1 ? 'VIDA' : 'CB';
            inputLog.Secuencia = log.Secuencia || 0;
            inputLog.status = indexLogStash === 'log_error'? Status.NOK : log.status;
            inputLog.EntradaLog = {
                currentTab: this.currentTab,
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...log.EntradaLog,
            };
            await this.tracesService.registerLog(inputLog, '', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }
}
