import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'mmb-td',
  templateUrl: './mmb-td.component.html',
  styleUrls: ['./mmb-td.component.scss'],
})
export class MmbTdComponent {

  @Input() show ?;
  @Input() tipo : string = "texto"; //texto, monto, centro
  @Input() title;
  @Input() imgFondo;
  @Input() maxWidth: number;
  @Input() symbol ?: string;

  @HostBinding('style.max-width.px') get HeadingWidth() {
    return this.maxWidth;
  }


  @HostBinding('style.flex') get textColor() {​​
     return this.maxWidth > 1 ? '100%' : '';
  }​​

  @HostBinding('class.cont-desktop') get HeadingClass() {
    return this.show === 'desktop';
  }

  @HostBinding('class.centro') get centro() {
    return this.tipo === 'centro';
  }

  @HostBinding('class.texto') get texto() {
    return this.tipo === 'texto';
  }

  @HostBinding('class.monto') get monto() {
    return this.tipo === 'monto';
  }

  @HostBinding("class.fondo") get fondo() {
    return this.tipo === "fondo";
  }

}
