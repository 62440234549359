import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, timeout } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RequestHandlerService {
    private readonly defaultHeaders = { 'content-type': 'application/json' };

    timeOut = 300000;

    constructor(private http: HttpClient) {}

    doGet<T>(url: string, params?: HttpParams, responseType?) {
        const httpHeaders = { 'content-type': 'application/json' };
        const bodyRequest = {
            headers: httpHeaders,
            params,
            responseType,
        };
        return this.http
            .get<T>(url, bodyRequest)
            .pipe(
                timeout(this.timeOut),
                catchError((e) => {
                    return throwError(e);
                }),
            )
            .toPromise();
    }

    doPut(url, body, headers?) {
        const requestHeaders = { ...this.defaultHeaders, ...headers };

        return new Promise((resolve, reject) => {
            this.http
                .put(url, body, {
                    headers: requestHeaders,
                })
                .pipe(
                    timeout(this.timeOut),
                    catchError((e) => {
                        return throwError(e);
                    }),
                )
                .toPromise()
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => reject(err));
        });
    }

    doPost<T>(url, body, headers?, timeoutParam?: number) {
        const requestHeaders = { ...this.defaultHeaders, ...headers };

        return this.http
            .post<T>(url, body, {
                headers: requestHeaders,
            })
            .pipe(
                timeout(timeoutParam ? timeoutParam : this.timeOut),
                catchError((e) => {
                    return throwError(e);
                }),
            )
            .toPromise()
            .then((res) => {
                return res;
            });
    }

    doDelete(url, params, headers) {
        const requestHeaders = { ...this.defaultHeaders, ...headers };

        return this.http.delete(url, {
            params,
            headers: requestHeaders,
            responseType: 'json',
        });
    }
}
