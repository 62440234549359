import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';
import { SearchItem } from '../search-list/search-list.component';

@Component({
  selector: 'app-search-list-item',
  templateUrl: './search-list-item.component.html',
  styleUrls: ['./search-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchListItemComponent implements Highlightable {
  @Input() item: SearchItem;
  @Input() querySearch: string;

  @Output() selected = new EventEmitter<SearchItem>();

  @ViewChild('listItem') liElement: ElementRef;

  disabled: boolean;

  active = false;

  @HostBinding('class.active')
  get activeElement() {
    return this.active;
  }

  getLabel(): string {
    return this.item.searchValue;
  }

  setActiveStyles(): void {
    this.active = true;
  }

  setInactiveStyles(): void {
    this.active = false;
  }
}
