// Angular Core
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

// RxJS
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';

// Models
import { ClientBalanceModel, DataClientModel, pdfDataClientBalance, totalBalanceData, IProductsCount } from '../../../../../../../src/app/features/models';
import { ConfigIndexLogStash, mandatoSuscritoError } from '../../../../../../../src/app/shared/models';

// Services
import { ContentfulService, DestroySubscriptionService, ExcelService, LoadingService } from '../../../../../../../src/app/shared/services';
import { ClientRequestService, ClientService } from '../../../../../../../src/app/features/services';

// Moment
import * as moment from 'moment';

// Pipes
import { CurrencyPipeCustom, RutPipe } from '../../../../../../../src/app/shared/pipes';

import * as utils from '../../../../../../../src/app/shared/helpers/utils';
import { HttpClient } from '@angular/common/http';
import { CoreStatusService } from '../../../../../../../src/app/core/services/coreStatus.services';
import { environment } from '@environment';
import { AliasInputLogType, InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-products-balances',
    templateUrl: './products-balances.component.html',
    styleUrls: ['./products-balances.component.scss'],
    providers: [ExcelService, CurrencyPipeCustom, RutPipe],
})
export class ProductsBalancesComponent implements OnInit, OnDestroy {
    @Output() productsDataEvent = new EventEmitter<IProductsCount>();

    clientData: DataClientModel;
    dataClient$: Observable<DataClientModel>;

    balancesAPV: ClientBalanceModel[] = [];
    balancesNOAPV: ClientBalanceModel[] = [];
    balancesSalud: ClientBalanceModel[] = [];

    apvSignMandateError: boolean;
    noApvSignMandateError: boolean;
    mandatoSuscritoError = mandatoSuscritoError;

    showButtonExportar: boolean;
    showCartolaSection: boolean = false;
    private unsubscribe$ = new Subject<void>();
    showBtnCertificadoSaldos = false;
    showBtnExcelNOAPV = false;
    showBtnExcelAPV = false;
    constructor(
        private clientRequestService: ClientRequestService,
        private destroySubscriptionService: DestroySubscriptionService,
        private excelService: ExcelService,
        private currencyPipeCustom: CurrencyPipeCustom,
        private clientService: ClientService,
        private clientRequest: ClientRequestService,
        private http: HttpClient,
        private loadingService: LoadingService,
        public coreStatusService: CoreStatusService,
        private contentfulService: ContentfulService,
        private tracesService: TracesService,
        private router: Router,
        private activateRouter: ActivatedRoute,
    ) {
        this.showButtonExportar = false;
    }

    ngOnInit() {
        this.processData();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
    }

    async processData() {
        this.dataClient$ = this.clientService.getDataClient();
        this.dataClient$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
            this.clientData = data;
        });
        this.showbtncertificadosaldos();
        combineLatest([this.clientRequestService.clientBalances, this.clientRequestService.signMandate])
            .pipe(takeUntil(this.destroySubscriptionService))
            .subscribe(([data, signMandate]) => {
                const findedAPFNOAPV = data?.find((item) => item.type === 'AFP' && item.prevision === 'NOAPV');
                const findedAPFAPV = data?.find((item) => item.type === 'AFP' && item.prevision === 'APV');

                this.noApvSignMandateError = typeof findedAPFNOAPV !== 'undefined' && !signMandate.mandatoSuscrito;
                this.apvSignMandateError = typeof findedAPFAPV !== 'undefined' && !signMandate.mandatoSuscrito;

                let list = data ? [...data] : [];

                if (!signMandate?.mandatoSuscrito) {
                    list = data?.filter((item) => item.type !== 'AFP');
                }

                this.balancesNOAPV = this.sortData(list?.filter((item) => item.prevision === 'NOAPV'));
                this.balancesAPV = this.sortData(list?.filter((item) => item.prevision === 'APV'));
                this.balancesSalud = list?.filter((item) => item.prevision !== 'APV' && item.prevision !== 'NOAPV').sort((a, b) => this.sortDataByName(a, b));

                const balanceCertificate = this.balancesNOAPV.concat(this.balancesAPV, this.balancesSalud);
                sessionStorage.setItem("balanceCertificate", JSON.stringify(balanceCertificate));

                this.productsDataEvent.emit({
                    balancesAPVQty: this.balancesAPV?.length,
                    balancesNOAPVQty: this.balancesNOAPV?.length,
                });

                let sumabalancesNOAPV = this.balancesNOAPV.reduce((acumulador, actual) => acumulador + actual.amount, 0);
                let sumabalancesAPV = this.balancesAPV.reduce((acumulador, actual) => acumulador + actual.amount, 0);

                this.showBtnExcelNOAPV = sumabalancesNOAPV > 0;
                this.showBtnExcelAPV =  sumabalancesAPV > 0;
                this.showButtonExportar = !(this.balancesNOAPV?.length === 0 && this.balancesAPV?.length === 0 && this.balancesSalud?.length > 0);

                const hide = this.balancesNOAPV?.length === 0 && this.balancesAPV?.length === 0 && this.balancesSalud?.length > 0;

                this.clientRequestService.showNoHealth.next(hide);

                this.showCartolaSection = !!(this.balancesAPV?.length + this.balancesNOAPV?.length);

            });
    }

    sortData(data) {
        const prodAFP = data?.filter((d) => d.type !== 'AFP').sort((a, b) => this.sortDataByName(a, b));
        const prodNOAFP = data?.filter((d) => d.type === 'AFP').sort((a, b) => this.sortDataByOrder(a, b));
        return prodNOAFP?.concat(prodAFP) || [];
    }

    sortDataByName(a, b) {
        if (a.name < b.name) {
            return -1;
        }

        if (a.name > b.name) {
            return 1;
        }

        return 0;
    }

    sortDataByOrder(a, b) {
        if (a.order < b.order) {
            return -1;
        }

        if (a.order > b.order) {
            return 1;
        }

        return 0;
    }


    public filtroProductosVigentes(listadoBalances:  ClientBalanceModel[]): ClientBalanceModel[] {
        let listaFiltrada: ClientBalanceModel[] = [];
            if(listadoBalances.length > 0) {
                listadoBalances.forEach( item => {
                    const esVigente: boolean = item.type === "INSURANCES" ? ![undefined, null, ""].includes(item.estado) && item.estado.toUpperCase() === "VIGENTE" : true;

                    if(esVigente && ![undefined, null].includes(item.amount)) {
                        if( Number(item.amount) > 0 ) {
                            listaFiltrada.push(item);
                        }
                    }
                });
            }
            return listaFiltrada
    }
      

    exportAsXLSX(): void {
        try{
            let now = new Date();
            const dateExcel = moment(now).format('DD/MM/YYYY HH:mm:ss');
            const data = [
                [''],
                ['Saldos consolidados'],
                [''],
                ['Nombre Cliente: ', this.clientData.name],
                ['Rut Cliente: ', this.clientData.rut],
                ['Saldos al Día: ', dateExcel],
                ['Correo Electrónico: ', this.clientData.clientInfo[0].text],
                [''],
                [''],
            ];

            let ap = this.filtroProductosVigentes(this.balancesAPV);
            let iv = this.filtroProductosVigentes(this.balancesNOAPV);

            let c = 10;
            let total = 0;
            let totaliv = 0;
            
            let sumaiv = iv.reduce((acumulador, actual) => acumulador + actual.amount, 0); // obtiene sumatoria de amount del elemento

            if (iv.length > 0){
                if(sumaiv > 0){
                    data[c] = ["Inversión financiera"];
                    c++;
                    data[c] = ["Nombre producto", "Saldo"];
                    c++;
                }
                iv.forEach((element: ClientBalanceModel) => {
                    if(element.amount > 0){
                    data[c] = [element.name, this.currencyPipeCustom.transform(element.amount).toString()];
                    totaliv += Math.round(element.amount);
                    c++;
                    }
                });
            }
            if(totaliv > 0) {
                data[c] = ["Saldo total Inversión financiera", this.currencyPipeCustom.transform(totaliv).toString()];
                c++;
            }
            c++;
            if (ap.length > 0){
                data[c] = ["Ahorro previsional"];
                c++;
                data[c] = ["Nombre producto", "Saldo"];
                c++;
                ap.forEach((element: ClientBalanceModel) => {
                    if(element.amount > 0){
                    data[c] = [element.name, this.currencyPipeCustom.transform(element.amount).toString()];
                    total += Math.round(element.amount);
                    c++;
                }
                });
            }
            if(total > 0) {
                data[c] = ["Saldo total Ahorro previsional", this.currencyPipeCustom.transform(total).toString()];
                c = c + 2;
            }
            
            if((totaliv + total) > 0) {
                data[c] = ["Saldo total", this.currencyPipeCustom.transform(totaliv + total).toString()];
            }
            totaliv = 0;
            total = 0;
            c++;

            const dateNameFile = moment(now).format('DDMMYYYY');
            const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
                Categoria: "Saldos Consolidados",
                Operacion: "Saldos Consolidados Home/Descargar excel",
                Metodo: "exportAsXLSX()",
                eventoLog: "Botón Descargar Excel",
                Linea_negocio: "FFMM",
                status: Status.OK,
                Secuencia: 1,
                EntradaLog: {
                    rut: this.clientData.rut, data
                },
            };
            this.logData(
                logtemp,
                "log_negocio",
            );
            this.excelService.exportApiExcel(data, `MisProductos_${this.clientData.rut.replace(/[\.\-]/g, '')}_${dateNameFile}`);
        }catch (error) {
            console.log(error);
        }
    }

    async exportAsPdf(): Promise<void> {
        let now = new Date();
        const date = moment(now).locale('es-es').format('LL');
        const dateBalance = moment(now).format('DDMMYYYY');
        const time = moment(now).format('HH:mm:ss');
        let balances = sessionStorage.getItem('totalBalance');
        const dataClient: totalBalanceData = JSON.parse(atob(balances));

        const data: pdfDataClientBalance = {
            fecha: date,
            hora: time,
            nombreCliente: this.clientData.name,
            rutCliente: this.clientData.rut,
            montoSaldoActual: String(dataClient.totalBalance),
            montoInversionFinanciera: String(dataClient.montoFinanciero),
            montoAhorroPrevisional: String(dataClient.montoPrevisional),
            balancesFinanciero: this.balancesNOAPV,
            balancesPrevisional: this.balancesAPV,
            balancesSalud: this.balancesSalud,
        };
        data.balancesFinanciero = data.balancesFinanciero.filter( item => !item.estado || item.estado.toLowerCase() === 'vigente');
        data.balancesPrevisional = data.balancesPrevisional.filter( item => !item.estado || item.estado.toLowerCase() === 'vigente');
        data.balancesSalud = data.balancesSalud.filter( item => !item.estado || item.estado.toLowerCase() === 'vigente');

        const file = await this.http.get('assets/PDF/consolidatedBalancesAndProducts.html', { observe: 'response', responseType: 'text' }).toPromise();
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: "Resumen Cliente",
            Operacion: "Resumen Cliente",
            Metodo: "exportAsPdf()",
            eventoLog: "click boton pdf (saldos)",
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton pdf (saldos) redireccionar',
            },
        };
        this.logData(
            logtemp,
            "log_negocio",
        );
        this.createProductsPDF(file, data, dateBalance);
    }

    async createProductsPDF(html, data: pdfDataClientBalance, dateBalance: string) {
        const currencyPipeCustom = new CurrencyPipeCustom();
        const filename = `MisProductos_${data.rutCliente.replace(/[\.\-]/g, '')}_${dateBalance}.pdf`;

        const options = {
            margin: 40,
            filename,
            jsPDF: {
                orientation: 'p',
                unit: 'px',
                hotfixes: ['px_scaling'],
                format: 'letter',
                putOnlyUsedFonts: true,
                floatPrecision: 16,
            },
            html2canvas: { scale: 1 },
        };

        const totalBalancePrevisional = data.balancesPrevisional?.reduce((prev, curr) => prev + (Number(curr.amount) ?? 0), 0);
        const totalBalanceFinanciero = data.balancesFinanciero?.reduce((prev, curr) => prev + (Number(curr.amount) ?? 0), 0);

        let htmlPDF = html.body;

        htmlPDF = htmlPDF.split('[fecha]').join(data.fecha);
        htmlPDF = htmlPDF.split('[hora]').join(data.hora);
        htmlPDF = htmlPDF.split('[nombreCliente]').join(data.nombreCliente);
        htmlPDF = htmlPDF.split('[rutCliente]').join(data.rutCliente);
        htmlPDF = htmlPDF.split('[montoSaldoActual]').join(currencyPipeCustom.transform(Number(data.montoSaldoActual)));
        htmlPDF = htmlPDF.split('[montoInversionFinanciera]').join(currencyPipeCustom.transform(Number(data.montoInversionFinanciera)));
        htmlPDF = htmlPDF.split('[montoAhorroPrevisional]').join(currencyPipeCustom.transform(Number(data.montoAhorroPrevisional)));

        if (data.balancesPrevisional.length > 0) {
            htmlPDF = htmlPDF.split('[rowsPrevisional]').join(this.fillRowProductsPDF(data.balancesPrevisional, false));
            htmlPDF = htmlPDF.split('[balancePrevisional]').join(currencyPipeCustom.transform(totalBalancePrevisional));
            htmlPDF = htmlPDF.split('[showPrevisional]').join('');
        } else htmlPDF = htmlPDF.split('[showPrevisional]').join('hideTab');

        if (data.balancesFinanciero.length > 0) {
            htmlPDF = htmlPDF.split('[rowsFinanciero]').join(this.fillRowProductsPDF(data.balancesFinanciero, false));
            htmlPDF = htmlPDF.split('[balanceFinanciero]').join(currencyPipeCustom.transform(totalBalanceFinanciero));
            htmlPDF = htmlPDF.split('[showFinanciero]').join('');
        } else htmlPDF = htmlPDF.split('[showFinanciero]').join('hideTab');

        if (data.balancesSalud.length > 0) {
            htmlPDF = htmlPDF.split('[rowsSalud]').join(this.fillRowProductsPDF(data.balancesSalud, true));
            htmlPDF = htmlPDF.split('[showSalud]').join('');
        } else htmlPDF = htmlPDF.split('[showSalud]').join('hideTab');

        utils.convertToPDF(htmlPDF, options, true);
    }

    fillRowProductsPDF(dataRow: ClientBalanceModel[], salud: boolean): string {
        const currencyPipeCustom = new CurrencyPipeCustom();
        let row: string = '';

        if (salud) {
            dataRow.forEach((item) => {
                row = `${row}<div class="row">
                                <div class="cell">
                                    <div class="label-detail">
                                        ${item.name}
                                    </div>
                                </div>
                            </div>`;
            });
        } else {
            dataRow.forEach((item) => {
                row = `${row}<div class="row">
                                <div class="cell">
                                    <div class="label-detail">
                                        ${item.name}
                                    </div>
                                </div>
                                <div class="cell"></div>
                                <div class="cell">
                                    <div class="txt-detail">
                                        ${currencyPipeCustom.transform(item.amount)}
                                    </div>
                                </div>
                            </div>`;
            });
        }
        return row;
    }

    async reloadBalance() {
        this.loadingService.showLoading()
        await this.clientRequestService.loadData({ rut: this.clientData.rut });
        this.loadingService.hideLoading();
    }

    async logData(log: Partial<InputLog> & Pick<InputLog, AliasInputLogType>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
                ...EntradaLog,
            };
            inputLog.Salida = "-";
            inputLog.Secuencia = 0;
            inputLog.Servicio = "";
            inputLog.Tipo_sesion = "ASESOR";
            inputLog.extra = {};

            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
        } catch (error) {
            console.error("error: ", error);
        }
    }

    async goTocertificados(){
        this.loadingService.showLoading();
        await this.router.navigate(['balance-certificate'], {
            relativeTo: this.activateRouter,
        });

        const logChannel = "NWSA_ASESOR";
        let inputLog: InputLog = new InputLog();
        inputLog.DetalleAccionExtra = {};
        inputLog.Categoria = "Resumen Cliente";
        inputLog.Operacion = "Resumen Cliente";
        inputLog.eventoLog = "click botón certificados (saldos)";
        inputLog.Metodo = "";
        inputLog.EntradaLog = {
            rut: sessionStorage.getItem("rut"),
        };
        inputLog.Secuencia = 0;
        inputLog = {
            ...inputLog,
        };
        await this.tracesService.registerLog(inputLog, logChannel, "", "", "", "log_negocio");
    }

    async showbtncertificadosaldos() {
        const entryID = environment.KEYS.CONTENTFUL.CERTIFICADO_SALDOS_SHOW;
        const dataContent = await this.contentfulService.getEntryFromFront(entryID);
        this.showBtnCertificadoSaldos = dataContent.environmentList.environments.filter((item: string) => {
            return item === environment.name;
        }).length > 0;
    }
}
