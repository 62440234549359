import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { UtilsService } from '../../helpers/utils.service';
import { LOG_CONFIG } from '../../constants/log.constant';
import { Entrada, InputMapLog, ValueLog } from '../../classes/log';
import { environment } from '@environment';

@Injectable()
export class LogService {
    constructor(private httpClient: HttpClient, private utils: UtilsService) {}

    save(data) {
        return new Promise(async (resolve, reject) => {
            const endpoint = environment.ENDPOINTS.MS.MS_LOGGER + '/logger'
            this.httpClient
                .post(endpoint, data)
                .toPromise()
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject({
                        message: "Ha ocurrido un error al conectar con el servicio logs de  SURA INVESTMENTS",
                        error,
                    });
                });
        });
    }

    mapInputMapLog(datosAdicionales){
        try {
            const { accion, glosa } = datosAdicionales;
            if(datosAdicionales) {
                const datosAcionalesValueLog = {
                    ...datosAdicionales,
                    destino: LOG_CONFIG.DESTINO,
                    tipoSesion: sessionStorage.getItem('sesionAsesor'),
                    canal: 'NWSA'
                }
                return this.saveInputMapLog(accion, glosa, datosAcionalesValueLog);
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveInputMapLog(accion, glosa, datosAdicionalesValueLog) {
        return new Promise(async (resolve, reject) => {
            const inputMapLog: InputMapLog = new InputMapLog();
            const rut = sessionStorage.getItem("idFirestoreSesion");
            const valueLog = this.mapearValueLog(accion, glosa, datosAdicionalesValueLog);
            inputMapLog.rut = rut;
            inputMapLog.valueLog = this.utils.encriptarData(valueLog);
            this.save(inputMapLog).then((response) => {
                resolve(response);
            })
                .catch(err => {
                    reject(err);
                })
        })
    }

    mapearValueLog(accion, glosa, datosAdicionalesValueLog) {
        try {
            const valueLog: ValueLog = new ValueLog();
            const entrada = this.mapearDatosEntrada(datosAdicionalesValueLog);

            Object.assign(valueLog, {
                accion,
                glosa,
                entrada: {...entrada, payload:  datosAdicionalesValueLog?.payload},
                tipoLog: datosAdicionalesValueLog?.tipoLog,
                estado: datosAdicionalesValueLog?.estado,
                metodo: datosAdicionalesValueLog?.metodo,
                stash: datosAdicionalesValueLog?.stash,
                destino: datosAdicionalesValueLog?.destino,
                salida: datosAdicionalesValueLog?.salida,
                tipoSesion: datosAdicionalesValueLog?.tipoSesion,
                servicio: datosAdicionalesValueLog?.servicio ?? "-",
                repo: datosAdicionalesValueLog?.repo
            });

            return valueLog;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    mapearDatosEntrada(datosAdicionalesValueLog) {
        try {
            const rut = sessionStorage.getItem("idFirestoreSesion");
            const idSession = sessionStorage.getItem("idSesion");
            if (rut && idSession) {
                const entrada = new Entrada();
                this.mapearDatosEntradaLog(entrada);
                this.mapearOtrosDatos(entrada, rut, datosAdicionalesValueLog);
                return entrada;
            } else {
                return "";
            }
        } catch (error) {
            console.log(error);
            return "";
        }
    }

    mapearDatosEntradaLog(entrada) {
        entrada.entradaLog = {
            esEjecutivo: true,
            usuarioAsesor: sessionStorage.getItem("UU") || "",
            fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        };
    }

    mapearOtrosDatos(entrada, rut ,datosAdicionalesValueLog) {
        try {
            const tipoPersona = sessionStorage.getItem("tipoPersona");
            const { detalleLog, lineaNegocio, stash } = datosAdicionalesValueLog;
            entrada.detalleLog = detalleLog ? JSON.stringify(detalleLog) : "";
            entrada.lineaNegocio = lineaNegocio ?? "-";
            entrada.sessionCliente = sessionStorage.getItem('idSesion');
            entrada.fecha = new Date();
            entrada.hostIP = sessionStorage.getItem("ipCliente") || "-";
            entrada.tipoPersona = '-';
            entrada.rutCliente = rut ?? "-";
            entrada.rutEjecutivo = sessionStorage.getItem("idFirestoreSesion") || "";
            entrada.rutAsesor = sessionStorage.getItem("idFirestoreSesion") || "";
            entrada.canal =  "NWSA";
            entrada.tipoDispositivo = /mobile/i.test(window.navigator.userAgent) ? 'Mobile' : /tablet/i.test(window.navigator.userAgent) ? 'Tablet' : 'Desktop';
            entrada.modeloDispositivo = "-";
            entrada.sistemaOperativo = entrada.tipoDispositivo;
            entrada.navegador = window.navigator.userAgent;
            this.validarStash(entrada, stash);
        } catch (error) {
            console.log(error);
        }
    }


    validarStash(entrada, stash) {
        let serviceValid = "-";
        if (stash === "log_transaccion") {
            serviceValid = "TRXA";
        } else if (stash === "log_error") {
            serviceValid =  "ERRA";
        } else {
            serviceValid = "NWSA"
        }
        entrada.servicio = serviceValid;
    }
}