import { formatNumber } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appNumeros]',
})
export class NumerosDirective {
    private separadormiles: string = '.';
    private separadordecimal: string = ',';

    @Input() appNumeros: boolean = false;

    constructor(private el: ElementRef, private renderer2: Renderer2) {}

    @HostListener('change', ['$event']) onChange(event) {
        let valor = event.target.value.replaceAll(this.separadormiles, '');
        if (valor.split(this.separadordecimal).length > 2) {
            event.target.value = '';
            event.preventDefault();
        }
        if (valor && !valor.endsWith(this.separadordecimal) && valor !== '') {
            let valorstr = valor.replaceAll('.', '').replaceAll(',', '.');
            let pos = valorstr.indexOf('.');
            if (pos <= 1) {
                let formateado = formatNumber(Number(valorstr), 'es-CL');
                event.target.value = formateado;
            }
            event.preventDefault();
        }
    }

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        let e = <KeyboardEvent>event;
        let puntosep = this.separadordecimal === '.' ? 190 : 188;
        if (!this.appNumeros) {
            puntosep = 110; //neutraliza el ingreso de separador decimal
        }
        if (this.pasaKeyCode(e, puntosep)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    }

    pasaKeyCode(e, puntosep) {
        if (
            [46, 8, 9, 27, 13, 110, puntosep].indexOf(e.keyCode) !== -1 ||
            // Allow: Ctrl+A
            (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+C
            (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+V
            (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
            // Allow: Ctrl+X
            (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
            // Allow: home, end, left, right
            (e.keyCode >= 35 && e.keyCode <= 39)
        ) {
            // let it happen, don't do anything
            return true;
        } else {
            return false;
        }
    }
}
