import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ModalController } from "@ionic/angular";
//import { ContratoRegistrarActividad, TraceService } from "src/shared/services";
import { CMSService } from "../../services/cms/cms.service";
import { Alerts } from '../../../shared/components/alert-market/interfaces'
import { PopupService } from "../../services/popup/popup.service";
import { UtilsService } from "../../services";
import * as _ from "lodash";

@Component({
  selector: "app-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"],
})
export class PopupComponent implements OnInit {
  @Input() data: any;

  isMobile: boolean;

  datosUsuario = "";

  hasImage: boolean;

  button1Style: string;

  button2Style: string;

  button1Text: string;

  button2Text: string;

  public alert: Alerts;

  constructor(public domSanitizer: DomSanitizer, 
    private modalController: ModalController, 
    private cmsService: CMSService, 
    private popupService: PopupService,
    private utilsService: UtilsService,
    ) {}

  ngOnInit(): void {
    this.hasImage = this.verifyImage();
    this.setPredifinedValues();
    const nombre = sessionStorage.getItem("usuario");
    this.datosUsuario = `${nombre}`;
  }

  closeModal(optionSelected: boolean): void {
    if (optionSelected && this.data.urlBoton1) {
      window.open(this.data.urlBoton1, this.data.abrirEnlaceNuevaPaginaBoton1 ? "_blank" : "_self");
    }
    this.modalController.dismiss(optionSelected);
  }

  verifyImage() {
    return this.data.imagen && this.data.imagen.url;
  }

  setPredifinedValues() {
    this.button1Style = this.data.estiloBoton1 ?? "filled-btn-yellow";
    this.button2Style = this.data.estiloBoton2 ?? "txt-btn-blue";
    this.button1Text = this.data.textoBoton1 ?? "CONFIRMO";
    this.button2Text = this.data.textoBoton2 ?? "RECHAZO";
  }

  async setAction(action: string, closeModal: boolean) {
    try {
      const titulopp = this.data.titulo?.toUpperCase();
      const segmento = sessionStorage.getItem("segmentoCliente") || "";
      const lineaNegocio = "";
      const accion = action?.toUpperCase();
      const idpopup = this.data.id || `POPUP-ADMINISTRABLE-${titulopp.split(" ").join("-")}`;
      const detallepopup = titulopp;

      const data = {
        segmento,
        lineaNegocio,
        accion,
        idpopup,
        detallepopup,
      };

    //  this.cmsService.guardarAccionPopupAdministrable(data);
     // this.setAccionLog(data);
      this.closeModal(closeModal);
    } catch (err) {
    //  this.setAccionLog({ ...this.data, error: String(err) });
      this.closeModal(closeModal);
    }
  }

      // // PRUEBAS DE POPUP ASESOR

    getPopups(): void {
        if (sessionStorage.getItem("cerrarPopupGenerico")) {
            return;
        }

        this.popupService
            .getConfigPopup()
            .then((response: any) => {
                console.log('listAlertasMercado: ',response)
                const listAlertasMercado = response?.alertasMercado;
                const listPopUpAdministrables = response?.popupAdministrables;
                console.log('LISTA1: ',listAlertasMercado)
                if (listAlertasMercado?.length || listPopUpAdministrables?.length) {
                    if (listAlertasMercado?.length) {
                        const alertasMercado = listAlertasMercado;
                        if (alertasMercado.length >= 1 && !this.alert) {
                            const orderAlerts: Alerts = alertasMercado.sort((a, b) => b.order - a.order);
                            console.log('ORDEN ALERT: ',orderAlerts)
                            this.alert = orderAlerts || null;
                            console.log('ORDER ALERT1:',this.alert)
                        }
                    }

                    if (listPopUpAdministrables?.length) {
                        const popupAministrables = _.sortBy(listPopUpAdministrables, (x) => x.order);
                        popupAministrables.forEach((item) => {
                            if (!item?.detalleAccionPopUp || item?.detalleAccionPopUp?.seMuestraPopUpPostEvaluaAccion) {
                                this.getModalAdministrable(item);
                            }
                        });
                    }
                } else {
                    // this.mservice.setShowHelp(true);
                   // sessionStorage.setItem("cerrarPopupGenerico", "true");
                }
            })
            .catch(() => { });
    }

    async getModalAdministrable(data: any): Promise<void> {
        const opts = {
            component: PopupComponent,
            cssClass: "modal-home-selecciom",
            backdropDismiss: false,
            showBackdrop: true,
            componentProps: {
                data,
            },
        };
        const modal = await this.modalController.create(opts);
        this.utilsService.appendModal(modal);
        await modal.present();
        modal.onDidDismiss().then(async (res) => {
            if (res && res.data !== null && res.data !== undefined) {
                this.utilsService.deleteModal(modal);
                sessionStorage.setItem("cerrarPopupGenerico", "true");
            }
        });
    }


}
