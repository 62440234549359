import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss'],
})
export class WelcomeMessageComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('executiveName') executiveName: string;
  welcomeMessage = '';

  ngOnInit(): void {
    this.executiveName = this.executiveName.split(' ')[0];
    this.welcomeMessage = this.getTypeWelcome();
  }

  getTypeWelcome(): string {
    /*
        06:00 AM a 12:00 PM = ‘Buenos días’
        12:00 PM a 20:00 = 'Buenas tardes'
        20:00 a 06:00 AM = 'Buenas noches'
         */
    const currentTime = moment();
    let message = '';
    if (currentTime.isBefore(moment('12:00 pm', 'HH:mm a'))) {
      message = 'Buenos días';
      return message;
    }
    if (currentTime.isBetween(moment('12:00 pm', 'HH:mm a'), moment('20:00 pm', 'HH:mm a'))) {
      message = 'Buenas tardes';
      return message;
    }
    if (currentTime.isAfter(moment('20:00 pm', 'HH:mm a'))) {
      message = 'Buenas noches';
      return message;
    }
    return message;
  }
}
