import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { TabItemComponent } from '../tab-item/tab-item.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { GtmService } from "../../../shared/services/gtm/gtm.service";

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements AfterContentInit {
  @ContentChildren(TabItemComponent) tabItemComponents!: QueryList<TabItemComponent>;

  tabItems: TabItemComponent[];
  currTab = 0;
  currContent: TemplatePortal;

  constructor(private gtmService: GtmService) {}

  ngAfterContentInit(): void {
    this.tabItems = this.tabItemComponents.toArray();
    this.currContent = this.tabItemComponents.first.content;
  }

  clickTab(item: TabItemComponent, index: number) {
    this.currContent = item.content;
    this.currTab = index;
    this.gtmService.pushTagEventoInteractivo(
      'evento-interactivo',
      'Resumen Cliente',
      'Click',
      `Tab Producto : ${item.title}` 
    );
  }
}
