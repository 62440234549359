import { Injectable } from '@angular/core';
import { PopupService, UtilsService } from 'src/app/shared/services';
import { Alerts } from 'src/app/shared/components/alert-market/interfaces';
import { PopupComponent } from 'src/app/shared/components/popup/popup.component';
import { ModalController } from "@ionic/angular";
import * as _ from "lodash";

@Injectable({
    providedIn: 'root'
})
export class ClientPopUpService {
    constructor(
        private popupService: PopupService,
        private modalController: ModalController,
        private utilsService: UtilsService,
    ) { }

    //POPUP ASESOR

    getPopups() {
        let alert: Alerts;
        if (sessionStorage.getItem("cerrarPopupGenerico")) {
            return;
        }

        return this.popupService
            .getConfigPopup()
            .then((response: any) => {
                const listAlertasMercado = response?.alertasMercado;
                const listPopUpAdministrables = response?.popupAdministrables;
                if (listAlertasMercado?.length || listPopUpAdministrables?.length) {
                    if (listAlertasMercado?.length) {
                        const alertasMercado = listAlertasMercado;
                        if (alertasMercado.length >= 1 && !alert) {
                            const orderAlerts: Alerts = alertasMercado.sort((a, b) => b.order - a.order);
                            alert = orderAlerts || null;
                        }
                    }

                    if (listPopUpAdministrables?.length) {
                        const popupAministrables = _.sortBy(listPopUpAdministrables, (x) => x.order);
                        popupAministrables.forEach((item) => {
                            if (!item?.detalleAccionPopUp || item?.detalleAccionPopUp?.seMuestraPopUpPostEvaluaAccion) {
                                this.getModalAdministrable(item);
                            }
                        });
                    }
                }
                return alert;
            })
            .catch(() => { });
    }

    async getModalAdministrable(data: any): Promise<void> {
        const opts = {
            component: PopupComponent,
            cssClass: "modal-home-selecciom",
            backdropDismiss: false,
            showBackdrop: true,
            componentProps: {
                data,
            },
        };
        const modal = await this.modalController.create(opts);
        this.utilsService.appendModal(modal);
        await modal.present();
        modal.onDidDismiss().then(async (res) => {
            if (res && res.data !== null && res.data !== undefined) {
                this.utilsService.deleteModal(modal);
                sessionStorage.setItem("cerrarPopupGenerico", "true");
            }
        });
    }

}

