import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { CdkStepperModule } from '@angular/cdk/stepper';



@NgModule({
  imports: [MatRippleModule, MatButtonModule, MatProgressSpinnerModule, MatDialogModule,CdkStepperModule],
  exports: [MatRippleModule, MatButtonModule, MatProgressSpinnerModule, MatDialogModule,CdkStepperModule],
})
export class MaterialModule {}
