import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-mobile-input-search',
  templateUrl: './mobile-input-search.component.html',
  styleUrls: ['./mobile-input-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileInputSearchComponent implements OnChanges, ControlValueAccessor {
  @Input() show = false;
  @Input() placeholder = '';
  @Input() maxLength: number;
  @Input() disabled = false;

  @Output() closeSearch = new EventEmitter<void>();

  @Output() clearSearch = new EventEmitter<void>();

  @ViewChild('input') input: ElementRef;

  _value: string;
  @Input()
  set value(value: string) {
    this._value = value;
  }

  get value() {
    return this._value;
  }

  @HostBinding('class.disabled')
  get isDisabled() {
    return this.disabled;
  }

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.show && !changes.show.firstChange && changes.show.currentValue !== changes.show.previousValue) {
      if (this.show) {
        this.input.nativeElement.focus();
      } else {
        this.input.nativeElement.blur();
      }
    }
  }

  toggleSearch() {
    this.show = !this.show;
  }

  onInput(event) {
    this.writeValue(event.target.value);
  }

  writeValue(value: any): void {
    this._value = value;
    this.onChange(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange = (_: string) => {};
  onTouched = () => {};
}
