export const mensajesCompliance = (rut) => {
    return [
        {
            id: 1, 
            titulo: 'Importante',
            mensaje: `<div style="display: block !important;">El RUT <b>${rut}</b> ya es cliente y no presenta ningún inconveniente en Compliance.</div>`,
            color: 'warning'
        },
        {
            id: 2,
            titulo: 'Importante',
            mensaje: `<div style="display: block !important;">El RUT <b>${rut}</b> ya es cliente, pero tiene bloqueo por el área de Cumplimiento.</div>`,
            color: 'error'
        },
        {
            id: 3,
            titulo: 'Importante',
            mensaje: `<div style="display: block !important;">Antes de gestionar al RUT <b>${rut}</b> debes contactar al <a href="mailto: cumplimiento.cl@surainvestments.com" target="_blank"><strong>área de Cumplimiento</strong></a>.</div>`,
            color: 'error'
        },
        {
            id: 4,
            titulo: 'Importante',
            mensaje: `<div style="display: block !important;">El RUT <b>${rut}</b> no cuenta con productos contratados, invítalo a hacerse cliente desde el <a href="https://inversiones.sura.cl/nosotros/Paginas/haztecliente.aspx" target="_blank">sitio público</a> o envíale una clave prospecto por <a href="http://acicwmweb/Operativo/AsignacionClaveAleatoria.aspx" target="_blank">ACIC</a>.</div>`,
            color: 'info'
        },
        {
            id: 5,
            titulo: 'Error',
            mensaje: `<div style="display: block !important;">Por el momento no podemos atender tu solicitud inténtelo en unos minutos.</div>`,
            color: 'error'
        },
        {
            id: 6,
            titulo: 'Importante',
            mensaje: `<div style="display: block !important;">El cliente que estás buscando no es parte de tu cartera.</div>`,
            color: 'info'
        },
        
    ]
}

export const mensajePorDefecto = () => {
    return {
            titulo: 'Error',
            mensaje: `<div style="display: block !important;">Por el momento no podemos atender tu solicitud inténtelo en unos minutos.</div>`,
            color: 'error'
        }
}
