import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, Optional, Output, Renderer2, Self, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';


export type LabelPosition = 'fixed' | 'default';


@Component({
  selector: 'mmb-input-monto',
  templateUrl: './mmb-input-monto.component.html',
  styleUrls: ['./mmb-input-monto.component.scss']
})
export class MmbInputMontoComponent implements ControlValueAccessor, OnChanges {
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() maxlength: number = 18;
  @Input() minlength: number = null;
  @Input() max: number = null;
  @Input() min: number = null;
  @Input() label: string;
  @Input() hintMessage: string;
  @Input() value = '';
  @Input() labelPosition: LabelPosition = 'default';
  @Input() autocomplete: string;

  @ViewChild('input', {static: true}) input: ElementRef;
  @Output() focus: EventEmitter<Event> = new EventEmitter(null)
  @Output() blur: EventEmitter<Event> = new EventEmitter(null)
  @Output() change: EventEmitter<Event> = new EventEmitter(null)

  private hasFocus = false;
  convalor: boolean;

  @HostBinding('class.has-focus')
  get isFocused() {
    return this.hasFocus;
  }

  @HostBinding('class.has-value')
  get hasValue() {
    return this.value !== '' && this.value !== null;
  }

  @HostBinding('class.label-fixed')
  get isFixedLabel() {
    return this.labelPosition === 'fixed';
  }

  @HostBinding('class.disabled')
  get isDisabled() {
    return this.disabled;
  }

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(ch: SimpleChanges): void {
   if (ch.type) {
    this.verifica();
   }
  }

  focusInput(event: FocusEvent) {
    if(event.type === 'focusin') {
      this.focus.emit(event);
    }
    this.hasFocus = event.type === 'focusin';
  }

  onInput(event) {
    this.change.emit(event);
    this.onTouched();
    this.writeValue(event.target.value);
  }

  writeValue(value: any): void {
    this.value = value;
    this.onChange(value);
    this.cd.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange = (_: string) => {
    this.verifica();
  };

  onTouched = () => {
    this.verifica();
  };

  verifica() {
    if (this.value) {
      this.convalor = true;
    }
  }

  onBlur($event){
    this.blur.emit($event);
  }


}
