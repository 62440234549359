// Angular Core
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Angular Material
import { MatRadioChange } from '@angular/material/radio';

@Component({
    selector: 'app-items-profile',
    templateUrl: './items-profile.component.html',
    styleUrls: ['./items-profile.component.scss'],
})
export class ItemsProfileComponent implements OnInit {
    @Output() itemSelect = new EventEmitter<object>();
    @Output() errorSelect = new EventEmitter<boolean>();
    @Output() page = new EventEmitter<number>();

    @Input() alternatives: any;
    @Input() statusSelected: boolean;
    @Input() pressButtomContinue: boolean;
    @Input() classSelected: string;
    @Input() name: string;

    @Input() numero: number;

    ngOnInit() {
        this.statusSelected = true;
        this.pressButtomContinue = true;
    }

    radioChange(event: MatRadioChange) {
        const item = {
            numeroInternoPregunta: event.source.name,
            identificadorAlternativa: event.value,
        };
        this.itemSelect.emit(item);
        this.errorSelect.emit(true);
    }
}
