// Angular Core
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';

// RxJS
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

// Models
import { SuraActionsExecutive } from '../shared/models/contentful.model';

// Components
import { TimeoutMessageComponent } from '../shared/components/timeout-message/timeout-message.component';
import { Keepalive } from '@ng-idle/keepalive';

// Services
import {
    AppService,
    ExecutiveService,
    DialogService,
    ContentfulService,
    SidebarMenuService,
    LoadingService,
    LocalStorageService,
    ChatBotService,
} from 'src/app/shared/services';
import { LoginService } from 'src/app/features/services';

// NG Idle
import { Idle } from '@ng-idle/core';

// Environments
import { environment } from '@environment';
import { IRespuestaConfiguracion } from '../shared/models/chat-bot.model';

@Component({
    selector: 'app-private-container',
    templateUrl: './private-container.component.html',
    styleUrls: ['./private-container.component.scss'],
})
export class PrivateContainerComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    collapsed = true;
    suraActionsExecutive: SuraActionsExecutive;
    lastPing?: Date = null;
    isModalOpen = false;
    subscriptions: Subscription[] = [];

    configFromContentful: any;
    showMessageConfigFromContentful = false;

    mostrarChatBot = false;
    datosConfiguracionBot: IRespuestaConfiguracion = null;

    constructor(
        public sidebarMenuService: SidebarMenuService,
        public loadingService: LoadingService,
        private breakPointObserver: BreakpointObserver,
        private dialogService: DialogService,
        private idle: Idle,
        private keepalive: Keepalive,
        private appService: AppService,
        private contentfulService: ContentfulService,
        private router: Router,
        private loginService: LoginService,
        private executiveService: ExecutiveService,
        private localStorageService: LocalStorageService,
        private chatBotService: ChatBotService
    ) {
        breakPointObserver
            .observe('(max-width: 768px)')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                if (val.matches) {
                    this.collapsed = false;
                }
            });

        this.sidebarMenuService.initializeMenu();
        this.onShowPoll();
        this.initIdle();
    }

    ngOnInit() {
        this.configMessageFromContentful();
        this.evaluarConfiguracionChatBot();
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
    }

    ngOnDestroy() {
        // this.unsubscribe$.unsubscribe();
        // this.sidebarMenuService.destroy();
        // this.idle.onIdleStart.unsubscribe();
        // this.idle.onIdleEnd.unsubscribe();
        // this.keepalive.onPing.unsubscribe();
        // this.reset();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private onShowPoll() {
        this.contentfulService.getShowPollService().then((data) => {
            this.suraActionsExecutive = data;
        });
    }

    private async initIdle() {
        const idleConfig = await this.contentfulService.getEntryFromFront(environment.KEYS.CONTENTFUL.SURA_ACTIONS_EXECUTIVE);
        // sets an idle timeout of 5 seconds, for testing purposes.

        this.idle.setIdle(idleConfig.startCounter); // 600 = 1 Minuto // 1140 // 19 minutos
        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        this.idle.setTimeout(idleConfig.timeOut); //
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        // this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        // this.idle.setInterrupts(
        //   [
        //     //new EventTargetInterruptSource(document, 'mousemove'),
        //     // new EventTargetInterruptSource(document, 'mousedown'),
        //     // new EventTargetInterruptSource(document, 'mouseup'),
        //     new EventTargetInterruptSource(document, 'click')
        //   ]
        // );
        // this.idle.setInterrupts([new EventTargetInterruptSource(this.element.nativeElement, "mousedown touchstart")]);

        this.subscriptions.push(
            this.idle.onIdleEnd.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
                this.reset();
            }),
        );

        this.idle.onTimeout.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            this.loginService.signOutSession();
        });

        this.subscriptions.push(
            this.idle.onIdleStart.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
                if (!this.isModalOpen) {
                    this.isModalOpen = true;
                    this.dialogMessage();
                }
            }),
        );

        // sets the ping interval to 15 seconds
        this.keepalive.interval(15);

        this.subscriptions.push(this.keepalive.onPing.pipe(takeUntil(this.unsubscribe$)).subscribe(() => (this.lastPing = new Date())));

        this.subscriptions.push(
            this.appService
                .getUserLoggedIn()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((userLoggedIn) => {
                    if (userLoggedIn) {
                        this.idle.watch();
                    } else {
                        this.idle.stop();
                    }
                }),
        );
        this.reset();
    }

    private reset() {
        this.idle.watch();
    }

    private dialogMessage(): void {
        const config = {
            panelClass: 'modal-timeout',
            disableClose: false,
        };
        const dialogRef = this.dialogService.openDialog(TimeoutMessageComponent, config);
        dialogRef.afterClosed().subscribe((value) => {
            this.isModalOpen = false;
            this.reset();
        });
    }

    async configMessageFromContentful() {
        const executiveWithPortfolio = this.executiveService.executiveModel.executiveProfile.ownCustomers;

        let option: string;

        if (executiveWithPortfolio) {
            option = environment.KEYS.CONTENTFUL.CONFIG_MESSAGE_EXECUTIVE_WITH_PORTFOLIO;
        } else {
            option = environment.KEYS.CONTENTFUL.CONFIG_MESSAGE_EXECUTIVE_WITHOUT_PORTFOLIO;
        }

        let configFromContentful = await this.contentfulService.getEntryFromFront(option);

        let dataContentful = this.localStorageService.get('dataContentful', true);

        dataContentful.executiveMessage = configFromContentful;

        this.localStorageService.set('dataContentful', dataContentful, true);
    }

    async evaluarConfiguracionChatBot(): Promise<void> {
        this.datosConfiguracionBot = await this.chatBotService.evaluarConfiguracionChatBot();
        this.mostrarChatBot = this.datosConfiguracionBot.data.habilitado;
    }
}
