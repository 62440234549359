//DTOS
type messageType = 'error' | 'info' | 'warning';
export interface MensajeBackend {
  messageContent?: string;
  messageType?: messageType;
  messageTitle?: string;
  showMessage: boolean;
}

export interface PershingClienteSession {
  idsession: string;
  'id-log-accion': string;
}

export interface PershingInstrumentoTipo {
  codigo: string;
  descripcion: string;
  seleccionado: boolean;
}

export interface FiltroCampo {
  campo: string;
  valor: string;
  like: boolean;
}

export interface ClienteActivoDto {
  rut: string;
  rutparteentera: number;
  rutdigitoverificador: string;
  nombre: string;
  email: string;
}



export interface DetalleCarritoInstrumentoPershing {
    ticker: string;
    descripcion: string;
    manager: string;
    categortia: number;
    riesgo: number;
    serie: string;
    tipocompraventa: string;
    monto: number;
}

export interface InstrumentoCompraPershing {
    ticket: string;
    descripcion: string;
    serie: string;
    manager: string;
    riesgo: number;
    categoria: string;

    tipocompra: string; //F= full P=porcentae C=Cantidad M=Monto
    seleccionado: boolean;
    agregado: boolean;

    agregadoTipo?: string;
    agregadoMontoCantidad?: number;
    agregadoTotalInstruccionUSD?: number;
}

//mmb nuevo
export interface InstrumentoCompraPershingJson {
  "fondo-ticker":             string;
  "fondo-manager":            string;
  "fondo-descripcion":        string;
  "fondo-categoria-sura":     string;
  "fondo-tipo-recomendacion": string;
  "fondo-moneda":             string;
  habilitado:                 number;
  "fondo-serie":              string;
  "indicador-comision":       string;
  "permite-comision":         string;
  "fondo-nota-riesgo":        number;
  "fondo-nota-descuento":     number;
  "minimo-compra-usd":        number;
  
  //para seleccionar y agregar
  seleccionado:                 boolean;
  agregado:                     boolean;
  tipocompra?                   :string; // se registra cuando de agrega F= full P=porcentae C=Cantidad M=Monto
  agregadoMontoCantidad?        :number; // se registra cuando de agrega
  agregadoTotalInstruccionUSD?  :number;   // se calcula cuando de agrega
  montocomisionUSD              :number;   // se calcula cuando de agrega

  //para manejo en carro item seleccionado
  accion                       : string //acciones angular en pantalla
}



export interface  ChipFilter {
    index: number;
    text: string;
    id: IDChip;
    paramKey?: EFilterParams;
}

export enum IDChip {
    keywordFondo = 'keywordFondo',
    param = 'param'
}

export enum EFilterParams {
    nombre = 'nombre',
    categoria = 'categoria',
    manager = 'manager',
    recomendacion= 'recomendacion',
}

export interface FilterFondos {
    noFilter?: boolean;
    manager?: string;
    categoria?: string;
    recomendacion?: string;
}
  

export interface TabList {
    index: number;
    txtTabs: Tabs;
}

export enum Tabs {
    todos = 'todos',
    misFondos = 'mis fondos',
    recomendados = 'recomendados',
}


//modal generico para las 
export type ModalGenericoOrdenes =  {
    titulo: string,
    mensaje: string,
    labelacepta: string,
    labelarechaza: string,
  };


export interface ResumenCdfVentaPershing {
    montodisponiblecomprausd: number,
    totalmoneymarketusd: number,
    fondoreservausd: number
}

export interface InstrumentoCdfVentaPershing {
    cuenta: string;
    descripcioncuenta: string;
    ticker: string;
    descripcionfondo: string;
    tipoventa: string; //C=cantidad, M=Monto , P=Porcentaje , F=Full
    montocantidad: number;
    totalventausd: number
}

// dto de servicio  
export interface SaldoCustodioCtaPershing {
    cuenta: string;
    descripcioncuenta: string;
    saldomoneymarket: number;
    saldoentransito: number;
    fondoreserva: number;
    saldodisponible: number;
    tiporegimen: string;
    instrumentos?: SaldoCustodioCtaInstrumentoPershing[];
    seleccionado?: boolean;
}

//mmb nuevo
export interface SaldoCustodioCtaPershingJson {
  "numero-cuenta":            string;
  "descripcion-cuenta":       string;
  "id-tipo-regimen":          number;
  "tipo-regimen":             string;
  "id-regimen-tributario":    number;
  "regimen-tributario":       string;
  "money-market-usd":         number;
  "money-market-clp":         number;
  "monto-transito-usd":       number;
  "monto-transito-clp":       number;
  "disponible-usd":           number;
  "disponible-clp":           number;
  "gestion-mandato":          boolean;
  "fondo-reserva-compra-usd": number;
  "fondo-reserva-compra-clp": number;
  "fondo-reserva-venta-usd":  number;
  "fondo-reserva-venta-clp":  number;
  "cuenta-preferente":        boolean;
  "tipo-cambio-fecha":        string;
  "tipo-cambio-usd":          number;
  seleccionado?: boolean;
}


export interface CuentaCustodioVentaJson {
  "numero-cuenta":      string;
  "descripcion-cuenta": string;
  "money-market-usd":   number;
  "money-market-clp":   number;
  "monto-transito-usd": number;
  "monto-transito-clp": number;
  "disponible-usd":     number;
  "disponible-clp":     number;
  "gestion-mandato":    boolean;
  "cuenta-preferente":  boolean;
  instrumentos?:         CuentaCustodioInstrumentoVentaJson[];
}

export interface CuentaCustodioInstrumentoVentaJson {
    ticker: string;
    'tipo-instrumento': string;
    'nombre-instrumento': string;
    cuotas: number;
    'valor-cuota-usd': number;
    'saldo-valorizado-usd': number;
    "porcentaje-riesgo":   number;
    "monto-maximo-full-riesgo": number;
    "ya-ingresado": boolean;

    //para seleccionar y agregar
    seleccionado: boolean;
    agregado: boolean;
    tipoventa: string; // se registra cuando de agrega F= full P=porcentae C=Cantidad M=Monto
    agregadoMontoCantidadCuotas: number; // se registra cuando de agrega
    agregadoTotalInstruccionUSD: number; // se calcula cuando de agrega
    montocomisionUSD: number; // se calcula cuando de agrega

    //para manejo en carro item seleccionado
    accion: string; //acciones angular en pantalla
}




export interface SaldoCustodioCtaInstrumentoPershing {
    ticket: string;
    tipoinstrumento: string;
    nombredescripcion: string;
    cuotas : number;
    valorcuotausd: number;
    saldovalorizadousd: number;
    seleccionado: boolean;
    agregado: boolean;
    riesgo?: number; //campo que tecnicamente no tenemos en custodio
    tipoventa?: string; //C=cantidad, M=Monto , P=Porcentaje , F=Full
    montocuotasporcentaje?: number, // 80,
}



export interface SolicitudPershingJson {
  "id-solicitud"?:                  number;
  "id-folio-orden"?:                number;
  "estado"?:                        string;
  "id-ejecutivo"?:                  string;
  "nombre-ejecutivo"?:              string;
  "email-ejecutivo"?:               string;
  "rut-cliente"?:                   number;
  "rut-dv-cliente"?:                string;
  "nombre-cliente"?:                string;
  "email-cliente"?:                 string;
  "cantidad-ordenes-compra"?:       number;
  "cantidad-ordenes-venta"?:        number;
  "cantidad-ordenes-cambio-fondo"?: number;
  "fecha-valorizacion"?:            string;
  ordenes?:                         SolicitudPershingOrdenJson[];
}

export interface SolicitudPershingOrdenJson {
  id?:                   number;
  instruccion?:          string;
  "numero-cuenta"?:      string;
  "descripcion-cuenta"?: string;
  "tipo-regimen"?:       string;
  instrumentos?:         SolicitudPershingOrdenInstrumentoJson[];

  totalusdcompra?:       number;
  totalusdventa?:        number;
}

export interface SolicitudPershingOrdenInstrumentoJson {
  ticker?:                        string;
  "tipo-instrumento"?:            string;
  "numero-cuenta"?:               string;
  "origen-destino"?:              string;
  "nombre-instrumento"?:          string;
  "tipo-compra"?:                 string;
  "tipo-venta"?:                  string;
  cuotas?:                        number;
  "valor-cuota-usd"?:             number;
  "saldo-valorizado-usd"?:        number;
  "monto-cuotas-porcentaje"?:     number;
  "total-instruccion-usd"?:       number;
  "comision-usd"?:                number;
  "porcentaje-riesgo"?:           number;
  "rango-porcentaje-minimo"?:     number;
  "rango-porcentaje-maximo"?:     number;
  "rango-cuotas-minimo"?:         number;
  "rango-cuotas-maximo"?:         number;
  "rango-monto-usd-minimo"?:      number;
  "rango-monto-usd-maximo"?:      number;
  "captura-nota-riesgo"?:         number;
  "captura-nota-descuento"?:      number;
  "captura-indicador-descuento"?: string;
  "captura-permite-descuento"?:   string;
  "tipo-serie"?:                  string;
}


export interface SolicitudLogAccionJson {
  "rut-cliente":    number;
  "dv-rut-cliente": string;
  "ip-remota":      string;
  "os-tipo":        string;
  "os-browser":     string;
}

export interface SolicitudLogAccionEventoJson {
  "id-log-accion": string;
  "ip-remota":     string;
  paso:            number;
  glosa:           string;
  servicio:        string;
  metodo:          string;
  entrada:         string;
  salida:          string;
  status:          string;
}

export interface SolicitudLogAccionErrorJson {
  "id-log-accion": string;
  "ip-remota":     string;
  glosa:           string;
  mensaje:         string;
}


export interface ParametroSistemaPershingJson {
  id:               string;
  descripcion:      string;
  "valor-numerico": number;
  "valor-texto":    null | string;
}


export interface UploadContentManagerJSON {
  idorden         : number;
  "archivo-base64": string;
  "tipo-contenido": string;
  nombre:           string;
  "rut-cliente":    string;
  "nombre-cliente": string;
}


export interface SolicitudDto {
    idejecutivo: string, //'mmaldonado',
    nombreejecutivo: string, //'Marcelo Maldonado',
    emailejecutivo: string, // 'ejecutivo@sura.cl',
    rutcliente: number, //14387661,
    rutdvcliente: number, //8,
    nombrecliente: string, // 'Marcelo ALferez Perez',
    emailcliente: string, // 'cliente@gmail.com',
    cantidadordenescompra: number, // 1,
    cantidadordenesventa: number, // 0,
    cantidadordenescambiofondo: number, // 0,
    ordenes?: OrdenDto[]
}



export interface OrdenDto {
    id: number; //id de la orden
    instruccion:  string; //Compra,
    numerocuenta:  string; //TD60089911,
    descripcioncuenta: string; // Ahorro Previsional Voluntario,
    tiporegimen: string; //NO APV,
    instrumentos?: OrdenInstrumentoDto[];
    totalusdcompra: number;
    totalusdventa: number;
}


export interface OrdenInstrumentoDto {
    ticker:  string; // L5447Q166,
    tipoinstrumento?:  string; // Fondos Mutuos,
    serie?: string, //Preferente
    manager?: string, //Amundi
    riesgo?: number, //1
    categoria?: string, //categoria
    numerocuenta?:  string; // TD6018759,
    origendestino?:  string; // Origen,
    nombreinstrumento?:  string; // JPMorgan  Asia Growth Fund,
    tipocompra?:  string; // F,
    tipoventa?:  string; // F,
    cuotas?: number, // 345,
    valorcuotausd?: number, // 3233,
    saldovalorizadousd?: number, // 322,
    montocuotasporcentaje?: number, // 80,
    totalinstruccionusd?: number, // 3223,
    comisionusd?: number, // 20,
    porcentajeriesgo?: number, // 5,
    rangoporcentajeminimo?: number, // 5,
    rangoporcentajemaximo?: number, // 97,
    rangocuotasminimo?: number, // 100,
    rangocuotasmaximo?: number, // 2000,
    rangomontousdminimo?: number, // 450.05,
    rangomontousdmaximo?: number, // 7800.05,
    capturanotariesgo?: number, // 1,
    capturanotadescuento?: number, // 1,
    capturaindicadordescuento?: number, // 1,
    capturapermitedescuento?: number, // 1,
    tiposerie?: number, // 3

    
    seleccionado?: boolean,
    accion?: string //acciones angular en pantalla
}
