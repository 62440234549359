import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import {
    SURAPassConsultaTransaccionesAsesorJson,
    SURAPassConsultaTransaccionesClienteJson,
    SURAPassDesbloqueoDispositivoJson,
    SURAPassDesEnrolaDispositivoJson,
    SURAPassTransaccionJson,
} from '../../models/surapass/surapass.modelo';

@Injectable({
    providedIn: 'root',
})
export class SurapassService {
    //CONSTANTES PUBLICAS
    public STORAGE_SURAPASS_ULTIMA_TRANSACCION: string = 'SURAPassUltimaTransaccion';

    private URL_BFF_ORDENES = environment.ENDPOINTS.BFF.BFF_ORDENES;

    constructor(private readonly http: HttpClient, private tracesService: TracesService) {}

    /**
     * Consulta Dispositivo
     * @param rut rut
     * @returns
     */
    public async getDispositivoActivo(rutcliente: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_ORDENES}/v1/surapass/dispositivo/activo`, {
                    params: {
                        rut: rutcliente
                     },
                })
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Recupera Historial de Dispositivos
     * @param rutcliente
     * @returns
     */
    public async getDispositivoHistorial(rutcliente: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_ORDENES}/v1/surapass/dispositivo/historial`, {
                    params: {
                        rut: rutcliente,
                    },
                })
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Grabar la solicitud en en la base de datos
     * @param parametros prametros
     * @returns
     */
    public postCrearTransaccion(convenio: number, parametros: SURAPassTransaccionJson): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.URL_BFF_ORDENES}/v1/surapass/transaccion/crear/${convenio}`, parametros)
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Consulta el estado de una transaccion
     * @param parametros aplicacion origen
     * @returns
     */
    public async getEstadoTransaccion(numerotransaccion: number, aplicacion: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_ORDENES}/v1/surapass/transaccion/estado`, {
                    params: {
                        numerotransaccion: String(numerotransaccion),
                        aplicacion: aplicacion,
                    },
                })
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Desenrola un dispositivo
     * @param parametros datos del desenrolamiento
     * @returns
     */
    public putDesenrolarDispositivo(parametros: SURAPassDesEnrolaDispositivoJson): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .put(`${this.URL_BFF_ORDENES}/v1/surapass/dispositivo/desenrolar`, parametros)
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Desbloquea un dispositivo
     * @param parametros datos del desenrolamiento
     * @returns
     */
    public putDesbloquearDispositivo(parametros: SURAPassDesbloqueoDispositivoJson): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .put(`${this.URL_BFF_ORDENES}/v1/surapass/dispositivo/desbloquear`, parametros)
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Consulta de Transacciones Asesor
     * @param parametros datos de consulta asesor
     * @returns
     */
    public async getTransaccionesAsesor(parametros: SURAPassConsultaTransaccionesAsesorJson): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_ORDENES}/v1/surapass/transacciones/asesor`, {
                    params: {
                        nombreEjecutivoSolicitante: parametros.nombreEjecutivoSolicitante,
                        cantidadDiasAtras: String(parametros.cantidadDiasAtras),
                    },
                })
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Consulta de Transacciones Cliente
     * @param parametros datos de consulta cliente
     * @returns
     */
    public async getTransaccionesCliente(parametros: SURAPassConsultaTransaccionesClienteJson): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_ORDENES}/v1/surapass/transacciones/cliente`, {
                    params: {
                        idToken: parametros.idToken,
                        rut: parametros.rut,
                        tipoConsulta: parametros.tipoConsulta,
                    },
                })
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Consulta de Transaccion Detalle
     * @param numerotransaccion numero de transaccion en ser consultada
     * @returns
     */
    public async getTransaccionDetalle(numerotransaccion: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_ORDENES}/v1/surapass/transaccion/detalle`, {
                    params: {
                        idTransaccion: String(numerotransaccion),
                    },
                })
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Consulta Configuracion SURAPass
     * @returns
     */
    public async getConfiguracion(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_ORDENES}/v1/surapass/configuracion`)
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
