import { Injectable } from '@angular/core';
import { LogService } from '../logs/logs.service';
import { environment } from '@environment';
import { IDatosAdicionales, ILogMapped } from '../../models/log.model';

@Injectable()
export class ChatBotLogService {

    constructor(private logService: LogService) {
    }


    get dataCollectionName() {
        return environment.MB_COLLECTIONS.CHATBOT_ASESOR
    }

    async guardarLog(datosAdicionales: IDatosAdicionales) {
        try {
                const datosAcionalesValueLog: ILogMapped = {
                    ...datosAdicionales,
                    tipoSolicitud: "CHATBOT_SURAASESOR",
                    lineaNegocio: "-",
                    repo: this.dataCollectionName,
                }
                await this.logService.mapInputMapLog(datosAcionalesValueLog);
        } catch (error) {
            console.log(error);
        }
    }
}