import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { ExecutiveService } from '../../../services/executive/executive.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    hideSearchClientInput = false;
    showSearchClientPortfolio = false;

    @Input() isCollapsed = false;

    @Output() menuClick = new EventEmitter<void>();

    constructor(private router: Router, private executiveService: ExecutiveService) {}

    ngOnInit() {
        this.hideSearchClientInput = window.location.pathname === '/main/client-search' || this.executiveService.executiveModel.executiveProfile.ownCustomers;

        this.showSearchClientPortfolio = this.executiveService.executiveModel.executiveProfile.ownCustomers;

        this.router.events.subscribe((e) => {
            if (e instanceof ResolveEnd) {
                this.hideSearchClientInput = e.url === '/main/client-search' || this.executiveService.executiveModel.executiveProfile.ownCustomers;
            }
        });
    }
}
