import { Component, Input } from '@angular/core';
import { PollComponent } from '../poll/poll.component';
import { DialogService } from '../../services/dialog/dialog.service';
import { Platform } from '@angular/cdk/platform';
import { InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import * as moment from 'moment';
import { ConfigIndexLogStash } from '../../models';
import { TracesService } from 'src/app/core/services/traces/traces.service';

@Component({
  selector: 'app-poll-button',
  templateUrl: './poll-button.component.html',
  styleUrls: ['./poll-button.component.scss'],
})
export class PollButtonComponent {
  @Input() title: string;

  private dniExecutive = JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut;
  private device = window.innerWidth > 760 ? 'DESKTOP' : 'MOBILE';
  private panelClassChoose = 'modal-encuesta';

  constructor(
    private dialogService: DialogService,
    private platform: Platform,
    private tracesService: TracesService,
  ) { }

  onOpenPoll() {
    const logtemp: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status"> = {
      Categoria: "Resumen Cliente",
      Operacion: "Resumen Cliente",
      Metodo: "onOpenPoll()",
      eventoLog: "click boton Encuesta",
      status: Status.OK,
      Secuencia: 0,
      EntradaLog: {
        accion: 'click boton Encuesta',
      },
    };
    this.logData(
      logtemp,
      "log_negocio",
    );
    const url = `https://encuestas.sura.cl/index.php/539445?newtest=Y&lang=es&rut=${this.dniExecutive}&urlorigen=/home&dispositivo=${this.device}`;
    const config = {
      panelClass: this.panelClassChoose,
      disableClose: true,
      data: {
        url,
      },
    };
    this.dialogService.openDialog(PollComponent, config);
  }

  async logData(log: Partial<InputLog> & Pick<InputLog, "Categoria" | "Metodo" | "Operacion" | "status">, indexLogStash: ConfigIndexLogStash) {
    try {
      const { EntradaLog, ...logData } = log;

      let inputLog: InputLog = new InputLog();
      inputLog.DetalleAccionExtra = {};
      inputLog.Categoria = log.Categoria;
      inputLog.Operacion = log.Operacion;
      inputLog.EntradaLog = {
        rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
        fechaHoraOp: moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
        ...EntradaLog,
      };
      inputLog.Salida = "-";
      inputLog.Secuencia = 0;
      inputLog.Servicio = "";
      inputLog.Tipo_sesion = "ASESOR";
      inputLog.extra = {};

      inputLog = {
        ...inputLog,
        ...logData,
      };
      await this.tracesService.registerLog(inputLog, "NWSA_ASESOR", "", "", "", indexLogStash);
    } catch (error) {
      console.error("error: ", error);
    }
  }
}
