import { Component, OnInit } from '@angular/core';
import { environment } from '@environment';
import * as moment from 'moment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { ResponseInvestorProfile } from 'src/app/features/models/client-summary/change-profile.model';
import { AliasInputLogType, InputLog } from 'src/app/features/models/log/InputLog';
import { ConfigIndexLogStash } from 'src/app/features/models/log/models';
import { Status } from 'src/app/features/models/log/status';
import * as utils from 'src/app/shared/helpers/utils';
import { ContentManagerService } from 'src/app/shared/services/content-manager/content-manager.service';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
@Component({
    selector: 'app-change-profile',
    templateUrl: './change-profile.component.html',
    styleUrls: ['./change-profile.component.scss'],
})
export class ChangeProfileComponent implements OnInit {
    visibledOptions: boolean = false;
    visibledChangeInit: boolean = true;
    itemsGroupSelect: any[] = [];
    resultQuestion: any;
    resultQuestionProfile: any;
    itemResponse: object = {};
    name: string;
    rut: string;
    perfil: any;
    alertStatus: boolean = true;
    activeEventResume: boolean = false;
    iconCard: string = 'assets/img/icono-informe-inversiones.svg';
    resultQuestionsProfile: Array<ResponseInvestorProfile>;
    responseFile: any;
    dataFileInfo: any;
    selectItem: any;
    asesor: any;
    fechalastUpdate: Date = new Date();
    constructor(
        private loadingService: LoadingService, 
        private contentManagerService: ContentManagerService,
        private tracesService: TracesService,
        ) {}

    async ngOnInit() {
        let data = sessionStorage.getItem('clientData');
        if (data) {
            const dataClient = JSON.parse(data);
            this.name = dataClient.name;
            this.rut = dataClient.rut.split('.').join('');
            this.perfil = [];
            this.fechalastUpdate = new Date(dataClient.profile.date);
            this.resultQuestion = {
                rut: this.rut,
                respuestas: this.itemsGroupSelect,
            };
        }
        let logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: 'Perfil Inversionista',
            Operacion: 'Perfil Inversionista/Seccion perfil de inversionista',
            Metodo: 'ngOnInit()',
            eventoLog: `Carga inicial`,
            status: Status.OK,
            Secuencia: 0,
        };
        this.logData(logtemp, 'log_negocio');
    }

    changeDisabled(status: boolean) {
        this.visibledChangeInit = status;
        this.visibledOptions = false;
        this.itemsGroupSelect = [];
    }

    optionSelect(item: any) {
        this.selectItem = item;

        if (this.itemsGroupSelect.length === 0) {
            this.itemsGroupSelect.push(item);
        } else {
            let indice = this.itemsGroupSelect.findIndex((data) => data.numeroInternoPregunta === item.numeroInternoPregunta);
            if (indice === -1) {
                this.itemsGroupSelect.push(item);
            } else {
                this.itemsGroupSelect[indice].identificadorAlternativa = item.identificadorAlternativa;
            }
        }
        this.itemResponse = item;
        this.alertStatus = false;
    }

    alertEvent(event) {
        this.alertStatus = event;
    }

    responseFileData(event) {
        this.responseFile = event;
    }

    async move(step: string) {
        switch (step) {
            case 'next':
                this.logData({
                    Categoria: 'Perfil Inversionista',
                    Operacion: 'Perfil Inversionista/Actualizar Perfil Paso 1',
                    Metodo: 'move(step: string)',
                    eventoLog: `Click en botón Empezar`,
                    status: Status.OK,
                    Secuencia: 1,
                }, 'log_negocio');
                this.visibledChangeInit = false;
                this.visibledOptions = true;
                this.alertStatus = false;
                this.resultQuestionProfile = this.resultQuestion;
                break;
            case 'resume':
                this.logData({
                    Categoria: 'Perfil Inversionista',
                    Operacion: 'Perfil Inversionista/Actualizar Perfil Paso 2',
                    Metodo: 'move(step: string)',
                    eventoLog: `Click en botón Continuar`,
                    status: Status.OK,
                    Secuencia: 5,
                }, 'log_negocio');
                this.visibledChangeInit = false;
                this.visibledOptions = false;
                this.alertStatus = false;
                this.activeEventResume = true;
                this.iconCard = 'assets/img/icono-check.svg';
                const result = this.resultQuestion;
                result.respuestas.forEach((element) => {
                    delete element.page;
                });
                this.loadingService.showLoading();
                this.resultQuestionProfile = this.resultQuestion;
                const contentInfo = this.responseFile.info;
                const authorizationDocument = this.responseFile.data.split(',');
                this.responseFile.data = `${authorizationDocument[0]},${authorizationDocument[1]}`;
                this.responseFile.info = contentInfo;
                let templog: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'> = {
                    Categoria: 'Perfil Inversionista',
                    Operacion: 'Perfil Inversionista/Actualizar Perfil Paso 2',
                    Metodo: 'async move(step: string)',
                    eventoLog: `Flujo subida de archivo de autorización SUBIDA`,
                    status: Status.OK,
                    Secuencia: 6,
                };
                try {
                    await this.contentManagerService.postContentManager(this.responseFile);
                    this.logData(templog, 'log_negocio');
                } catch (error) {
                    templog.status = Status.NOK;
                    templog.EntradaLog = {
                        ...templog.EntradaLog,
                        error,
                    }
                    this.logData(templog, 'log_error');
                }
                break;
            default:
                break;
        }
    }

    async logData(log: Partial<InputLog> & Pick<InputLog, 'Categoria' | 'Metodo' | 'Operacion' | 'status'>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;
            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...EntradaLog,
            };
            inputLog.Salida = '-';
            inputLog.Tipo_sesion = 'ASESOR';
            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, 'NWSA_ASESOR', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }
}
