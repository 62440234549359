import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'mmb-th',
  templateUrl: './mmb-th.component.html',
  styleUrls: ['./mmb-th.component.scss'],
})
export class MmbThComponent {

  @Input() show ?;
  @Input() tipo;
  @Input() margen;
  @Input() title;
  @Input() maxWidth: number;

  @HostBinding('style.max-width.px') get HeadingWidth() {
    return this.maxWidth;
  }

  @HostBinding('style.flex') get textColor() {​​
    return this.maxWidth > 1 ? '100%' : '';
 }​​

  @HostBinding('class.cont-desktop') get HeadingClass() {
    return this.show === 'desktop';
  }

  @HostBinding('class.cont-input') get Input() {
    return this.tipo === 'input';
  }

  @HostBinding('class.texto') get texto() {
    return this.tipo === 'texto';
  }

  @HostBinding('class.monto') get monto() {
    return this.tipo === 'monto';
  }

  @HostBinding("class.fondo") get fondo() {
    return this.tipo === "fondo";
  }

}
