import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment";
import { RequestHandlerService } from "src/app/core/services/request-handler/request-handler.service";

@Injectable()
export class CMSService {
    constructor(private requestHandler: RequestHandlerService) { }


    public async getPopupAdministrableGenerico() {
        try {
            if (!sessionStorage.getItem("alertas")) {
                const endpoint = '/getPopupAdministrableGenerico';
                let env = environment?.name;
                env = env === 'PREPROD' ? 'PRE' : env;
                const alertas =  await this.requestHandler.doPost<any>(`${environment.ENDPOINTS.BFF.BFF_CMS}${endpoint}`, { env, esAsesor: true } );
                sessionStorage.setItem("alertas", JSON.stringify(alertas));
                return alertas;
            } else {
                return JSON.parse(sessionStorage.getItem("alertas"));
            }
        } catch (error) {
            console.info(error);
            throw error;
        }
    }
}
