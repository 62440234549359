// Angular Core
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ClientRequestService } from '../../../../services/client-request/client-request.service';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { RequestSaveLogModel, ValueModel } from '../../../../../shared/models';
@Component({
    selector: 'app-cliente-fallecido',
    templateUrl: './cliente-fallecido.component.html',
    styleUrls: ['./cliente-fallecido.component.scss'],
})

export class ClienteFallecidoMessageComponent implements OnInit, OnDestroy {
    @Input() clientData: '';
    mostrarMensajeClienteFallecido = true;
    public ClientRequestService: ClientRequestService;
    private unsubscribe$ = new Subject<void>();
    fechaDefuncion: string = '';
    fechaDefuncionFormateada: string = '';
    tipoBloqueo: string = null;
    rutCliente: string = '';
    clienteEstado: string = '';
    tracesService: any;
    executiveService: any;

    constructor(
        private clientRequestService: ClientRequestService,
    ) { }

    async ngOnInit() {
        this.clientRequestService.clientAccionInversion.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((data) => {
            this.initVariables();
            try {
                const tieneFicha = data?.poseeFichacliente?.tieneFicha;
                if (tieneFicha) {
                    const { IdTipoBloqueo, FechaDefuncion, DocumentoIdentidad, Estado } = data?.poseeFichacliente?.dataOptimusNueva?.SocioNegocio[0];
                    this.fechaDefuncion = moment(FechaDefuncion).format("DD/MM/YYYY");
                    this.tipoBloqueo = IdTipoBloqueo;
                    this.clienteEstado = Estado;
                    this.rutCliente = DocumentoIdentidad;
                    this.verificaEstadoBloqueo();
                }
            } catch (error) {
                this.saveLogClienteFallecidoError("Cliente Fallecido - ngOnInit()", error);
                console.error("Error al Verificar Estado del Bloqueo: ", error);
            }
        });
    }

    initVariables() {
        this.fechaDefuncion = '';
        this.tipoBloqueo = '';
        this.clienteEstado = '';
        this.rutCliente = '';
        this.mostrarMensajeClienteFallecido = false;
    }

    async verificaEstadoBloqueo() {
        try {
            if (this.clienteEstado==='BLOQUEADO' && this.tipoBloqueo === 'BMUERT') {
                if (!this.fechaDefuncion) {
                    this.fechaDefuncion = '"Sin información"';
                }
                this.mostrarMensajeClienteFallecido = true;
            } else {
                this.mostrarMensajeClienteFallecido = false;
                this.fechaDefuncion = null;
            }
        } catch (error) {
            this.saveLogClienteFallecidoError("Cliente Fallecido - verificaEstadoBloqueo()", error);
            console.error("Error al Verificar Estado del Bloqueo: ", error);
        }
    }

    async saveLogClienteFallecidoError(codigo, mensaje) {
        const dataContentful = this.executiveService.executiveModel;
        const logData = {} as RequestSaveLogModel;
        logData.indexLogStash = 'log_error';
        logData.field = 'weblog';
        logData.service = 'Asesor';
        logData.value = {} as ValueModel;
        logData.value.Tipo_sesion = 'ASESOR'
        logData.value.channel = 'NWSA_ASESOR'
        logData.value.user = dataContentful.executiveRut;
        logData.value.EventoLog = 'No se puede ejecutar el servicio';
        const entradaLog = {
            rut: this.rutCliente,
            TipoMensaje: "ERROR",
            Codigo: codigo,
            Mensaje: mensaje
        };
        logData.value.EntradaLog = JSON.stringify(entradaLog);
        await this.tracesService.saveLog(logData);

    }

    async ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
    }
}
