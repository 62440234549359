import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { AliasInputLogType, InputLog } from 'src/app/features/models/log/InputLog';
import { Status } from 'src/app/features/models/log/status';
import { ClientRequestService, ClientService } from 'src/app/features/services';
import { OrdenesCorredoraService } from 'src/app/features/services/ordenes/corredora/ordenes.corredora.service';
import { OrdenesDivisasService } from 'src/app/features/services/ordenes/divisas/ordenes.divisas.service';
import { OrdenesPershingService } from 'src/app/features/services/ordenes/pershing/ordenes.pershing.service';
import { ConfigIndexLogStash } from 'src/app/shared/models';
import { ContentfulService } from 'src/app/shared/services';
import { ExecutiveService } from '../../../../../shared/services/executive/executive.service';
import { LinkOrderEntry } from './order-entry.model';
import { SessionStorageService } from 'src/app/features/services/session-storage/session-storage.service';

@Component({
    selector: 'app-order-entry',
    templateUrl: './order-entry.component.html',
    styleUrls: ['./order-entry.component.scss'],
})
export class OrderEntryComponent {
    @Input() products: any | null;
    @Input() configLinks: any | null;
    
    existPershing: any | null;
    buttonList: LinkOrderEntry[] = [];
    listButtonTemp: LinkOrderEntry[] = [];
    showSection: boolean = false;
    isExistCorredora: any = true;
    countButton: number;
    widthButton: number;

    cssButtonCont: any;

    dataClient$: Observable<any>;
    private readonly unsubscribe$ = new Subject<void>();

    constructor(
        private executiveService: ExecutiveService,
        private contentfulService: ContentfulService,
        private router: Router,
        private pershingService: OrdenesPershingService,
        private corredoraService: OrdenesCorredoraService,
        private divisasService: OrdenesDivisasService,
        private activateRouter: ActivatedRoute,
        private tracesService: TracesService,
        private clientService: ClientService,
        private clientRequestService: ClientRequestService,
        private sessionStorageService: SessionStorageService
    ) {}

    async ngOnInit() {
        this.dataClient$ = this.clientService.getDataClient();
        this.dataClient$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
            this.products = data.products;
            if (this.products && this.products.length > 0) {
                this.isExistCorredora = this.products.find((product) => product.displayName.toUpperCase() === 'CORREDORA' && product.exists === true);
                this.getLinks();
                this.getStatusButton(this.buttonList);
            }
        });
        this.clientRequestService.clientBalances.pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
            this.existPershing = false;
            if (data) {
                for (let prod of data) {
                    if (prod.codigoCb == 'APV_PERSHING' || prod.codigoCb == 'NOAPV_PERSHING') {
                        this.existPershing = true;
                    }
                }
            }
            this.getLinks();
            this.getStatusButton(this.buttonList);
        });
    }

    getLinks() {
        this.buttonList = [];
        this.showSection = this.executiveService.executiveModel.executiveProfile.allowTransaction;
        if (!this.isExistCorredora && !this.existPershing) {
            this.showSection = false;
        }
        if (this.showSection) {
            if (this.configLinks) {
                this.configLinks.links.list?.forEach((configLink) => {
                    let link = Object.create(configLink);
                    if (!this.existPershing && link.enabled && link.type === 'pershing') {
                        link.enabled = false;
                    } else if (link.enabled && link.type === 'pershing') link.enabled = true;
                    if (!this.isExistCorredora && link.enabled && link.type === 'corredora') {
                        link.enabled = false;
                    } else if (link.enabled && link.type === 'corredora') link.enabled = true;

                    link.name = link.name.toUpperCase();
                    this.buttonList.push(link) ;
                });
            }
        }
    }

    getStatusButton(buttonList: any) {
        this.listButtonTemp = [];
        buttonList.forEach((button: any) => {
            if (button.enabled) {
                this.listButtonTemp.push(button);
            }
        });
        this.countButton = this.listButtonTemp.length;
        if (this.countButton === 1) {
            this.cssButtonCont = 'block cont-1';
        }

        if (this.countButton === 2) {
            this.cssButtonCont = 'cont-2';
        }

        if (this.countButton === 3) {
            this.cssButtonCont = 'cont-3';
        }

        const statusFull: any = this.listButtonTemp.find((button) => button.enabled === true);
        if (!statusFull) {
            this.showSection = false;
        }
    }

    /**
     * Redirecciona a funcionalidades respectiva
     * @param link
     */
    linkAction(link: LinkOrderEntry) {
        const logtemp: Partial<InputLog> & Pick<InputLog, AliasInputLogType> = {
            Categoria: 'Resumen Cliente',
            Operacion: 'Resumen Cliente',
            Metodo: 'linkAction()',
            eventoLog: 'click boton Inversion Extranjera',
            status: Status.OK,
            Secuencia: 0,
            EntradaLog: {
                accion: 'click boton Inversion Extranjera redireccionar',
            },
        };
        switch (link.name) {
            case 'Inversión Extranjera':
                logtemp.eventoLog = 'click boton Inversion Extranjera';
                logtemp.EntradaLog.accion = 'click boton Inversion Extranjera redireccionar';
                break;
            case 'Corredora':
                logtemp.eventoLog = 'click boton Corredora';
                logtemp.EntradaLog.accion = 'click boton Corredora redireccionar';
                break;
            case 'Compra y venta divisas':
                logtemp.eventoLog = 'click compra y venta divisas';
                logtemp.EntradaLog.accion = 'click compra y venta divisas redireccionar';
                break;
        }

        this.logData(logtemp, 'log_negocio');
        if (link.id === 'foreignInvestment') {
            //ELIMINA LA SOLICITUD Y ARCHIVO
            sessionStorage.removeItem(this.pershingService.STORAGE_PERSHING_CARRO_FINAL);
            sessionStorage.removeItem(this.pershingService.STORAGE_PERSHING_COMPRA_CARRO);
            sessionStorage.removeItem(this.pershingService.STORAGE_PERSHING_VENTA_CARRO);
            this.pershingService.archivobase64 = null;
            sessionStorage.removeItem(this.pershingService.STORAGE_PERSHING_SESSION);

            this.router.navigate(['pershing-selector-cuenta'], {
                relativeTo: this.activateRouter,
            });
        }
        if (link.id === 'brokerOrders') {
            //ELIMINA LA SOLICITUD Y ARCHIVO
            sessionStorage.removeItem(this.corredoraService.STORAGE_CORREDORA_CARRO_FINAL);
            sessionStorage.removeItem(this.corredoraService.STORAGE_CORREDORA_COMPRA_VENTA_CARRO);
            this.sessionStorageService.remove(this.corredoraService.STORAGE_CORREDORA_ARCHIVO_CONTENT_MANAGER);
            sessionStorage.removeItem(this.corredoraService.STORAGE_CORREDORA_SESSION);

            this.router.navigate(['corredora-orden-compra-venta'], {
                relativeTo: this.activateRouter,
            });
        }
        if (link.id === 'foreignExchange') {
            //ELIMINA LA SOLICITUD Y ARCHIVO
            sessionStorage.removeItem(this.divisasService.STORAGE_DIVISAS_CARRO_FINAL);
            sessionStorage.removeItem(this.divisasService.STORAGE_DIVISAS_COMPRA_VENTA_CARRO);
            sessionStorage.removeItem(this.divisasService.STORAGE_DIVISAS_ARCHIVO_CONTENT_MANAGER);
            sessionStorage.removeItem(this.divisasService.STORAGE_DIVISAS_SESSION);

            this.router.navigate(['divisas-orden-compra-venta'], {
                relativeTo: this.activateRouter,
            });
        }
    }

    async logData(log: Partial<InputLog> & Pick<InputLog, AliasInputLogType>, indexLogStash: ConfigIndexLogStash) {
        try {
            const { EntradaLog, ...logData } = log;

            let inputLog: InputLog = new InputLog();
            inputLog.DetalleAccionExtra = {};
            inputLog.Categoria = log.Categoria;
            inputLog.Operacion = log.Operacion;
            inputLog.EntradaLog = {
                rut: JSON.parse(sessionStorage.getItem('dataContentful')).executiveRut,
                fechaHoraOp: moment(new Date()).format('DD/MM/YYYY HH:mm:ss'),
                ...EntradaLog,
            };
            inputLog.Salida = '-';
            inputLog.Secuencia = 0;
            inputLog.Servicio = '';
            inputLog.Tipo_sesion = 'ASESOR';
            inputLog.extra = {};

            inputLog = {
                ...inputLog,
                ...logData,
            };
            await this.tracesService.registerLog(inputLog, 'NWSA_ASESOR', '', '', '', indexLogStash);
        } catch (error) {
            console.error('error: ', error);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
    }
}
