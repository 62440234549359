import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class SessionStorageService {

    item:any = {}

    set(index, value){
        this.item[index] = value;
    }

    remove(index){
        this.item[index] = null;
    }

    get(index){
        return this.item[index];
    }
}