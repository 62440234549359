// Angular Core
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Angular CDK
import { CdkStepper } from '@angular/cdk/stepper';
@Component({
    selector: 'app-custom-stepper',
    templateUrl: './custom-stepper.component.html',
    styleUrls: ['./custom-stepper.component.scss'],

    providers: [{ provide: CdkStepper, useExisting: CustomStepperComponent }],
})
export class CustomStepperComponent extends CdkStepper {
    @Output() disabled = new EventEmitter<boolean>();
    @Output() pressContinueReturn = new EventEmitter<boolean>();
    @Output() alertMessageReturn = new EventEmitter<boolean>();
    @Output() resumeActive = new EventEmitter<string>();
    @Output() page = new EventEmitter<number>();
    @Output() responseSimulation = new EventEmitter<boolean>();

    @Input() pressContinue: boolean = false;
    @Input() alertStatus: boolean;
    @Input() itemsGroupSelect: any;
    @Input() responseFileData: any;
    @Input() messageAlertFile: boolean;
    @Output() newItemEvent = new EventEmitter<string>();
    pagina: number = 1;
    returnButtom: boolean = false;
    continueButtom: boolean;
    nextButtom: boolean;
    message: string;
    
    return() {
        this.disabled.emit(true);
    }

    ngOnInit() {
        this.page.emit(this.selectedIndex);
    }

    checkOptionSelected(page) {
        const statusPage = this.itemsGroupSelect.find((x) => x.page === page);
        if (statusPage === undefined) {
            return false;
        } else {
            return true;
        }
    }

    next() {
        if(this.selectedIndex === 10){
            this.responseSimulation.emit(true);
        }

        this.pressContinue = this.checkOptionSelected(this.selectedIndex + 1);
        if (!this.pressContinue) {
            this.alertMessageReturn.emit(true);
            this.message = 'Debes contestar la pregunta para poder continuar.';
            this.pressContinueReturn.emit(false);
        } else {
            this.page.emit(this.selectedIndex + 1);
            this.selectedIndex = this.selectedIndex + 1;
            this.pressContinueReturn.emit(true);
        }
    }

    previous() {
        this.selectedIndex = this.selectedIndex - 1;
        this.alertMessageReturn.emit(false);
        this.message = '';
        if(this.messageAlertFile || this.messageAlertFile === undefined){
            this.alertStatus = false;
        }
    }

    resumePerfil() {
        if(!this.messageAlertFile){
        this.alertStatus = true;
        this.message = 'Se debe adjuntar un documento para poder guardar el perfil.';
        }else{
            this.alertStatus = this.messageAlertFile;
            this.alertStatus = true;
            this.resumeActive.emit('resume');
        }
    }

    errorValue() {
        this.alertMessageReturn.emit(true);
        this.message = 'Debes contestar la pregunta para poder continuar.';
    }
}
