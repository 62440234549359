import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/services';
import { XAuthService } from 'src/app/shared/services/xauth/xauth.service';


@Component({
  selector: 'app-visualizador-clientes',
  templateUrl: './visualizador-clientes.component.html',
  styleUrls: ['./visualizador-clientes.component.scss']
})
export class VisualizadorClientesComponent implements OnInit {
  urlAuth;
  datosCliente;
  clienteProfile;
  xToken;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private xAuthService: XAuthService,
    public dialogRef: MatDialogRef<VisualizadorClientesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public domSanitizer: DomSanitizer,
    private dialogService: DialogService) {
    this.urlAuth = this.domSanitizer.bypassSecurityTrustResourceUrl(data?.urlAuth);
    this.dialogRef.addPanelClass('open-web-client');
    this.datosCliente = data?.datosCliente;
    this.clienteProfile = data?.clienteProfile;
    this.xToken = data?.xtoken;
    this.cerrarCession();
    }

  ngOnInit(): void {    
  }

  cerrarCession(){
    this.dialogService.closeDialog$.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      if(data?.cerrarDialogo){
        this.xAuthService.borrarXToken(this.xToken);
        this.dialogRef.close();
      }
    })
  }

}
