import { Injectable } from '@angular/core';
import { RequestHandlerService } from '../../../core/services/request-handler/request-handler.service';
import { environment } from '@environment';
import { ContentManagerRequest, EmailErrorContentManager } from '../../models/content-manager.model';

@Injectable({
    providedIn: 'root',
})
export class ContentManagerService {
    constructor(private requestHandler: RequestHandlerService) { }

    public async postContentManager(body: ContentManagerRequest) {
        try {
            const endpoint = '/upload-file';
            return await this.requestHandler.doPost<any>(`${environment.ENDPOINTS.BFF.BFF_USER}${endpoint}`, body);
        } catch (error) {
            console.info(error);
            throw error;
        }
    }
}
