import { Pipe, PipeTransform } from '@angular/core';

//TODO: Debe eliminarse
@Pipe({
  name: 'dynamicPipe',
})
export class DynamicPipe implements PipeTransform {
  transform(value: any, pipeFunc: (value, ...params) => any): any {
    if (!pipeFunc && typeof pipeFunc !== 'function') {
      return value;
    }
    return pipeFunc(value);
  }
}
