import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatBotConstant } from '../../../constants/chat-bot.constant';
import { ChatBotService } from '../../../services';

@Component({
  selector: 'app-terminos-servicio',
  templateUrl: './terminos-servicio.component.html',
  styleUrls: ['./terminos-servicio.component.scss']
})
export class TerminosServicioComponent implements OnInit {

  terminosYCondiciones: string;

  constructor(
      public dialogRef: MatDialogRef<TerminosServicioComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private chatBotService: ChatBotService,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.terminosYCondiciones = this.chatBotService.terminosCondicionesChatBot;
  }

}
