import { Component, Input } from '@angular/core';
import { CoreStatusService } from '../../../../../../app/core/services/coreStatus.services';

@Component({
    selector: 'app-total-balances',
    templateUrl: './total-balances.component.html',
    styleUrls: ['./total-balances.component.scss'],
})
export class TotalBalancesComponent {
    @Input() totalBalance: number | null;
    @Input() apvAmount: number | null;
    @Input() noApvAmount: number | null;
    public AFPCoreIsAvailable: boolean = true;

    constructor(public coreStatusService: CoreStatusService){}
}
