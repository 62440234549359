// Angular Core
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import localES from '@angular/common/locales/es-CL';
import { CdkScrollableModule } from '@angular/cdk/scrolling';

// Components
import { AppComponent } from './app.component';
import { PrivateContainerComponent } from './private-container/private-container.component';
import { PublicContainerComponent } from './public-container/public-container.component';

// Rounting
import { AppRoutingModule } from './app-routing.module';

// Services
import { AppServices } from './features/services';
import { InterceptorService } from './core/services/interceptor/interceptor.service';
import { CMSService, DocumentsService, ExcelService, LocalStorageService, PopupService } from './shared/services';

// Modules
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './shared/modules/layout/layout.module';
import { MaterialModule } from './shared/modules/material.module';

// NG Recaptcha
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha';

// Environment
import { environment } from '@environment';

// NGX Mask
import { NgxMaskModule } from 'ngx-mask';

// NG Idle
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AssetAllocationComponent } from './features/components/client-summary/components/asset-allocation/asset-allocation.component';
import { IndicadorClienteService } from './shared/services/indicadores/indicador-cliente.service';
import { ConfirmaModalComponentPershing } from './features/components/asesor/pershing/orden-carro/components/confirma-modal/confirma-modal.component';
import { ConfirmaModalComponentCorredora } from './features/components/asesor/corredora/orden-carro/components/confirma-modal/confirma-modal.component';
import { ConfirmaModalComponentDivisas } from './features/components/asesor/divisas/orden-carro/components/confirma-modal/confirma-modal.component';
import { OrdenCarroModulePershing } from './features/components/asesor/pershing/orden-carro/orden-carro.module';
import { OrdenCarroModuleCorredora } from './features/components/asesor/corredora/orden-carro/orden-carro.module';
import { OrdenCarroModuleDivisas } from './features/components/asesor/divisas/orden-carro/orden-carro.module';
import { DialogMessageComponent } from './shared/components/dialog-message/dialog-message.component';
import { ClientSummaryModule} from "./features/components/client-summary/client-summary.module";
import { OrdenCambioFondoCompraModule } from "./features/components/asesor/pershing/orden-cambio-fondo-compra/orden-cambio-fondo-compra.module";
import { ModalFiltroInstrumentosComponent } from "./features/components/asesor/pershing/orden-cambio-fondo-compra/components/modal-filtro-instrumentos/modal-filtro-instrumentos.component";
import {ConfirmModalComponent} from "./shared/components/confirm-modal/confirm-modal.component";
import {PollComponent} from "./shared/components/poll/poll.component";
import { ComplianceModule } from './features/components/compliance/compliance.module';
import { MultipleSesionComponent } from './shared/components/multiple-sesion/multiple-sesion.component';
import { XAuthService } from './shared/services/xauth/xauth.service';
import { SessionStorageService } from './features/services/session-storage/session-storage.service';

registerLocaleData(localES);

@NgModule({
    declarations: [AppComponent, PrivateContainerComponent, PublicContainerComponent],
    entryComponents: [ConfirmaModalComponentPershing, ConfirmaModalComponentCorredora, ConfirmaModalComponentDivisas, DialogMessageComponent, ModalFiltroInstrumentosComponent, ConfirmModalComponent, PollComponent, MultipleSesionComponent],
    imports: [
        SharedModule,
        LayoutModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        RecaptchaModule,
        RecaptchaV3Module,
        BrowserAnimationsModule,
        CommonModule,
        MaterialModule,
        NgxMaskModule.forRoot(),
        CdkScrollableModule,
        NgIdleKeepaliveModule.forRoot(),
        OrdenCarroModulePershing,
        OrdenCarroModuleCorredora,
        OrdenCarroModuleDivisas,
        ClientSummaryModule,
        OrdenCambioFondoCompraModule,
        ComplianceModule
    ],
    providers: [
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.KEYS.GOOGLE.RECAPTCHA_V3_SITE_KEY,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
        },
        {
            provide: 'googleTagManagerId',
            useValue: environment.TAG_MANAGER_PROJECT_ID,
        },
        { provide: LOCALE_ID, useValue: 'es-cl' },
        AppServices,
        LocalStorageService,
        AssetAllocationComponent,
        ExcelService,
        DocumentsService,
        PopupService,
        CMSService,
        XAuthService,
        SessionStorageService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
    constructor(
        /** Se agrega aquí para que se inicialice */
        _IndicadorClienteService: IndicadorClienteService
    ) {}
}
