import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import * as rutHelpers from 'rut-helpers';

@Directive({
  selector: '[appFormatRut]',
})
export class RutDirective {
  @Output() onenter: EventEmitter<string> = new EventEmitter<string>();

  @HostListener('keyup', ['$event']) onBlur(ev: Event) {
    const htmlInputElement: HTMLInputElement = ev.target as HTMLInputElement;
    if (htmlInputElement.value.trim() === '0') {
      htmlInputElement.value = '';
    }

    if (htmlInputElement.value.trim().charAt(0) === '0') {
      htmlInputElement.value = htmlInputElement.value.substr(1);
    }
    htmlInputElement.value = rutHelpers.rutFormat(htmlInputElement.value) || '';
    htmlInputElement.dispatchEvent(new Event('input'));
  }

  @HostListener('keydown.enter', ['$event']) onKeydownHandler(_: KeyboardEvent) {
    this.onenter.emit('enter');
  }
}
