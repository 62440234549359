import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { TracesService } from 'src/app/core/services/traces/traces.service';
import { CorredoraClienteSession, SolicitudCorredoraJson, SolicitudLogAccionErrorJson, SolicitudLogAccionEventoJson, SolicitudLogAccionJson, UploadContentManagerJSON } from 'src/app/features/models/ordenes/corredora/orden.modelo';
import { CategoriasContratoLog } from 'src/app/features/models/ordenes/log.contrato.categoria';
import { OperacionesContratoLog } from 'src/app/features/models/ordenes/log.contrato.operaciones';
import { SaveLogModel, ValueModel } from 'src/app/shared/models';

@Injectable({
    providedIn: 'root',
})
export class OrdenesCorredoraService {
    //CONSTANTES
    public STORAGE_CORREDORA_COMPRA_VENTA_CARRO: string = 'corredoraCompraVentaCarro';
    public STORAGE_CORREDORA_CARRO_FINAL: string = 'corredoraCarroFinal';
    public STORAGE_CORREDORA_SESSION: string = 'corredoraClienteSession';
    public STORAGE_CORREDORA_ARCHIVO_CONTENT_MANAGER: string = 'corrdoraArchivoCM';

    private URL_BFF_ORDENES = environment.ENDPOINTS.BFF.BFF_ORDENES;

    constructor(
        private readonly http: HttpClient,
        private tracesService: TracesService) {}


   /**
     * Notificar correo electronico
     * @param request prametros
     * @returns
     */
    public PostSolicitudNotificarCorreo(solicitud: SolicitudCorredoraJson): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.URL_BFF_ORDENES}/v1/corredora/solicitud/notificar/correo`, solicitud)
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Consulta el Custodio de compra
     * @param rut rut
     * @param verificador digito verificador
     * @returns
     */
    public async getCustodioCompra(rut: string, fecha: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_ORDENES}/v1/corredora/cuenta-inversion/custodio/compra`, {
                    params: {
                        rut: rut,
                        fecha: fecha,
                    },
                })
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

        /**
     * Consulta el Custodio de compra
     * @param rut rut
     * @param verificador digito verificador
     * @returns
     */
         public async getCustodioCompraFX(rut: string, fecha: string): Promise<any> {
            return new Promise((resolve, reject) => {
                this.http
                    .get(`${this.URL_BFF_ORDENES}/v1/divisas/cuenta-inversion/custodio/compra`, {
                        params: {
                            rut: rut,
                            fecha: fecha,
                        },
                    })
                    .toPromise()
                    .then((data) => {
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }


    /**
     * Consulta el Custodio de compra
     * @param rut rut
     * @param verificador digito verificador
     * @returns
     */
    public async getCustodioVenta(rut: string, fecha: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_ORDENES}/v1/corredora/cuenta-inversion/custodio/venta`, {
                    params: {
                        rut: rut,
                        fecha: fecha,
                    },
                })
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

     /**
     * Recupera Instrumentos de compra
     * @param request prametros
     * @returns
     */
      public getInstrumentos(mercado: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .get(`${this.URL_BFF_ORDENES}/v1/corredora/cuenta-inversion/instrumentos`, {
                    params: {
                      mercado: mercado,
                    },
                })
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

      /**
     * Crear log de accion
     * @param request prametros
     * @returns
     */
       public PostSolicitudLogAccion(logaccion: SolicitudLogAccionJson): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.URL_BFF_ORDENES}/v1/corredora/solicitud/log/accion`, logaccion)
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Crear log de accion evento
     * @param request prametros
     * @returns
     */
    public PutSolicitudLogAccionEvento(logaccion: SolicitudLogAccionEventoJson): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .put(`${this.URL_BFF_ORDENES}/v1/corredora/solicitud/log/accion/evento`, logaccion)
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Crear log de accion evento
     * @param request prametros
     * @returns
     */
    public PutSolicitudLogAccionError(logerror: SolicitudLogAccionErrorJson): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .put(`${this.URL_BFF_ORDENES}/v1/corredora/solicitud/log/accion/error`, logerror)
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Grabar la transaccion en reporte de LOG Transaccional
     * @param request prametros
     * @returns
     */
    public PostSolicitudLogTransaccion(solicitud: SolicitudCorredoraJson): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(`${this.URL_BFF_ORDENES}/v1/corredora/solicitud/log/transaccion`, solicitud)
                .toPromise()
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

        /**
     * Grabar el archivo en el content manager
     * @param request prametros
     * @returns
     */
         public PostUploadContentManager(archivo: UploadContentManagerJSON): Promise<any> {
           return new Promise((resolve, reject) => {
               this.http
                   .post(`${this.URL_BFF_ORDENES}/v1/corredora/upload/contentmanager`, archivo)
                   .toPromise()
                   .then((data) => {
                       resolve(data);
                   })
                   .catch((error) => {
                       reject(error);
                   });
           });
       }

    
    /**
     * Escribe Log
     * @param categoria 
     * @param operacion 
     * @param metodo 
     * @param errok 
     * @param mensajeaccion 
     * @param objjson 
     * @param objjsonsalida 
     */
      async saveLogAccionAsesor(categoria: CategoriasContratoLog, operacion: OperacionesContratoLog, metodo: string, errok: string, mensajeaccion: string, objjson?: any, istransaccionnegocio?: boolean) {
        let idSession = sessionStorage.getItem('idSesion');
        let ejecutivoJson = sessionStorage.getItem('dataContentful');
        let ejecutivo = JSON.parse(ejecutivoJson);
        let data = sessionStorage.getItem('clientData');
        let nombreCliente: string = '';
        let rutcliente: string = '';
        if (data) {
            const dataClient = JSON.parse(data);
            nombreCliente = dataClient.name;
            rutcliente = dataClient.rut.split('.').join('');
        }
     
        //recupera el id de session para 
        let sessionJson = sessionStorage.getItem(this.STORAGE_CORREDORA_SESSION);
        let session = this.crearIdSessionLogAccionesAntiguaWeb(rutcliente, idSession, sessionJson);


         //Registra LOG de Nueva WEB
        const logData = {} as SaveLogModel;
  
        logData.service = 'Asesor';
        logData.field = 'weblog';
        logData.value = {} as ValueModel;
        logData.value.SesionCliente = idSession; 
        logData.value.Categoria = categoria; //contratoCategoria
        logData.value.Operacion = operacion; //contratoOperaciones
        logData.value.Metodo = metodo;
        logData.value.rutCliente = rutcliente;
        logData.value.rutEjecutivo = ''; //EJECUTIVO ASIGHNADO A LA CARTERA DEL CLIENTE
        logData.value.rutAsesor = ejecutivo.executiveRut;
        logData.value.nombreCliente = nombreCliente;
        logData.value.Status = errok.toUpperCase() != 'OK' ? 'NOK' : 'OK'; 
  
        logData.value.EntradaLog = {
            isExecutive: true,
            accion: mensajeaccion,
            json: objjson,
        };

        //REGISTRO TRANSACCIONAL
        if (istransaccionnegocio) {
            logData.indexLogStash = 'log_transaccion';
            logData.service = 'TRXA';
        }
        //ERROR
        if (logData.value.Status === 'NOK') {
            logData.indexLogStash = 'log_error';
            logData.service = 'ERR';
        }

        await this.tracesService.saveLog(logData);

        //===========================
        //registra LOG WEB ANtigua
        //===========================
        if (session && !istransaccionnegocio) {
            await this.PutSolicitudLogAccionEvento({
                'id-log-accion': session['id-log-accion'],
                'ip-remota':     "127.0.0.1",
                paso:            1,
                glosa:           mensajeaccion, //'Paso 1 - Selecciona Cuenta',
                servicio:        operacion, //'DetalleCuentaSeleccionarComponent',
                metodo:          metodo, //'ngOnInit()',
                entrada:         (objjson && objjson !== undefined && objjson !== null ? JSON.stringify(objjson) : ''),
                salida:          '',
                status:          errok
            });
        }

    }

  

    /**
     * Crea Log de Accion Antigua Web
     * @param rutcliente 
     * @param idSession 
     * @param sessionJson 
     * @returns 
     */
    crearIdSessionLogAccionesAntiguaWeb(rutcliente, idSession, sessionJson) {
        let crearsession: boolean = false;
        
        
        let session: CorredoraClienteSession = JSON.parse(sessionJson) || undefined;
        if (session === undefined ) {
            session = {
            "id-log-accion": null,
            idsession: idSession,
            }
            crearsession = true;
        } else {
            if (session.idsession !== idSession ) {
                crearsession = true;
            }
        }
        //CREA ID LOG EN CASO DE NO EXISTIR
        if (crearsession) {
            let logaccionsave: SolicitudLogAccionJson = {
                "rut-cliente":    Number(rutcliente.split('-')[0]),
                "dv-rut-cliente": rutcliente.split('-')[1],
                "ip-remota":      "127.0.0.1",
                "os-tipo":        this.getOS(),
                "os-browser":     this.getBrowserName()
            };
            this.PostSolicitudLogAccion(logaccionsave).then((logdata) => {
                if (logdata.codigo === 1) {
                    session['id-log-accion'] = logdata['id-log-accion'];
                    session.idsession = idSession;
                    sessionStorage.setItem(this.STORAGE_CORREDORA_SESSION, JSON.stringify(session));
                } else {
                    session = undefined;
            }

            }).catch((errorlog) => {
                session = undefined;
        });
        }
        return session;
    }



    private getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
          case agent.indexOf('edge') > -1:
            return 'edge';
          case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
          case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
          case agent.indexOf('trident') > -1:
            return 'ie';
          case agent.indexOf('firefox') > -1:
            return 'firefox';
          case agent.indexOf('safari') > -1:
            return 'safari';
          default:
            return 'other';
        }
    }

    private getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        }
      
        return os;
      }
   
}
