import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, Self, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

export type LabelPosition = 'fixed' | 'default';

@Component({
    selector: 'mmb-dropdown-autocomplete',
    templateUrl: './mmb-dropdown-autocomplete.component.html',
    styleUrls: ['./mmb-dropdown-autocomplete.component.scss'],
})
export class MmbDropdownAutocompleteComponent implements ControlValueAccessor, OnInit, OnChanges {
    @Input() label = '';
    @Input() items: string[];
    @Input() reset: boolean;
    @Input() value = '';
    convalor: boolean;

    @Input() labelPosition: LabelPosition = 'default';
    @Output() itemSeleccionado: EventEmitter<any> = new EventEmitter();
   
    @ViewChild('comboDiv', { static: true }) comboDiv: ElementRef;
    @ViewChild('autoComplete') arrowDown: ElementRef<HTMLElement>;

    itemsFiltered = [];
    isFocus = false;
    sIndex = 0;
    countClick = 0;
    teclas = '';

    @HostBinding('class.label-fixed')
    get isFixedLabel() {
        return this.labelPosition === 'fixed';
    }


    constructor(@Self() public ngControl: NgControl, private cd: ChangeDetectorRef) {
        if (this.ngControl) {
            ngControl.valueAccessor = this;
        }
    }

    writeValue(valuex: any): void {
        this.value = valuex;
        this.teclas = valuex;
        this.onChange(valuex);
        this.cd.markForCheck();
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    onChange = (valor: string) => {
        this.value = valor;
    };
    onTouched = () => {
        if (this.value) {
            this.convalor = true;
        }
    };
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.reset && changes.reset.currentValue) {
            this.itemsFiltered = this.items;
        }
    }

    ngOnInit() {
        this.itemsFiltered = this.items;
    }

    ionViewWillEnter() {
        this.itemsFiltered = this.items;
    }

    resetFiltered() {
        this.itemsFiltered = this.items;
    }

    changePlacehoder() {
        if (this.labelPosition !== 'default') {
            return 'Escriba para comenzar a buscar';
        }

        return this.isFocus ? 'Escriba para comenzar a buscar' : '';
    }

    onInput(event) {
        this.teclas = event.target.value;
        this.sIndex = 0;
        const str = this.teclas;
        this.itemsFiltered = this.items.filter((ee) => {
            return ee.toLowerCase().includes(str.toLowerCase());
        });
     }

    changeInput(e) {
        if (e.which === 38 || e.which === 40 || e.which === 13) {
            const combo = this.comboDiv.nativeElement;
            if (e.which === 38) {
                this.sIndex = this.sIndex === 0 ? 0 : this.sIndex - 1;
                if (this.sIndex < 5) {
                    combo.scrollTop = 0;
                } else {
                    combo.scrollTop = combo.scrollTop - 39;
                }
            } else if (e.which === 40) {
                const nFil = this.itemsFiltered.length;
                this.sIndex = this.sIndex === nFil - 1 ? nFil - 1 : this.sIndex + 1;
                if (this.sIndex > 4) {
                    combo.scrollTop = combo.scrollTop + 39;
                }
            } else {
                this.teclas = this.itemsFiltered[this.sIndex];
                combo.scrollTop = 0;
            }
        }
    }

    selectItem(item) {
        this.onTouched();
        this.writeValue(item);
        this.teclas = this.value;
        this.itemSeleccionado.emit(this.value);
        this.resetFiltered();
    }

    focusIn() {
        if(!this.teclas){
            this.resetFiltered();
        }
        this.comboDiv.nativeElement.scrollTop = 0;
        this.isFocus = true;
        this.sIndex = 0;
    }

    focusOut() {
        setTimeout(() => {
            this.isFocus = false;
            this.comboDiv.nativeElement.scrollTop = 0;
        }, 300);
    }

    removeItem() {
        this.onTouched();
        this.writeValue('');
        this.teclas = this.value;
        this.itemSeleccionado.emit('');
        this.resetFiltered();
    }

    simulateInputOnFocus() {
        if (this.countClick === 0 || !this.isFocus) {
            let el: HTMLElement = this.arrowDown.nativeElement;
            el.focus();
        }
        this.countClick++;
    }

    getValidationCss() {
        if (!this.ngControl) return {};

        return {
            //'ng-invalid': this.ngControl.invalid,
            //'is-invalid': this.ngControl.invalid && this.ngControl.touched,
            //'ng-valid': this.ngControl.valid,
            'ng-touched': this.ngControl.touched,
            'ng-untouched': this.ngControl.untouched,
            //'ng-pristine': this.ngControl.pristine,
            'ng-dirty': this.ngControl.dirty,
        };
    }
}
