import { Log } from "./log";
import { ConfigIndexLogStash } from "./models";

export class RegisterLog {
  data: Log;

  channel: string;

  service: string;

  field: string;

  indexLogStash?: ConfigIndexLogStash;
}
