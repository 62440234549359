import { Component, Input } from '@angular/core';

@Component({
  selector: 'mmb-table',
  templateUrl: './mmb-table.component.html',
  styleUrls: ['./mmb-table.component.scss'],
})
export class MmbTableComponent {
  @Input() border ?: boolean = true;
}
