import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MovementDetail,
  TransactionsCategories,
  TransactionTableData,
} from '../../../../models/client-summary/transactions.model';
import { ColumnDefinition } from '@surainvestments/sura-common-ui/app/sura-ui-kit/models/table.model';

@Component({
  selector: 'app-movements-detail-dialog',
  templateUrl: './movements-detail-dialog.component.html',
  styleUrls: ['./movements-detail-dialog.component.scss'],
})
export class MovementsDetailDialogComponent implements OnInit {
  transaction: TransactionsCategories;
  movementsDetails: MovementDetail[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: MovementsDetailDialogData) {}

  ngOnInit(): void {
    this.transaction = this.data.data;
    this.movementsDetails = this.data.data.movementDetails;
  }
}

export interface MovementsDetailDialogData {
  data: TransactionTableData;
  transactionDefinition: ColumnDefinition<TransactionsCategories>[];
}
